import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Table, Space } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet as HelmetAsync } from "react-helmet-async";
import styled from "styled-components";
import { useIntl } from "react-intl";
import {
  useAppDispatch,
  useAppSelector
} from "../../redux/store";
import {
  actionInteractionSelector,
  searchFieldValueSelector
} from "../../redux/selectors";
import {
  getActionInteraction
} from "../../redux/actions";
import { formatDate } from "../../utils/utilities";
import Tag from "../../components/Tag";

const DeclinedAction = ({ className }) => {
  const intl = useIntl();
  const baseClassName = clsx("applicationAction", className);
  const [allInteractions, setAllInteractions] = useState([]);
  const dispatch = useAppDispatch();
  const actionInteraction = useAppSelector(actionInteractionSelector);
  const searchFieldValue = useAppSelector(searchFieldValueSelector);
  const location = useLocation();
  const navigate = useNavigate();

  const getAllInteractions = () => {
    if (actionInteraction) {
      const fetchSecuredUrls = async () => {
        const updatedInteractions = await Promise.all(
          actionInteraction?.map(async (item) => {
            return { ...item };
          })
        );
        setAllInteractions(updatedInteractions);
      };
      fetchSecuredUrls();
    }
  }

  const checkLocation = location.pathname === '/impact-maker/applications/declined'

  useEffect(() => {
    const verifyLocation = location.pathname === '/impact-maker/applications/declined';
    if (verifyLocation) {
      setTimeout(() => {
        dispatch(getActionInteraction({
          sortBy: "programName",
          asc: "true",
          filterBy: {
            status: [
              "Declined"
            ]
          },
          limit: "100"
        }));
      })
    }
  }, [checkLocation]);

  useEffect(() => {
    if (searchFieldValue) {
      const filterTable = allInteractions?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(searchFieldValue.toLowerCase())));
      const val = filterTable?.length ? filterTable : null;
      setAllInteractions(val);
    } else {
      getAllInteractions();
    }
  }, [searchFieldValue]);

  useEffect(() => {
    if (actionInteraction?.length) {

      getAllInteractions();
    }
  }, [actionInteraction]);

  const getTableData = (list) => {
    if (list?.length > 0) {
      return list?.map((item, idx) => {
        const {
          programName,
          charityName,
          opportunityName,
          updateDate,
          status,
          userID,
          companyName,
          programID,
          opportunityID
        } = item || {};
        return {
          key: idx,
          programName,
          charityName,
          opportunityName,
          updateDate: formatDate(updateDate),
          status,
          userID,
          companyName,
          programID,
          opportunityID
        };
      });
    }
    return null;
  }

  const handleSort = (sortId) => {
    if (['programName', 'charityName', 'companyName', 'opportunityName', 'updateDate'].includes(sortId.key)) {
      dispatch(getActionInteraction({
        sortBy: sortId?.key,
        asc: "true",
        filterBy: {
          status: [
            "Declined"
          ]
        }
      }));
    }
  }

  const columns = [
    {
      title: intl.formatMessage({ id: "programme" }),
      dataIndex: 'programName',
      key: 'programName',
      render: (_, { programName }) => (
        <div className="nameContainer">
          <div className="blockWithText">
            {programName}
          </div>
        </div>
      ),
      onHeaderCell: (column) => {
        return {
          onClick: () => handleSort(column),
        };
      },
      sorter: () => { },
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: "entity" }),
      dataIndex: 'companyName',
      key: 'companyName',
      onHeaderCell: (column) => {
        return {
          onClick: () => handleSort(column),
        };
      },
      sorter: () => { },
    },
    {
      title: intl.formatMessage({ id: "opportunity" }),
      dataIndex: 'opportunityName',
      key: 'opportunityName',
      onHeaderCell: (column) => {
        return {
          onClick: () => handleSort(column),
        };
      },
      sorter: () => { },
    },
    {
      title: intl.formatMessage({ id: "date_declined" }),
      dataIndex: 'updateDate',
      key: 'updateDate',
      onHeaderCell: (column) => {
        return {
          onClick: () => handleSort(column),
        };
      },
      sorter: () => { },
    },
    {
      title: intl.formatMessage({ id: "status" }),
      key: 'status',
      dataIndex: 'status',
      render: (_, { status }) => {
        return (<Tag key={status} type='invited'>
          {status?.toUpperCase()}
        </Tag>)
      }
      ,
    },
    {
      title: intl.formatMessage({ id: "actions" }),
      key: 'actions',
      dataIndex: 'status',
      render: (_, { programID, opportunityID }) => (
        <Space size="middle">
          <button className='button' onClick={() => navigate(`/impact-maker/applications/${programID}/${opportunityID}`)
          }>{intl.formatMessage({ id: "review" })}</button>
        </Space>
      ),
      fixed: 'right',
    },
  ];

  const isValid = Array.isArray(allInteractions) && allInteractions?.length === 0

  return (
    <div className={baseClassName}>
      <HelmetAsync>
        <title>Applications Page</title>
      </HelmetAsync>
      <div className='subContentContainer'>
        <Table columns={columns} loading={isValid} dataSource={getTableData(allInteractions)} />
      </div>
    </div>
  );
};

const StyledDeclinedAction = styled(DeclinedAction)`
  &.applicationAction {
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    .nameContainer{
      display: flex;
      gap: 10px;
    }
    .ant-tabs-tab-active {
      .title {
        color: ${(props) => props.theme.bizGiveThemeColor} !important;
      }
    }
    .ant-tabs-ink-bar {
      background: ${(props) => props.theme.bizGiveThemeColor} !important;
    }
    .button{
        border: 0;
        background: transparent;
        color: #5550F7;
        cursor: pointer;
        min-width: 100px;
    }
  }
`;

export default StyledDeclinedAction;
