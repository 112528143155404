import React, { useEffect } from 'react';
import { Helmet as HelmetAsync } from "react-helmet-async";
import clsx from "clsx";
import { Avatar, Divider } from 'antd';
import { useIntl } from 'react-intl';
import { getProfileDataCompany } from '../../../redux/actions';
import styled from "styled-components";
import LinkComponent from "../../../components/LinkComponent";
import LinkedinIcon from "../../../assets/linkedin.png";
import InstaGramIcon from "../../../assets/instagram.png";
import TwitterIcon from "../../../assets/twitter.png";
import FacebookIcon from "../../../assets/facebook.png";
import {
    useAppSelector,
    useAppDispatch
} from "../../../redux/store";
import {
    programDataSelector,
    profileSelector,
} from "../../../redux/selectors";
import StyledText from "../../../components/StyledText";

const ProgramReviewHomePage = ({ className }) => {
    const baseClassName = clsx("dashboardPage", className);
    const programData = useAppSelector(programDataSelector);
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const profile = useAppSelector(profileSelector) || {};

    const {
        companyID
    } = programData || {};

    const { description, registeredAddress, socialMedia, webURI } = profile?.company || {}

    useEffect(() => {
        if (companyID) {
            dispatch(getProfileDataCompany(`companyID=${companyID}`))
        }
    }, [companyID])

    return (
        <div className={baseClassName}>
            <HelmetAsync>
                <title>Programme Review Page</title>
            </HelmetAsync>
            <div className='contentContainer'>
                <StyledText as="p" variant="H2">
                    {intl.formatMessage({ id: "description" })}
                </StyledText>
                <StyledText as="p" variant="B3">
                    {description}
                </StyledText>
                <Divider />
                <StyledText as="p" variant="H2">
                    {intl.formatMessage({ id: "detail" })}
                </StyledText>
                <StyledText as="p" variant="B3d">
                    {intl.formatMessage({ id: "registered_address" })}
                </StyledText>
                <StyledText as="p" variant="B3">
                    {registeredAddress?.addressLine1}
                </StyledText>
                <div className='subContentContainer'>
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "media" })}
                    </StyledText>
                    <div className='linkContainer'>
                        <Avatar src={LinkedinIcon} />
                        <LinkComponent href={`http://${socialMedia?.linkedInURL}`}>
                            <StyledText as="p" variant="B3b">
                                {socialMedia?.linkedInURL}
                            </StyledText>
                        </LinkComponent>
                    </div>
                    <div className='linkContainer'>
                        <Avatar src={FacebookIcon} />
                        <LinkComponent href={`http://${socialMedia?.facebookURL}`}>
                            <StyledText as="p" variant="B3b">
                                {socialMedia?.facebookURL}
                            </StyledText>
                        </LinkComponent>
                    </div>
                    <div className='linkContainer'>
                        <Avatar src={InstaGramIcon} />
                        <LinkComponent href={`http://${socialMedia?.instagramURL}`}>
                            <StyledText as="p" variant="B3b">
                                {socialMedia?.instagramURL}
                            </StyledText>
                        </LinkComponent>
                    </div>
                    <div className='linkContainer'>
                        <Avatar src={TwitterIcon} />
                        <LinkComponent href={`http://${socialMedia?.xURL}`}>
                            <StyledText as="p" variant="B3b">
                                {socialMedia?.xURL || socialMedia?.twitterURL}
                            </StyledText>
                        </LinkComponent>
                    </div>
                </div>
                <StyledText as="p" variant="B3d">
                    {intl.formatMessage({ id: "website" })}
                </StyledText>
                <StyledText as="a" variant="B3b">
                    {webURI}
                </StyledText>
            </div>
        </div>
    )
}

const StyledDashboardPage = styled(ProgramReviewHomePage)`
    &.dashboardPage {
        background: ${(props) => props.theme.backgroundCanvas};
        min-height: 100vh;
        display: flex;
        gap: 20px;
        .contentContainer{
        margin-block: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .titleContainer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .editButton{
                padding-inline: 50px;
            }
            .logoContentContainer{
            display: flex;
            align-items: center;
            .logoContainer{
                height: 100px;
                width: 100px;
                img{
                    width: 100%;
                }
               
            }
            .companyName{
                    margin-left: 20px;
                }
        }
        }
        .subContentContainer{
            display: flex;
        flex-direction: column;
        gap: 10px;
        .linkContainer{
        display: flex;
        align-items: center;
        gap: 20px;
       }
        }
       
    }
    }
`;


export default StyledDashboardPage;
