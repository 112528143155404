import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Avatar, Collapse, Spin, Divider } from "antd";
import * as constants from "../../../constants";
import clsx from "clsx";
import { useIntl } from "react-intl";
import styled from "styled-components";
import {
    CheckOutlined,
} from "@ant-design/icons";
import { getSecuredUrl, getLocalStorageItem } from "../../../utils/utilities";
import { getProgramById, getUserImage, getOpportunity } from '../../../redux/actions';
import CharityIndicatorsPreview from "../CharityIndicatorPreview";
import StyledText from "../../../components/StyledText";
import MediaCarousel from "../../../components/MediaCarousel";
import {
    programDataSelector,
    opportunityDataSelector,
    toggleSpinnerSelector
} from "../../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../../redux/store";
import HorizontalGraph from "../../../components/HorizontalGraph";
import file from "../../../assets/file.svg";
import grant from "../../../assets/grant.svg";
import volunteer from "../../../assets/volunteer.svg";
import inkind from "../../../assets/inkind.svg";
import goal1 from "../../../assets/goal1.png";
import goal2 from "../../../assets/goal2.png";
import goal3 from "../../../assets/goal3.png";
import goal4 from "../../../assets/goal4.png";
import goal5 from "../../../assets/goal5.png";
import goal6 from "../../../assets/goal6.png";
import goal7 from "../../../assets/goal7.png";
import goal8 from "../../../assets/goal8.png";
import goal9 from "../../../assets/goal9.png";
import goal10 from "../../../assets/goal10.png";
import goal11 from "../../../assets/goal11.png";
import goal12 from "../../../assets/goal12.png";
import goal13 from "../../../assets/goal13.png";
import goal14 from "../../../assets/goal14.png";
import goal15 from "../../../assets/goal15.png";
import goal16 from "../../../assets/goal16.png";
import goal17 from "../../../assets/goal17.png";
import goalInverted1 from "../../../assets/goalInverted1.png";
import goalInverted2 from "../../../assets/goalInverted2.png";
import goalInverted3 from "../../../assets/goalInverted3.png";
import goalInverted4 from "../../../assets/goalInverted4.png";
import goalInverted5 from "../../../assets/goalInverted5.png";
import goalInverted6 from "../../../assets/goalInverted6.png";
import goalInverted7 from "../../../assets/goalInverted7.png";
import goalInverted8 from "../../../assets/goalInverted8.png";
import goalInverted9 from "../../../assets/goalInverted9.png";
import goalInverted10 from "../../../assets/goalInverted10.png";
import goalInverted11 from "../../../assets/goalInverted11.png";
import goalInverted12 from "../../../assets/goalInverted12.png";
import goalInverted13 from "../../../assets/goalInverted13.png";
import goalInverted14 from "../../../assets/goalInverted14.png";
import goalInverted15 from "../../../assets/goalInverted15.png";
import goalInverted16 from "../../../assets/goalInverted16.png";
import goalInverted17 from "../../../assets/goalInverted17.png";
import outcomeIcon from "../../../assets/outcomeIcon.svg";
import environmentIcon from "../../../assets/environmentIcon.svg";
import socialIcon from "../../../assets/socialIcon.svg";

const goals = [{
    key: 1,
    image: goalInverted1,
    selected: goal1
},
{
    key: 2,
    image: goalInverted2,
    selected: goal2
},
{
    key: 3,
    image: goalInverted3,
    selected: goal3
},
{
    key: 4,
    image: goalInverted4,
    selected: goal4
},
{
    key: 5,
    image: goalInverted5,
    selected: goal5
},
{
    key: 6,
    image: goalInverted6,
    selected: goal6
},
{
    key: 7,
    image: goalInverted7,
    selected: goal7
},
{
    key: 8,
    image: goalInverted8,
    selected: goal8
},
{
    key: 9,
    image: goalInverted9,
    selected: goal9
},
{
    key: 10,
    image: goalInverted10,
    selected: goal10
},
{
    key: 11,
    image: goalInverted11,
    selected: goal11
},
{
    key: 12,
    image: goalInverted12,
    selected: goal12
},
{
    key: 13,
    image: goalInverted13,
    selected: goal13
},
{
    key: 14,
    image: goalInverted14,
    selected: goal14
},
{
    key: 15,
    image: goalInverted15,
    selected: goal15
},
{
    key: 16,
    image: goalInverted16,
    selected: goal16
},
{
    key: 17,
    image: goalInverted17,
    selected: goal17
}
]

const onDownloadFile = async (data) => {
    const { name, key = '' } = data || {};

    const getSecuredURL = await getSecuredUrl(key, name);
    const securedURL = URL.createObjectURL(getSecuredURL);
    const fileLink = document.createElement('a');
    fileLink.href = securedURL;
    fileLink.download = name || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(securedURL);
            fileLink.removeEventListener('click', clickHandler);
        }, 150);
    };

    fileLink.addEventListener('click', clickHandler, false);
    fileLink.click();
    return fileLink;
};

const getUniqueSortedOutcomeUNSDG = (data, isProgramCustom) => {
    const outcomes = data?.objectives?.flatMap(objective =>
        isProgramCustom ?
            objective?.objectivesOutcome?.flatMap(outcome => outcome?.outcomeUNSDG) :
            objective?.objectiveUNSDG
    ) || [];

    const uniqueSortedOutcomes = Array.from(new Set(outcomes)).sort((a, b) => a - b);

    return uniqueSortedOutcomes.map(el => {
        const selectedOutcomes = goals.find(gl => gl.key === el);
        return {
            key: el,
            image: selectedOutcomes?.image,
            selected: selectedOutcomes?.selected,
        };
    });
};


const ProgramReview = ({ className }) => {
    const baseClassName = clsx("programReview", className);
    const intl = useIntl();
    const [selectedUnsdgs, setSelectedUnsdgs] = useState([]);
    const [mediaImages, setMediaImages] = useState([]);
    const [selectedName, setSelectedName] = useState('');
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const [expandAll, setExpandAll] = useState(false);
    const programReviewDetails = JSON.parse(getLocalStorageItem('programReviewDetails') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const opportunityData = useAppSelector(opportunityDataSelector) || {}
    const dispatch = useAppDispatch();
    const programData = useAppSelector(programDataSelector);

    const {
        impactStrategy,
        currencySymbol,
        cashGiving,
        BizGiveUser,
        outcomeMethod
    } = programData || {};

    const { opportunity:
        { opportunitySummary,
            opportunityObjectives,
            totalCashRequested,
            wholeProjectFunding,
            grantBudgetPercentage = 0,
            donationUse,
            additionalFundsSource,
            fundingSecured,
            oppFullBudgetNotRequired,
            volunteerUse,
            inKindUse,
            expectedOutput,
            opportunityBeneficiaries,
            environmentalMeasure,
            media,
            impactApproach: { objectives } = {},
            customQuestionAnswer,
            document,
            directBeneficiaries,
            indirectBeneficiaries,
        } = {} }
        = opportunityData || {};

    useEffect(() => {
        const fetchMediaImages = async () => {
            try {
                if (media?.length > 0 && !mediaImages?.length) {
                    const promises = media.map(async (file) => {
                        const securedURI = await getSecuredUrl(file.key);
                        const securedURL = URL.createObjectURL(securedURI);
                        return { url: securedURL, name: file.name, mediaDescription: file.mediaDescription };
                    });
                    const resolvedMediaImages = await Promise.all(promises);
                    const validMediaImages = resolvedMediaImages.filter(Boolean);
                    setMediaImages(validMediaImages);
                }
            } catch (error) {
                console.error('Error fetching media images:', error);
            }
        };

        fetchMediaImages();
    }, [media]);

    const isProgramCustom = outcomeMethod === "Programme Custom";

    const goals = getUniqueSortedOutcomeUNSDG(impactStrategy, isProgramCustom);

    useEffect(() => {
        if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [opportunityId, programID]);

    const extractAndGroupObjectivesOutcome = data => {
        return data?.map(({ name: title, objectivesOutcome, objectiveUNSDG }, index) => ({
            key: index,
            label: <StyledText as="p" variant="B3d">{title}</StyledText>,
            children: objectivesOutcome?.length > 0 && objectivesOutcome?.map(({ name, outcomeDescription, outcomeApproach, outcomeUNSDG }) => {
                return <button key={outcomeDescription} className={`collapseChildContainer ${selectedName === name ? 'selectedName' : ''}`} onClick={() => {
                    setSelectedUnsdgs([
                        ...(objectiveUNSDG ?? []),
                        ...(outcomeUNSDG ?? [])
                    ]); setSelectedName(name)
                }}><StyledText as="p" variant="B3d">{name}</StyledText><StyledText as="p" variant="B3">{outcomeDescription || outcomeApproach}</StyledText></button>
            })
        }));
    }

    const impactStrategyOutcomes = extractAndGroupObjectivesOutcome(objectives);

    useEffect(() => {
        if (BizGiveUser?.length > 0) {
            BizGiveUser.forEach((user, index) => {
                setTimeout(() => {
                    dispatch(getUserImage(user));
                }, 1000 * index);
            });
        }
    }, [BizGiveUser, dispatch]);

    useEffect(() => {
        if (programID) {
            dispatch(getProgramById(programID));
        }
    }, []);

    return (
        <div className={baseClassName}>
            {(!programData || showSpinner) && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={!programData || showSpinner} />
                </div>
            )}
            <div className="leftSubContainer">
                <div className="subContainer">
                    <StyledText as="p" variant="H2">
                        {intl.formatMessage({ id: "summary_title" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {opportunitySummary}
                    </StyledText>
                </div>
                <div className="subContainer">
                    <StyledText as="p" variant="H2">
                        {intl.formatMessage({ id: "description" })}
                    </StyledText>
                    <span className="descriptionContainer">{ReactHtmlParser(ReactHtmlParser(opportunityObjectives))}</span>
                </div>
                {impactStrategyOutcomes?.length > 0 && <div className="subContainer">
                    <StyledText as="p" variant="H2">
                        {intl.formatMessage({ id: "impact_alignment" })}
                    </StyledText>
                    {isProgramCustom && <button className="expandAll" onClick={() => setExpandAll(prev => !prev)}>
                        {intl.formatMessage({ id: "expand_all" })}
                    </button>}
                    <div className="unsdgPartitionContainer">
                        <div className="unsdgsContainer">
                            <button className="showAllGoals" onClick={() => { setSelectedUnsdgs([]); setSelectedName('') }}><div><CheckOutlined /></div><StyledText as="p" variant="B3">{intl.formatMessage({ id: "show_all_goals" })}</StyledText></button>{goals.map((el) => <Avatar key={el.image} src={selectedUnsdgs?.includes(el.key) ? el.selected : el.image} shape="square" className="unsdgs" />)}
                        </div>
                        <div className="collapseContainer">
                            {<Collapse items={impactStrategyOutcomes} activeKey={expandAll ? impactStrategyOutcomes.map(({ key }) => key) : undefined} expandIconPosition="end" />}
                        </div>
                    </div>
                </div>}
                <StyledText as="p" variant="H2">
                    {intl.formatMessage({ id: "requested_support" })}
                </StyledText>
                <div className="supportFlexContainer">
                    {cashGiving === 'Y' && <div className="supportContainer grantContainer">
                        <div className="titleContainer">
                            <div><Avatar src={grant} /></div>
                            <div>
                                <StyledText as="p" variant="H3">
                                    {intl.formatMessage({ id: "grants" })}
                                </StyledText>
                            </div>
                        </div>
                        <div className="flexBox">
                            <div className="flexBoxChild">
                                <div className="flexColumn">
                                    <StyledText as="p" variant="B3" className="noBreak">
                                        {intl.formatMessage({ id: "total_request" })}:
                                    </StyledText>
                                    <StyledText as="p" variant="B3c">
                                        {currencySymbol}{totalCashRequested?.trim()}
                                    </StyledText>
                                </div>
                                <div className="flexColumn">
                                    <StyledText as="p" variant="B3" className="noBreak">
                                        {intl.formatMessage({ id: "total_project_budget" })}:
                                    </StyledText>
                                    <StyledText as="p" variant="B3c">
                                        {currencySymbol}{wholeProjectFunding?.trim()}
                                    </StyledText>
                                </div>
                                <div className="flexColumn">
                                    <StyledText as="p" variant="B3" className="noBreak">
                                        {intl.formatMessage({ id: "funder_contribution" })}:
                                    </StyledText>
                                    <StyledText as="p" variant="B3c">
                                        {`${Number(grantBudgetPercentage)?.toFixed(2)}%`}
                                    </StyledText>
                                </div>
                            </div>
                            <Divider type="vertical" className="divider" />
                            <div className="flexBoxChild"><StyledText as="p" variant="B3" >
                                {intl.formatMessage({ id: "planned_expenditure" })}:
                            </StyledText>
                                {donationUse?.map((el) => <StyledText key={el?.numberOfItems} as="p" variant="B3">
                                    <span className="expenditureValues">{`${currencySymbol}${el?.numberOfItems}`}</span>{` provides `} <span className="expenditureValues">{`${el?.monetaryUnit}`}</span> {el?.itemDescription}
                                </StyledText>)}
                            </div>
                        </div>
                        {((additionalFundsSource && Object.keys(additionalFundsSource)?.length > 0) || (Number(grantBudgetPercentage)?.toFixed(2) < 100)) && <div className="additionalFunding">
                            <StyledText as="p" variant="B3d" >
                                {intl.formatMessage({ id: "additional_funding" })}:
                            </StyledText>
                            <StyledText as="p" variant="B3" >
                                <CheckOutlined />{intl.formatMessage({ id: "additional_funding_is_required_to_meet_the_total_opportunity_budget" })}:
                            </StyledText>
                            <div className="flexBox">
                                <Divider type="vertical" className="divider" />
                                <ul><li><StyledText as="p" variant="B3d" >
                                    {intl.formatMessage({ id: "where_will_additional_funds_be_sourced" })}
                                </StyledText>
                                    {additionalFundsSource?.internal && <StyledText as="p" variant="B3" >
                                        {intl.formatMessage({ id: "internal_resources" })}
                                    </StyledText>}
                                    {additionalFundsSource?.external && <StyledText as="p" variant="B3" >
                                        {intl.formatMessage({ id: "external_funding" })}
                                    </StyledText>}
                                </li>
                                    <li><StyledText as="p" variant="B3d" >
                                        {intl.formatMessage({ id: "has_this_funding_been_secured" })}
                                    </StyledText>
                                        <StyledText as="p" variant="B3" >
                                            {fundingSecured === 'N' ? intl.formatMessage({ id: "no" }) : intl.formatMessage({ id: "yes" })}
                                        </StyledText>
                                    </li><li><StyledText as="p" variant="B3d" >
                                        {intl.formatMessage({ id: "can_this_project_go_ahead_without_additional_funding" })}
                                    </StyledText>
                                        <StyledText as="p" variant="B3" >
                                            {oppFullBudgetNotRequired === 'N' ? intl.formatMessage({ id: "no" }) : intl.formatMessage({ id: "yes" })}
                                        </StyledText>
                                    </li>
                                </ul>
                            </div>
                        </div>}
                    </div>}
                    {volunteerUse?.length > 0 && <div className="supportContainer volunteeringContainer">
                        <div className="titleContainer">
                            <div><Avatar src={volunteer} /></div>
                            <div>
                                <StyledText as="p" variant="H3">
                                    {intl.formatMessage({ id: "volunteering" })}
                                </StyledText>
                            </div>
                        </div>
                        <ul>{volunteerUse.map((el) => <li key={el}><StyledText as="span" variant="B3"><span className="outputNumber">{el?.volunteerUnit}</span> for {el?.volunteerValue}</StyledText></li>)}</ul>
                    </div>}
                    {inKindUse?.length > 0 && <div className="supportContainer inKindContainer">
                        <div className="titleContainer">
                            <div><Avatar src={inkind} /></div>
                            <div>
                                <StyledText as="p" variant="H3">
                                    {intl.formatMessage({ id: "inkind" })}
                                </StyledText>
                            </div>
                        </div>
                        <ul>{inKindUse?.map((el) => <li key={el}><span className="outputNumber">{el.inKindOfferingDescription}</span> for {el.inKindActivityDescription}</li>)}</ul>
                    </div>}
                </div>
                <div className="subContainer">
                    <StyledText as="p" variant="H2">
                        {intl.formatMessage({ id: "planned_impact_title" })}
                    </StyledText>
                    {expectedOutput?.length > 0 && <div className="whiteSupportContainer volunteeringContainer">
                        <div className="titleContainer">
                            <div><Avatar src={outcomeIcon} /></div>
                            <div>
                                <StyledText as="p" variant="H3">
                                    {intl.formatMessage({ id: "outputs" })}
                                </StyledText>
                            </div>
                        </div>
                        <ul>{expectedOutput.map((el) => <li key={el?.outputNumber}><StyledText as="span" variant="B3"><span className="outputNumber">{el?.outputNumber}</span> for {el?.outputDescription}</StyledText></li>)}</ul>
                    </div>}
                    <div className="flexBox justifyContent">
                        {Array.isArray(opportunityBeneficiaries) && opportunityBeneficiaries?.length > 0 && <div className="whiteSupportContainer volunteeringContainer">
                            <div className="titleContainer">
                                <div><Avatar src={socialIcon} /></div>
                                <div>
                                    <StyledText as="p" variant="H3">
                                        {intl.formatMessage({ id: "social_beneficiaries" })}
                                    </StyledText>
                                </div>
                            </div>
                            <ul>{opportunityBeneficiaries.map((el) => <li key={el}><StyledText as="span" variant="B3">{el}</StyledText></li>)}</ul>
                            <HorizontalGraph
                                name={intl.formatMessage({
                                    id: 'direct',
                                })}
                                percentage={Number(
                                    directBeneficiaries
                                )}
                                maxValue={Math.max(Number(
                                    directBeneficiaries
                                ), Number(
                                    indirectBeneficiaries
                                ))}
                                background="#56D9FE"
                            />

                            <HorizontalGraph
                                name={intl.formatMessage({
                                    id: 'indirect',
                                })}
                                percentage={Number(
                                    indirectBeneficiaries
                                )}
                                maxValue={Math.max(Number(
                                    directBeneficiaries
                                ), Number(
                                    indirectBeneficiaries
                                ))}
                                background="#A4A1FB"
                            />
                        </div>}
                        {environmentalMeasure?.length > 0 && <div className="whiteSupportContainer volunteeringContainer">
                            <div className="titleContainer">
                                <div><Avatar src={environmentIcon} /></div>
                                <div>
                                    <StyledText as="p" variant="H3">
                                        {intl.formatMessage({ id: "environmental_beneficiaries" })}
                                    </StyledText>
                                </div>
                            </div>
                            <ul>{environmentalMeasure?.map((el) => <li key={el}><StyledText as="span" variant="B3"><span className="outputNumber">{el?.amount}</span>{` ${el?.unit} ${el?.unit === 'Hectares' ? 'of ' : ''}${el?.subCategory} ${el?.category === 'Habitats' ? `${intl.formatMessage({ id: 'habitats' })} ` : ''} ${el?.description}`}</StyledText></li>)}</ul>
                        </div>}
                    </div>
                </div>
                <div className="subContainer">
                    <CharityIndicatorsPreview />
                </div>
                {Array.isArray(media) && media?.length > 0 && <div className="subContainer mediaSection">
                    <StyledText as="p" variant="H2">
                        {intl.formatMessage({ id: "media" })}
                    </StyledText>
                    {mediaImages?.length === media?.length && <MediaCarousel data={mediaImages} />}
                </div>}
                <div className="subContainer">
                    <StyledText as="p" variant="H2">
                        {intl.formatMessage({ id: "additional_questions" })}
                    </StyledText>
                    {customQuestionAnswer?.map((el) => <div key={el?.question} className="whiteSupportContainer">
                        <StyledText as="p" variant="B3d">
                            {el?.question}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {el?.answer}
                        </StyledText>
                    </div>)}
                </div>
                <div className="subContainer">
                    <StyledText as="p" variant="H2">
                        {intl.formatMessage({ id: "required_documents" })}
                    </StyledText>
                    {<div className="fileContainer">
                        {Array.isArray(document) && document?.map((el) => <div key={el.fileKey} onClick={() => onDownloadFile(el)} onKeyDown={() => onDownloadFile(el)} className="fileKey whiteSupportContainer"><StyledText as="p" variant="B3">
                            {el.docName}
                        </StyledText> <Avatar src={file} className="file" onClick={() => onDownloadFile(el)} />  <StyledText className="helperText" as="p" variant="B3">
                                {el.name}
                            </StyledText>
                        </div>)}
                    </div>}
                </div>
            </div>
        </div>
    );
};

const StyledProgramReview = styled(ProgramReview)`
  &.programReview {
    display: flex;
    gap: 24px;
    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.45);
    }
        .spinner {
        z-index: 2000;
        margin: auto;
        left: 50%;
        right: 50%;
        position: absolute;
        bottom: 50%;
        }
            ul {
            margin: 0;
            }

    .leftSubContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
    } 

    .whiteSupportContainer{
        background: white;
        box-shadow: 0px 3px 16px #0000000d;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 16px;
          width: 35vw;
          &.volunteeringContainer {
         box-shadow: 0px 3px 16px #0000000d;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
    }

    .ant-collapse-expand-icon {
      color: #313131 !important;
    }

    .additionalFunding{
        background: white !important;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .mediaSection{
        margin-bottom: 20px;
    }

    .flexBox{
        display: flex;
        align-items: flex-start;
        gap: 20px;
        &.justifyContent{
            justify-content: flex-start;
        }
        .flexBoxChild{
            display: flex;
            flex-direction: column;
            gap: 10px;
            .flexColumn{
                display: flex;
                flex-direction: column;
                .noBreak{
                    white-space: nowrap;
                }
            }
        }
    }

    .divider{
        height: 100%;
    margin-inline: 10px;
    }
    .expenditureValues{
        background: #5DB76A;
        border-radius: 5px;
        padding: 1px 8px;
        border: 1px solid #5DB76A;
    }
    .outputNumber{
        background: #dee3ed;
        border-radius: 5px;
        padding: 1px 8px;
        border: 1px solid grey; 
    }

    .supportFlexContainer {
      display: flex;
      gap: 30px;
      flex-direction: column;

      .supportContainer {
        padding: 16px;
        width: max-content;
        width: 35vw;

        &.grantContainer {
          background: transparent linear-gradient(223deg, #ffffff 0%, #b2f9bd 100%) 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 16px #0000000d;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        &.volunteeringContainer {
          background: transparent linear-gradient(223deg, #ffffff 0%, #8bdaff 100%) 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 16px #0000000d;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        &.inKindContainer {
          background: transparent linear-gradient(223deg, #ffffff 0%, #ffaffa 100%) 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 16px #0000000d;
          border-radius: 10px;
        }

        
      }
    }
    .titleContainer {
          display: flex;
          gap: 2px;
          align-items: center;
        }
    .fileContainer {
      display: flex;
      gap: 20px;
        .fileKey{
            background: white;
            padding: 15px;
            border-radius: 10px;
            box-shadow: 0px 3px 16px #0000000D;
            .ant-avatar-image {
                cursor: pointer;
      }
        }
     
    }
    .fileContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: max-content;
        .fileKey{
            display: flex;
            align-items: center;
            background: white;
            padding: 15px;
            border-radius: 10px;
            box-shadow: 0px 3px 16px #0000000D;
            flex-direction: row;
            justify-content: space-between;
            .ant-avatar-image {
                cursor: pointer;
      }
    }
    }
    .helperText {
    color: #5271FF !important;
  }
    .webLinks {
        background: white;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0px 3px 16px #0000000D;
        display: flex;
        align-items: center;
        width: max-content;

      img {
        transform: scale(0.5);
      }

      p {
        color: #3e526c;
      }
    }

    .mapIFrameStyle {
      border: 0;
    }

    .locationIcon {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .locationContainer {
      .locationDistance {
        font-style: italic;
      }

      svg {
        color: #f96675;
      }
    }

    .administrators {
      height: 50px;
      width: 50px;
    }

    .file {
      height: 70px;
      width: 70px;
    }

    .collapseChildContainer {
      border: 1px solid #d7dde1;
      padding: 12px 16px;
      margin-bottom: 6px;
      cursor: pointer;
      text-align: left;
      background: white;
      font-size: 14px;
      font-weight: ${constants.FONT_WEIGHT_SEMIBOLD};
      color: ${constants.COLOR_GREY_DARK};
      font-family: ${constants.POPPINS_FONT_FAMILY};

      &.selectedName {
        border: 1px solid #04ac9c;
        border-radius: 6px;
        background: #bcfbf5;
      }
    }

    .collapsible {
      display: flex;
      flex-direction: column;

      .collapseChildContainer {
        background: white;
        border: 0;
        border-bottom: 1px solid #d9d9d9;
        padding: 12px 16px;
        text-align: left;

        &.selectedName {
          border: 1px solid #04ac9c;
          background: #bcfbf5;
        }

        cursor: pointer;
      }
    }

    .subContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .descriptionContainer{
        max-width: 70ch;
            word-wrap: break-word;
            overflow-wrap: break-word;
        p, li{
            font-size: 14px;
            font-weight: 500;
            color: #242424;
            font-family: sans-serif;
            max-width: 70ch;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }
      }
    }

    .ant-collapse-expand-icon {
      transform: rotate(90deg) translate(-5px);
      svg{
            fill: #313131 !important;
        }
    }

    .ant-collapse-item-active {
      .ant-collapse-expand-icon {
        transform: rotate(0deg) translate(-5px);
      }
    }

    .ant-timeline-item-head {
      background: transparent !important;
    }

    .anticon-check {
      color: #58b55e;
    }

    .unsdgPartitionContainer {
      display: flex;
      gap: 20px;
      background: white;
    padding: 30px;
    border-radius: 10px;

      .collapseContainer {
        width: 50%;
        button {
            width: 100%;
        }
      }

      .unsdgsContainer {
        display: flex;
        gap: 15px;
        width: 48%;
        flex-wrap: wrap;
        height: max-content;

        .showAllGoals {
          height: 100px;
          width: 100px;
          background: white;
          display: flex;
          flex-direction: column;
          padding: 15px;
          justify-content: center;
          align-items: center;
          text-align: center;
          border: 2px solid #00877a;
          border-radius: 6px;

          p,
          svg {
            color: #00877a !important;
          }
        }

        .unsdgs {
          height: 100px;
          width: 100px;
        }
      }
    }

    .expandAll {
      color: #00877a;
      text-align: end;
      background: transparent;
      border: 0;
      cursor: pointer;
    }

    .ant-collapse{
        border: 0;
        border-bottom: 1px solid #D7DDE1;
        .ant-collapse-header{
        background: white !important;
        }
    }
    .ant-collapse-content-active{
        border-top: 0;
    }
  }
`;

export default StyledProgramReview;