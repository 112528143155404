import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import ReactQuill from 'react-quill';
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
    MinusCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";
import { Divider, Form, Input, Space, Spin, Button as AntdButton } from "antd";
import styled from "styled-components";
import { getLocalStorageItem, numericValidator } from "../../utils/utilities";
import { updateProgram, getOpportunity, getProgramById, toggleSpinner, setUpdateProgram } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import {
    programDataSelector,
    updateProgramSelector,
    toggleSpinnerSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 24 },
};

const CompanyEntities = ({ className }) => {
    const baseClassName = clsx("companyEntities", className);
    const intl = useIntl();
    const [formData, setFormData] = useState({});
    const [savedClicked, setSavedClicked] = useState(false);
    const programData = useAppSelector(programDataSelector) || {};
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const programReviewDetails = JSON.parse(getLocalStorageItem('createdProgramID') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const updateProgramData = useAppSelector(updateProgramSelector) || {};
    const { programID: updateProgramID } = updateProgramData || {};

    useEffect(() => {
        if (updateProgramID && !savedClicked) {
            dispatch(setUpdateProgram(''))
            dispatch(toggleSpinner(false));
            navigate("/company/programme/create/impact");
        }
    }, [updateProgramID])

    useEffect(() => {
        if (programData?.programName) {
            const data = {
                ...(programData?.customQuestion && { customQuestion: programData?.customQuestion }),
                ...(programData?.requiredDocuments && Array.isArray(programData?.requiredDocuments) && {
                    requiredDocuments: programData?.requiredDocuments?.map((el) => ({
                        documents: el
                    }))
                }),
                ...(programData?.privacyPolicyURL && { privacyPolicyURL: programData?.privacyPolicyURL }),
                ...(programData?.applicationAgreement && { applicationAgreement: programData?.applicationAgreement }),
            };
            form.setFieldsValue(data);
            setFormData(data);
        }
    }, [programData]);

    useEffect(() => {
        if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [opportunityId, programID]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
        }
    }, [programID]);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = (e) => {
        const formData = form.getFieldsValue();
        const data = {
            customQuestion: formData?.customQuestion,
            requiredDocuments: formData?.requiredDocuments?.map((el) => el.documents),
            programID,
            privacyPolicyURL: formData?.privacyPolicyURL,
            applicationAgreement: formData?.applicationAgreement
        }
        dispatch(updateProgram(data));
        dispatch(toggleSpinner(true));
    }

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
            navigate(`/company/programmes/review/${programID}`);
        }, 300);
    }

    const handlePrevious = () => {
        navigate("/company/programme/create/entities")
    }

    const onFormChange = (_, allValues) => {
        setFormData(allValues);
    };

    return (
        <div className={baseClassName}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <div className='contentContainer'>
                <div>
                    <StyledText as="p" variant="H1" className='mainTitle'>
                        {intl.formatMessage({ id: "application_questions" })}
                    </StyledText>
                    <Form
                        {...formItemLayout}
                        labelAlign="left"
                        form={form}
                        className="entitiesFormContainer formContainer"
                        onFinish={handleProceed}
                        onFinishFailed={onFinishFailed}
                        scrollToFirstError
                        onValuesChange={onFormChange}
                    >
                        <StyledText as="span" variant="B3d">
                            {intl.formatMessage({ id: "required_documents" })}
                            <StyledText as="span" variant="B3a">
                                {` (${intl.formatMessage({ id: "optional" })})`}
                            </StyledText>
                        </StyledText>
                        <Form.List name="requiredDocuments">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex', marginBottom: 0 }} className='optionsContainer'>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'documents']}
                                                className="formItemInput"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: intl.formatMessage({ id: "enter" }),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={intl.formatMessage({ id: "required_document_name" })}
                                                />
                                            </Form.Item>

                                            <MinusCircleOutlined onClick={() => remove(name)} style={{ color: '#04ac9c' }} className='for' />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <AntdButton htmlType='button' className='addButton' type="dashed" onClick={() => add()} block icon={<PlusOutlined fill="red" />}>
                                            <StyledText as="p" variant="B3">
                                                {intl.formatMessage({ id: "add" })}
                                            </StyledText>
                                        </AntdButton>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <StyledText as="span" variant="B3d">
                            {intl.formatMessage({ id: "do_you_want_to_add_any_custom_application_questions" })}
                            <StyledText as="span" variant="B3a">
                                {` (${intl.formatMessage({ id: "optionalg" })})`}
                            </StyledText>
                        </StyledText>
                        <Form.List name="customQuestion">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex', marginBottom: 0 }} className='optionsContainer'>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'question']}
                                                className="formItemInput questionField"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: intl.formatMessage({ id: "enter" }),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={intl.formatMessage({ id: "question" })}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'questionCharLimit']}
                                                className="formItemInput wordLimitField"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: intl.formatMessage({ id: "enter" }),
                                                    },
                                                    { validator: numericValidator, message: intl.formatMessage({ id: 'please_enter_numeric_value' }) }
                                                ]}
                                            >
                                                <Input
                                                    placeholder={intl.formatMessage({ id: "word_limit" })}
                                                />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} style={{ color: '#04ac9c' }} className='for' />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <AntdButton htmlType='button' className='addButton' type="dashed" onClick={() => add()} block icon={<PlusOutlined fill="red" />}>
                                            <StyledText as="p" variant="B3">
                                                {intl.formatMessage({ id: "add" })}
                                            </StyledText>
                                        </AntdButton>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <StyledText as="span" variant="B3d">
                            {intl.formatMessage({ id: "please_enter_your_application_terms_and_conditions" })}
                            <StyledText as="span" variant="B3a">
                                {` (${intl.formatMessage({ id: "optional" })})`}
                            </StyledText>
                        </StyledText>
                        <Form.Item
                            className="dFlex"
                            name="applicationAgreement"
                        >
                            <ReactQuill theme="snow" placeholder={intl.formatMessage({ id: "enter" })} />
                        </Form.Item>
                        <StyledText as="span" variant="B3d">
                            {intl.formatMessage({ id: "please_enter_a_link_to_your_privacy_policy" })}
                        </StyledText>
                        <StyledText as="span" variant="B3">
                            {intl.formatMessage({ id: "we_ask_all_applicants_to_acknowledge_this_before_submitting_their_application" })}
                        </StyledText>
                        <Form.Item
                            name="privacyPolicyURL"
                            className="formItem"
                            rules={[
                                { required: true, message: intl.formatMessage({ id: "enter" }) },
                            ]}
                        >
                            <Input
                                addonBefore={"https://"}
                                type="text"
                            />
                        </Form.Item>
                        <div className='buttonContainer'>
                            <Divider />
                            <div className='buttonContentContainer'>
                                <div className='nextAndPreviousContainer'>
                                    <Button
                                        variant="secondary"
                                        type="button"
                                        onClick={() => handlePrevious()}>
                                        {intl.formatMessage({ id: "previous" })}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        htmlType="submit"
                                    >
                                        {intl.formatMessage({ id: "next" })}
                                    </Button>
                                </div>
                                <div className="saveAndExitButton">
                                    <Button
                                        variant="secondary"
                                        htmlType="button"
                                        onClick={() => handleSaveAndExit()}>
                                        {intl.formatMessage({ id: "save_n_exit" })}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

const StyledCompanyEntities = styled(CompanyEntities)`
&.companyEntities{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    .questionField.formItemInput{
        width: 50vw;
    }
    .wordLimitField.formItemInput{
        width: 100px;
    }
    .flex{
        display: flex;
        gap: 40px;
    }
    .flexColumn{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .errorText{
        color: #ff4d4f;
    }
    .contentContainer{
        display: flex;
        padding: 20px;
        justify-content: space-between;
        height: 180vh;
    }
    .addButton{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .formContainer{
        display: flex;
        gap: 12px;
        flex-direction: column;
    }
    .checkboxGroup{
        display: flex;
        flex-direction: row;
        width: 20vw;
    }
    .optionsContainer{
        display: flex;
        align-items: center;
        gap: 30px;
        .for{
            margin-bottom: 10px;
        }
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }

    .radioGroup {
            display: flex;
            flex-direction: column;
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .formItemTextField{
        .ant-form-item-control-input{
            width: 34.5vw !important;
        }
    }
    .formItemTextFieldPart{
        .ant-form-item-control-input{
            width: 16vw !important;
        } 
    }
  .ant-form-item{
    margin-bottom: 10px;
  }
  .formItemInput{
    width: 300px;
  }
}`;

export default StyledCompanyEntities;