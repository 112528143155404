import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import Config from "../../config";
import axios from '../../utils/axios';
import { useNavigate } from "react-router-dom";
import { Divider, Form, Avatar, Spin, Table, Steps, Input, Layout, Checkbox, message, Upload, Select, Button as AntButton } from "antd";
import styled from "styled-components";
import { getOpportunity, getProgramById, setEditOpportunitiesData, toggleSpinner, getIndicatorAnswers } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import CustomModal from '../../components/Modal';
import Button from "../../components/Button";
import { getLocalStorageItem, numericValidator } from "../../utils/utilities";
import {
    opportunityDataSelector,
    editOpportunityData,
    toggleSpinnerSelector,
    indicatorDataSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";
import lightbulb from '../../assets/lightbulb.svg'

const { TextArea } = Input;
const { Option } = Select;

const getTitleText = (text, intl) => {
    let status = 'add_title';
    let className = 'editButton';
    if (text === 'Pending') {
        status = 'pending_title';
        className = 'companyDonationDelete';
    } else if (text === 'Complete') {
        status = 'edit_title';
        className = 'companyDonationButton';
    }
    return {
        title: <StyledText as="p" variant="B3d">
            {intl.formatMessage({ id: status })}
        </StyledText>,
        className
    };
};

const getKeysArray = (objArray) => {
    const keysArray = [];
    if (objArray?.length) {
        objArray.forEach((obj) => {
            const keys = Object.keys(obj);
            if (obj[keys[0]] === 'Y') {
                keysArray.push(keys[0]);
            }
        });
        return keysArray;
    }
}

const CharityIndicators = ({ className }) => {
    const baseClassName = clsx("charityIndicators", className);
    const intl = useIntl();
    const [savedClicked, setSavedClicked] = useState(false);
    const [formData, setFormData] = useState({});
    const [indicatorsPopUpData, setIndicatorsPopUpData] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [unit, setUnit] = useState('');
    const [ID, setID] = useState(null);
    const [absoluteChange, setAbsoluteChange] = useState(undefined);
    const [relativeChange, setRelativeChange] = useState(undefined);
    const [editStatus, setEditStatus] = useState(null);
    const opportunityData = useAppSelector(opportunityDataSelector) || {}
    const indicators = useAppSelector(indicatorDataSelector) || {};
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const programReviewDetails = JSON.parse(getLocalStorageItem('programReviewDetails') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const { opportunity } = opportunityData || {};
    const editOpp = useAppSelector(editOpportunityData) || {};
    const { opportunityID } = editOpp || {};

    useEffect(() => {
        if (opportunityID && !savedClicked) {
            dispatch(setEditOpportunitiesData(''))
            dispatch(toggleSpinner(false));
            navigate("/impact-maker/applications/create/custom-questions")
        }
    }, [opportunityID])

    useEffect(() => {
        if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [opportunityId, programID]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
            dispatch(getIndicatorAnswers({
                programID,
                opportunityID: opportunityId
            }))
        }
    }, [programID]);

    const handleProceed = (e) => {
        if (indicators?.every(
            (item) => item.initialStatus === 'Complete'
        )) {
            navigate("/impact-maker/applications/create/custom-questions");
        } else {
            message.error('Please make sure you have all the indicators complete before proceeding.');
        }
    }

    const onFormChange = (_, allValues) => {
        setFormData(allValues);
    };

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
            navigate(`/impact-maker/programme/${programID}/${opportunityId}`);
        }, 300);
    }

    const handlePrevious = () => {
        navigate("/impact-maker/applications/create/beneficiaries")
    }

    const getColumnData = (list) => {
        if (list?.length > 0) {
            return list?.map((item, idx) => {
                const {
                    name,
                    description,
                    isMandatory,
                    targetRequired,
                    targetLevel,
                    status,
                    linkedObjective,
                    linkedOutcome,
                    objectiveName,
                    outcomeName,
                    ID,
                    unit,
                    initialStatus,
                    finalStatus,
                } = item || {};
                return {
                    key: idx,
                    name,
                    description,
                    isMandatory,
                    targetRequired,
                    targetLevel,
                    status,
                    linkedObjective,
                    linkedOutcome,
                    objectiveName,
                    outcomeName,
                    ID,
                    unit,
                    initialStatus,
                    finalStatus,
                };
            });
        }
        return null;
    };

    const handleModalBtnClick = (res) => {
        if (res?.data) {
            const timer = setTimeout(() => {
                clearTimeout(timer);
                dispatch(getIndicatorAnswers({
                    programID,
                    opportunityID: opportunityId
                }))
                setIsModalVisible(false);
                dispatch(toggleSpinner(false));
                setIndicatorsPopUpData([])
            }, 500);
        }
    };

    const convertArrayToObject = (inputArray) => {
        if (inputArray?.length) {
            const convertedArray = inputArray.map((item) => ({ [item]: 'Y' }));
            return convertedArray;
        }
    };

    const transformJSON = (jsonData) => {
        const data = jsonData;

        const startingLevel = Object.entries(data).reduce(
            (result, [key, value]) => {
                if (key.startsWith('startingLevel')) {
                    const level = key.split('-')[1];
                    result[level] = value;
                }
                return result;
            },
            {}
        );

        const targetLevel = Object.entries(data).reduce((result, [key, value]) => {
            if (key.startsWith('targetLevel')) {
                const level = key.split('-')[1];
                result[level] = value;
            }
            return result;
        }, {});

        return {
            startingLevel,
            targetLevel,
        };
    };

    const onFinish = () => {
        dispatch(toggleSpinner(true));
        const {
            evidenceRequiredList: evidenceRequiredListState,
        } = indicatorsPopUpData || {};
        const { indicatorType } = indicatorsPopUpData || {};
        const values = formData;
        const arrayType = indicatorType === 'array-percentage' || indicatorType === 'array-number';
        const {
            startingLevel,
            targetLevel,
            approachDescription,
            baseLevel,
            evidenceList: evidenceListVal,
            startingLevelList,
            targetLevelList,
        } = values || {};
        const data = {
            startingLevel,
            targetLevel,
            approachDescription,
            baseLevel,
            answerEvidenceRequiredList:
                evidenceListVal?.length > 0
                    ? convertArrayToObject(evidenceListVal)
                    : undefined,
            indicatorAnswerID: ID,
            absoluteChange: absoluteChange || undefined,
            relativeChange: relativeChange || undefined,
            reportLevel: 'Y',
            initialStatus: editStatus,
            startingLevelArray: arrayType
                ? transformJSON(values).startingLevel
                : undefined,
            targetLevelArray: arrayType
                ? transformJSON(values).targetLevel
                : undefined,
            startingLevelList,
            targetLevelList,
            evidenceRequiredList: evidenceRequiredListState?.length > 0 ? evidenceRequiredListState : evidenceRequiredListState
        };
        const { editIndicatorAnswer } = Config.dev.url;
        axios.put(editIndicatorAnswer, handleModalBtnClick, data, true);

    };

    const resetAllFields = () => {
        form.setFieldsValue({
            startingLevel: undefined,
            targetLevel: undefined,
            evidenceList: undefined,
            approachDescription: undefined,
        });
        setID(null);
        setAbsoluteChange(null);
        setRelativeChange(null);
        setUnit(null);
        setIsModalVisible(false);
    };

    const saveExtraData = (ID, absoluteChange, relativeChange, editStatus) => {
        setID(ID);
        setAbsoluteChange(absoluteChange);
        setRelativeChange(relativeChange);
        setEditStatus(editStatus)
    };

    const renderEvidenceSection = (param) => {
        const CheckboxGroup = Checkbox.Group;
        const options = param;
        return (
            options?.length > 0 && (
                <div style={{ marginBottom: '12px', display: 'flex', flexDirection: 'column' }}>
                    <StyledText as="p" variant="B3d" className='mainTitle'>
                        {intl.formatMessage({ id: "evidence" })}
                    </StyledText>
                    <StyledText as="p" variant="B3" className='mainTitle'>
                        {intl.formatMessage({ id: "please_confirm_that_you_can_provide_any_of_following_evidence_items" })}
                    </StyledText>
                    <div style={{ display: 'flex', margin: '5px 0', alignItems: 'center' }} className='checkboxParentContainer'>
                        <Form.Item
                            name="evidenceList"
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (!value || value.length < options.length) {
                                            return Promise.reject(
                                                new Error(intl.formatMessage({ id: 'select_all_options' }))
                                            );
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ]}
                            className='checkboxParentContainerFormItem'
                            style={{ marginBottom: '0px' }}
                        >
                            <CheckboxGroup className="checkboxContainer" style={{ display: 'flex', flexDirection: 'column' }}>
                                {options?.length > 0 &&
                                    options.map((parent) => (
                                        <Checkbox
                                            key={parent}
                                            value={parent}
                                            style={{ marginLeft: '0', fontSize: '16px', marginBottom: '10px' }}
                                        >
                                            <StyledText as="span" variant="B3">
                                                {parent}
                                            </StyledText>
                                        </Checkbox>
                                    ))
                                }
                            </CheckboxGroup>
                        </Form.Item>
                    </div>
                </div>
            )
        );
    };

    const getOptionalSection = (startingRequired, targetRequired, indicatorType) => (startingRequired === 'Y' || targetRequired === 'Y')
        && ['percentage', 'number'].includes(indicatorType);

    const getRelativeChangeValue = (absoluteChange, values) => (absoluteChange && values?.baseLevel
        ? absoluteChange + (values?.startingLevel - values?.baseLevel)
        : null);

    const getAbsoluteChangeValue = (values) => (values?.startingLevel && values?.targetLevel
        ? values.targetLevel - values.startingLevel
        : null);


    const getAddOnAfter = (unit, indicatorType) => (unit || indicatorType === 'percentage' || indicatorType === 'array-percentage' ? (
        <span>{`${unit || '%'}`}</span>
    ) : undefined);

    const renderModal = () => {
        const {
            name,
            description,
            indicatorType,
            startingRequired,
            targetRequired,
            ID,
            predefinedList,
            evidenceRequiredList,
            startingLevel,
            targetLevel,
            baseLevel,
            approachDescription,
            startingLevelList,
            targetLevelList,
            evidenceList
        } = indicatorsPopUpData || {};

        const showOptionalSection = getOptionalSection(
            startingRequired,
            targetRequired,
            indicatorType
        );
        const absoluteChange = getAbsoluteChangeValue(formData);
        const relativeChange = getRelativeChangeValue(absoluteChange, formData);
        const useArrayFields = indicatorType === 'pre-def-multi';
        const mode = useArrayFields ? 'multiple' : undefined;
        return (
            <CustomModal
                title={intl.formatMessage({ id: 'create_indicator' })}
                open={isModalVisible}
                onCancel={() => resetAllFields()}
                className="indicatorPop"
            >
                {showSpinner ? (
                    <div className="overlay">
                        <Spin className="spinner" size="large" spinning={showSpinner} />
                    </div>
                ) :
                    <>
                        <Form form={form} onFinish={onFinish} onValuesChange={onFormChange} initialValues={{
                            baseLevel: baseLevel
                        }}>
                            <Layout className="dropDownBackgroundModal">
                                <div>
                                    <StyledText as="span" variant="B3d">
                                        {intl.formatMessage({ id: "indicator_name" })}
                                        {': '}
                                    </StyledText>
                                    <StyledText as="span" variant="B3">
                                        {name}
                                    </StyledText>
                                </div>
                                <div>
                                    <StyledText as="span" variant="B3d">
                                        {intl.formatMessage({ id: "description" })}
                                        {': '}
                                    </StyledText>
                                    <StyledText as="span" variant="B3">
                                        {description}
                                    </StyledText>
                                </div>
                                <StyledText as="span" variant="B3d">
                                    {intl.formatMessage({ id: "measuring_progress" })}
                                </StyledText>
                                <div>
                                    {['percentage', 'number'].includes(indicatorType) && (
                                        <>
                                            {startingRequired === 'Y' && (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Form.Item
                                                        className="dFlex inputStyle"
                                                        label={<StyledText as="p" variant="B3">
                                                            {intl.formatMessage({
                                                                id: 'starting_number_level_of_your_indicator',
                                                            })}
                                                        </StyledText>
                                                        }
                                                        name="startingLevel"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: intl.formatMessage({
                                                                    id: 'please_enter_numeric_value',
                                                                }),
                                                            },
                                                            { validator: numericValidator, message: intl.formatMessage({ id: 'please_enter_numeric_value' }) }
                                                        ]}
                                                        initialValue={startingLevel}
                                                    >
                                                        <Input
                                                            className="inputAlign"
                                                            placeholder={intl.formatMessage({
                                                                id: 'please_enter',
                                                            })}
                                                            addonAfter={getAddOnAfter(unit, indicatorType)}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            )}
                                            {targetRequired === 'Y' && (
                                                <Form.Item
                                                    className="dFlex marginBottomStandard inputStyle"
                                                    label={<StyledText as="p" variant="B3">
                                                        {intl.formatMessage({
                                                            id: 'target_number_or_level_to_reach',
                                                        })}
                                                    </StyledText>}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: intl.formatMessage({
                                                                id: 'please_enter_numeric_value',
                                                            }),
                                                        },
                                                        { validator: numericValidator, message: intl.formatMessage({ id: 'please_enter_numeric_value' }) }
                                                    ]}
                                                    initialValue={targetLevel}
                                                    name="targetLevel"
                                                >
                                                    <Input
                                                        className="inputAlign"
                                                        placeholder={intl.formatMessage({
                                                            id: 'please_enter',
                                                        })}
                                                        addonAfter={getAddOnAfter(unit, indicatorType)}
                                                    />
                                                </Form.Item>
                                            )}
                                        </>
                                    )}
                                    {(showOptionalSection) && (
                                        <div style={{ alignItems: 'flex-end' }} className='estimateTextContainer'>
                                            {(typeof absoluteChange === 'number') && (
                                                <><StyledText as="p" variant="B3b" className='customModalTitle'>
                                                    {intl.formatMessage({
                                                        id: 'the_target_we_aim_to_reach_is_employed_from_the_starting_number_level',
                                                    }).replace(
                                                        'employedNumber',
                                                        absoluteChange > 0
                                                            ? `+${absoluteChange}`
                                                            : `${absoluteChange}`
                                                    )}
                                                </StyledText>
                                                    <Form.Item
                                                        className="dFlexTop whiteSpaceBreak marginBottomStandard optional inputStyle"
                                                        labelCol={{ span: 12 }}
                                                        wrapperCol={{ span: 12 }}
                                                        label={(
                                                            <div style={{
                                                                display: 'flex', flexDirection: 'column', textAlign: 'left', lineHeight: '18px'
                                                            }}
                                                            ><StyledText as="p" variant="B3">
                                                                    {intl.formatMessage({
                                                                        id: 'optional_title',
                                                                    })}:
                                                                </StyledText>
                                                                <StyledText as="p" variant="B3" className='estimateText'>
                                                                    {intl.formatMessage({
                                                                        id: 'estimate_what_the_level_would_be',
                                                                    })}
                                                                </StyledText>
                                                            </div>
                                                        )}
                                                        name="baseLevel"
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: intl.formatMessage({
                                                                    id: 'please_enter',
                                                                }),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            className="inputAlign"
                                                            placeholder={intl.formatMessage({
                                                                id: 'please_enter',
                                                            })}
                                                        />
                                                    </Form.Item>
                                                    {typeof relativeChange === 'number'
                                                        && !isNaN(relativeChange)
                                                        && typeof absoluteChange === 'number' && (
                                                            <StyledText as="p" variant="B3b" className='customModalTitle'>
                                                                {intl
                                                                    .formatMessage({
                                                                        id: 'your_total_impact_will_be_employed',
                                                                    })
                                                                    .replace(
                                                                        'impactNumber',
                                                                        relativeChange > 0
                                                                            ? `+${relativeChange}`
                                                                            : `${relativeChange}`
                                                                    )}
                                                            </StyledText>
                                                        )}
                                                </>
                                            )}
                                            {indicatorType === 'qualitative' && (
                                                <>
                                                    {startingRequired === 'Y' && (
                                                        <Form.Item
                                                            className="dFlex"
                                                            label={intl.formatMessage({
                                                                id: 'describe_the_starting_situation',
                                                            })}
                                                            name="startingLevel"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: intl.formatMessage({
                                                                        id: 'please_enter',
                                                                    }),
                                                                },
                                                            ]}
                                                            initialValue={startingLevel}
                                                        >
                                                            <TextArea
                                                                className="textContent"
                                                                maxLength="300"
                                                                placeholder="please_enter"
                                                                rows={2}
                                                            />
                                                        </Form.Item>
                                                    )}
                                                    {targetRequired === 'Y' && (
                                                        <Form.Item
                                                            className="dFlex marginBottom"
                                                            label={intl.formatMessage({
                                                                id: 'describe_the_final_situation',
                                                            })}
                                                            name="targetLevel"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: intl.formatMessage({
                                                                        id: 'please_enter',
                                                                    }),
                                                                },
                                                            ]}
                                                            initialValue={targetLevel}
                                                        >
                                                            <TextArea
                                                                className="textContent"
                                                                maxLength="300"
                                                                placeholder="please_enter"
                                                                rows={2}
                                                            />
                                                        </Form.Item>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    )}
                                    {['pre-def-multi', 'pre-def-single'].includes(indicatorType) && (
                                        <>
                                            {startingRequired === 'Y' && (
                                                <Form.Item
                                                    className="dFlex"
                                                    label={intl.formatMessage({
                                                        id: 'describe_the_starting_situation',
                                                    })}
                                                    name={useArrayFields ? 'startingLevelList' : 'startingLevel'}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: intl.formatMessage({
                                                                id: 'please_enter',
                                                            }),
                                                        },
                                                    ]}
                                                    initialValue={useArrayFields
                                                        ? startingLevelList
                                                        : startingLevel}
                                                >
                                                    <Select
                                                        placeholder={intl.formatMessage({ id: 'select_title' })}
                                                        className="selectBox"
                                                        mode={mode}
                                                    >
                                                        {predefinedList?.length > 0
                                                            && predefinedList.map((item) => (
                                                                <Option key={item} value={item}>
                                                                    {item}
                                                                </Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            )}
                                            {targetRequired === 'Y' && (
                                                <Form.Item
                                                    className="dFlex marginBottom"
                                                    label={intl.formatMessage({
                                                        id: 'describe_the_final_situation',
                                                    })}
                                                    name={useArrayFields ? 'targetLevelList' : 'targetLevel'}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: intl.formatMessage({
                                                                id: 'please_enter',
                                                            }),
                                                        },
                                                    ]}
                                                    initialValue={useArrayFields
                                                        ? targetLevelList
                                                        : targetLevel}
                                                >
                                                    <Select
                                                        placeholder={intl.formatMessage({ id: 'select_title' })}
                                                        className="selectBox"
                                                        mode={mode}
                                                    >
                                                        {predefinedList?.length > 0
                                                            && predefinedList.map((item) => (
                                                                <Option key={item} value={item}>
                                                                    {item}
                                                                </Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            )}
                                        </>
                                    )}
                                    {['array-percentage', 'array-number'].includes(indicatorType) && (
                                        <>
                                            {startingRequired === 'Y' && (
                                                <>
                                                    <span>
                                                        {intl.formatMessage({
                                                            id: 'describe_the_starting_situation',
                                                        })}
                                                    </span>
                                                    {predefinedList?.length > 0
                                                        && predefinedList.map((item) => (
                                                            <div style={{ display: 'flex' }} key={item}>
                                                                <Form.Item
                                                                    key={item}
                                                                    className="dFlex marginBottomStandard inputStyle"
                                                                    name={`startingLevel-${item}`}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: intl.formatMessage({
                                                                                id: indicatorType === 'array-number' ? 'please_enter_number' : 'please_enter',
                                                                            }),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        placeholder={intl.formatMessage({
                                                                            id: 'enter_title',
                                                                        })}
                                                                        addonAfter={getAddOnAfter(unit, indicatorType)}
                                                                    />
                                                                </Form.Item>
                                                                <div style={{ display: 'flex', margin: '9px' }}>{item}</div>
                                                            </div>
                                                        ))}
                                                </>
                                            )}
                                            {targetRequired === 'Y' && (
                                                <>
                                                    <span>
                                                        {intl.formatMessage({
                                                            id: 'describe_the_final_situation',
                                                        })}
                                                    </span>
                                                    {predefinedList?.length > 0
                                                        && predefinedList.map((item) => (
                                                            <div style={{ display: 'flex' }} key={item}>
                                                                <Form.Item
                                                                    key={item}
                                                                    className="dFlex marginBottomStandard inputStyle"
                                                                    name={`targetLevel-${item}`}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: intl.formatMessage({
                                                                                id: indicatorType === 'array-number' ? 'please_enter_number' : 'please_enter',
                                                                            }),
                                                                        },
                                                                        { validator: numericValidator, message: intl.formatMessage({ id: 'please_enter_numeric_value' }) }
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        placeholder={intl.formatMessage({
                                                                            id: 'enter_title',
                                                                        })}
                                                                        addonAfter={getAddOnAfter(unit, indicatorType)}
                                                                    />
                                                                </Form.Item>
                                                                <div style={{ display: 'flex', margin: '9px' }}>{item}</div>
                                                            </div>
                                                        ))}
                                                </>
                                            )}
                                        </>
                                    )}
                                    {renderEvidenceSection(evidenceList, startingRequired, targetRequired, evidenceRequiredList)}
                                </div>
                                <StyledText as="p" variant="B3d">
                                    {intl.formatMessage({ id: "how_will_you_gather_evidence" })}
                                </StyledText>
                                <Form.Item
                                    className="dFlex whiteSpaceBreak marginBottom"
                                    name="approachDescription"
                                    rules={[
                                        {
                                            required: true,
                                            message: intl.formatMessage({ id: 'please_enter' }),
                                        },
                                    ]}
                                    initialValue={approachDescription}
                                >
                                    <TextArea
                                        style={{ width: '530px' }}
                                        placeholder={intl.formatMessage({
                                            id: 'enter_description',
                                        })}
                                    />
                                </Form.Item>
                                <div className="modalButtonContainer">
                                    <AntButton
                                        onClick={() => resetAllFields()}
                                        className="deleteButton"
                                        type="primary"
                                    >
                                        {intl.formatMessage({ id: 'delete_title' })}
                                    </AntButton>
                                    <Button
                                        onClick={() => saveExtraData(
                                            ID,
                                            absoluteChange,
                                            relativeChange,
                                            'Complete',
                                        )}
                                        className="editButton"
                                        type="primary"
                                        variant="primary"
                                        htmlType="submit"
                                    >
                                        {intl.formatMessage({ id: 'complete' })}
                                    </Button>
                                    <Button
                                        onClick={() => saveExtraData(
                                            ID,
                                            absoluteChange,
                                            relativeChange,
                                            'Pending',
                                        )}
                                        className="saveExit"
                                        htmlType="submit"
                                        variant="secondary"
                                    >
                                        {intl.formatMessage({ id: 'save_n_exit' })}
                                    </Button>
                                </div>
                            </Layout>
                        </Form>
                    </>}
            </CustomModal>
        );
    };

    const transformObject = (inputObj, check) => Object.keys(inputObj).reduce((result, key) => {
        const newKey = check ? `targetLevel-${key}` : `startingLevel-${key}`;
        result[newKey] = inputObj[key];
        return result;
    }, {});

    const handleModalResponse = (res) => {
        if (res?.data?.indicator) {
            setIndicatorsPopUpData([])
            dispatch(toggleSpinner(false));
            setIndicatorsPopUpData(res.data.indicator);
            if (
                (res?.data?.indicator?.indicatorType === 'array-percentage'
                    || res?.data?.indicator?.indicatorType === 'array-number')
                && res?.data?.indicator?.startingLevelArray
                && res?.data?.indicator?.targetLevelArray
            ) {
                const staringLevel = transformObject(
                    res?.data?.indicator?.startingLevelArray
                );
                const targetLevel = transformObject(
                    res?.data?.indicator?.targetLevelArray,
                    true
                );
                const mergedArray = { ...staringLevel, ...targetLevel };
                form.setFieldsValue(mergedArray);
            }
            const keysArray = getKeysArray(res?.data?.indicator?.answerEvidenceRequiredList) || [];
            if (keysArray?.length > 0) {
                form.setFieldsValue({
                    evidenceList: keysArray,
                });
            }
            form.setFieldsValue({
                approachDescription: res?.data?.indicator?.approachDescription,
                startingLevel: res?.data?.indicator?.startingLevel,
                targetLevel: res?.data?.indicator?.targetLevel
            });
            setTimeout(() => {
                setIsModalVisible(true);
            }, 500);
        }
    };

    const handleStatusClick = (report) => {
        resetAllFields();
        dispatch(toggleSpinner(true));
        setUnit(unit)
        const indicatorUrl = `${Config.dev.url.getEditIndicatorAnswers}?indicatorAnswerID=${report?.ID}`;
        axios.get(indicatorUrl, handleModalResponse);
    };

    const generalIndicatorsColumns = [
        {
            title: intl.formatMessage({ id: 'indicator' }),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: intl.formatMessage({ id: 'required' }),
            dataIndex: 'isMandatory',
            key: 'isMandatory',
            render: (text) => (text === 'Y' ? (
                <span>
                    {intl.formatMessage({ id: 'mandatory_title' })}
                </span>
            ) : (
                <span>
                    {intl.formatMessage({ id: 'optional_title' })}
                </span>
            )),
        },
        {
            title: intl.formatMessage({ id: 'target' }),
            dataIndex: 'targetLevel',
            key: 'targetLevel',
        },
        {
            title: intl.formatMessage({ id: 'status' }),
            dataIndex: 'status',
            key: 'status',
            render: (text, report) => {
                const status = text || report.initialStatus || report.finalStatus;
                return (
                    <Button
                        className={getTitleText(status, intl)?.className}
                        type={
                            getTitleText(status, intl)?.title === 'Add'
                                ? 'primary'
                                : undefined
                        }
                        onClick={() => handleStatusClick(report)}
                    >
                        {getTitleText(status, intl)?.title}
                    </Button>
                );
            },
        },
    ];

    const oppIndicatorsColumns = [
        {
            title: intl.formatMessage({ id: 'objective' }),
            dataIndex: 'objectiveName',
            key: 'objectiveName',
        },
        {
            title: intl.formatMessage({ id: 'outcome' }),
            dataIndex: 'outcomeName',
            key: 'outcomeName',
        },
        {
            title: intl.formatMessage({ id: 'indicator' }),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: intl.formatMessage({ id: 'required' }),
            dataIndex: 'isMandatory',
            key: 'isMandatory',
            render: (text) => (text === 'Y' ? (
                <span>
                    {intl.formatMessage({ id: 'mandatory_title' })}
                </span>
            ) : (
                <span>
                    {intl.formatMessage({ id: 'optional_title' })}
                </span>
            )),
        },
        {
            title: intl.formatMessage({ id: 'target' }),
            dataIndex: 'targetLevel',
            key: 'targetLevel',
        },
        {
            title: intl.formatMessage({ id: 'status' }),
            dataIndex: 'status',
            key: 'status',
            render: (text, report) => {
                const status = text || report.initialStatus || report.finalStatus;
                return (
                    <Button
                        className={getTitleText(status, intl)?.className}
                        type={
                            getTitleText(status, intl)?.title === 'Add'
                                ? 'primary'
                                : undefined
                        }
                        onClick={() => handleStatusClick(report)}
                    >
                        {getTitleText(status, intl)?.title}
                    </Button>
                );
            },
        },
    ];

    const generalIndicators = Array.isArray(indicators) && indicators?.filter(
        (item) => item.isGeneral === 'Y'
    );
    const oppIndicators = Array.isArray(indicators) && indicators?.filter((item) => item.isGeneral === 'N');

    return (
        <div className={baseClassName}>
            <Steps
                current={2}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "about_your_project" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "your_request" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "planned_impact_title" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_4" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "award_criteria" })}
                        </StyledText>,
                    },
                ]}
            />
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <div className='contentContainer'>
                <div>
                    <StyledText as="p" variant="H1" className='mainTitle'>
                        {intl.formatMessage({ id: "measuring_progress" })}
                    </StyledText>
                    <StyledText as="p" variant="B3" className='mainTitle'>
                        {intl.formatMessage({ id: "the_company_has_requested_you_to_add_indicators_to_measure_the_progress_of_your_project" })}
                    </StyledText>
                    <StyledText as="p" variant="H2" className='mainTitle'>
                        {intl.formatMessage({ id: "general_indicators" })}
                    </StyledText>
                    <StyledText as="p" variant="B3" className='mainTitle'>
                        {intl.formatMessage({ id: "these_indicators_are_general_to_your_opportunity_or_entity_please_click_add_to_create_them" })}
                    </StyledText>
                    <Table
                        className="components-table-demo-nested generalIndicators"
                        columns={generalIndicatorsColumns}
                        dataSource={getColumnData(generalIndicators)}
                        pagination={false}
                    />
                    <StyledText as="p" variant="H2" className='mainTitle'>
                        {intl.formatMessage({ id: "objective_outcome_specific_indicators" })}
                    </StyledText>
                    <StyledText as="p" variant="B3" className='mainTitle'>
                        {intl.formatMessage({ id: "the_indicators_below_are_linked_to_your_selected_objectives_and_outcomes_please_click_add_to_create_them" })}
                    </StyledText>
                    <Table
                        className="generalIndicators"
                        columns={oppIndicatorsColumns}
                        dataSource={getColumnData(oppIndicators)}
                        pagination={false}
                    />
                    {renderModal()}
                    <div className='buttonContainer'>
                        <Divider />
                        <div className='buttonContentContainer'>
                            <div className='nextAndPreviousContainer'>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    onClick={() => handlePrevious()}>
                                    {intl.formatMessage({ id: "previous" })}
                                </Button>
                                <Button
                                    variant="primary"
                                    type="button"
                                    onClick={() => handleProceed()}
                                >
                                    {intl.formatMessage({ id: "next" })}
                                </Button>
                            </div>
                            <div className="saveAndExitButton">
                                <Button
                                    variant="secondary"
                                    htmlType="button"
                                    onClick={() => handleSaveAndExit()}>
                                    {intl.formatMessage({ id: "save_n_exit" })}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tipsContainer'>
                    <div className='titleContainer'>
                        <Avatar src={lightbulb} />
                        <StyledText as="p" variant="H1">
                            {intl.formatMessage({ id: "tips" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "what_is_an_indicator" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "an_indicator_is_a_measure_of_progress" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "an_indicator_is_an_estimate" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "indicator_examples" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "no_of_people_gaining_a_new_skill_or_qualification" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "no_of_workshops_hosted" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "decrease_in_local_unemployment" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "when_do_i_update_my_indicators" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "if_your_application_is_approved" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "how_often_do_I_update_my_indicators" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "you_will_be_asked_to_update_your_indicators_at_the_same_time" })}
                        </StyledText>
                    </div>
                </div>
            </div>
        </div>
    )
}

const StyledCharityIndicators = styled(CharityIndicators)`
&.charityIndicators{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    .contentContainer{
        display: flex;
        padding: 20px;
        justify-content: space-between;
        height: 280vh;
    }
    .mainTitle{
        margin-bottom: 20px;
    }

    .formContainer{
        display: flex;
        gap: 12px;
        flex-direction: column;
    }
    .generalIndicators{
        margin-bottom: 40px;
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }
    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
  .ant-form-item{
    margin-bottom: 10px;
  }
  .tipsContainer{
    background: #D7FEFB;
    border: 1px solid #04ac9c;
    padding: 20px;
    border-radius: 10px;
    width: max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .titleContainer {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        gap: 10px;
    }
  }
  .companyDonationDelete{
  background: #ff1616;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid;
  p {
    color: white !important;
  }
}

.companyDonationDelete:hover{
  background: #ff1616;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid;
  p {
    color: white !important;
  }
}

.editButton{
    background: #04ac9c !important;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid;
  p {
    color: white !important;
  }
}

.companyDonationDelete:focus{
  background: #ff1616;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid;
  p {
    color: white !important;
  }
}
.companyDonationButton {
  background: #5271FF;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #5271FF;
  p {
    color: white !important;
  }
}

.companyDonationButton:hover{
  background: #5271FF;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #5271FF;
  p {
    color: white !important;
  }
}

.companyDonationButton:focus{
  background: #5271FF;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #5271FF;
  p {
    color: white !important;
  }
}
.oppTheme {
  padding: 20px 0px 0 50px;
  width: 100%;
  display: inline-block;
  background: none;
  top: 0;
  position: relative;
}
.rightContainer {
  width: 50px;
}
.blueTag{
  color: #5271ff;
  background: #eef1ff;
  border-color: #5271ff;
}
.dummyContainer {
  width: 50px;
  height: 50px;
}

.companyDonationDelete{
  background: #ff1616;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid;
}

.downloadBtn {
  color: #5271FF !important;
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  font-weight: bold !important;
  cursor: pointer;
}

.companyDonationDelete:hover{
  background: #ff1616;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid;
}
.checkboxParentContainerFormItem.ant-form-item{
        margin-bottom: 0 !important;
    }

.checkboxParentContainer{
   
    .checkboxContainer{
        display: flex !important;
        flex-direction: column !important;
    }
}


.companyDonationDelete:focus{
  background: #ff1616;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid;
}

.dropDownBackground{
  background: none;
  display: flex;
  flex-direction: column;
}

.buttonContainer{
  display: flex;
  justify-content: space-between;
  background: white;
}

.fontBold{
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
}

.marginBottom{
  margin-bottom: 24px !important;
  display: inline-block;
}

.optional ::after{
  display: none;
}

.marginLeft{
  margin-left: 15px !important;
}

.marginBottomStandard{
  margin-bottom: 12px !important;
  display: inline-block;
}

.delete{
  background: red;
}

.complete{
background: green;
}

.dFlex{
  display: flex;
  font-weight: 400;
}

.dFlexTop {
  display: flex;
  font-weight: 400;
  align-items: flex-start !important;
}

.whiteSpaceBreak {
  white-space: break-spaces;
  align-items: center;
}

.whiteSpaceBreak label{
  white-space: break-spaces;
}

.companyDonationButton {
  background: #5271FF;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #5271FF;
}

.inputStyle input {
  width: 90px !important;
}

.inputAlign {
  /* margin-left: 100px !important; */
}

.editButtonTile{
  display: inline-block !important;
  white-space: nowrap !important;
}

.companyDonationButton:hover{
  background: #5271FF;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #5271FF;
}

.companyDonationButton:focus{
  background: #5271FF;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #5271FF;
}

.darkGraytext {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 700;
  font-size: 16px;
}

.standardText {
  font-size: 16px;
}

.saveExit{
  color: #00aa9d !important;
}

.selectBox {
  width: 150px !important;
}

.helperText {
  color: #5271FF !important;
  font-weight: bold !important;
  display: flex;
  margin-top: 10px;
}
.coverImageContainer {
  width: 100%;
  height: auto;
  min-height: 80px;
}

.imgSet {
  max-width: 100%;
  display: block;
}

.oppTheme > div > label {
  width: 100%;
  margin-bottom: 6px;
}
.themesMain {
  display: flex;
  flex-direction: row;
  margin-left: 24px;
}
.bizgiveTheme {
  display: flex;
}
.themesParent {
  background-color: #f9fafb;
  width: 10%;
}
.themeSelectedContainerSet {
  width: 30%;
  top: -3px;
  position: relative;
  padding-left: 10px;
}
.oppTheme > section:nth-last-child(2) {
  margin: 10px 0;
}
.radioStyle {
  display: block;
  line-height: 20px;
}
.hintText {
  display: block;
  font-size: 11px;
}
.oppTheme .radioButtons {
  margin-top: 10px;
  margin-bottom: 5px;
}
.themecolumns {
  width: 60%;
  margin-left: 0;
  margin-top: 10px;
}
.themecolumns > section > section > div {
  margin-bottom: 10px;
}
.mandatory::after {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  line-height: 1;
  content: "*";
}

.tipsLink {
  color: #04ac9c;
  text-decoration: underline;
}
.inputFormField {
  margin-top: 10px;
}
.inputFormField > div:nth-child(2) {
  width: 50%;
}
.oprtnitySupport > div:first-child {
  margin-bottom: 10px;
}
.oprtnitySupport > section {
  margin-bottom: 20px;
}
.oprtnitySupport > section > div:nth-child(2) {
  width: 30%;
  margin-bottom: 0;
}
.accountDetails {
  width: 100%;
}
.accountDetails > div {
  margin-bottom: 0;
  display: inline-block;
}
.accountDetails > div > div:nth-child(1) {
  float: left;
  width: auto;
  min-width: 70px;
  text-align: left;
}
.accountDetails > div > div:nth-child(2) {
  float: left;
  margin-bottom: 0;
}
.redText {
  color: red;
  margin-top: 15px;
  margin-bottom: 16px;
}
.localSecondaryTHolder > div {
  margin-left: 5px;
}
.localSecondaryTHolder > div > section > article {
  right: 50px !important;
}
.summaryObjContainer {
  border-bottom: 1px solid #ccc;
  padding-bottom: 25px;
  width: 98%;
  margin-bottom: 25px;
}
.oppTheme > section {
  margin-left: 0px !important;
}
.hintHeight {
  margin-bottom: 25px;
}
.spanSpace::after {
  content: "\A\A";
  white-space: pre;
}
.boldFont {
  font-weight: 800;
}
.opportunityThemes {
  display: block;
}
.adjustmentOne {
  margin-bottom: -5px;
}
.adjustmentTwo {
  margin-top: 15px;
}
.themesContainer {
  width: 100%;
  display: inline-block !important;
}
.themesContainer > div:nth-child(1) {
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.themesContainer > div:nth-child(2) {
  float: left;
  width: 65%;
  margin-bottom: 0;
}
.themesContainer .formItem .value {
  line-height: 20px;
  font-size: 14px;
}
.themesContainer > section {
  float: left;
  margin-bottom: 10px;
  position: relative;
  line-height: 20px;
  display: inline-block;
}
.themesContainer > section:nth-child(1) {
  width: 35%;
}
.themesContainer > section:nth-child(2) {
  width: 60%;
}
.bizGiveThemes {
  width: 100%;
  padding-bottom: 1%;
}
.bizGivePrimaryThemes {
  width: 51% !important;
}
.bizGiveSecondaryThemes {
  width: 49% !important;
}
.localNoSecondary {
  min-height: 50px !important;
  padding-top: 3px !important;
}
}`;

export default StyledCharityIndicators;