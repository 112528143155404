import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Input, Radio, Spin } from "antd";
import styled from "styled-components";
import { getLocalStorageItem } from "../../utils/utilities";
import { updateProgram, getOpportunity, getProgramById, toggleSpinner, setUpdateProgram, getUser } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import Select from "../../components/CustomSelect"
import {
    programDataSelector,
    updateProgramSelector,
    toggleSpinnerSelector,
    usersSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 24 },
};

const CompanyTimeline = ({ className }) => {
    const baseClassName = clsx("companyTimeline", className);
    const intl = useIntl();
    const [formData, setFormData] = useState({});
    const [savedClicked, setSavedClicked] = useState(false);
    const programData = useAppSelector(programDataSelector) || {};
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const programReviewDetails = JSON.parse(getLocalStorageItem('createdProgramID') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const users = useAppSelector(usersSelector);
    const updateProgramData = useAppSelector(updateProgramSelector) || {};
    const { programID: updateProgramID } = updateProgramData || {};

    useEffect(() => {
        if (updateProgramID && !savedClicked) {
            dispatch(setUpdateProgram(''))
            dispatch(toggleSpinner(false));
            navigate("/company/programme/create/preview");
        }
    }, [updateProgramID])


    useEffect(() => {
        if (programData?.programName) {
            const data = {
                ...(programData?.BizGiveUser && { BizGiveUser: programData?.BizGiveUser }),
                ...(programData?.userViewer && { userViewer: programData?.userViewer }),
                ...(programData?.visibility && { visibility: programData?.visibility }),
                ...(programData?.programmeURLCode && { programmeURLCode: programData?.programmeURLCode }),
            };
            form.setFieldsValue(data);
            setFormData(data);
        }
    }, [programData]);


    useEffect(() => {
        if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [opportunityId, programID]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
            dispatch(getUser());
        }
    }, [programID]);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = (e) => {
        const formData = form.getFieldsValue();
        const data = {
            BizGiveUser: formData?.BizGiveUser,
            userViewer: formData?.userViewer,
            visibility: formData?.visibility,
            programmeURLCode: formData?.programmeURLCode,
            programID
        }
        dispatch(updateProgram(data));
        dispatch(toggleSpinner(true));
    }

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
            navigate(`/company/programmes/review/${programID}`);
        }, 300);
    }

    const handlePrevious = () => {
        navigate("/company/programme/create/approval")
    }

    const onFormChange = (_, allValues) => {
        setFormData(allValues);
    };

    return (
        <div className={baseClassName}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <div className='contentContainer'>
                <div>
                    <StyledText as="p" variant="H1" className='mainTitle'>
                        {intl.formatMessage({ id: "programme_administration" })}
                    </StyledText>
                    <StyledText as="p" variant="B3d" className='mainTitle'>
                        {intl.formatMessage({ id: "who_will_manage_this_programme" })}
                    </StyledText>
                    <Form
                        {...formItemLayout}
                        labelAlign="left"
                        form={form}
                        onFinish={handleProceed}
                        onFinishFailed={onFinishFailed}
                        className="formContainer"
                        scrollToFirstError
                        onValuesChange={onFormChange}
                    >
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "managers_can_edit_the_programme_interact_with_applicants_approve_applications_and_receive_related_email_notifications" })}
                        </StyledText>
                        <Form.Item label="" name="BizGiveUser" rules={[
                            {
                                required: true,
                                message: 'Please select',
                            },
                        ]}>
                            <Select
                                allowClear
                                placeholder={intl.formatMessage({ id: "select_user" })}
                                type="formSelect"
                                mode="multiple"
                                options={users?.map((value, index) => ({
                                    key: value,
                                    label: `${value?.['custom:entityName']}  -  ${value?.email}`,
                                    value: value?.userID
                                }))}
                            />
                        </Form.Item>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "who_can_view_this_programme" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "viewers_can_view_the_programme_and_applications_but_not_edit_the_programme" })}
                        </StyledText>
                        <Form.Item label="" name="userViewer" rules={[
                            {
                                required: true,
                                message: 'Please select',
                            },
                        ]}>
                            <Select
                                allowClear
                                placeholder={intl.formatMessage({ id: "select_user" })}
                                type="formSelect"
                                mode="multiple"
                                options={users?.map((value, index) => ({
                                    key: value,
                                    label: `${value?.['custom:entityName']}  -  ${value?.email}`,
                                    value: value?.userID
                                }))}
                            />
                        </Form.Item>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "programme_visibility" })}
                        </StyledText>
                        <Form.Item
                            name="visibility"
                            rules={[
                                { whitespace: true, required: true, message: intl.formatMessage({ id: "enter" }) }
                            ]}
                        >
                            <Radio.Group className="radioGroup">
                                <Radio className="radio" value="BizGive (Open)">
                                    <StyledText as="p" variant="B3c">
                                        {intl.formatMessage({ id: "bizGive_open" })}
                                    </StyledText>
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "any_charity_signed_up_to_bizGive_can_view_and_apply_to_your_programme" })}
                                    </StyledText>
                                </Radio>
                                <Radio className="radio" value="BizGive (Invite Only)">
                                    <StyledText as="p" variant="B3c">
                                        {intl.formatMessage({ id: "bizGive_invite_only" })}
                                    </StyledText>
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "only_charities_you_invite_to_apply_to_your_programme_can_view_it" })}
                                    </StyledText>
                                </Radio>
                                <Radio className="radio" value="Public (Open)">
                                    <StyledText as="p" variant="B3c">
                                        {intl.formatMessage({ id: "public_open" })}
                                    </StyledText>
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "your_programme_will_have_its_own_link_and_branded_landing_page_on_bizGive" })}
                                    </StyledText>
                                </Radio>
                                <Radio className="radio" value="Public (Invite only)">
                                    <StyledText as="p" variant="B3c">
                                        {intl.formatMessage({ id: "public_invite_only" })}
                                    </StyledText>
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "your_programme_will_have_its_own_link_and_branded_landing_page_on_bizGive_invite_them_to_apply" })}
                                    </StyledText>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "add_a_custom_programme_url" })}
                        </StyledText>
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <StyledText as="span" variant="B3">
                                bizgive.app/public/?a=
                            </StyledText>
                            <Form.Item
                                name="programmeURLCode"
                                className="formItem"
                                style={{ marginBottom: '0px' }}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: "enter" }) },
                                ]}
                            >
                                <Input
                                    placeholder={intl.formatMessage({ id: "enter" })}
                                    type="text"
                                />
                            </Form.Item>
                        </div>
                        <div className='buttonContainer'>
                            <Divider />
                            <div className='buttonContentContainer'>
                                <div className='nextAndPreviousContainer'>
                                    <Button
                                        variant="secondary"
                                        type="button"
                                        onClick={() => handlePrevious()}>
                                        {intl.formatMessage({ id: "previous" })}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        htmlType="submit"
                                    >
                                        {intl.formatMessage({ id: "next" })}
                                    </Button>
                                </div>
                                <div className="saveAndExitButton">
                                    <Button
                                        variant="secondary"
                                        htmlType="button"
                                        onClick={() => handleSaveAndExit()}>
                                        {intl.formatMessage({ id: "save_n_exit" })}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

const StyledCompanyTimeline = styled(CompanyTimeline)`
&.companyTimeline{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    .flex{
        display: flex;
        gap: 40px;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .contentContainer{
        display: flex;
        padding: 20px;
        justify-content: space-between;
        height: 180vh;
    }
    .formContainer{
        display: flex;
        gap: 12px;
        flex-direction: column;
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }

    .radioGroup {
            display: flex;
            flex-direction: column;
            gap: 20px;
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
  .ant-form-item{
    margin-bottom: 10px;
  }
  .formItemInput{
    width: 300px;
  }
}`;

export default StyledCompanyTimeline;