import React from 'react';
import { Carousel, Image } from 'antd';
import clsx from "clsx";
import styled from "styled-components";
import StyledText from './StyledText';
import 'antd/dist/reset.css';

const CustomCarousel = ({ className, data, ...rest }) => {
  const baseClassName = clsx("customCarousel", className);
  return (
    <Carousel
      {...rest}
      className={baseClassName}
      arrows
    >
      {data?.map((item, index) => (
        <div key={item.url} className='carouselItem'>
          <Image
            src={item.url}
            alt={item.name}
            className='carouselImage'
          />
          {item?.mediaDescription && (
            <div className='mediaDescriptionContainer'>
              <StyledText
                as="p"
                variant="B3"
                className='mediaDescription'
              >
                {item?.mediaDescription}
              </StyledText>
            </div>
          )}
        </div>
      ))}
    </Carousel>
  );
};

const StyledCustomCarousel = styled(CustomCarousel)`
  &.customCarousel {
    .carouselItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .carouselImage {
      width: 100%;
      height: 350px;
      object-fit: contain;
    }

    .mediaDescriptionContainer {
      margin-top: 15px;
      padding: 0 10px;
      width: 100%;
    }

    .mediaDescription {
      word-wrap: break-word;
      white-space: pre-wrap;
      text-align: center;
      max-width: 75vw;
    }

    .slick-prev, .slick-next {
      width: 50px;
      height: 50px;
      background-color: #04ac9c;
      border-radius: 50%;
      display: flex !important;
      justify-content: center;
      align-items: center;
      color: white;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      transition: background-color 0.3s, color 0.3s;
    }

    .slick-prev:hover, .slick-next:hover {
      background-color: #60f8ea;
    }

    .slick-prev:before, .slick-next:before {
      font-size: 24px;
      color: white;
    }
    .slick-prev::after, .slick-next:after {
      transform: translate(18px, 17px) rotate(-45deg)
    }

    .slick-next:after {
      transform: translate(18px, 17px) rotate(135deg)
    }

    .slick-prev {
      left: 25px;
    }

    .slick-next {
      right: 25px;
    }
  }
`;

export default StyledCustomCarousel;
