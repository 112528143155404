import React, { forwardRef } from "react";
import clsx from "clsx";
import { Select } from "antd";
import styled from "styled-components";
import StyledText from "./StyledText";

const CustomSelect = forwardRef(({ className, options = [], type, ...rest }, ref) => {
  const baseClassName = clsx("customSelect", className);

  return (
    <Select ref={ref} className={baseClassName} {...rest}>
      {options?.map(({ label, value }) => (
        <Select.Option key={`${label}-${value}`} value={value}>
          <StyledText as="p" variant="B3" className="customSelectOption">
            {label}
          </StyledText>
        </Select.Option>
      ))}
    </Select>
  );
});

const StyledCustomSelect = styled(CustomSelect)`
  &.customSelect {
    cursor: pointer;
    width: 200px;
    .ant-select-selector {
      background-color: ${(props) => (props.type === 'formSelect' ? '#FFFFFF' : '#5550F7')};
      color: ${(props) => (props.type === 'formSelect' ? '#242424' : '#FFFFFF')};
      border-color: ${(props) => (props.type === 'formSelect' ? '#D9D9D9' : '#5550F7')};
      .ant-select-selection-placeholder {
        color: ${(props) => (props.type === 'formSelect' ? '#242424' : '#FFFFFF')};
        opacity: 0.5;
      }
      &:hover {
        background-color: ${(props) => (props.type === 'formSelect' ? '#e9e8fa' : '#5550F7')};
      }
    }
    &.ant-select-disabled {
      .ant-select-selector {
        border-color: #D9D9D9 !important;
        color: #242424;
        .ant-select-selection-placeholder {
          color: #D9D9D9 !important;
          opacity: 0.8;
        }
        &:hover {
          background-color: #eeeff1;
        }
      }
    }
    input, p, .ant-select-arrow {
      color: ${(props) => (props.type === 'formSelect' ? '#242424' : '#FFFFFF')} !important;
    }
  }
`;

export default StyledCustomSelect;
