import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode'
import { Helmet as HelmetAsync } from "react-helmet-async";
import clsx from "clsx";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Tabs, Layout, Divider, Timeline, Form, Checkbox } from 'antd';
import { useIntl } from 'react-intl';
import { getProfileDataCompany, getNewToken, getProfileDataGuestCompany } from '../../../redux/actions';
import styled from "styled-components";
import Alert from "../../../components/Alert";
import CompanySubTab from "./CompanySubTab";
import { getSecuredUrl, getLocalStorageItem } from "../../../utils/utilities";
import {
    useAppSelector,
    useAppDispatch
} from "../../../redux/store";
import {
    programDataSelector,
    profileSelector,
    loginSelector,
    guestTokenSelector,
    guestProgramDataSelector
} from "../../../redux/selectors";
import CustomModal from "../../../components/Modal";
import ProgramReview from './CharityApplicationCreateReview';
import StyledText from "../../../components/StyledText";
import Button from '../../../components/Button';
import MapBoxAreaComponent from "../../../components/MapBoxAreaComponent";
import ProgStartData from '../../charityCreate//bizGiveThemeImageData';
import FullScreenWrapper from "../../../components/FullscreenWrapper";
import {
    EnvironmentFilled,
    CheckOutlined,
    StarFilled,
    CalendarFilled
} from "@ant-design/icons";
import DateFormatter from "../../../components/DateFormatter";

const getUpdateDueDateMessage = ({
    updateDueDateRelativeInterval,
}) => {
    if (updateDueDateRelativeInterval) {
        return `Every ${updateDueDateRelativeInterval} days during the project running dates`;
    } else {
        return 'Not Required';
    }
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const ProgramReviewHomePage = ({ className }) => {
    const baseClassName = clsx("dashboardPage", className);
    const [logoS3URL, setLogoS3URL] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [form] = Form.useForm();
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [selectedBizGiveImage, setSelectedBizGiveImage] = useState({});
    const [programClosedAcceptStartDate, setProgramClosedAcceptDate] = useState(false);
    const [disableApply, setDisableApply] = useState(false);
    const [programClosedPopUp, setProgramClosedPopUp] = useState(false);
    const [logoImageURL, setLogoImageURL] = useState('');
    const programData = useAppSelector(programDataSelector);
    const guestProgramData = useAppSelector(guestProgramDataSelector);
    const intl = useIntl();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const programmeURLCode = queryParams.get("a");
    const { programID } = useParams();
    const dispatch = useAppDispatch();
    const { company: { logoS3URL: logoImage, } = {} } = useAppSelector(profileSelector) || {};
    const navigate = useNavigate();
    const loginObj = useAppSelector(loginSelector);
    const IDTokenSaved = getLocalStorageItem('id_Token');
    const guestToken = useAppSelector(guestTokenSelector);
    const decodedGuestToken = guestToken ? jwtDecode(guestToken) : '';
    const decodedTokenSaved = IDTokenSaved ? jwtDecode(IDTokenSaved) : '';
    const { IDToken } = loginObj || {};
    const decodedToken = IDToken ? jwtDecode(IDToken) : [];
    const entity = decodedTokenSaved['custom:entity'] || decodedToken['custom:entity'] || decodedGuestToken['custom:entity'];
    const isPublic = entity === 'Public';

    const {
        programmeLocation,
        programRadius,
        acceptStartDate,
        acceptEndDate,
        donationSubmitPeriod,
        donationSubmitStartDate,
        donationSubmitEndDate,
        outcomeDueDateRelativeDays,
        entitiesSupported,
        requiredDocuments,
        companyName,
        programName,
        applicationAcceptPeriod,
        programmeLocationType,
        updateDueDateRelativeInterval,
        opportunityStartWithinMonths,
        opportunityLengthMonths,
        status,
        opportunityCriteriaChecklist,
        coverImage,
        coverImageType,
        ID,
        companyID
    } = programData || guestProgramData || {};

    useEffect(() => {
        if (!localStorage.getItem('id_Token') && guestToken) {
            localStorage.setItem('id_Token', guestToken)
        }
    }, [guestToken])

    const setLogoImage = async fileKey => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogoS3URL(securedURL);
        return fileKey ? securedURL : '';
    };

    const getDaysUntilAlertPopUp = (dateStr) => {
        const date = dayjs(dateStr, 'YYYYMMDD');
        if (date.isValid() && date.isAfter(dayjs()) && date.isBefore(dayjs().add(10, 'day'))) {
            return date.diff(dayjs(), 'day');
        }
        return false;
    };

    useEffect(() => {
        if (!entity) {
            dispatch(getNewToken("action=getPublicToken"));
        }
    }, [entity])

    useEffect(() => {
        if (status && acceptEndDate && acceptEndDate) {
            if (status === 'OPEN') {
                const isDateOutsideAcceptanceRange = (status, acceptStartDate, acceptEndDate) =>
                    status === 'OPEN' &&
                    acceptEndDate &&
                    (dayjs().isBefore(dayjs(acceptStartDate)) || dayjs().isAfter(dayjs(acceptEndDate)));
                setDisableApply(true);
                setProgramClosedAcceptDate(isDateOutsideAcceptanceRange(status, acceptEndDate, acceptEndDate))
            }
            if (status === 'CLOSED') {
                setDisableApply(true);
                setProgramClosedPopUp(true)
            }
        }
    }, [status, acceptEndDate, acceptEndDate])

    const setLogoBannerImage = async fileKey => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogoImageURL(securedURL);
        return fileKey ? securedURL : '';
    };

    useEffect(() => {
        if (programName) {
            if (programmeURLCode) {
                dispatch(getProfileDataGuestCompany(`?companyID=${companyID}`))
            } else {
                dispatch(getProfileDataCompany())
            }
            if (coverImage) {
                const selectedImage = ProgStartData.bizGiveThemeImageData.find(el => el.id === coverImage);
                if (coverImageType === '2') {
                    setLogoImage(coverImage).then(savedCover => setSelectedBizGiveImage(savedCover));
                } else {
                    setSelectedBizGiveImage(selectedImage);
                }
                ProgStartData.bizGiveThemeImageData.forEach(item => {
                    if (item.id === coverImage) item.checked = true;
                });
            }
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [programName]);


    useEffect(() => {
        if (logoImage) {
            setLogoBannerImage(logoImage)
        }
    }, [logoImage])

    const onFinish = (values) => {
        if (isPublic) {
            navigate('/signup');
        } else {
            navigate('/impact-maker/applications/create/start');
        }
        setOpenModal(false)
    };

    const renderCoverImage = () => {
        if (selectedBizGiveImage?.path && typeof selectedBizGiveImage?.path === 'string') return <img alt="covercc" className="cameraIcon" src={selectedBizGiveImage.path} />;
        if (logoS3URL && typeof logoS3URL === 'string') return <img alt="coverdd" className="cameraIcon" src={logoS3URL} />;
        return '';
    };
    const updateDueDateMessage = getUpdateDueDateMessage({
        updateDueDateRelativeInterval,
    });

    const items = [
        {
            key: "programme",
            label: <StyledText as="p" variant="B3" className="title">
                {intl.formatMessage({ id: "programme" })}
            </StyledText>,
            children: <ProgramReview />,
        },
        {
            key: "company",
            label: <StyledText as="p" variant="B3" className="title">
                {intl.formatMessage({ id: "company" })}
            </StyledText>,
            children: <CompanySubTab/>,
        },
    ];

    const handlePrevious = () => {
        navigate("/discover")
    }

    const validateCheckboxGroup = (_, value) => {
        if (!value || value.length < opportunityCriteriaChecklist.length) {
            return Promise.reject(new Error(intl.formatMessage({ id: 'please_select' })));
        }
        return Promise.resolve();
    };


    return (
        <div className={baseClassName}>
            <HelmetAsync>
                <title>Programme Review Page</title>
            </HelmetAsync>
            <div className='leftContainer'>
                {getDaysUntilAlertPopUp(acceptEndDate) && <Alert
                    className="customAlert"
                    type="error"
                    showIcon
                    showAfterTime
                    position="static"
                    message={
                        <StyledText as="p" variant="H2" className="title">
                            {intl.formatMessage({ id: 'this_programme_is_closing_in_number_days' }).replace('number', getDaysUntilAlertPopUp(acceptEndDate))}
                        </StyledText>}

                    visible
                />}
                <div >
                    <Layout className="bannerImageContainer">
                        {renderCoverImage()}
                    </Layout>
                    <div className='companyTitleContainer'>
                        <div className='imageContainer'>{logoImageURL && <img src={logoImageURL} alt="logoImage" />}</div>
                        <div>
                            <StyledText as="p" variant="H1c" className="title">
                                {programName}
                            </StyledText>
                            <StyledText as="p" variant="H3" className="title">
                                {companyName}
                            </StyledText>
                        </div>
                        <div><Button variant="primary" disabled={disableApply} onClick={() => {
                            if (isPublic) {
                                localStorage.setItem('actions', JSON.stringify({ programId: ID, companyLogo: logoImageURL, programName, companyName, completed: false }))
                            }
                            setOpenModal(true)
                        }}>{intl.formatMessage({ id: 'apply' })}</Button></div>
                    </div>
                </div>
                {!isPublic && <Button
                    className='goBackButton'
                    variant="secondary"
                    type="button"
                    onClick={() => handlePrevious()}>
                    <StyledText as="p" variant="B3c">
                        {`< ${intl.formatMessage({ id: "go_back" })}`}
                    </StyledText>
                </Button>}
                <Tabs defaultActiveKey={items[0]} items={items} />
            </div>
            {<div className="rightSideContainer">
                {Array.isArray(programmeLocation?.features) && programmeLocation?.features?.length > 0 && programmeLocation?.features?.[0] &&
                    <FullScreenWrapper isFullscreen={isFullscreen}
                        setIsFullscreen={setIsFullscreen}>
                        <MapBoxAreaComponent
                            zoom={6}
                            defaultStyle="MonoCrome"
                            enableDraw
                            enableClick
                            id="submission"
                            programID={programID || programmeURLCode}
                            geometries={programmeLocation?.features}
                            savedGeometries={programmeLocation?.features}
                            isFullscreen={isFullscreen}
                            isReviewScreen
                        />
                    </FullScreenWrapper>
                }
                <div className="rightContainer">
                    {Array.isArray(programmeLocation?.features) && programmeLocation?.features?.length > 0 && <div className="locationContainer subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "location_supported" })}
                        </StyledText>
                        {['polygon', 'administrative', 'radius']?.includes(programmeLocationType) && programmeLocation?.features?.map((item) => <div key={item} className="locationIcon">
                            <EnvironmentFilled />
                            <StyledText as="p" variant="B3" key={item} className="city">
                                {item === 'anyLocation'
                                    ? intl.formatMessage({ id: 'any_location' })
                                    : item?.preferred_name}
                            </StyledText>
                        </div>)}
                        {programmeLocationType === 'radius' && <StyledText as="p" variant="B3" className="locationDistance">
                            {intl.formatMessage({ id: "project_must_be_within" })} {programRadius} KM {intl.formatMessage({ id: "of_this_location" })}
                        </StyledText>}
                    </div>}
                    <Divider />
                    <StyledText as="p" variant="H2" className="subContainer">
                        {intl.formatMessage({ id: "programme_dates" })}
                    </StyledText>
                    <Timeline
                        items={[
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "application_accepted_between" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /> <StyledText as="p" variant="B3c">
                                                {acceptStartDate?.trim() && acceptEndDate?.trim() ? (
                                                    <>
                                                        <DateFormatter dateValue={acceptStartDate?.trim()} /> - <DateFormatter dateValue={acceptEndDate?.trim()} />
                                                    </>
                                                ) : (
                                                    applicationAcceptPeriod
                                                )}
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "donation_to_be_made_between" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /><StyledText as="p" variant="B3c">
                                                {donationSubmitPeriod || <>
                                                    <DateFormatter dateValue={donationSubmitStartDate?.trim()} /> - <DateFormatter dateValue={donationSubmitEndDate?.trim()} />
                                                </>}
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "interim_update_requested" })}
                                    </StyledText>
                                        {<StyledText as="p" variant="B3c">
                                            {updateDueDateMessage}
                                        </StyledText>}
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "final_report_requested" })}
                                    </StyledText>
                                        {<StyledText as="p" variant="B3c">
                                            {outcomeDueDateRelativeDays?.trim() ? `${outcomeDueDateRelativeDays} days after the project end date` : 'Not Required'}
                                        </StyledText>}
                                    </>
                                ),
                            },
                        ]}
                    />
                    <Divider />
                    <div className="subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "project_timeline" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {`${intl.formatMessage({ id: "projects_must_start_within" })} `}<StyledText as="span" variant="B3d">{`${opportunityStartWithinMonths} `}</StyledText><StyledText as="span" variant="B3">{intl.formatMessage({ id: "months" })}</StyledText>
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {`${intl.formatMessage({ id: "projects_must_not_be_longer_than" })} `}<StyledText as="span" variant="B3d">{`${opportunityLengthMonths} `}</StyledText><StyledText as="span" variant="B3">{intl.formatMessage({ id: "months" })}</StyledText>
                        </StyledText>
                    </div>
                    <Divider />
                    <div className="subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "application_criteria" })}
                        </StyledText>
                        <StyledText as="p" variant="B3c">
                            {intl.formatMessage({ id: "entities_supported" })}
                        </StyledText>
                        {Array.isArray(entitiesSupported) && entitiesSupported?.length > 0 ? <ul>{entitiesSupported?.map((el) => <li key={el}> <StyledText as="span" variant="B3">
                            {el}
                        </StyledText></li>)}</ul> :
                            <div className="locationIcon">
                                <CheckOutlined />
                                <StyledText as="p" variant="B3">
                                    {intl.formatMessage({ id: "any" })}
                                </StyledText>
                            </div>}
                    </div>
                    <Divider />
                    {<div className="subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "documentation_required" })}
                        </StyledText>
                        <div className="locationIcon">
                            {Array.isArray(requiredDocuments) && requiredDocuments?.length > 0 ? <ul>{requiredDocuments?.map((el) => <li key={el}> <StyledText as="span" variant="B3">
                                {el}
                            </StyledText></li>)}</ul> : <><CheckOutlined /><StyledText as="p" variant="B3d">
                                {intl.formatMessage({ id: "none" })}
                            </StyledText></>}
                        </div>
                    </div>}
                </div>
            </div>}
            <CustomModal
                title={intl.formatMessage({ id: 'eligibility' })}
                open={openModal}
                onCancel={() => setOpenModal(false)}
                closable={false}>
                <Form
                    {...formItemLayout}
                    form={form}
                    className="eligibilityFormContainer"
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                >
                    {opportunityCriteriaChecklist?.length > 0 && <><StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "before_applying_please_confirm_your_eligibility" })}
                    </StyledText>
                        <Form.Item
                            name="checkboxGroup"
                            rules={[{ validator: validateCheckboxGroup }]}
                        >
                            <Checkbox.Group>
                                {opportunityCriteriaChecklist?.map((el) => typeof el === 'string' && (
                                    <div className="checkboxWrapper" key={el}>
                                        <Checkbox key={el} value={el} />
                                        <span className="checkboxText">{el}</span>
                                    </div>
                                ))}
                            </Checkbox.Group>
                        </Form.Item>
                    </>
                    }
                    <Form.Item
                        name="yesCheck"
                        valuePropName="checked"
                        rules={[{ required: true, message: intl.formatMessage({ id: 'please_select' }) }]}
                    >
                        <Checkbox value='yes'>
                            {intl.formatMessage({ id: "yes" })}
                        </Checkbox>
                    </Form.Item>
                    <div className='modalFooterButtonCenter'>
                        <Button
                            variant="secondary"
                            htmlType="button"
                            onClick={() => setOpenModal(false)}>
                            {intl.formatMessage({ id: "cancel" })}
                        </Button>
                        <Button
                            variant="primary"
                            htmlType="submit"
                            className='deleteButton'>
                            {intl.formatMessage({ id: "apply" })}
                        </Button>
                    </div>
                </Form>
            </CustomModal>
            <CustomModal
                title={intl.formatMessage({ id: 'this_programme_is_now_closed' })}
                open={programClosedAcceptStartDate}
                onOk={() => setProgramClosedAcceptDate(false)}
                onCancel={() => setProgramClosedAcceptDate(false)}
                closable
                footer={null}
            >
                <>
                    <StyledText as="p" variant="B3" className="title">
                        {`${intl.formatMessage({ id: 'the_application_window_is' })} `}<DateFormatter dateValue={acceptStartDate?.trim()} /> - <DateFormatter dateValue={acceptEndDate?.trim()} />
                    </StyledText>
                    {isPublic ? <StyledText as="p" variant="B3" className="title">
                        {intl.formatMessage({ id: "login_or_signup_into_bizGive_to_discover_new_funding_programmes" })}
                    </StyledText> : <StyledText as="p" variant="B3" className="title">
                        {intl.formatMessage({ id: "discover_more_programmes" })} <a href='/bizgive.app/impact-maker/discover'>here</a>
                    </StyledText>}
                    <Button
                        className='goBackButton'
                        variant="primary"
                        htmlType="button"
                        onClick={() => navigate('/signup')}>
                        {intl.formatMessage({ id: "start" })}
                    </Button>
                </>
            </CustomModal>
            <CustomModal
                title={intl.formatMessage({ id: 'this_programme_is_now_closed' })}
                open={programClosedPopUp}
                onOk={() => setProgramClosedPopUp(false)}
                onCancel={() => setProgramClosedPopUp(false)}
                closable
                footer={null}
            >
                <>
                    {isPublic ? <StyledText as="p" variant="B3" className="title">
                        {intl.formatMessage({ id: "login_or_signup_into_bizGive_to_discover_new_funding_programmes" })}
                    </StyledText> : <><StyledText as="p" variant="B3" className="title">
                        {intl.formatMessage({ id: "login_or_signup_into_bizGive_to_discover_new_funding_programmes" })}
                    </StyledText>
                        <StyledText as="p" variant="B3" className="title">
                            {intl.formatMessage({ id: "discover_more_programmes" })} <a href='/bizgive.app/impact-maker/discover'>here</a>
                        </StyledText>
                    </>}
                    <Button
                        className='goBackButton'
                        variant="primary"
                        htmlType="button"
                        onClick={() => navigate('/signup')}>
                        {intl.formatMessage({ id: "start" })}
                    </Button>
                </>
            </CustomModal>
        </div>
    )
}

const StyledDashboardPage = styled(ProgramReviewHomePage)`
    &.dashboardPage {
        background: ${(props) => props.theme.backgroundCanvas};
        min-height: 100vh;
        display: flex;
        padding: 20px;
        gap: 20px;

        .ant-tabs-tab-active .title {
            color: ${(props) => props.theme.bizGiveThemeColor} !important;
        }

        .ant-tabs-ink-bar {
            background: ${(props) => props.theme.bizGiveThemeColor} !important;
        }

        .goBackButton {
            width: max-content;
        }

        .companyTitleContainer {
            display: flex;
            padding: 10px 0px 20px 200px;
            justify-content: space-between;
            position: relative;
            align-items: center;

            .imageContainer {
                height: 144px;
                width: 144px;
                border-radius: 50%;
                top: -80px;
                left: 25px;
                position: absolute;

                img {
                    height: 144px;
                    width: 144px;
                    border-radius: 50%;
                    border: 1px solid black;
                }
            }
        }

        .bannerImageContainer {
            width: 100%;
            height: 216px;
            overflow: hidden;
            position: relative;
            background: white;
            border-radius: 10px;
        }

        .leftContainer {
            display: flex;
            flex-direction: column;
            gap: 35px;
            overflow: scroll;
            height: 90vh;
            width: 95%;

            svg {
                fill: #04ac9c !important;
            }

            .customAlert {
                svg {
                    fill: red !important;
                }
            }
        }

        .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
        }

        ul {
            margin: 0;
        }

        .ant-collapse-expand-icon {
            color: #313131 !important;
        }

        .mapIFrameStyle {
            border: 0;
        }

        .locationIcon {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .locationContainer {
            margin-top: 50px;

            .locationDistance {
                font-style: italic;
            }

            svg {
                color: #f96675;
            }
        }

        .subContainer {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .descriptionContainer {
                p, li {
                    font-size: 14px;
                    font-weight: 500;
                    color: #242424;
                    font-family: sans-serif;
                }
            }
        }

        .ant-timeline-item-head {
            background: transparent !important;
        }

        .anticon-check {
            color: #58b55e;
        }

        .rightSideContainer {
            overflow: scroll;
            height: 90vh;
            width: 25vw;

            .rightContainer {
                display: flex;
                flex-direction: column;
                gap: 20px;
                background: white;
                padding: 12px 16px;
            }
        }

        .ant-collapse {
            border: 0;
            border-bottom: 1px solid #D7DDE1;
            border-radius: 0px;

            .ant-collapse-header {
                background: white !important;
            }
        }

        .ant-collapse-content-active {
            border-top: 0;
        }
    }
`;


export default StyledDashboardPage;
