import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet as HelmetAsync } from "react-helmet-async";
import {
    EnvironmentFilled,
    StarFilled,
    CalendarFilled
} from "@ant-design/icons";
import clsx from "clsx";
import { Tabs, Layout, Divider, Timeline, Form, Checkbox } from 'antd';
import { useIntl } from 'react-intl';
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import {
    useAppSelector,
    useAppDispatch
} from "../../../redux/store";
import {
    programDataSelector,
    opportunityDataSelector,
    profileSelector,
    editOpportunityData
} from "../../../redux/selectors";
import MapBoxComponent from "../../../components/MapBoxComponent";
import FullScreenWrapper from '../../../components/FullscreenWrapper';
import { getProfileData, editOpportunity, addInteraction } from '../../../redux/actions';
import ProgStartData from '../bizGiveThemeImageData';
import { getLocalStorageItem, getSecuredUrl } from "../../../utils/utilities";
import DateFormatter from "../../../components/DateFormatter";
import ProgramReview from './CharityCreateReview';
import StyledText from "../../../components/StyledText";
import Button from '../../../components/Button';
import CustomModal from "../../../components/Modal";
import LinkComponent from "../../../components/LinkComponent";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const getUpdateDueDateMessage = ({
    updateDueDateRelativeDays,
    updateDueDate,
    currentBudgetStartDate,
    currentBudgetEndDate
}) => {
    if (updateDueDateRelativeDays) {
        if (currentBudgetStartDate && currentBudgetEndDate) {
            return `Every ${updateDueDateRelativeDays} days during the project running dates`;
        } else {
            return `${updateDueDateRelativeDays} days after the project start date`;
        }
    } else if (updateDueDate) {
        return updateDueDate.join(', ');
    } else {
        return 'Not Required';
    }
}

const ProgramReviewHomePage = ({ className }) => {
    const baseClassName = clsx("dashboardPage", className);
    const [openModal, setOpenModal] = useState(false);
    const [selectedBizGiveImage, setSelectedBizGiveImage] = useState({});
    const [logoS3URL, setLogoS3URL] = useState('');
    const [logoImageURL, setLogoImageURL] = useState('');
    const programData = useAppSelector(programDataSelector);
    const intl = useIntl();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const editOpp = useAppSelector(editOpportunityData) || {};
    const programReviewDetails = JSON.parse(getLocalStorageItem('programReviewDetails') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const dispatch = useAppDispatch();
    const { charity: { logoS3URL: logoImage, tradingName, } = {} } = useAppSelector(profileSelector) || {};
    const opportunityData = useAppSelector(opportunityDataSelector) || {};
    const [isFullscreen, setIsFullscreen] = useState(false);
    const { opportunityID } = editOpp || {};

    const {
        currentBudgetStartDate,
        currentBudgetEndDate,
        programmeLocation,
        updateDueDateRelativeDays,
        outcomeDueDateRelativeDays,
        applicationAgreement,
        companyName,
        privacyPolicyURL
    } = programData || {};
    const { opportunity:
        { startDate,
            endDate,
            coverImage,
            donationDeadLineDate,
            updateDueDate,
            outComeDate,
            opportunityName,
            coverImageType,
            opportunityLocation2: {
                features
            } = {},
        } = {} }
        = opportunityData || {};


    const setLogoImage = async fileKey => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogoS3URL(securedURL);
        return fileKey ? securedURL : '';
    };

    const setLogoBannerImage = async fileKey => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogoImageURL(securedURL);
        return fileKey ? securedURL : '';
    };

    useEffect(() => {
        if (opportunityID) {
            navigate("/impact-maker/applications/pending");
        }
    }, [opportunityID])

    useEffect(() => {
        if (opportunityName) {
            dispatch(getProfileData())
            form.setFieldsValue({
                opportunityName: opportunityName,
                coverImageType: coverImageType,
                coverImage: coverImage,
            });

            if (coverImage) {
                const selectedImage = ProgStartData.bizGiveThemeImageData.find(el => el.id === coverImage);
                if (coverImageType === '2') {
                    setLogoImage(coverImage).then(savedCover => setSelectedBizGiveImage(savedCover));
                } else {
                    setSelectedBizGiveImage(selectedImage);
                }
                ProgStartData.bizGiveThemeImageData.forEach(item => {
                    if (item.id === coverImage) item.checked = true;
                });
            }
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [opportunityName]);

    useEffect(() => {
        if (logoImage) {
            setLogoBannerImage(logoImage)
        }
    }, [logoImage])

    const renderCoverImage = () => {
        if (selectedBizGiveImage?.path && typeof selectedBizGiveImage?.path === 'string') return <img alt="covercc" className="cameraIcon" src={selectedBizGiveImage.path} />;
        if (logoS3URL && typeof logoS3URL === 'string') return <img alt="coverdd" className="cameraIcon" src={logoS3URL} />;
        return '';
    };

    const updateDueDateMessage = getUpdateDueDateMessage({
        updateDueDateRelativeDays,
        updateDueDate,
        currentBudgetStartDate,
        currentBudgetEndDate
    });

    const onFinish = () => {
        form.validateFields().then(values => {
            const data = {
                applicationAgreementConfirmation: values.applicationAgreementConfirmation ? 'Y' : 'N',
                privacyPolicyConfirmation: values.privacyPolicyConfirmation ? 'Y' : 'N',
                opportunityID: opportunityId,
                appliedDate: new Date().toISOString().slice(0, 10).replace(/-/g, ''),
                privacyPolicyLink: privacyPolicyURL,
                applicationAgreementTerms: applicationAgreement,
                programID,
                status: 'OPEN'
            };
            dispatch(editOpportunity(data));
            dispatch(addInteraction({
                programID,
                opportunityID: opportunityId,
                action: "Apply"
            }))

        }).catch(errorInfo => {
            console.log('Validation Failed:', errorInfo);
        });
    };

    const items = [
        {
            key: "programme",
            label: <StyledText as="p" variant="B3" className="title">
                {intl.formatMessage({ id: "application" })}
            </StyledText>,
            children: <ProgramReview />,
        },
    ];

    const handleProceed = () => {
        setOpenModal(true)
    };

    const handleSaveAndExit = () => {
        setOpenModal(true)
    };

    const handlePrevious = () => {
        navigate("/impact-maker/applications/create/admin-details");
    };

    return (
        <div className={baseClassName}>
            <HelmetAsync>
                <title>Programme Review Page</title>
            </HelmetAsync>
            <div className='leftContainer'>
                <div >
                    <Layout className="bannerImageContainer">
                        {renderCoverImage()}
                    </Layout>
                    <div className='companyTitleContainer'>
                        <div className='imageContainer'>{logoImageURL && <img src={logoImageURL} alt="logoImage" />}</div>
                        <div>
                            <StyledText as="p" variant="H1c" className="title">
                                {opportunityName}
                            </StyledText>
                            <StyledText as="p" variant="H3" className="title">
                                {tradingName}
                            </StyledText>
                        </div>
                        {/* <div className='titleButtonContainer'>
                            <Button variant="primary" className="declineButton" onClick={() => setOpenModal(true)}>{intl.formatMessage({ id: 'decline' })}</Button>
                            <Button variant="primary" onClick={() => setOpenModal(true)}>{intl.formatMessage({ id: 'approve' })}</Button>
                        </div> */}
                    </div>
                </div>
                <Tabs defaultActiveKey={items[0]} items={items} />
            </div>
            <CustomModal
                title={intl.formatMessage({ id: 'submit_your_application' })}
                open={openModal}
                onCancel={() => setOpenModal(false)}
                closable={false}>
                <div
                    {...formItemLayout}
                    form={form}
                    className="createSubmitContainer"
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                >
                    <div>
                        <StyledText as="span" variant="B3">
                            {intl.formatMessage({ id: "to_submit_your_application_you_must_read_and_agree_to_the_application" })}
                        </StyledText>
                        <StyledText as="span" variant="B3d">
                            {` ${intl.formatMessage({ id: "terms_and_conditions" })} `}
                        </StyledText>
                        <StyledText as="span" variant="B3">
                            {intl.formatMessage({ id: "below" })}
                        </StyledText>
                    </div>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "please_note_you_will_not_be_able_to_edit_your_application_after_it_has_been_submitted_unless_requested_to_by_the_company_youre_applying_to" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "by_submitting_this_application_you_will_be_sharing_your_application_user_details_and_ongoing_entity_profile_information_with_the_funder" })}
                    </StyledText>
                    <Divider />
                    <StyledText as="p" variant="B3">
                        {ReactHtmlParser(applicationAgreement)}
                    </StyledText>
                    <Form
                        {...formItemLayout}
                        form={form}
                        className="createSubmitContainer"
                        name="register"
                        onFinish={onFinish}
                        scrollToFirstError
                    >

                        <div className='submitModalCheckboxContainer'>
                            <Form.Item
                                name="applicationAgreementConfirmation"
                                valuePropName="checked"
                                rules={[{ required: true, message: intl.formatMessage({ id: 'please_select' }) }]}
                            >
                                <Checkbox>
                                    <StyledText as="span" variant="B3">
                                        {intl.formatMessage({ id: "i_agree" })}
                                    </StyledText>
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="privacyPolicyConfirmation"
                                valuePropName="checked"
                                rules={[{ required: true, message: intl.formatMessage({ id: 'please_select' }) }]}
                            >
                                <Checkbox>
                                    <StyledText as="span" variant="B3">
                                        <LinkComponent href={`https://${privacyPolicyURL}`}><StyledText as="span" variant="B3">
                                            {`${companyName} `}
                                        </StyledText>
                                            <StyledText as="span" variant="B3b">
                                                {` ${intl.formatMessage({ id: 'privacy_policy' })}`}
                                            </StyledText></LinkComponent>
                                    </StyledText>
                                </Checkbox>
                            </Form.Item>
                        </div>
                        <div className='modalFooterButtonCenter'>
                            <Button
                                variant="secondary"
                                htmlType="button"
                                onClick={() => setOpenModal(false)}>
                                {intl.formatMessage({ id: "cancel" })}
                            </Button>
                            <Button
                                variant="primary"
                                htmlType="submit"
                                className='deleteButton'>
                                {intl.formatMessage({ id: "submit" })}
                            </Button>
                        </div>
                    </Form>
                </div>
            </CustomModal>
            <div className="rightSideContainer">
                <FullScreenWrapper isFullscreen={isFullscreen}
                    setIsFullscreen={setIsFullscreen}>
                    <MapBoxComponent
                        zoom={4}
                        defaultStyle="MonoCrome"
                        id="submission"
                        markers={features}
                        geoJsonData={programmeLocation}
                        hideStyles
                        isFullscreen={isFullscreen}
                        hideZoomControls
                        styles="mapbox://styles/mapbox/outdoors-v12"
                    />
                </FullScreenWrapper>
                <div className="rightContainer">
                    <div className="locationContainer subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "project_location" })}
                        </StyledText>
                        {features?.map((item) => <div key={item} className="locationIcon">
                            <EnvironmentFilled />
                            <StyledText as="p" variant="B3" key={item} className="city">
                                {item?.place_name === 'anyLocation'
                                    ? intl.formatMessage({ id: 'any_location' })
                                    : (item.preferred_name || item?.place_name)}
                            </StyledText>
                        </div>)}
                    </div>
                    <Divider />
                    <StyledText as="p" variant="H2" className="subContainer">
                        {intl.formatMessage({ id: "project_timelines" })}
                    </StyledText>
                    <Timeline
                        items={[
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "project_running_dates" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /> <StyledText as="p" variant="B3c">
                                                <>
                                                    <DateFormatter dateValue={startDate?.trim()} /> - <DateFormatter dateValue={endDate?.trim()} />
                                                </>
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "donations_required_before" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /><StyledText as="p" variant="B3c">
                                                <DateFormatter dateValue={donationDeadLineDate?.trim()} />
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "interim_updates" })}
                                    </StyledText>
                                        {Array.isArray(updateDueDate) && updateDueDate?.map((el) => <div key={el} className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /><StyledText as="p" variant="B3c">
                                                {el ? <DateFormatter dateValue={el} /> : updateDueDateMessage}
                                            </StyledText></div>)}
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "final_report" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /><StyledText as="p" variant="B3c">
                                                {<DateFormatter dateValue={outComeDate?.trim()} /> || `${outcomeDueDateRelativeDays} after the project end date`}
                                            </StyledText></div>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
            <div className='buttonContainer'>
                <Divider />
                <div className='buttonContentContainer'>
                    <div className='nextAndPreviousContainer'>
                        <Button
                            variant="secondary"
                            type="button"
                            onClick={() => handlePrevious()}>
                            {intl.formatMessage({ id: "previous" })}
                        </Button>
                        <Button
                            variant="primary"
                            type="button"
                            onClick={() => handleProceed()}
                        >
                            {intl.formatMessage({ id: "submit" })}
                        </Button>
                    </div>
                    <div className="saveAndExitButton">
                        <Button
                            variant="secondary"
                            htmlType="button"
                            onClick={() => handleSaveAndExit()}>
                            {intl.formatMessage({ id: "save_n_exit" })}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const StyledDashboardPage = styled(ProgramReviewHomePage)`
  &.dashboardPage {
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    padding: 20px;
    gap: 20px;
        .radioGroup {
            display: flex;
            flex-direction: column;
        }

        .bizgiveImageContainer {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            .imageContainer {
                position: relative;

                &:hover {
                    transform: scale(2);
                    transition: transform .2s, -webkit-transform .2s;
                    z-index: 99;
                }

                img {
                    height: 40px;
                    width: 120px;
                }

                .checkedCircle {
                    position: absolute;
                    right: 2px;
                    top: 2px;
                }
            }
        }

    .declineButton{
        background: #ed6c2d !important;
        border: 1px solid #ed6c2d !important;
    }

    .titleButtonContainer{
        display: flex;
        gap: 20px;
    }

    .ant-tabs-tab-active .title {
      color: ${(props) => props.theme.bizGiveThemeColor} !important;
    }

    .ant-tabs-ink-bar {
      background: ${(props) => props.theme.bizGiveThemeColor} !important;
    }

    .bannerImageContainer {
      width: 100%;
      overflow: hidden;
      background: white;
      border-radius: 10px;
      position: relative;
    }

    .leftContainer {
      display: flex;
      flex-direction: column;
      gap: 35px;
      overflow: scroll;
      height: 90vh;
      width: 95%;

      svg {
        fill: #04ac9c !important;
      }
    }

    .companyTitleContainer {
      display: flex;
      padding: 10px 0px 20px 200px;
      justify-content: space-between;
      position: relative;
      align-items: center;

      .imageContainer {
        height: 144px;
        width: 144px;
        border-radius: 50%;
        border: 1px solid black;
        background-color: grey;
        top: -80px;
        left: 25px;
        position: absolute;
        img{
            height: 144px;
    width: 144px;
    border-radius: 50%;
    border: 1px solid black;
        }
      }
    }

    .spinner {
      z-index: 2000;
      margin: auto;
      left: 50%;
      right: 50%;
      position: absolute;
      bottom: 50%;
    }

    ul {
      margin: 0;
    }

    .ant-collapse-expand-icon {
      color: #313131 !important;
    }

    .mapIFrameStyle {
      border: 0;
      position: relative;
      height: 300px;
    }

    .locationIcon {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .locationContainer {
      .locationDistance {
        font-style: italic;
      }

      svg {
        color: #f96675;
      }
    }

    .webLinks{
        a{
            display: flex;
            align-items: center;
        }
    }

    .subContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .ant-timeline-item-head {
      background: transparent !important;
    }

    .anticon-check {
      color: #58b55e;
    }

    .rightSideContainer {
      overflow: scroll;
      height: 90vh;
      margin-top: 5px;
      width: 25vw;

      .rightContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        background: white;
        padding: 12px 16px;
      }
    }

    .ant-collapse {
      border: 0;
      border-bottom: 1px solid #D7DDE1;
      border-radius: 0px;

      .ant-collapse-header {
        background: white !important;
      }
    }

    .ant-collapse-content-active {
      border-top: 0;
    }
    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
  }
`;

export default StyledDashboardPage;
