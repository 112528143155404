import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import styled from "styled-components";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Radio, Spin, Input } from "antd";
import { getLocalStorageItem } from "../../utils/utilities";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import { updateProgram, toggleSpinner, setUpdateProgram, getProgramById } from "../../redux/actions";
import { programDataSelector, updateProgramSelector, toggleSpinnerSelector, createProgramSelector } from "../../redux/selectors";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import MapBoxAreaComponent from "../../components/MapBoxAreaComponent"

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const CompanyCreateName = ({ className }) => {
    const [savedClicked, setSavedClicked] = useState(false);
    const [geometries, setGeometries] = useState([]);
    const [showError, setShowError] = useState('');
    const [isSet, setIsSet] = useState(false);
    const intl = useIntl();
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const programData = useAppSelector(programDataSelector);
    const { programID: createProgramID } = useAppSelector(createProgramSelector) || {};
    const updateProgramData = useAppSelector(updateProgramSelector) || {};
    const programReviewDetails = JSON.parse(getLocalStorageItem('createdProgramID') || '{}');
    const { programID } = programReviewDetails || {};
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const { programID: updateProgramID } = updateProgramData || {};
    const { programmeLocationType } = formData || {};

    useEffect(() => {
        if (programmeLocationType) {
            setGeometries([])
        }
    }, [programmeLocationType]);

    useEffect(() => {
        if (geometries?.length) {
            setShowError('')
        }
    }, [geometries]);

    useEffect(() => {
        if ((updateProgramID || createProgramID) && !savedClicked) {
            dispatch(setUpdateProgram(''))
            dispatch(toggleSpinner(false));
            navigate("/company/programme/create/details");
        } else if (savedClicked) {
            dispatch(toggleSpinner(false));
            navigate(`/company/programmes/review/${programID}`);
        }
    }, [updateProgramID, createProgramID, savedClicked]);

    useEffect(() => {
        if (programData?.programName) {
            if (isSet || geometries?.length) return
            const pointers = programData?.programmeLocation?.features?.map((el) => {
                return {
                    [el.id]: el?.preferred_name
                }
            }
            )
            const data = {
                ...(programData?.programmeLocationType && { programmeLocationType: programData?.programmeLocationType }),
                ...pointers
            };
            form.setFieldsValue(data);
            setFormData(data);
            if (programData?.programmeLocation?.features) {
                setGeometries(programData?.programmeLocation?.features);
                setTimeout(() => {
                    setIsSet(true);
                }, 100)
            }
        }
    }, [programData, geometries]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
        }
    }, [dispatch, programID]);

    const handleProceed = () => {
        if (!geometries.length || !geometries.every((el) => el.preferred_name)) return setShowError(intl.formatMessage({ id: "please_save_a_location" }))
        const updateProgramData = {
            programmeLocation: {
                features: geometries?.map((el) => ({
                    ...el,
                })),
                type: "FeatureCollection",
            },
            programmeLocationType,
            programID: programID
        }
        dispatch(toggleSpinner(true));
        dispatch(updateProgram(updateProgramData));
    };

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            const updateProgramData = {
                programmeLocation: geometries,
                programID: programID
            }
            dispatch(updateProgram(updateProgramData));
            dispatch(toggleSpinner(true));
        }, 300);
    }

    const handlePrevious = () => navigate("/company/programme/create/name");

    const onFormChange = (_, allValues) => {
        setFormData(allValues);
    };

    const debouncedHandleLocationChange = (e, marker) => {
        const newLocationMapData = geometries.map((location) => {
            const existingLocation = location.id && marker?.id ? (location.id === marker?.id) : (location?.properties?.place_id === marker?.properties?.place_id)
            return existingLocation
                ? {
                    ...location,
                    preferred_name: e.target.value
                }
                : location
        }
        );
        setGeometries(newLocationMapData);
    };

    const removeMarker = (id) => {
        setGeometries(prev => prev.filter(marker => marker?.id !== id));
    };

    return (
        <div className={clsx("createProfile", className)}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <StyledText as="p" variant="H1" className='mainTitle'>
                {intl.formatMessage({ id: "program_location" })}
            </StyledText>
            <div>
                <Form
                    {...formItemLayout}
                    labelAlign="left"
                    form={form}
                    onFinish={handleProceed}
                    onValuesChange={onFormChange}
                    className="formContainer"
                    scrollToFirstError
                >
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "what_is_the_area_of_impact" })}
                    </StyledText>
                    <Form.Item
                        name="programmeLocationType"
                        rules={[
                            { whitespace: true, required: true, message: intl.formatMessage({ id: "enter" }) }
                        ]}
                    >
                        <Radio.Group className="radioGroup">
                            <Radio className="radio" value="polygon">
                                <StyledText as="p" variant="B3">
                                    {intl.formatMessage({ id: "a_custom_area" })}
                                </StyledText>
                            </Radio>
                            <Radio className="radio" value="administrative">
                                <StyledText as="p" variant="B3">
                                    {intl.formatMessage({ id: "an_administrative_boundary_eg_a_council_area_or_country" })}
                                </StyledText>
                            </Radio>
                            <Radio className="radio" value="radius">
                                <StyledText as="p" variant="B3">
                                    {intl.formatMessage({ id: "a_certain_distance_from_a_point" })}
                                </StyledText>
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    {showError && <StyledText as="p" variant="B3" className='error'>
                        {showError}
                    </StyledText>}
                    {programmeLocationType && <>
                        {programmeLocationType === 'polygon' && <StyledText as="p" variant="B3b">
                            {intl.formatMessage({ id: "click_on_the_map_to_start_drawing_your_area" })}
                        </StyledText>}
                        {programmeLocationType === 'administrative' && <StyledText as="p" variant="B3b">
                            {intl.formatMessage({ id: "use_the_search_box_to_find_administrative_boundaries" })}
                        </StyledText>
                        }
                        {programmeLocationType === 'radius' && <StyledText as="p" variant="B3b">
                            {intl.formatMessage({ id: "click_on_the_map_to_add_a_point" })}
                        </StyledText>}
                        <div className="mapBoxContainer">
                            <MapBoxAreaComponent
                                zoom={10}
                                defaultStyle="MonoCrome"
                                enableDraw
                                enableClick
                                id="submission"
                                selectedMode={programmeLocationType}
                                programID={programID}
                                setGeometries={setGeometries}
                                geometries={geometries}
                                savedGeometries={programData?.programmeLocation?.features}
                                flyToZoom={8.5}
                            />
                        </div>
                        {geometries?.length > 0 && <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "what_is_the_area_called" })}
                        </StyledText>}
                        {geometries?.map((marker) => {
                            return (
                                <Form.Item
                                    key={marker?.properties?.place_id || Number(marker?.geometry?.id) || Number(marker?.id)}
                                    name={marker?.properties?.place_id || Number(marker?.geometry?.id) || Number(marker?.id)}
                                    className='customFormItem'
                                    rules={[
                                        {
                                            required: !(marker?.properties?.display_name || marker?.preferred_name),
                                            message: intl.formatMessage({ id: "please_enter" }),
                                        },
                                    ]}
                                >
                                    <div className='inputContainer'>
                                        <Input
                                            id={marker?.id || marker?.properties?.display_name}
                                            defaultValue={marker?.properties?.display_name}
                                            value={marker?.properties?.display_name || marker?.preferred_name}
                                            className="locationNameInput"
                                            onChange={(e) => debouncedHandleLocationChange(e, marker)}
                                            placeholder={intl.formatMessage({ id: "enter" })}
                                        />
                                        <button className="removeIcon" onClick={() => removeMarker(marker?.id)}><MinusCircleOutlined style={{ fontSize: '20px', color: '#04ac9c' }} /></button>
                                    </div>
                                </Form.Item>
                            )
                        }
                        )
                        }
                    </>}
                    <div className='buttonContainer'>
                        <Divider />
                        <div className='buttonContentContainer'>
                            <div className='nextAndPreviousContainer'>
                                <Button variant="secondary" type="button" onClick={handlePrevious}>
                                    {intl.formatMessage({ id: "previous" })}
                                </Button>
                                <Button variant="primary" htmlType="submit">
                                    {intl.formatMessage({ id: "next" })}
                                </Button>
                            </div>
                            <div className="saveAndExitButton">
                                <Button variant="secondary" type="button" onClick={handleSaveAndExit}>
                                    {intl.formatMessage({ id: "save_n_exit" })}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

const StyledCompanyCreateName = styled(CompanyCreateName)`
    &.createProfile {
        background: white;
        display: flex;
        padding: 20px;
        flex-direction: column;
        min-height: 150svh;
        .error{
        color: red !important;
    }
    .inputContainer{
        display: flex;
        gap: 10px;
        width: 30vw;
    }
        .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
            .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
        }

        .mainTitle {
            margin-bottom: 20px;
        }

        .mapBoxContainer {
        position: relative;
        width: 50vw;
    }

        .formContainer {
            display: flex;
            gap: 5px;
            flex-direction: column;
            width: 1000px;
            .customFormItem{
            .ant-form-item-control-input-content{
                display: flex !important;
            }  
        }
        }
        .removeIcon{
        background: transparent !important;
        border: none !important;
        cursor: pointer !important;
    }

        .buttonContainer {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            position: fixed;
            width: 100%;
            bottom: 0px;
            background: white;
            margin-left: -80px;

            .buttonContentContainer {
                display: flex;
                justify-content: center;
                width: 100%;
                position: relative;

                .saveAndExitButton {
                    position: absolute;
                    right: 0;
                    margin-bottom: 15px;
                }

                .nextAndPreviousContainer {
                    display: flex;
                    gap: 20px;
                    margin-bottom: 15px;
                }
            }
        }

        .radioGroup {
            display: flex;
            flex-direction: column;
        }
    }
`;

export default StyledCompanyCreateName;
