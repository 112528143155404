import React, { useState, useEffect, useRef } from 'react';
import clsx from "clsx";
import mapboxgl from 'mapbox-gl';
import styled from "styled-components";
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { Input, Modal, List } from 'antd';
import Button from './Button';
import axios from 'axios';
import { getProgramById } from '../redux/actions';
import { programDataSelector } from '../redux/selectors';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { mapBoxToken } from '../config';
import { useDebounce } from "../utils/utilities";

const { Search } = Input;

const getMiddleCoordinate = (coordinates) => {
    const midIndex = Math.floor(coordinates.length / 2);
    return coordinates[midIndex];
};

const MapBoxAreaComponent = ({ className, selectedMode, programID, setGeometries, geometries, savedGeometries, isFullscreen, flyToZoom, isReviewScreen }) => {
    const [draw, setDraw] = useState(null);
    const [mapInstance, setMapInstance] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [clickedCoordinates, setClickedCoordinates] = useState(null);
    const [circleRadius, setCircleRadius] = useState('');
    const [circleModalVisible, setCircleModalVisible] = useState(false);
    const [mapZoom, setMapZoom] = useState(1);
    const [mapCenter, setMapCenter] = useState([0, 0]);
    const mapContainerRef = useRef(null);
    const programData = useAppSelector(programDataSelector);
    const dispatch = useAppDispatch();
    const { programmeLocation: { geometry } = {} } = programData || {};

    const debouncedSearch = useDebounce(searchTerm, 100) || ''

    useEffect(() => {
        if (geometry?.coordinates && selectedMode === 'radius' && draw) {
            const circleFeature = {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    id: geometry.coordinates?.[0]?.[0]?.[0],
                    coordinates: [geometry?.coordinates?.[0]],
                },
            };
            draw.add(circleFeature);
            if (mapInstance) {
                mapInstance.fitBounds(circleFeature.geometry.coordinates[0], { padding: 300 });
                setGeometries([circleFeature]);
            }
        }
    }, [geometry, selectedMode]);

    useEffect(() => {
        if (savedGeometries?.length && draw && mapInstance) {
            const currentFeatures = draw.getAll().features;
            currentFeatures.forEach((feature) => {
                draw.delete(feature.id);
            });
            savedGeometries.forEach((geoFeature) => {
                draw.add(geoFeature);
            });
            if (savedGeometries.length > 0 && savedGeometries[0].geometry.type === 'Polygon') {
                const bounds = savedGeometries[0].geometry.coordinates[0].reduce((bounds, coord) => {
                    return bounds.extend(coord);
                }, new mapboxgl.LngLatBounds(savedGeometries[0].geometry.coordinates[0][0], savedGeometries[0].geometry.coordinates[0][0]));
                mapInstance.fitBounds(bounds, { padding: 50 });
                const midPoints = getMiddleCoordinate(savedGeometries[0].geometry.coordinates[0]);
                mapInstance.flyTo({ center: midPoints, zoom: flyToZoom || 8 });
            }
        }
    }, [savedGeometries, draw, mapInstance]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Delete') {
                if (draw && mapInstance) {
                    const currentGeometries = draw.getAll().features;
                    const geometryIds = geometries?.map((geometry) => geometry.id);
                    currentGeometries.forEach((mapFeature) => {
                        const mapFeatureId = mapFeature.id;
                        if (!geometryIds?.includes(mapFeatureId)) {
                            draw.delete(mapFeatureId);
                        }
                    });
                    setGeometries(currentGeometries)
                }
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [geometries]);

    useEffect(() => {
        if (mapInstance && selectedMode === 'radius') {
            const customCursor = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' id='pin'%3E%3Cpath fill='%23f05542' d='M8 1C5.239 1 3 3.357 3 6.264S8 15 8 15s5-5.829 5-8.736C13 3.357 10.761 1 8 1zm0 2.925a1.667 1.755 0 0 1 1.667 1.754A1.667 1.755 0 0 1 8 7.434a1.667 1.755 0 0 1-1.667-1.755A1.667 1.755 0 0 1 8 3.925z'%3E%3C/path%3E%3C/svg%3E"), auto`;
            mapInstance.getCanvas().style.cursor = customCursor;
        }
    }, [mapInstance, selectedMode]);

    useEffect(() => {
        mapboxgl.accessToken = mapBoxToken;
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: mapCenter,
            zoom: mapZoom,
        });

        map.on('load', () => {
            const drawInstance = new MapboxDraw({
                displayControlsDefault: false,
            });
            if (!flyToZoom) {
                map.addControl(new mapboxgl.NavigationControl({
                    visualizePitch: true
                }));
            }
            setMapInstance(map);
            map.addControl(drawInstance);
            setDraw(drawInstance);
            map.on('click', (e) => {
                if (selectedMode === 'radius') {
                    setClickedCoordinates(e.lngLat);
                    setCircleModalVisible(true);
                }
            });

            map.on('draw.create', (e) => {
                if (setGeometries) {
                    setGeometries(e.features);
                }
            });

            map.on('zoom', () => {
                setMapZoom(map.getZoom());
            });

            map.on('moveend', () => {
                setMapCenter(map.getCenter().toArray());
            });
        });
    }, [selectedMode, isFullscreen, flyToZoom]);

    useEffect(() => {
        if (mapInstance && (selectedMode === 'radius' || selectedMode === 'polygon') && !savedGeometries?.length) {
            mapInstance.setZoom(mapZoom);
            mapInstance.setCenter(mapCenter);
        }
    }, [selectedMode, mapInstance, mapZoom]);

    useEffect(() => {
        if (draw && mapInstance && !isReviewScreen) {
            const currentGeometries = draw.getAll().features;
            const geometryIds = geometries?.map((geometry) => geometry.id);
            currentGeometries.forEach((mapFeature) => {
                const mapFeatureId = mapFeature.id;
                if (!geometryIds?.includes(mapFeatureId) && selectedMode !== 'radius') {
                    draw.delete(mapFeatureId);
                } else if (selectedMode === 'radius' && geometries?.length === 0) {
                    draw.delete(mapFeatureId);
                }
            });
        }
    }, [geometries, draw, mapInstance]);

    useEffect(() => {
        if (draw && !geometries?.length) {
            if (selectedMode === 'polygon') {
                draw.changeMode('draw_polygon');
            } else {
                draw.changeMode('simple_select');
            }
        }
    }, [selectedMode, draw, geometries]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const fetchSearchResults = async () => {
        if (debouncedSearch) {
            const response = await fetch(
                `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(debouncedSearch)}&polygon_geojson=1&polygon_threshold=0.4&format=jsonv2`
            );
            const data = await response.json();
            setSearchResults(data);
        }
    };

    useEffect(() => {
        if (selectedMode === 'administrative' && debouncedSearch) {
            fetchSearchResults();
        } else if (selectedMode === 'administrative') {
            setSearchResults([]);
            setSearchTerm('');
        }
    }, [debouncedSearch, selectedMode]);

    const handleResultClick = (feature) => {
        if (feature && feature?.geojson) {
            if (draw) draw.add(feature?.geojson);
            if (mapInstance) mapInstance.flyTo({ center: [feature?.lon, feature?.lat], zoom: 3 });
            setTimeout(() => {
                const currentGeometries = draw.getAll().features;
                const nonMatchingGeometries = geometries?.length > 0 ? currentGeometries?.filter(currentGeometry =>
                    !geometries?.some(geometry => geometry?.id === currentGeometry?.id)
                ) : currentGeometries;
                setGeometries(prev => (prev ? [...prev, ...nonMatchingGeometries] : [...nonMatchingGeometries]));
                mapInstance.fitBounds(nonMatchingGeometries?.[0]?.geometry.coordinates[0], { padding: 150 });
            }, 500);
            setSearchResults([]);
            setSearchTerm('');
        } else {
            alert('No bounding box available for this location');
        }
    };

    const handleCircleRadiusChange = (e) => {
        setCircleRadius(e.target.value);
    };

    const handleCircleSubmit = async () => {
        if (!clickedCoordinates || !circleRadius) return;
        const { lng, lat } = clickedCoordinates;
        const radiusInKilometers = parseFloat(circleRadius);

        const circleFeature = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [lng, lat],
            },
        };

        const payload = {
            programmeLocationType: 'radius',
            programmeLocation: {
                type: 'FeatureCollection',
                features: [circleFeature],
            },
            programRadius: radiusInKilometers,
            programID,
            action: "createCircle"
        };

        try {
            const token = localStorage.getItem('id_Token');
            const response = await axios({
                method: 'POST',
                url: 'https://api.dev.bizgive.app/api/program/update',
                headers: {
                    Authorization: token,
                },
                data: payload,
            });
            dispatch(getProgramById(response?.data?.programID));
        } catch (error) {
            console.error('Error submitting circle data:', error);
        }

        setCircleModalVisible(false);
        setCircleRadius('');
    };

    return (
        <div className={clsx("companyLocationMap", className)}>
            {selectedMode === 'administrative' && (
                <div>
                    <Search
                        placeholder="Search for a location"
                        value={searchTerm}
                        onChange={(e) => handleSearchChange(e)}
                        enterButton={<Button variant="primary" type="button" className='searchButton'>Search</Button>}
                        style={{ marginBottom: '10px' }}
                    />
                    {searchResults?.length > 0 && (
                        <List
                            bordered
                            dataSource={searchResults}
                            renderItem={(feature) => (
                                <List.Item
                                    onClick={() => handleResultClick(feature)}
                                    style={{ cursor: 'pointer' }}
                                    className='listItem'
                                >
                                    <div>
                                        <span>{feature?.display_name}</span>
                                        <span style={{ marginLeft: '10px', color: 'grey' }}>
                                            {feature?.category}
                                        </span>
                                    </div>
                                </List.Item>
                            )}
                        />
                    )}
                </div>
            )}
            <div id="map" ref={mapContainerRef} style={{ width: '100%', height: '350px' }}></div>
            {clickedCoordinates && selectedMode === 'radius' && (
                <Modal
                    title={geometries?.length ? "Only One Circle can be added" : "Enter Circle Radius"}
                    open={circleModalVisible && !geometries?.length}
                    onOk={() => { geometries?.length ? setCircleModalVisible(false) : handleCircleSubmit() }}
                    onCancel={() => setCircleModalVisible(false)}
                >
                    <Input
                        type="number"
                        value={circleRadius}
                        onChange={handleCircleRadiusChange}
                        placeholder="Enter radius in kilometers"
                        min={0}
                        disabled={geometries?.length}
                    />
                </Modal>
            )}
        </div>
    );
};

const StyledMapBoxAreaComponent = styled(MapBoxAreaComponent)`
    &.companyLocationMap {
        .listItem{
            cursor: pointer;
            transition: background-color 0.3s;
            width: 100%;
            justify-content: space-between;
            display: flex;
            &:hover {
                background-color: rgba(96, 248, 234, 0.5);
            }
        }
    }
`;

export default StyledMapBoxAreaComponent;
