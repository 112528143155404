import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import axios from '../../utils/axios';
import Config from '../../config';
import { useNavigate } from "react-router-dom";
import { Divider, Form, Input, Spin, Space, Button as AntdButton, Upload, message } from "antd";
import { getLocalStorageItem, setLocalStorageItem, uploadFile } from "../../utils/utilities";
import styled from "styled-components";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import { addInteraction, getProgramById, updateProgram, getOpportunity, setCreateProgram, toggleSpinner, setUpdateProgram } from "../../redux/actions";
import { programDataSelector, createOpportunitySelector, updateProgramSelector, toggleSpinnerSelector, createProgramSelector } from "../../redux/selectors";
import { useAppDispatch, useAppSelector } from "../../redux/store";

const { TextArea } = Input;

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const CompanyCreateDetails = ({ className }) => {
    const [opportunitySummary, setOpportunitySummary] = useState('');
    const [savedClicked, setSavedClicked] = useState(false);
    const intl = useIntl();
    const [form] = Form.useForm();
    const [pictures, setPictures] = useState([]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const programData = useAppSelector(programDataSelector) || {};
    const createOpportunityData = useAppSelector(createOpportunitySelector) || {};
    const { programID: createProgramID } = useAppSelector(createProgramSelector) || {};
    const updateProgramData = useAppSelector(updateProgramSelector) || {};
    const programReviewDetails = JSON.parse(getLocalStorageItem('createdProgramID') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const { programID: updateProgramID } = updateProgramData || {};
    const maxWordsSummary = 60;

    useEffect(() => {
        if ((updateProgramID || createProgramID) && !savedClicked) {
            dispatch(setUpdateProgram(''))
            dispatch(setCreateProgram(''))
            navigate("/company/programme/create/support");
            dispatch(toggleSpinner(false));
        }
    }, [updateProgramID, createProgramID])

    useEffect(() => {
        if (programData?.programName) {
            form.setFieldsValue({
                programSummary: programData?.programSummary,
                programObjectives: programData?.programObjectives,
                keyWebLinks: programData?.keyWebLinks?.map((el) => ({ keyWeb: el })),
            });
            if (Array.isArray(programData?.supportingMaterial) && programData?.supportingMaterial?.length > 0) {
                const data = programData?.supportingMaterial?.length ? programData.supportingMaterial : [];
                setPictures(data)
            }
            setOpportunitySummary(programData?.programSummary);
        }
    }, [programData]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
        }
    }, [dispatch, programID]);

    useEffect(() => {
        if (!opportunityId && programID && createOpportunityData?.opportunityID) {
            dispatch(addInteraction({ programID, opportunityID: createOpportunityData?.opportunityID, action: "pendingApplication" }));
            setLocalStorageItem('programReviewDetails', JSON.stringify({ programID, opportunityId: createOpportunityData?.opportunityID }));
        } else if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [createOpportunityData?.opportunityID, programID]);

    const countWords = (text) => {
        return text?.trim()?.split(/\s+/)?.filter(word => word.length > 0).length;
    };

    const handleTextChange = (setter, maxWords) => (e) => {
        const text = e.target.value;
        if (countWords(text) <= maxWords) {
            setter(text);
            form.setFieldsValue({ [e.target.name]: text });
        }
    };

    const handleProceed = () => {
        const formData = form.getFieldsValue();
        const data = {
            programSummary: formData?.programSummary,
            programObjectives: formData?.programObjectives,
            keyWebLinks: formData?.keyWebLinks?.map((el) => el.keyWeb),
            supportingMaterial: pictures,
            programID: programID
        }
        dispatch(updateProgram(data));
        dispatch(toggleSpinner(true));
    };

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
            navigate(`/company/programmes/review/${programID}`);
        }, 300);
    }

    const onChange = ({ fileList: newFileList }) => {
        setPictures(prevPictures => {
            const updatedPictures = newFileList.map(newFile => {
                const existingPicture = prevPictures.find(pic => pic.uid === newFile.uid);
                return existingPicture ? { ...existingPicture, ...newFile } : newFile;
            });
            return updatedPictures;
        });
    };

    const processFileUpload = async ({ onSuccess, onError, file, onProgress }) => {
        try {
            let presignedPostData = await getPresignedPostData(file);
            presignedPostData = await presignedPostData.data;
            await uploadFileToS3(presignedPostData, file, onProgress);
            onSuccess(null, file);
            setPictures(prevPictures =>
                prevPictures.map(pic =>
                    pic.uid === file.uid ? { ...pic, ...presignedPostData.fields } : pic
                )
            );
        } catch (e) {
            message.error(`File upload failed. ${e.message || ''}`);
            onError(e);
        }
    };


    const getPresignedPostData = async (selectedFile) => new Promise((resolve, reject) => {
        const fileName = selectedFile.name.replace(
            /(?:\.(?![^.]+$)|[^\w.])+/g,
            ''
        );
        const url = `${Config.dev.url.companyLogoUploadUrl}?fileName=${fileName}`;
        axios.get(url, (res, err) => {
            if (res && res.status === 200) {
                resolve(res.data);
            } else {
                reject(err.response);
            }
        });
    });

    const uploadFileToS3 = async (presignedPostData, file, onProgress) =>
        new Promise((resolve, reject) => {
            const formData = new FormData();
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            Object.keys(presignedPostData.fields).forEach((key) => {
                formData.append(key, presignedPostData.fields[key]);
            });
            formData.append("file", file);
            uploadFile(
                presignedPostData.url,
                formData,
                (res, err) => {
                    if (res && res.status === 204) {
                        resolve(res.data);
                    } else {
                        reject(err.response);
                    }
                },
                config
            );
        });

    const onPreview = async (file) => {
        let src = file.url;
        if (!src && file?.originFileObj instanceof Blob) {
            src = await new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(new Error('Failed to read file'));
            });
        }

        if (src) {
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow?.document.write(image.outerHTML);
        } else {
            console.error('Failed to preview image: Source is invalid or unavailable.');
        }
    };
    const onDelete = (file) => {
        setPictures((prevPictures) => prevPictures.filter(picture => picture.uid !== file.uid));
    }

    const onRemove = async (file) => {
        const { deleteURL } = Config.dev.url;
        const data = { bucketName: 'bg-uploaded-media', fileName: file.key };
        axios.put(deleteURL, () => onDelete(file), data, true);
    };

    const handlePrevious = () => navigate("/company/programme/create/location");

    const wordCountSummary = countWords(opportunitySummary);

    return (
        <div className={clsx("createProfile", className)}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <StyledText as="p" variant="H1" className='mainTitle'>
                {intl.formatMessage({ id: "programme_details" })}
            </StyledText>
            <div>
                <Form
                    {...formItemLayout}
                    labelAlign="left"
                    form={form}
                    onFinish={handleProceed}
                    className="formContainer"
                    scrollToFirstError
                >
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "write_a_short_summary_of_your_programme" })}
                    </StyledText>
                    <Form.Item
                        name="programSummary"
                        className="formItem"
                        rules={[
                            { whitespace: true, required: true, message: intl.formatMessage({ id: "enter" }) }
                        ]}
                    >
                        <>
                            <TextArea
                                name="programSummary"
                                rows={2}
                                value={opportunitySummary}
                                placeholder={intl.formatMessage({ id: "enter" })}
                                onChange={handleTextChange(setOpportunitySummary, maxWordsSummary)}
                            />
                            <div className={`wordCount ${wordCountSummary >= maxWordsSummary ? 'error' : ''}`}>
                                {wordCountSummary}/{maxWordsSummary} words
                            </div>
                        </>
                    </Form.Item>
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "write_a_longer_description" })}
                    </StyledText>
                    <Form.Item
                        name="programObjectives"
                        className="formItem"
                        rules={[
                            { whitespace: true, required: true, message: intl.formatMessage({ id: "enter" }) }
                        ]}
                    >
                        <TextArea
                            name="programObjectives"
                            rows={2}
                            placeholder={intl.formatMessage({ id: "enter" })}
                        />
                    </Form.Item>
                    <StyledText as="span" variant="B3d">
                        {intl.formatMessage({ id: "do_you_wish_to_share_any_supporting_material" })}
                        <StyledText as="span" variant="B3a">
                            {` (${intl.formatMessage({ id: "optional" })})`}
                        </StyledText>
                    </StyledText>
                    <Form.List name="keyWebLinks">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} style={{ display: 'flex' }} align="baseline" className='outcomeChildContainer'>
                                        <Form.Item
                                            {...restField}
                                            className="emailAddressField"
                                            name={[name, 'keyWeb']}
                                            rules={[{ required: true, message: 'Missing first name' }]}
                                        >
                                            <Input
                                                addonBefore={"https://"}
                                                type="text"
                                            />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} style={{ color: '#04ac9c', marginBottom: '-10px' }} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <AntdButton
                                        type="dashed"
                                        onClick={() => add()}
                                        icon={<PlusOutlined />}
                                        className='addButton'
                                        htmlType='button'
                                    >
                                        <StyledText as="p" variant="B3">
                                            {intl.formatMessage({ id: "add" })}
                                        </StyledText>
                                    </AntdButton>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <Form.Item className='uploadWrapper'>
                        <Upload
                            listType="picture-card"
                            onChange={onChange}
                            fileList={pictures || []}
                            onPreview={onPreview}
                            onRemove={onRemove}
                            customRequest={({
                                onSuccess, onError, file, onProgress
                            }) => processFileUpload({
                                onSuccess, onError, file, onProgress
                            })}
                            multiple
                        >
                            {`+ ${intl.formatMessage({ id: "upload" })}`}
                        </Upload>
                    </Form.Item>
                    <div className='buttonContainer'>
                        <Divider />
                        <div className='buttonContentContainer'>
                            <div className='nextAndPreviousContainer'>
                                <Button variant="secondary" type="button" onClick={handlePrevious}>
                                    {intl.formatMessage({ id: "previous" })}
                                </Button>
                                <Button variant="primary" htmlType="submit">
                                    {intl.formatMessage({ id: "next" })}
                                </Button>
                            </div>
                            <div className="saveAndExitButton">
                                <Button variant="secondary" type="button" onClick={handleSaveAndExit}>
                                    {intl.formatMessage({ id: "save_n_exit" })}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

const StyledCompanyCreateDetails = styled(CompanyCreateDetails)`
    &.createProfile {
        background: white;
        display: flex;
        padding: 20px;
        flex-direction: column;
        min-height: 150svh;
        .wordCount{
            text-align: right;
        &.error{
            color: red;
        }
    }
    .addButton{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .outcomeChildContainer{
            display: flex;
            .ant-form-item{
                width: 100%;
            }
            .inputContainer {
                width: 60%;
                margin-bottom: 0;
            }
        }
        .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
            .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
        }

        .mainTitle {
            margin-bottom: 20px;
        }

        .formContainer {
            display: flex;
            gap: 20px;
            flex-direction: column;
            width: 1000px;
        }

        .buttonContainer {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            position: fixed;
            width: 100%;
            bottom: 0px;
            background: white;
            margin-left: -80px;

            .buttonContentContainer {
                display: flex;
                justify-content: center;
                width: 100%;
                position: relative;

                .saveAndExitButton {
                    position: absolute;
                    right: 0;
                    margin-bottom: 15px;
                }

                .nextAndPreviousContainer {
                    display: flex;
                    gap: 20px;
                    margin-bottom: 15px;
                }
            }
        }

    }
`;

export default StyledCompanyCreateDetails;
