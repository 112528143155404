import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
    MinusCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";
import dayjs from 'dayjs';
import { Divider, Form, Input, Checkbox, Space, Radio, Upload, Spin, Button as AntdButton, DatePicker, message } from "antd";
import styled from "styled-components";
import { currencyData } from "../../constants";
import { getLocalStorageItem, numericValidator, uploadFile } from "../../utils/utilities";
import { updateProgram, getProgramById, setUpdateProgram, toggleSpinner, setCreateProgram } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import Select from "../../components/CustomSelect"
import {
    programDataSelector,
    toggleSpinnerSelector,
    updateProgramSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";
import axios from "../../utils/axios";
import Config from "../../config";

const { TextArea } = Input;

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 24 },
};

const cashGivingTargetPerOpportunityOptions = [
    "Maximum",
    "Minimum",
    "Exactly",
]

const CompanySupport = ({ className }) => {
    const baseClassName = clsx("companySupport", className);
    const intl = useIntl();
    const [pictures, setPictures] = useState([]);
    const [formData, setFormData] = useState({});
    const [inKindOfferingError, setInKindOfferingError] = useState(null);
    const [volunteeringUseError, setVolunteeringUseError] = useState(null);
    const [savedClicked, setSavedClicked] = useState(false);
    const programData = useAppSelector(programDataSelector) || {};
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const updateProgramData = useAppSelector(updateProgramSelector) || {};
    const programReviewDetails = JSON.parse(getLocalStorageItem('createdProgramID') || '{}');
    const { programID } = programReviewDetails || {};
    const { currencySymbol } = programData || {};
    const { programID: updateProgramID } = updateProgramData || {};

    useEffect(() => {
        if (updateProgramID && !savedClicked) {
            dispatch(setUpdateProgram(''))
            dispatch(setCreateProgram(''))
            dispatch(toggleSpinner(false));
            navigate("/company/programme/create/timelines");
        }
    }, [updateProgramID])

    useEffect(() => {
        const validateFormList = () => {
            const inKind = formData?.checkboxGroup?.includes("inKind");
            const inKindOffering = form.getFieldValue('inKindOffering');
            const volunteering = formData?.checkboxGroup?.includes("volunteering");
            const volunteeringSkillList = form.getFieldValue('volunteeringSkillList');
            if (inKind && !inKindOffering?.length) {
                setInKindOfferingError('Please enter at least 1 row');
            } else {
                setInKindOfferingError(null);
            }
            if (volunteering && !volunteeringSkillList?.length) {
                setVolunteeringUseError('Please enter at least 1 row');
            } else {
                setVolunteeringUseError(null);
            }
        };

        validateFormList();
    }, [form, formData]);

    useEffect(() => {
        if (programData?.programName) {
            const group = [];
            if (programData?.inKind === 'Y') group.push('inKind');
            if (programData?.volunteering === 'Y') group.push('volunteering');
            if (programData?.cashGiving === 'Y') group.push('cashGiving');
            const data = {
                checkboxGroup: group,
                ...(programData?.budgetEndDate && { budgetEndDate: dayjs(programData?.budgetEndDate?.trim()) }),
                ...(programData?.budgetStartDate && { budgetStartDate: dayjs(programData?.budgetStartDate?.trim()) }),
                ...(programData?.budgetAmount && { budgetAmount: programData?.budgetAmount }),
                ...(programData?.isBudgetRecurring && { isBudgetRecurring: programData?.isBudgetRecurring }),
                ...(programData?.invoiceDetails && { invoiceDetails: programData?.invoiceDetails }),
                ...(programData?.cashFullFundingRequired && { cashFullFundingRequired: programData?.cashFullFundingRequired }),
                ...(programData?.volunteeringSkillList && { volunteeringSkillList: programData?.volunteeringSkillList?.map((el) => ({ volunteerUnit: el })) }),
                ...(programData?.volunteeringType && { volunteeringType: programData?.volunteeringType }),
                ...(programData?.budgetVisible && { budgetVisible: programData?.budgetVisible }),
                ...(programData?.cashGivingCurrencyCode && { cashGivingCurrencyCode: programData?.cashGivingCurrencyCode }),
                ...(programData?.cashGivingTargetPerOpportunity && { cashGivingTargetPerOpportunity: programData?.cashGivingTargetPerOpportunity }),
                ...(programData?.cashGivingTargetPerOpportunityAmount && { cashGivingTargetPerOpportunityAmount: programData?.cashGivingTargetPerOpportunityAmount }),
                ...(programData?.totalCashRequested && { totalCashRequested: programData?.totalCashRequested }),
                ...(programData?.wholeProjectFunding && { wholeProjectFunding: programData?.wholeProjectFunding }),
                ...(programData?.additionalFundsSource && { additionalFundsSource: Object.keys(programData?.additionalFundsSource).filter(key => programData?.additionalFundsSource[key]) }),
                ...(programData?.expectedOutput && { expectedOutput: programData?.expectedOutput }),
                ...(programData?.inKindOffering ? { inKindOffering: programData?.inKindOffering } : { inKindOffering: [{ inKindOfferingDescription: undefined, InKindOfferingNumber: "" }] }),
            };
            form.setFieldsValue(data);
            setFormData(data);
            if (programData?.invoiceTemplate) {
                setPictures(programData?.invoiceTemplate)
            }
        }
    }, [programData]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
        }
    }, [programID]);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = (e) => {
        const formData = form.getFieldsValue();
        if (inKindOfferingError || volunteeringUseError) return;
        const data = {
            inKind: formData?.checkboxGroup?.includes("inKind") ? "Y" : "N",
            volunteering: formData?.checkboxGroup?.includes("volunteering") ? "Y" : "N",
            cashGiving: formData?.checkboxGroup?.includes("cashGiving") ? "Y" : "N",
            programID: programID,
            cashGivingCurrencyCode: formData?.cashGivingCurrencyCode,
            cashGivingTargetPerOpportunity: formData?.cashGivingTargetPerOpportunity,
            cashGivingTargetPerOpportunityAmount: formData?.cashGivingTargetPerOpportunityAmount,
            currencySymbol,
            inKindOffering: formData?.inKindOffering,
            budgetVisible: formData?.budgetVisible ? "Y" : "N",
            volunteeringType: formData?.volunteeringType,
            volunteeringSkillList: formData?.volunteeringSkillList?.map((el) => el.volunteerUnit),
            cashFullFundingRequired: formData?.cashFullFundingRequired ? "Y" : "N",
            invoiceDetails: formData?.invoiceDetails,
            isBudgetRecurring: formData?.isBudgetRecurring,
            budgetAmount: formData?.budgetAmount,
            budgetEndDate: formData?.isBudgetRecurring === "N" && formData?.budgetEndDate ? dayjs(formData?.budgetEndDate).format('YYYY/MM/DD') : '',
            budgetStartDate: formData?.isBudgetRecurring === "Y" && formData?.budgetStartDate ? dayjs(formData?.budgetStartDate).format('MM/DD') : formData?.budgetStartDate ? dayjs(formData?.budgetStartDate).format('YYYY/MM/DD') : undefined,
            invoiceTemplate: pictures,
        }
        dispatch(updateProgram(data));
        dispatch(toggleSpinner(true));
    }

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
            navigate(`/company/programmes/review/${programID}`);
        }, 300);
    }

    const handlePrevious = () => {
        navigate("/company/programme/create/details")
    }

    const onFormChange = (_, allValues) => {
        setFormData(allValues);
    };

    const onChange = ({ fileList: newFileList }) => {
        setPictures(prevPictures => {
            const updatedPictures = newFileList.map(newFile => {
                const existingPicture = prevPictures.find(pic => pic.uid === newFile.uid);
                return existingPicture ? { ...existingPicture, ...newFile } : newFile;
            });
            return updatedPictures;
        });
    };

    const processFileUpload = async ({ onSuccess, onError, file, onProgress }) => {
        try {
            let presignedPostData = await getPresignedPostData(file);
            presignedPostData = await presignedPostData.data;
            await uploadFileToS3(presignedPostData, file, onProgress);
            onSuccess(null, file);
            setPictures(prevPictures =>
                prevPictures.map(pic =>
                    pic.uid === file.uid ? { ...pic, ...presignedPostData.fields } : pic
                )
            );
        } catch (e) {
            message.error(`File upload failed. ${e.message || ''}`);
            onError(e);
        }
    };


    const getPresignedPostData = async (selectedFile) => new Promise((resolve, reject) => {
        const fileName = selectedFile.name.replace(
            /(?:\.(?![^.]+$)|[^\w.])+/g,
            ''
        );
        const url = `${Config.dev.url.companyLogoUploadUrl}?fileName=${fileName}`;
        axios.get(url, (res, err) => {
            if (res && res.status === 200) {
                resolve(res.data);
            } else {
                reject(err.response);
            }
        });
    });

    const uploadFileToS3 = async (presignedPostData, file, onProgress) =>
        new Promise((resolve, reject) => {
            const formData = new FormData();
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            Object.keys(presignedPostData.fields).forEach((key) => {
                formData.append(key, presignedPostData.fields[key]);
            });
            formData.append("file", file);
            uploadFile(
                presignedPostData.url,
                formData,
                (res, err) => {
                    if (res && res.status === 204) {
                        resolve(res.data);
                    } else {
                        reject(err.response);
                    }
                },
                config
            );
        });

    const onPreview = async (file) => {
        let src = file.url;
        if (!src && file?.originFileObj instanceof Blob) {
            src = await new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(new Error('Failed to read file'));
            });
        }

        if (src) {
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow?.document.write(image.outerHTML);
        } else {
            console.error('Failed to preview image: Source is invalid or unavailable.');
        }
    };
    const onDelete = (file) => {
        setPictures((prevPictures) => prevPictures.filter(picture => picture.uid !== file.uid));
    }

    const onRemove = async (file) => {
        const { deleteURL } = Config.dev.url;
        const data = { bucketName: 'bg-uploaded-media', fileName: file.key };
        axios.put(deleteURL, () => onDelete(file), data, true);
    };

    const { checkboxGroup, isBudgetRecurring } = formData || {};

    return (
        <div className={baseClassName}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <div className='contentContainer'>
                <div>
                    <StyledText as="p" variant="H1" className='mainTitle'>
                        {intl.formatMessage({ id: "programme_support" })}
                    </StyledText>
                    <StyledText as="p" variant="B3d" className='mainTitle'>
                        {intl.formatMessage({ id: "what_support_can_you_offer" })}
                    </StyledText>
                    <Form
                        {...formItemLayout}
                        labelAlign="left"
                        form={form}
                        onFinish={handleProceed}
                        onFinishFailed={onFinishFailed}
                        className="formContainer"
                        scrollToFirstError
                        onValuesChange={onFormChange}
                        initialValues={{
                            inKindOffering: [
                                {
                                    inKindOfferingDescription: undefined,
                                },
                            ],
                            isBudgetRecurring: programData?.isBudgetRecurring
                        }}
                    >
                        <Form.Item
                            name="checkboxGroup"
                        >
                            <Checkbox.Group className='checkboxGroup'>
                                <Checkbox key="cashGiving" value="cashGiving">
                                    {intl.formatMessage({ id: "grant" })}
                                </Checkbox>
                                <Checkbox key="volunteering" value="volunteering">
                                    {intl.formatMessage({ id: "volunteering" })}
                                </Checkbox>
                                <Checkbox key="inKind" value="inKind">
                                    {intl.formatMessage({ id: "in_kind" })}
                                </Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                        {checkboxGroup?.includes('cashGiving') && <div className="flexColumn">
                            <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "what_is_your_budget" })}</StyledText>
                            <div className='flex'>
                                <Form.Item
                                    name="cashGivingCurrencyCode"
                                    className="formItem"
                                    rules={[
                                        { required: true, message: intl.formatMessage({ id: "enter" }) },
                                    ]}
                                >
                                    <Select
                                        allowClear
                                        placeholder={intl.formatMessage({ id: "currency" })}
                                        type="formSelect"
                                        options={currencyData.map((value, index) => ({
                                            key: value?.CountryName,
                                            label: `${value?.Code} - ${value?.CountryName}`,
                                            value: `${value?.Code} - ${value?.CountryName}`
                                        }))
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="budgetAmount"
                                    className="formItem"
                                    rules={[
                                        { required: true, message: intl.formatMessage({ id: "enter" }) },
                                        { validator: numericValidator }
                                    ]}
                                >
                                    <Input
                                        placeholder={intl.formatMessage({ id: "enter_amount" })}
                                        type="text"
                                    />
                                </Form.Item>
                            </div>
                            <Form.Item
                                name="budgetVisible"
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select',
                                    },
                                ]}
                            >
                                <Checkbox key="internal" value="budgetVisible">
                                    {intl.formatMessage({ id: "make_this_budget_amount_visible_in_your_programme_as_an_estimated_budget" })}
                                </Checkbox>
                            </Form.Item>
                            <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "is_there_a_limit_donation_amount_per_project" })}</StyledText>
                            <div className='flex'>
                                <Form.Item
                                    name="cashGivingTargetPerOpportunity"
                                    className="formItem"
                                    rules={[
                                        { required: true, message: intl.formatMessage({ id: "enter" }) },
                                    ]}
                                >
                                    <Select
                                        allowClear
                                        placeholder={intl.formatMessage({ id: "per_project" })}
                                        type="formSelect"
                                        options={cashGivingTargetPerOpportunityOptions.map((value, index) => ({
                                            key: value,
                                            label: value,
                                            value: value
                                        }))
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="cashGivingTargetPerOpportunityAmount"
                                    className="formItem"
                                    rules={[
                                        { required: true, message: intl.formatMessage({ id: "enter" }) },
                                        { validator: numericValidator }
                                    ]}
                                >
                                    <Input
                                        placeholder={intl.formatMessage({ id: "enter_amount" })}
                                        type="text"
                                    />
                                </Form.Item>
                            </div>
                            <Form.Item
                                name="cashFullFundingRequired"
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select',
                                    },
                                ]}
                            >
                                <Checkbox key="internal" value="cashFullFundingRequired">
                                    {intl.formatMessage({ id: "projects_must_be_fully_funded_by_you_without_additional_third_party_funding" })}
                                </Checkbox>
                            </Form.Item>
                            <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "does_this_budget_recur" })}</StyledText>
                            <Form.Item
                                name="isBudgetRecurring"
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: "enter" }) }
                                ]}
                            >
                                <Radio.Group className="radioGroup">
                                    <Radio className="radio" value="N">
                                        <StyledText as="p" variant="B3">
                                            {intl.formatMessage({ id: "no_it_is_fixed" })}
                                        </StyledText>
                                    </Radio>
                                    <Radio className="radio" value="Y">
                                        <StyledText as="p" variant="B3">
                                            {intl.formatMessage({ id: "yes_annual_starting_on" })}
                                        </StyledText>
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                            {["N"].includes(isBudgetRecurring) && <div className='flex'>
                                <div className='flexColumn'>
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "start_date" })}
                                    </StyledText>
                                    <Form.Item
                                        name="budgetStartDate"
                                        className="formItem"
                                        rules={[
                                            { required: true, message: intl.formatMessage({ id: "enter" }) },
                                        ]}
                                    >
                                        <DatePicker
                                            format="YYYY/MM/DD"
                                            placeholder={intl.formatMessage({ id: "estimated_start" })}
                                        />
                                    </Form.Item>
                                </div>

                                <div className='flexColumn'><StyledText as="p" variant="B3">
                                    {intl.formatMessage({ id: "end_date" })}
                                </StyledText>
                                    <Form.Item
                                        name="budgetEndDate"
                                        className="formItem"
                                        rules={[
                                            { required: true, message: intl.formatMessage({ id: "enter" }) },
                                        ]}
                                    >
                                        <DatePicker format="YYYY/MM/DD" placeholder={intl.formatMessage({ id: "estimated_finish" })} />
                                    </Form.Item>
                                </div>
                            </div>}
                            {["Y"].includes(isBudgetRecurring) &&
                                <div className='flexColumn'>
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "start_date" })}
                                    </StyledText>
                                    <Form.Item
                                        name="budgetStartDate"
                                        className="formItem"
                                        rules={[
                                            { required: true, message: intl.formatMessage({ id: "enter" }) },
                                        ]}
                                    >
                                        <DatePicker
                                            format="MM/DD"
                                            placeholder={intl.formatMessage({ id: "estimated_start" })}
                                        />
                                    </Form.Item>
                                </div>}
                            <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "share_any_invoice_instructions_for_approved_grantees_to_view" })}</StyledText>
                            <Form.Item
                                name='invoiceDetails'
                                className="formItemTextField"
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage({ id: "enter" }),
                                    },
                                ]}
                            >
                                <TextArea rows={2} placeholder={intl.formatMessage({ id: "please_include_in_your_invoice_the_following_details" })} />
                            </Form.Item>
                            <StyledText as="span" variant="B3d">
                                {intl.formatMessage({ id: "do_you_want_to_upload_an_invoice_template_for_approved_applicants_to_use" })}
                                <StyledText as="span" variant="B3a">
                                    {` (${intl.formatMessage({ id: "optional" })})`}
                                </StyledText>
                            </StyledText>
                            <Form.Item className='uploadWrapper'>
                                <Upload
                                    listType="picture-card"
                                    onChange={onChange}
                                    fileList={pictures || []}
                                    onPreview={onPreview}
                                    onRemove={onRemove}
                                    customRequest={({
                                        onSuccess, onError, file, onProgress
                                    }) => processFileUpload({
                                        onSuccess, onError, file, onProgress
                                    })}
                                    multiple
                                >
                                    {`+ ${intl.formatMessage({ id: "upload" })}`}
                                </Upload>
                            </Form.Item>
                        </div>}

                        {checkboxGroup?.includes('inKind') && <>
                            <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "what_type_of_in_kind_support_can_you_offer" })}</StyledText>
                            <Form.List name="inKindOffering">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Space key={key} style={{ display: 'flex', marginBottom: 0 }} className='optionsContainer'>
                                                <div className='flexColumn'>
                                                    <StyledText as="p" variant="B3">{intl.formatMessage({ id: "number_of_item_service" })}</StyledText>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'InKindOfferingNumber']}
                                                        className="formItem"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: intl.formatMessage({ id: "enter" }),
                                                            },
                                                            { validator: numericValidator }
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder={intl.formatMessage({ id: "number" })}
                                                            type="text"
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className='flexColumn'>
                                                    <StyledText as="p" variant="B3">{intl.formatMessage({ id: "item_service_description" })}</StyledText>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'inKindOfferingDescription']}
                                                        className="formItemTextField"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: intl.formatMessage({ id: "enter" }),
                                                            },
                                                        ]}
                                                    >
                                                        <TextArea rows={1} placeholder={intl.formatMessage({ id: "description" })} />
                                                    </Form.Item>
                                                </div>
                                                <MinusCircleOutlined onClick={() => remove(name)} style={{ color: '#04ac9c', marginTop: '30px' }} className='for' />
                                            </Space>
                                        ))}
                                        {inKindOfferingError && (
                                            <StyledText as="p" variant="B3" className="errorText">
                                                {inKindOfferingError}
                                            </StyledText>
                                        )}
                                        <Form.Item>
                                            <AntdButton htmlType='button' className='addButton' type="dashed" onClick={() => add()} block icon={<PlusOutlined fill="red" />}>
                                                <StyledText as="p" variant="B3">
                                                    {intl.formatMessage({ id: "add" })}
                                                </StyledText>
                                            </AntdButton>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </>}
                        {checkboxGroup?.includes('volunteering') && <>
                            <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "what_type_of_volunteering_can_you_offer" })}</StyledText>
                            <Form.Item
                                name="volunteeringType"
                                rules={[
                                    {
                                    validator: (_, value) =>
                                        value && value.length > 0
                                        ? Promise.resolve()
                                        : Promise.reject(new Error(intl.formatMessage({ id: "select" }))),
                                    },
                                ]}
                                >
                                <Checkbox.Group className="checkboxGroup">
                                    <Checkbox key="general" value="General">
                                    {intl.formatMessage({ id: "general" })}
                                    </Checkbox>
                                    <Checkbox key="skilled" value="Skilled">
                                    {intl.formatMessage({ id: "skilled" })}
                                    </Checkbox>
                                </Checkbox.Group>
                                </Form.Item>
                            <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "what_skilled_volunteering_is_available" })}</StyledText>
                            <Form.List name="volunteeringSkillList">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Space key={key} style={{ display: 'flex', marginBottom: 0 }} className='optionsContainer'>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'volunteerUnit']}
                                                    className="formItem"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: intl.formatMessage({ id: "enter" }),
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder={intl.formatMessage({ id: "skill" })}
                                                        type="text"
                                                    />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)} style={{ color: '#04ac9c' }} className='for' />
                                            </Space>
                                        ))}
                                        {volunteeringUseError && (
                                            <StyledText as="p" variant="B3" className="errorText">
                                                {volunteeringUseError}
                                            </StyledText>
                                        )}
                                        <Form.Item>
                                            <AntdButton htmlType='button' className='addButton' type="dashed" onClick={() => add()} block icon={<PlusOutlined fill="red" />}>
                                                <StyledText as="p" variant="B3">
                                                    {intl.formatMessage({ id: "add" })}
                                                </StyledText>
                                            </AntdButton>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </>}

                        <div className='buttonContainer'>
                            <Divider />
                            <div className='buttonContentContainer'>
                                <div className='nextAndPreviousContainer'>
                                    <Button
                                        variant="secondary"
                                        type="button"
                                        onClick={() => handlePrevious()}>
                                        {intl.formatMessage({ id: "previous" })}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        htmlType="submit"
                                    >
                                        {intl.formatMessage({ id: "next" })}
                                    </Button>
                                </div>
                                <div className="saveAndExitButton">
                                    <Button
                                        variant="secondary"
                                        htmlType="button"
                                        onClick={() => handleSaveAndExit()}>
                                        {intl.formatMessage({ id: "save_n_exit" })}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

const StyledCompanySupport = styled(CompanySupport)`
&.companySupport{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    .flex{
        display: flex;
        gap: 40px;
    }
    .flexColumn{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .errorText{
        color: #ff4d4f;
    }
    .contentContainer{
        display: flex;
        padding: 20px;
        justify-content: space-between;
        height: 280vh;
    }
    .addButton{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .formContainer{
        display: flex;
        gap: 12px;
        flex-direction: column;
    }
    .checkboxGroup{
        display: flex;
        flex-direction: column;
    }
    .optionsContainer{
        display: flex;
        align-items: center;
        gap: 30px;
        .for{
            margin-bottom: 10px;
        }
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }

    .radioGroup {
            display: flex;
            flex-direction: column;
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .formItemTextField{
        .ant-form-item-control-input{
            width: 34.5vw !important;
        }
    }
    .formItemTextFieldPart{
        .ant-form-item-control-input{
            width: 16vw !important;
        } 
    }
  .ant-form-item{
    margin-bottom: 10px;
  }
  .formItemInput{
    width: 300px;
  }
}`;

export default StyledCompanySupport;