import { createSelector } from "reselect";
import { removeDuplicatesByKey } from "../utils/utilities"

const slice = (state) => state;

export const appselector = createSelector([slice], (slice) => slice);

export const tokenSelector = createSelector([slice], (slice) => slice?.loginObj?.IDToken);

export const loginSelector = createSelector([slice], (slice) => slice?.loginObj);

export const resetPasswordSelector = createSelector([slice], (slice) => slice?.resetObj);

export const errorSelector = createSelector([slice], (slice) => slice?.errorObj);

export const profileSelector = createSelector([slice], (slice) => slice?.profileData);

export const assetsSelector = createSelector([slice], (slice) => slice?.assets);

export const allAssetsSelector = createSelector([slice], (slice) => slice?.allAssets?.assets);

export const progressAnalyticsSelector = createSelector([slice], (slice) => slice?.progressAnalytics);

export const responderTypeAnalyticsSelector = createSelector([slice], (slice) => slice?.responderTypeAnalytics);

export const submissionsByPhaseSelector = createSelector([slice], (slice) => slice?.submissionsByPhase);

export const themeAnalyticsSelector = createSelector([slice], (slice) => slice?.themeAnalytics);

export const popularCategoryAnalyticsSelector = createSelector([slice], (slice) => slice?.popularCategoryAnalytics);

export const popularSubCategoryAnalyticsSelector = createSelector([slice], (slice) => slice?.popularSubCategoryAnalytics);

export const submissionsSelector = createSelector([slice], (slice) => slice?.submissions?.submissions);

export const submissionsByCategorySelector = createSelector([slice], (slice) => slice?.submissionsByCategory?.submissions);

export const addCeSubmissionReviewSelector = createSelector([slice], (slice) => slice?.addCeSubmissionReview);

export const submissionsMapDataSelector = createSelector([slice], (slice) => slice?.submissionsMapData);

export const feedbackMapDataSelector = createSelector([slice], (slice) => slice?.feedbackMapData);

export const submissionReviewSelector = createSelector([slice], (slice) => slice?.submissionReview?.submissions);

export const usersSelector = createSelector([slice], (slice) => slice?.users);

export const totalVisitsSelector = createSelector([slice], (slice) => slice?.totalVisits);

export const ideaProgressAnalyticsSelector = createSelector([slice], (slice) => slice?.ideaProgressAnalytics);

export const feedbackProgressAnalyticsSelector = createSelector([slice], (slice) => slice?.feedbackProgressAnalytics);

export const collaborateProgressAnalyticsSelector = createSelector([slice], (slice) => slice?.collaborateProgressAnalytics);

export const ideasPendingActionsSelector = createSelector([slice], (slice) => slice?.ideasPendingActions);

export const feedbackPendingActionsSelector = createSelector([slice], (slice) => slice?.feedbackPendingActions);

export const collaboratePendingActionsSelector = createSelector([slice], (slice) => slice?.collaboratePendingActions);

export const historicalVisitorsSelector = createSelector([slice], (slice) => slice?.historicalVisitors);

export const signUpSelector = createSelector([slice], (slice) => slice?.signup);

export const editProfileSelector = createSelector([slice], (slice) => slice?.editProfile);

export const programmeListSelector = createSelector([slice], (slice) => slice?.programmeList?.program);

export const createPasswordSelector = createSelector([slice], (slice) => slice?.createPassword);

export const editUserProfileSelector = createSelector([slice], (slice) => slice?.editUserProfile);

export const interactionSelector = createSelector([slice], (slice) => slice?.interaction?.interaction);

export const allInteractionsSelector = createSelector([slice], (slice) => slice?.allInteractions?.interaction);

export const actionInteractionSelector = createSelector([slice], (slice) => slice?.actionInteraction?.interaction);

export const actionInteractionVotesSelector = createSelector([slice], (slice) => {
  const interactions = slice?.actionInteraction?.interaction;
  const votes = slice?.actionInteraction?.votes || [];

  if (!interactions) {
    return [];
  }

  function mergeInteractionAndVotes(interactions, votes) {
    const votesLookup = votes.reduce((acc, vote) => {
      const baseInteractionID = vote?.interactionID?.split('===')[0];
      const primaryInteractionID = vote?.interactionID?.split('===')[1];
      acc[`${baseInteractionID}-${primaryInteractionID}`] = vote;
      return acc;
    }, {});

    return interactions.map(interaction => {
      const baseID = interaction?.ID?.split('==')[0];
      const primaryId = interaction?.ID?.split('==')[1];

      const voteData = votesLookup[`${baseID}-${primaryId}`];
      return {
        ...interaction,
        votes: voteData
      };
    });
  }


  const value = mergeInteractionAndVotes(interactions, votes);

  return value
});

export const interactionStatusSelector = createSelector([slice], (slice) => slice?.interactionStatus?.interaction);

export const searchFieldValueSelector = createSelector([slice], (slice) => slice?.searchActionValue);

export const sharePreApprovedSelector = createSelector([slice], (slice) => slice?.sharePreApproved);

export const donationSelector = createSelector([slice], (slice) => slice?.donationTwo);

export const donationListSelector = createSelector([slice], (slice) => slice?.donationListData);

export const opportunitiesOptionsSelector = createSelector([slice], (slice) => slice?.opportunitiesOptions);

export const toggleSpinnerSelector = createSelector([slice], (slice) => slice?.toggleSpinner);

export const programDataSelector = createSelector([slice], (slice) => slice?.programData?.program);

export const userImageSelector = createSelector([slice], (slice) => removeDuplicatesByKey(slice?.userImages, 'picture'));

export const opportunityDataSelector = createSelector([slice], (slice) => slice?.opportunityData);

export const editOpportunityData = createSelector([slice], (slice) => slice?.editOpportunityData);

export const createOpportunitySelector = createSelector([slice], (slice) => slice?.createOpportunity);

export const addInteractionSelector = createSelector([slice], (slice) => slice?.addInteraction);

export const indicatorDataSelector = createSelector([slice], (slice) => slice?.indicatorData?.indicatorAnswerList);

export const programListSelector = createSelector([slice], (slice) => slice?.programList?.program);

export const createProgramSelector = createSelector([slice], (slice) => slice?.createProgram);

export const updateProgramSelector = createSelector([slice], (slice) => slice?.updateProgram);

export const impactStrategyListProgramSelector = createSelector([slice], (slice) => slice?.impactStrategyListProgram?.impactStrategyList);

export const guestTokenSelector = createSelector([slice], (slice) => slice?.guestToken?.IDToken);

export const guestProgramDataSelector = createSelector([slice], (slice) => slice?.guestProgramData?.program);

export const deleteProgramSelector = createSelector([slice], (slice) => slice?.deleteProgram);

export const getConversationSelector = createSelector([slice], (slice) => slice?.conversation);

export const multipleApplicationsSelector = createSelector([slice], (slice) => slice?.interaction?.multipleApplications);

export const voteAnalyticsSelector = createSelector([slice], (slice, (slice) => slice?.voteAnalytics));

export const addVoteSelector = createSelector([slice], (slice) => slice?.addVote);

export const getVoteSelector = createSelector([slice], (slice) => slice?.getVote);

export const saveVoteSelector = createSelector([slice], (slice) => slice?.saveVote);

export const deleteOpportunitySelector = createSelector(
[state => state], (state) => state?.deleteOpportunity
);

export const declineConversationSelector = createSelector([slice], () => slice?.declineConversation);