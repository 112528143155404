import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Avatar, Collapse, Spin, message } from "antd";
import * as constants from "../../constants";
import clsx from "clsx";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import {
    EyeFilled,
    CopyOutlined,
    CheckOutlined,
} from "@ant-design/icons";
import { getSecuredUrl, formatCurrency } from "../../utils/utilities";
import { getProgramById, getUserImage } from '../../redux/actions';
import StyledText from "../../components/StyledText";
import {
    programDataSelector,
    userImageSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";
import file from "../../assets/file.svg";
import person from "../../assets/person.svg";
import grant from "../../assets/grant.svg";
import volunteer from "../../assets/volunteer.svg";
import LinkComponent from "../../components/LinkComponent";
import inkind from "../../assets/inkind.svg";
import goal1 from "../../assets/goal1.png";
import goal2 from "../../assets/goal2.png";
import goal3 from "../../assets/goal3.png";
import goal4 from "../../assets/goal4.png";
import goal5 from "../../assets/goal5.png";
import goal6 from "../../assets/goal6.png";
import goal7 from "../../assets/goal7.png";
import goal8 from "../../assets/goal8.png";
import goal9 from "../../assets/goal9.png";
import goal10 from "../../assets/goal10.png";
import goal11 from "../../assets/goal11.png";
import goal12 from "../../assets/goal12.png";
import goal13 from "../../assets/goal13.png";
import goal14 from "../../assets/goal14.png";
import goal15 from "../../assets/goal15.png";
import goal16 from "../../assets/goal16.png";
import goal17 from "../../assets/goal17.png";
import goalInverted1 from "../../assets/goalInverted1.png";
import goalInverted2 from "../../assets/goalInverted2.png";
import goalInverted3 from "../../assets/goalInverted3.png";
import goalInverted4 from "../../assets/goalInverted4.png";
import goalInverted5 from "../../assets/goalInverted5.png";
import goalInverted6 from "../../assets/goalInverted6.png";
import goalInverted7 from "../../assets/goalInverted7.png";
import goalInverted8 from "../../assets/goalInverted8.png";
import goalInverted9 from "../../assets/goalInverted9.png";
import goalInverted10 from "../../assets/goalInverted10.png";
import goalInverted11 from "../../assets/goalInverted11.png";
import goalInverted12 from "../../assets/goalInverted12.png";
import goalInverted13 from "../../assets/goalInverted13.png";
import goalInverted14 from "../../assets/goalInverted14.png";
import goalInverted15 from "../../assets/goalInverted15.png";
import goalInverted16 from "../../assets/goalInverted16.png";
import goalInverted17 from "../../assets/goalInverted17.png";

const goals = [{
    key: 1,
    image: goalInverted1,
    selected: goal1
},
{
    key: 2,
    image: goalInverted2,
    selected: goal2
},
{
    key: 3,
    image: goalInverted3,
    selected: goal3
},
{
    key: 4,
    image: goalInverted4,
    selected: goal4
},
{
    key: 5,
    image: goalInverted5,
    selected: goal5
},
{
    key: 6,
    image: goalInverted6,
    selected: goal6
},
{
    key: 7,
    image: goalInverted7,
    selected: goal7
},
{
    key: 8,
    image: goalInverted8,
    selected: goal8
},
{
    key: 9,
    image: goalInverted9,
    selected: goal9
},
{
    key: 10,
    image: goalInverted10,
    selected: goal10
},
{
    key: 11,
    image: goalInverted11,
    selected: goal11
},
{
    key: 12,
    image: goalInverted12,
    selected: goal12
},
{
    key: 13,
    image: goalInverted13,
    selected: goal13
},
{
    key: 14,
    image: goalInverted14,
    selected: goal14
},
{
    key: 15,
    image: goalInverted15,
    selected: goal15
},
{
    key: 16,
    image: goalInverted16,
    selected: goal16
},
{
    key: 17,
    image: goalInverted17,
    selected: goal17
}
]

const onDownloadFile = async (data) => {
    const { name, fileKey = '' } = data || {};

    const getSecuredURL = await getSecuredUrl(fileKey, name);
    const securedURL = URL.createObjectURL(getSecuredURL);
    const fileLink = document.createElement('a');
    fileLink.href = securedURL;
    fileLink.download = name || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(securedURL);
            fileLink.removeEventListener('click', clickHandler);
        }, 150);
    };

    fileLink.addEventListener('click', clickHandler, false);
    fileLink.click();
    return fileLink;
};

const getUniqueSortedOutcomeUNSDG = (data, isProgramCustom) => {
    const outcomes = data?.objectives?.flatMap(objective =>
        isProgramCustom ?
            objective?.objectivesOutcome?.flatMap(outcome => outcome?.outcomeUNSDG) :
            objective?.objectiveUNSDG
    ) || [];

    const uniqueSortedOutcomes = Array.from(new Set(outcomes)).sort((a, b) => a - b);

    return uniqueSortedOutcomes.map(el => {
        const selectedOutcomes = goals.find(gl => gl.key === el);
        return {
            key: el,
            image: selectedOutcomes?.image,
            selected: selectedOutcomes?.selected,
        };
    });
};


const ProgramReview = ({ className }) => {
    const baseClassName = clsx("programReview", className);
    const intl = useIntl();
    const [images, setImages] = useState();
    const [selectedUnsdgs, setSelectedUnsdgs] = useState([]);
    const [selectedName, setSelectedName] = useState('');
    const [expandAll, setExpandAll] = useState(false);
    const { programID } = useParams();
    const dispatch = useAppDispatch();
    const programData = useAppSelector(programDataSelector);
    const userImage = useAppSelector(userImageSelector);

    const {
        budgetAmount,
        currentBudgetStartDate,
        currentBudgetEndDate,
        isBudgetRecurring,
        programSummary,
        programObjectives,
        impactStrategy,
        currencySymbol,
        cashGiving,
        cashGivingOption,
        budgetVisible,
        cashGivingTargetPerOpportunity,
        cashGivingTargetPerOpportunityAmount,
        volunteering,
        volunteeringOption,
        volunteeringType,
        volunteeringSkillList,
        supportingMaterial,
        inKind,
        inKindOption,
        inKindOffering,
        keyWebLinks,
        BizGiveUser,
        outcomeMethod,
        programmeURLCode
    } = programData || {};

    const isProgramCustom = outcomeMethod === "Programme Custom";

    const goals = getUniqueSortedOutcomeUNSDG(impactStrategy, isProgramCustom);

    const extractAndGroupObjectivesOutcome = data =>
        data?.map(({ name: title, objectivesOutcome }, index) => ({
            key: index,
            label: <StyledText as="p" variant="B3d">{title}</StyledText>,
            children: objectivesOutcome?.length > 0 && objectivesOutcome?.map(({ name, outcomeDescription, outcomeUNSDG }) =>
                <button key={outcomeDescription} className={`collapseChildContainer ${selectedName === name ? 'selectedName' : ''}`} onClick={() => { setSelectedUnsdgs(outcomeUNSDG); setSelectedName(name) }}><StyledText as="p" variant="B3d">{name}</StyledText><StyledText as="p" variant="B3">{outcomeDescription}</StyledText></button>)
        }));

    const impactStrategyOutcomes = extractAndGroupObjectivesOutcome(impactStrategy?.objectives);

    const setLogoImage = async (fileKey) => {
        const securedURI = await getSecuredUrl(fileKey.picture);
        const securedURL = URL.createObjectURL(securedURI);
        setImages(prev => [{ url: securedURL, name: fileKey.name }]);
    };

    useEffect(() => {
        if (userImage?.length > 0) {
            userImage.forEach((user, index) => {
                setLogoImage(user)
            });
        }
    }, [userImage])

    useEffect(() => {
        if (BizGiveUser?.length > 0) {
            BizGiveUser.forEach((user, index) => {
                setTimeout(() => {
                    dispatch(getUserImage(user));
                }, 1000 * index);
            });
        }
    }, [BizGiveUser, dispatch]);

    useEffect(() => {
        if (programID) {
            dispatch(getProgramById(programID));
        }
    }, []);

    const handleCopy = () => {
        navigator.clipboard.writeText(`${window.location.origin}/public/?a=${programmeURLCode}`)
            .then(() => {
                message.success('Text copied to clipboard!');
            })
            .catch(() => {
                message.error('Failed to copy text.');
            });
    };

    return (
        <div className={baseClassName}>
            {!programData && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={!programData} />
                </div>
            )}
            <div className="leftSubContainer">
                <div className="containerBox">
                    <div>
                        <div className="flex">
                            <StyledText as="p" variant="H2">
                                {intl.formatMessage({ id: "settings" })}
                            </StyledText>
                            <StyledText as="p" variant="B3">
                                [{intl.formatMessage({ id: "internal_only" })}]
                            </StyledText>
                        </div>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "visibility_title" })}
                        </StyledText>
                        <div className="flex">
                            <StyledText as="p" variant="B3c">
                                {intl.formatMessage({ id: "public_title" })}
                            </StyledText>
                            <EyeFilled onClick={() => window.open(`${window.location.origin}/public/?a=${programmeURLCode}`)} />
                            <CopyOutlined onClick={handleCopy} />
                        </div>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "budget_amount" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {budgetAmount}
                        </StyledText>
                    </div>
                    {(currentBudgetStartDate || currentBudgetEndDate) && <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "budget_dates" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {formatCurrency(currentBudgetStartDate)} - {formatCurrency(currentBudgetEndDate)}
                        </StyledText>
                    </div>}
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "budget_recurring" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {isBudgetRecurring === 'Y' ? intl.formatMessage({ id: "yes" }) : intl.formatMessage({ id: "no" })}
                        </StyledText>
                    </div>
                </div>
                <div className="subContainer">
                    <StyledText as="p" variant="H2">
                        {intl.formatMessage({ id: "summary_title" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {programSummary}
                    </StyledText>
                </div>
                <div className="subContainer">
                    <StyledText as="p" variant="H2">
                        {intl.formatMessage({ id: "description" })}
                    </StyledText>
                    <span className="descriptionContainer">{ReactHtmlParser(ReactHtmlParser(programObjectives))}</span>
                </div>
                {impactStrategyOutcomes?.length > 0 && <div className="subContainer">
                    <StyledText as="p" variant="H2">
                        {intl.formatMessage({ id: "objectives" })}
                    </StyledText>
                    {isProgramCustom && <button className="expandAll" onClick={() => setExpandAll(prev => !prev)}>
                        {intl.formatMessage({ id: "expand_all" })}
                    </button>}
                    <div className="unsdgPartitionContainer">
                        <div className="unsdgsContainer">
                            <button className="showAllGoals" onClick={() => { setSelectedUnsdgs([]); setSelectedName('') }}><div><CheckOutlined /></div><StyledText as="p" variant="B3">{intl.formatMessage({ id: "show_all_goals" })}</StyledText></button>{goals.map((el) => <Avatar key={el.image} src={selectedUnsdgs.includes(el.key) ? el.selected : el.image} shape="square" className="unsdgs" />)}
                        </div>
                        <div className="collapseContainer">
                            {isProgramCustom ? <Collapse items={impactStrategyOutcomes} activeKey={expandAll ? impactStrategyOutcomes.map(({ key }) => key) : undefined} expandIconPosition="end" /> : <div className="collapsible">{impactStrategy?.objectives?.map((el) => <button key={el.name} className={`collapseChildContainer ${selectedName === el.name ? 'selectedName' : ''}`} onClick={() => { setSelectedUnsdgs(el.objectiveUNSDG); setSelectedName(el.name) }}>{el.name}</button>)}</div>}
                        </div>
                    </div>
                </div>}
                <StyledText as="p" variant="H2">
                    {intl.formatMessage({ id: "available_support" })}
                </StyledText>
                <div className="supportFlexContainer">
                    {cashGiving === 'Y' && <div className="supportContainer grantContainer">
                        <div className="titleContainer">
                            <div><Avatar src={grant} /></div>
                            <div>
                                <StyledText as="p" variant="H3">
                                    {intl.formatMessage({ id: "grants" })}
                                </StyledText>
                                <StyledText as="p" variant="B4">
                                    {cashGivingOption === 'N' ? intl.formatMessage({ id: "mandatory" }) : intl.formatMessage({ id: "optional" })}
                                </StyledText>
                            </div>
                        </div>
                        {budgetVisible && <div>
                            <StyledText as="p" variant="B3">
                                {intl.formatMessage({ id: "expected_budget" })}:
                            </StyledText>
                            <StyledText as="p" variant="B3c">
                                {currencySymbol}{budgetAmount}
                            </StyledText>
                        </div>}
                        <div>
                            <StyledText as="p" variant="B3">
                                {intl.formatMessage({ id: "per_opportunity" })}:
                            </StyledText>
                            <StyledText as="p" variant="B3c">
                                {cashGivingTargetPerOpportunity === 'Maximum' ? `Max ${currencySymbol}${cashGivingTargetPerOpportunityAmount}` : `Min ${currencySymbol}${cashGivingTargetPerOpportunityAmount}`}
                            </StyledText>
                        </div>
                    </div>}
                    {volunteering === 'Y' && <div className="supportContainer volunteeringContainer">
                        <div className="titleContainer">
                            <div><Avatar src={volunteer} /></div>
                            <div>
                                <StyledText as="p" variant="H3">
                                    {intl.formatMessage({ id: "volunteering" })}
                                </StyledText>
                                <StyledText as="p" variant="B4">
                                    {volunteeringOption === 'N' ? intl.formatMessage({ id: "mandatory" }) : intl.formatMessage({ id: "optional" })}
                                </StyledText>
                            </div>
                        </div>
                        <StyledText as="p" variant="B3">
                            {volunteeringType === 'General' ? intl.formatMessage({ id: "general_skills_available" }) : intl.formatMessage({ id: "specific_skills_available" })}
                        </StyledText>
                        <ul>{volunteeringSkillList?.map((el) => <li key={el}><StyledText as="span" variant="B3">{el}</StyledText></li>)}</ul>
                    </div>}
                    {inKind === 'Y' && <div className="supportContainer inKindContainer">
                        <div className="titleContainer">
                            <div><Avatar src={inkind} /></div>
                            <div>
                                <StyledText as="p" variant="H3">
                                    {intl.formatMessage({ id: "inkind" })}
                                </StyledText>
                                <StyledText as="p" variant="B4">
                                    {inKindOption === 'N' ? intl.formatMessage({ id: "mandatory" }) : intl.formatMessage({ id: "optional" })}
                                </StyledText>
                            </div>
                        </div>
                        <ul>{inKindOffering?.map((el) => <li key={el}>{el.InKindOfferingNumber} {el.inKindOfferingDescription}</li>)}</ul>
                    </div>}
                </div>
                <div className="subContainer">
                    <StyledText as="p" variant="H2">
                        {intl.formatMessage({ id: "supporting_materials" })}
                    </StyledText>
                    <div className="fileContainer">
                        {Array.isArray(supportingMaterial) && supportingMaterial?.map((el) => <div key={el.fileKey} className="fileKey"><Avatar src={file} className="file" onClick={() => onDownloadFile(el)} />  <StyledText as="p" variant="B3">
                            {el.name}
                        </StyledText>
                        </div>)}
                    </div>
                </div>
                <div className="subContainer">
                    <StyledText as="p" variant="H2">
                        {intl.formatMessage({ id: "web_links" })}
                    </StyledText>
                    {keyWebLinks?.map((el) => <LinkComponent key={el} href={`http://${el}`}><StyledText as="p" variant="B3b">
                        {el}
                    </StyledText> </LinkComponent>)}
                </div>
                <div className="subContainer">
                    <StyledText as="p" variant="H2">
                        {intl.formatMessage({ id: "administrators" })}
                    </StyledText>
                    {images?.map((el) => <div key={el?.name} className="locationIcon"><Avatar src={el?.url || person} className="administrators" />
                        <StyledText as="p" variant="B3">
                            {el.name}
                        </StyledText></div>)}
                </div>
            </div>
        </div>
    );
};

const StyledProgramReview = styled(ProgramReview)`
  &.programReview {
    display: flex;
    gap: 24px;
    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.45);
    }
        .spinner {
        z-index: 2000;
        margin: auto;
        left: 50%;
        right: 50%;
        position: absolute;
        bottom: 50%;
        }
            ul {
            margin: 0;
            }

    .leftSubContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
    } 

    .ant-collapse-expand-icon {
      color: #313131 !important;
    }

    .containerBox {
      background: transparent linear-gradient(269deg, #ffffff 0%, #3131310d 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 16px #3131310d;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .flex {
        display: flex;
        gap: 10px;
      }
    }

    .supportFlexContainer {
      display: flex;
      gap: 30px;

      .supportContainer {
        padding: 16px;
        width: 200px;

        &.grantContainer {
          background: transparent linear-gradient(223deg, #ffffff 0%, #b2f9bd 100%) 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 16px #0000000d;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        &.volunteeringContainer {
          background: transparent linear-gradient(223deg, #ffffff 0%, #8bdaff 100%) 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 16px #0000000d;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        &.inKindContainer {
          background: transparent linear-gradient(223deg, #ffffff 0%, #ffaffa 100%) 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 16px #0000000d;
          border-radius: 10px;
        }

        .titleContainer {
          display: flex;
          gap: 2px;
        }
      }
    }

    .fileContainer {
      display: flex;
      gap: 20px;
        .fileKey{
            background: white;
            padding: 15px;
            border-radius: 10px;
            box-shadow: 0px 3px 16px #0000000D;
            .ant-avatar-image {
                cursor: pointer;
      }
        }
     
    }

    .webLinks {
        background: white;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0px 3px 16px #0000000D;
        display: flex;
        align-items: center;
        width: max-content;

      img {
        transform: scale(0.5);
      }

      p {
        color: #3e526c;
      }
    }

    .mapIFrameStyle {
      border: 0;
    }

    .locationIcon {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .locationContainer {
      .locationDistance {
        font-style: italic;
      }

      svg {
        color: #f96675;
      }
    }

    .administrators {
      height: 50px;
      width: 50px;
    }

    .file {
      height: 70px;
      width: 70px;
    }

    .collapseChildContainer {
      border: 1px solid #d7dde1;
      padding: 12px 16px;
      margin-bottom: 6px;
      cursor: pointer;
      text-align: left;
      background: white;
      font-size: 14px;
      font-weight: ${constants.FONT_WEIGHT_SEMIBOLD};
      color: ${constants.COLOR_GREY_DARK};
      font-family: ${constants.POPPINS_FONT_FAMILY};

      &.selectedName {
        border: 1px solid #04ac9c;
        border-radius: 6px;
        background: #bcfbf5;
      }
    }

    .collapsible {
      display: flex;
      flex-direction: column;

      .collapseChildContainer {
        background: white;
        border: 0;
        border-bottom: 1px solid #d9d9d9;
        padding: 12px 16px;
        text-align: left;

        &.selectedName {
          border: 1px solid #04ac9c;
          background: #bcfbf5;
        }

        cursor: pointer;
      }
    }

    .subContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .descriptionContainer{
        p, li{
            font-size: 14px;
            font-weight: 500;
            color: #242424;
            font-family: sans-serif;
        }
      }
    }

    .ant-collapse-expand-icon {
      transform: rotate(90deg) translate(-5px);
      svg{
            fill: #313131 !important;
        }
    }

    .ant-collapse-item-active {
      .ant-collapse-expand-icon {
        transform: rotate(0deg) translate(-5px);
      }
    }

    .ant-timeline-item-head {
      background: transparent !important;
    }

    .anticon-check {
      color: #58b55e;
    }

    .unsdgPartitionContainer {
      display: flex;
      gap: 20px;
      background: white;
    padding: 30px;
    border-radius: 10px;

      .collapseContainer {
        width: 50%;
        button {
            width: 100%;
        }
      }

      .unsdgsContainer {
        display: flex;
        gap: 15px;
        width: 48%;
        flex-wrap: wrap;
        height: max-content;

        .showAllGoals {
          height: 100px;
          width: 100px;
          background: white;
          display: flex;
          flex-direction: column;
          padding: 15px;
          justify-content: center;
          align-items: center;
          text-align: center;
          border: 2px solid #00877a;
          border-radius: 6px;

          p,
          svg {
            color: #00877a !important;
          }
        }

        .unsdgs {
          height: 100px;
          width: 100px;
        }
      }
    }

    .expandAll {
      color: #00877a;
      text-align: end;
      background: transparent;
      border: 0;
      cursor: pointer;
    }

    .ant-collapse{
        border: 0;
        border-bottom: 1px solid #D7DDE1;
        .ant-collapse-header{
        background: white !important;
        }
    }
    .ant-collapse-content-active{
        border-top: 0;
    }
  }
`;

export default StyledProgramReview;