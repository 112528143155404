import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { Avatar, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import styled from "styled-components";
import {
    useAppSelector,
    useAppDispatch
} from "../../../redux/store";
import { getProfileDataCompany } from "../../../redux/actions"
import LinkComponent from "../../../components/LinkComponent";
import { profileSelector } from "../../../redux/selectors";
import { getSecuredUrl } from "../../../utils/utilities";
import StyledText from "../../../components/StyledText";
import Button from "../../../components/Button";
import LinkedinIcon from "../../../assets/linkedin.png";
import InstaGramIcon from "../../../assets/instagram.png";
import TwitterIcon from "../../../assets/twitter.png";
import FacebookIcon from "../../../assets/facebook.png";

const CreateProfile = ({ className }) => {
    const baseClassName = clsx("createProfile", className);
    const [logo, setLogo] = useState(null);
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const profile = useAppSelector(profileSelector) || {};

    useEffect(() => {
        dispatch(getProfileDataCompany())
    }, [])

    const { logoS3URL, tradingName, description, registeredAddress, webURI, socialMedia } = profile?.company || {};

    const setLogoImage = async (fileKey) => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogo(securedURL);
    };

    const handleEdit = () => {
        navigate("/company/profile/create/start")
    }

    useEffect(() => {
        if ((logoS3URL && typeof logoS3URL === 'string') || logoS3URL?.length > 0) {
            const url = logoS3URL?.[0]?.thumbUrl || logoS3URL;
            setLogoImage(url)
        }
    }, [logoS3URL])

    return (
        <div className={baseClassName}>
            <StyledText as="p" variant="H1">
                {intl.formatMessage({ id: "organization_profile" })}
            </StyledText>
            <div className='contentContainer'>
                <div className="titleContainer">
                    <div className='logoContentContainer'>
                        <div className='logoContainer'>
                            <img src={logo} alt="logo" className="hero-image" />
                        </div>
                        <StyledText as="p" variant="H1a" className='companyName'>
                            {tradingName}
                        </StyledText>
                    </div>
                    <div>
                        <Button className='editButton' variant="primary" type="button" onClick={() => handleEdit()}>{intl.formatMessage({ id: "edit" })}</Button>
                    </div>
                </div>
                <StyledText as="p" variant="H2">
                    {intl.formatMessage({ id: "description" })}
                </StyledText>
                <StyledText as="p" variant="B3">
                    {description}
                </StyledText>
                <Divider />
                <StyledText as="p" variant="H2">
                    {intl.formatMessage({ id: "detail" })}
                </StyledText>
                <StyledText as="p" variant="B3d">
                    {intl.formatMessage({ id: "registered_address" })}
                </StyledText>
                <StyledText as="p" variant="B3">
                    {registeredAddress?.addressLine1}
                </StyledText>
                <div className='subContentContainer'>
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "media" })}
                    </StyledText>
                    <div className='linkContainer'>
                        <Avatar src={LinkedinIcon} />
                        <LinkComponent href={`http://${socialMedia?.linkedInURL}`}>
                            <StyledText as="p" variant="B3b">
                                {socialMedia?.linkedInURL}
                            </StyledText>
                        </LinkComponent>
                    </div>
                    <div className='linkContainer'>
                        <Avatar src={FacebookIcon} />
                        <LinkComponent href={`http://${socialMedia?.facebookURL}`}>
                            <StyledText as="p" variant="B3b">
                                {socialMedia?.facebookURL}
                            </StyledText>
                        </LinkComponent>
                    </div>
                    <div className='linkContainer'>
                        <Avatar src={InstaGramIcon} />
                        <LinkComponent href={`http://${socialMedia?.instagramURL}`}>
                            <StyledText as="p" variant="B3b">
                                {socialMedia?.instagramURL}
                            </StyledText>
                        </LinkComponent>
                    </div>
                    <div className='linkContainer'>
                        <Avatar src={TwitterIcon} />
                        <LinkComponent href={`http://${socialMedia?.xURL}`}>
                            <StyledText as="p" variant="B3b">
                                {socialMedia?.xURL || socialMedia?.twitterURL}
                            </StyledText>
                        </LinkComponent>
                    </div>
                </div>
                <StyledText as="p" variant="B3d">
                    {intl.formatMessage({ id: "website" })}
                </StyledText>
                <LinkComponent href={`http://${webURI}`}>
                    <StyledText as="p" variant="B3b">
                        {webURI}
                    </StyledText>
                </LinkComponent>
            </div>
        </div>
    )
}

const StyledCreateProfileEntity = styled(CreateProfile)`
&.createProfile{
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    padding: 20px;
    flex-direction: column;
    .contentContainer{
        margin-block: 20px;
        background-color: white;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-radius: 10px;
        .titleContainer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .editButton{
                padding-inline: 50px;
            }
            .logoContentContainer{
            display: flex;
            align-items: center;
            .logoContainer{
                height: 100px;
                width: 100px;
                img{
                    width: 100%;
                }
               
            }
            .companyName{
                    margin-left: 20px;
                }
        }
        }
        .subContentContainer{
            display: flex;
        flex-direction: column;
        gap: 10px;
        .linkContainer{
        display: flex;
        align-items: center;
        gap: 20px;
       }
        }
       
    }
}`;

export default StyledCreateProfileEntity;