import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Spin } from "antd";
import { getLocalStorageItem } from "../../utils/utilities";
import styled from "styled-components";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import { getProgramById, updateProgram, toggleSpinner, setUpdateProgram, getImpactStrategyListProgram } from "../../redux/actions";
import { programDataSelector, updateProgramSelector, toggleSpinnerSelector, impactStrategyListProgramSelector } from "../../redux/selectors";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import Select from "../../components/CustomSelect"

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const CompanyCreateName = ({ className }) => {
    const [savedClicked, setSavedClicked] = useState(false);
    const [deleteClicked, setDeleteClick] = useState(false);
    const intl = useIntl();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const programData = useAppSelector(programDataSelector);
    const impactStrategyListProgram = useAppSelector(impactStrategyListProgramSelector) || [];
    const updateProgramData = useAppSelector(updateProgramSelector) || {};
    const programReviewDetails = JSON.parse(getLocalStorageItem('createdProgramID') || '{}');
    const { programID } = programReviewDetails || {};
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const { message } = updateProgramData || {};

    useEffect(() => {
        if (message && !savedClicked && !deleteClicked) {
            dispatch(setUpdateProgram(''))
            navigate("/company/programme/create/measure");
            dispatch(toggleSpinner(false));
        } if (message && deleteClicked) {
            window.location.reload(false);
            dispatch(setUpdateProgram(''))
            setDeleteClick(false);
            dispatch(toggleSpinner(false));
        }
    }, [message])

    useEffect(() => {
        if (programData?.programName) {
            const data = {
                ...(programData?.outcomeMethod && { outcomeMethod: programData?.outcomeMethod }),
                ...(programData?.impactStrategyID && { impactStrategyID: programData?.impactStrategyID }),
                ...(programData?.impactStrategy && { impactStrategy: programData?.impactStrategy }),
            };
            form.setFieldsValue(data);
        }
    }, [programData]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getImpactStrategyListProgram())
            dispatch(getProgramById(programID));
        }
    }, [dispatch, programID]);


    const handleProceed = () => {
        const formData = form.getFieldsValue();
        const data = {
            action: "add",
            outcomeMethod: impactStrategyListProgram.find((el) => el.ID === formData?.impactStrategyID)?.outcomeMethod,
            impactStrategyID: formData?.impactStrategyID,
            impactStrategy: impactStrategyListProgram.find((el) => el.ID === formData?.impactStrategyID)?.impactStrategy,
            programID
        }

        dispatch(updateProgram(data));
        dispatch(toggleSpinner(true));
    };

    const handleChange = (id) => {
        setDeleteClick(true);
        const data = {
            impactStrategyID: id,
            programID,
            action: "remove"
        }
        setTimeout(() => {
            dispatch(updateProgram(data));
            dispatch(toggleSpinner(true));
        }, 300);

    }

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
            navigate(`/company/programmes/review/${programID}`);
        }, 300);
    }

    const handlePrevious = () => {
        navigate("/company/programme/create/questions")
    }

    return (
        <div className={clsx("createProfile", className)}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <StyledText as="p" variant="H1" className='mainTitle'>
                {intl.formatMessage({ id: "impact_strategy" })}
            </StyledText>
            <div>
                <Form
                    {...formItemLayout}
                    labelAlign="left"
                    form={form}
                    onFinish={handleProceed}
                    className="formContainer"
                    scrollToFirstError
                >
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "please_select_your_impact_strategy" })}
                    </StyledText>
                    {impactStrategyListProgram?.length > 0 && !programData?.impactStrategyID ? <Form.Item
                        name="impactStrategyID"
                        className="formItem"
                        rules={[
                            { required: true, message: intl.formatMessage({ id: "enter" }) },
                        ]}
                    >
                        <Select
                            allowClear
                            placeholder={intl.formatMessage({ id: "select_title" })}
                            type="formSelect"
                            options={impactStrategyListProgram?.map((el, index) => ({
                                key: el?.strategyName,
                                label: el?.strategyName,
                                value: el?.ID,
                            }))
                            }
                        />
                    </Form.Item> : programData?.impactStrategyID && <div className='flex'>
                        <StyledText as="p" variant="B3e">
                            {impactStrategyListProgram.find((el) => el.ID === programData?.impactStrategyID)?.strategyName}
                        </StyledText>
                        <Button onClick={() => handleChange(programData?.impactStrategyID)}>
                            <StyledText as="p" variant="B3bb" className='change'>
                                {intl.formatMessage({ id: "change" })}
                            </StyledText>
                        </Button>
                    </div>}
                    <div className='buttonContainer'>
                        <Divider />
                        <div className='buttonContentContainer'>

                            <div className='nextAndPreviousContainer'>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    onClick={() => handlePrevious()}>
                                    {intl.formatMessage({ id: "previous" })}
                                </Button>
                                <Button variant="primary" htmlType="submit">
                                    {intl.formatMessage({ id: "next" })}
                                </Button>
                            </div>
                            <div className="saveAndExitButton">
                                <Button variant="secondary" type="button" onClick={handleSaveAndExit}>
                                    {intl.formatMessage({ id: "save_n_exit" })}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

const StyledCompanyCreateName = styled(CompanyCreateName)`
    &.createProfile {
        background: white;
        display: flex;
        padding: 20px;
        flex-direction: column;
        min-height: 150svh;
        .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
            .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
        }

        .mainTitle {
            margin-bottom: 20px;
        }

        .formContainer {
            display: flex;
            gap: 20px;
            flex-direction: column;
            width: 1000px;
        }

        .buttonContainer {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            position: fixed;
            width: 100%;
            bottom: 0px;
            background: white;
            margin-left: -80px;

            .buttonContentContainer {
                display: flex;
                justify-content: center;
                width: 100%;
                position: relative;

                .saveAndExitButton {
                    position: absolute;
                    right: 0;
                    margin-bottom: 15px;
                }

                .nextAndPreviousContainer {
                    display: flex;
                    gap: 20px;
                    margin-bottom: 15px;
                }
            }
        }
        .flex{
            display: flex;
            gap: 20px;
            align-items: center;
        }
        .change{
            text-decoration: underline;
        }
    }
`;

export default StyledCompanyCreateName;
