import React, { useState } from "react";
import { Table, Checkbox } from "antd";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { addInteraction } from '../../../redux/actions';
import StyledText from "../../../components/StyledText";
import Button from '../../../components/Button';
import CustomModal from "../../../components/Modal";
import {
    interactionSelector
} from "../../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../../redux/store";

const ProgramReview = ({ className }) => {
    const baseClassName = clsx("programReview", className);
    const intl = useIntl();
    const [checked, setChecked] = useState(false);
    const dispatch = useAppDispatch();
    const interactions = useAppSelector(interactionSelector);
    const { programID, opportunityID: opportunityId } = useParams();

    const { approvalStepCompletion } = interactions?.[0] || {};

    const getTableData = (list) => {
        if (list?.length > 0) {
            return list?.map((item, idx) => {
                const {
                    approvalName,
                    userName,
                    approvalDate,
                    approvalID,
                    userId
                } = item || {};
                return {
                    key: idx,
                    approvalName,
                    userName,
                    approvalDate,
                    approvalID,
                    userId
                };
            });
        }
        return null;
    }

    const onChange = (name) => {
        setChecked(name);
    };

    const columns = [
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "approval" })}
            </StyledText>,
            dataIndex: 'approvalName',
            key: 'approvalName',
            fixed: 'left',
        },
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "approved_by" })}
            </StyledText>,
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "date_approved" })}
            </StyledText>,
            dataIndex: 'approvalDate',
            key: 'approvalDate',
        },
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "approve" })}
            </StyledText>,
            key: 'actions',
            dataIndex: 'status',
            fixed: 'right',
            render: (_, { approvalDate, approvalName }) => (
                <Checkbox checked={approvalDate} disabled={approvalDate} onChange={() => onChange(approvalName)}>
                </Checkbox>
            ),
        },
    ];

    return (
        <div className={baseClassName}>
            <div className='subContentContainer'>
                <Table columns={columns} dataSource={getTableData(approvalStepCompletion)} />
            </div>
            <CustomModal
                title={intl.formatMessage({ id: 'confirm_step_completion' })}
                open={checked}
                onOk={() => setChecked(false)}
                onCancel={() => setChecked(false)}
                closable
                footer={null}
            >
                <>
                    <StyledText as="p" variant="B3" className="title">
                        {intl.formatMessage({ id: 'please_confirm_this_step_has_been_completed' })}
                    </StyledText>
                    <div className='dFlexModal'>
                        <Button
                            variant="secondary"
                            htmlType="button"
                            onClick={() => setChecked(false)}>
                            {intl.formatMessage({ id: "cancel" })}
                        </Button>
                        <Button
                            variant="primary"
                            htmlType="button"
                            onClick={() => {
                                dispatch(addInteraction(
                                    {
                                        "action": "approvalSteps",
                                        programID,
                                        opportunityID: opportunityId,
                                        approvalSteps: {
                                            approvalName: checked
                                        }
                                    }))
                                setChecked(false)
                            }
                            }>
                            {intl.formatMessage({ id: "confirm_title" })}
                        </Button>
                    </div>
                </>
            </CustomModal>
        </div>
    );
};

const StyledProgramReview = styled(ProgramReview)`
  &.programReview {
    display: flex;
    gap: 24px;
    .subContentContainer{
        .ant-table-wrapper{
        width: 50vw !important;
    }
    }
  }
`;

export default StyledProgramReview;
