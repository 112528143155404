import React, { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import ReactHtmlParser from "react-html-parser";
import clsx from "clsx";
import styled from "styled-components";
import { UploadOutlined, UserOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { Form, List, Upload, Avatar, Button as AntButton, Switch, Input } from 'antd';
import StyledText from "../components/StyledText";
import { addInteraction } from '../redux/actions';
import {
    useAppDispatch
} from "../redux/store";
import Config from "../config"
import { getSecuredUrl, uploadFile, get, formatDate } from "../utils/utilities";
import axios from '../utils/axios';
import Button from '../components/Button';
import fileIcon from "../assets/file.svg";
import 'react-quill/dist/quill.snow.css';

const { TextArea } = Input;

const CommentComponent = ({ className, data, users, onSuccess, showSwitch, status }) => {
    const baseClassName = clsx("customComment", className);
    const intl = useIntl();
    const [comments, setComments] = useState([]);
    const [switchLabel, setSwitchLabel] = useState(intl.formatMessage({ id: 'enable_editing_of_application' }));
    const [newComment, setNewComment] = useState('');
    const [files, setFiles] = useState([]);
    const dispatch = useAppDispatch();
    const [pictures, setPictures] = useState({});
    const { programID, opportunityID } = useParams();

    const setLogoImage = async (fileKey) => {
        if (!fileKey) return '';
        const securedURI = await getSecuredUrl(fileKey);
        return URL.createObjectURL(securedURI);
    };

    const onDownloadFile = async (data) => {
        const { name, key = '' } = data || {};
        const getSecuredURL = await getSecuredUrl(key, name);
        const securedURL = URL.createObjectURL(getSecuredURL);
        const newTab = window.open(securedURL, '_blank');
        setTimeout(() => {
            URL.revokeObjectURL(securedURL);
            newTab?.close();
        }, 5000);

        return newTab;
    };

    const handleSwitchChange = (checked) => {
        setSwitchLabel(checked ? intl.formatMessage({ id: 'application_editing_enabled' }) : intl.formatMessage({ id: 'enable_editing_of_application' }));
    };


    useEffect(() => {
        if (!data?.length) return;
        const fetchCommentsWithImages = async () => {
            const tempPictures = { ...pictures };
            const uniqueUserIds = [...new Set(data.map((el) => el.userid))];
            await Promise.all(
                uniqueUserIds.map(async (id) => {
                    const user = users?.[id];
                    if (user?.picture && !tempPictures[id]) {
                        try {
                            const picture = await setLogoImage(user.picture);
                            tempPictures[id] = picture;
                        } catch (error) {
                            console.error(`Error fetching picture for user ${id}:`, error);
                        }
                    }
                })
            );

            const updatedComments = data.map((el) => {
                const user = users?.[el?.userid];
                return {
                    author: user?.name,
                    message: el?.message,
                    messageStatus: el?.messageStatus,
                    date: el?.date,
                    files: el?.files,
                    picture: tempPictures[el?.userid],
                };
            });
            setPictures(tempPictures);
            setComments(updatedComments);
        };

        fetchCommentsWithImages();
    }, [data, users]);

    const sendConversation = (responseData) => {
        if (responseData) {
            onSuccess(responseData);
            if (showSwitch && switchLabel === intl.formatMessage({ id: 'application_editing_enabled' })) {
                dispatch(addInteraction({
                    programID,
                    opportunityID,
                    action: "EditMode",
                    application: "Y"
                }));
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
        }
    };

    const handleCommentSubmit = () => {
        if ((showSwitch && ["Applied", "PreApproved", "InEdit"].includes(status) && !newComment.trim()) || (!files?.length && !newComment)) {
            return;
        }

        const newCommentData = {
            programID,
            opportunityID,
            message: newComment,
            ...(showSwitch && switchLabel === intl.formatMessage({ id: 'application_editing_enabled' }) && { messageStatus: 'Editing Enabled' }),
            files: [...files],
        };

        axios.put(Config.dev.url.sendConversation, sendConversation, newCommentData, true);
        setComments([...comments, newCommentData]);
        setNewComment('');
        setFiles([]);
    };

    const uploadFileToS3 = async (presignedPostData, file) =>
        new Promise((resolve, reject) => {
            const formData = new FormData();
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            Object.keys(presignedPostData.fields).forEach((key) => {
                formData.append(key, presignedPostData.fields[key]);
            });
            setFiles(prev => [...prev, { ...presignedPostData?.fields, name: file.name }])
            formData.append("file", file);
            uploadFile(
                presignedPostData.url,
                formData,
                (res, err) => {
                    if (res && res.status === 204) {
                        resolve(res.data);
                    } else {
                        reject(err.response);
                    }
                },
                config
            );
        });

    const getPresignedPostData = async (selectedFile) =>
        new Promise((resolve, reject) => {
            const fileName = (selectedFile.name).replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
            const url = `${Config.dev.url.companyLogoUploadUrl}?fileName=${fileName}`;
            get(url, (res, err) => {
                if (res && res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(err.response);
                }
            });
        });

    const processFileUpload = async ({ onSuccess, onError, file }) => {
        try {
            let presignedPostData = await getPresignedPostData(file);
            presignedPostData = await presignedPostData.data;
            await uploadFileToS3(presignedPostData, file);
            onSuccess(null, file);
        } catch (e) {
            onError(e);
        }
    };

    const handleRemove = (file) => {
        setFiles((prevFiles) => prevFiles.filter((f) => f.uid !== file.uid));
    };

    return (
        <div className={baseClassName}>
            {comments?.length > 0 && <List
                className="commentList"
                itemLayout="horizontal"
                dataSource={comments}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src={item.picture || <UserOutlined />} />}
                            title={<div style={{ display: 'flex', alignItems: 'center', width: '20vw', justifyContent: 'space-between' }}><StyledText as="p" variant="B3d" className="status">
                                {item.author}
                            </StyledText>
                                <StyledText as="p" variant="B3a" className="status">
                                    {formatDate(item.date)}
                                </StyledText>

                            </div>}
                            description={
                                <div>
                                    <StyledText as="p" variant="B3b" className="messageStatus">
                                        {item.messageStatus}
                                    </StyledText>
                                    <StyledText as="p" variant="B3" className="status">
                                        {ReactHtmlParser(ReactHtmlParser(item.message))}
                                    </StyledText>
                                    {item?.files?.length > 0 && (
                                        <div>
                                            {item?.files?.map((file) => (
                                                <div onClick={() => onDownloadFile(file)} className='fileContainer'>
                                                    <Avatar src={fileIcon} />
                                                    <StyledText key={file.uid} as="p" variant="B3" className="status">
                                                        {file.name}
                                                    </StyledText>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            }
                        />
                    </List.Item>
                )}
            />}
            <Form layout="vertical" className='formContainer'>
                <Form.Item
                    name="comment"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter a comment',
                        },
                        {
                            validator(_, value) {
                                if ((!value || value.trim() === '') && showSwitch && ["Applied", "PreApproved", "InEdit"].includes(status)) {
                                    return Promise.reject(new Error('Content cannot be empty'));
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                >
                    <TextArea
                        rows={4}
                        className='textArea'
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        placeholder={intl.formatMessage({ id: 'enter' })}
                        disabled={(((!["InEdit", "Approved"].includes(status)) && !data?.length) || status === "Declined") && !showSwitch}
                    />
                </Form.Item>
                <Upload
                    fileList={Array.isArray(files) ? files : !Array.isArray(files) ? [files] : []}
                    customRequest={({
                        onSuccess, onError, file
                    }) => processFileUpload({
                        onSuccess, onError, file
                    })}
                    onRemove={handleRemove}
                >
                    <AntButton variant="primary" icon={<UploadOutlined />} className='uploadButton'>
                        {intl.formatMessage({ id: 'upload' })}
                    </AntButton>
                </Upload>
                {showSwitch && ["Applied", "PreApproved", "InEdit"].includes(status) && <div className='switchContainer'>
                    <Form.Item name="toggleSwitch">
                        <Switch disabled={status === "InEdit"} onChange={handleSwitchChange}></Switch>
                    </Form.Item>
                    <StyledText as="p" variant="B3" className="status">
                        {switchLabel}
                    </StyledText>
                </div>}
                <Form.Item>
                    <Button variant="primary" type='button' onClick={handleCommentSubmit} className='sendButton'>
                        {intl.formatMessage({ id: 'send' })}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

const StyledCommentComponent = styled(CommentComponent)`
  &.customComment {
    .commentList{
        background: white;
        padding: 10px;
        width: 50vw;
    }
    .formContainer{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        .textArea{
            width: 50vw;
        }
    }
    .fileContainer{
        display: flex; 
        align-items: center;
        cursor: pointer;
        width: max-content;
        img{
            transform: scale(0.7);
        }
    }
    .messageStatus{
        margin-left: 100px;
    }
    .sendButton{
        margin-top: 15px;
    }
    .switchContainer{
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
        .ant-form-item{
            margin-bottom: 0px;
        }
    }
    .filesName{
        margin-top: 10px;
    }
    .ant-upload-list-item{
        width: 20vw;
    }
  }
`

export default StyledCommentComponent;
