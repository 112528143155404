import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Card, Form, Divider, Input, Avatar } from "antd";
import { Helmet as HelmetAsync } from "react-helmet-async";
import styled from "styled-components";
import { useIntl } from "react-intl";
import clsx from "clsx";
import DataCard from "./DataCard";
import * as constants from "../../constants";
import MapBoxComponent from "../../components/MapBoxComponent";
import {
    assetsSelector,
    tokenSelector,
    submissionsByCategorySelector,
    submissionsMapDataSelector,
    feedbackMapDataSelector,
    addCeSubmissionReviewSelector,
    submissionReviewSelector,
    usersSelector
} from "../../redux/selectors";
import backArrow from "../../assets/back-arrow.png"
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";
import { removeDuplicatesByKey } from "../../utils/utilities";
import {
    getAssets,
    getAllAssets,
    getSubmissions,
    getSubmissionsByCategory,
    addCeSubmissionReview,
    getSubmissionsMapData,
    getFeedbackMapData,
    getCeSubmissionReview,
    getUser,
    setAddCeSubmissionReview
} from "../../redux/actions";
import CustomSelect from "../../components/CustomSelect";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import Alert from "../../components/Alert";

const getScrollCoordinates = (card, selectedButton) => {
    const coordinates = selectedButton === 'submission' ?
        (card?.submitterLocation?.[0]?.geometry?.coordinates) || (card?.location?.[0]?.geometry?.coordinates) :
        card?.location?.[0]?.geometry?.coordinates;
    const name = selectedButton === 'submission' ? card?.submitterLocation?.[0]?.properties?.name : card?.location?.[0]?.properties?.name;
    const firstCoordinate = coordinates?.[0]?.[0]?.[0] || coordinates?.[0];
    const lastCoordinate = coordinates?.[0]?.[card?.location?.[0]?.geometry?.coordinates?.[0].length - 1]?.[1] || coordinates?.[1];
    const elementName = name ? `scroll-${name.replaceAll(' ', '-')}` : `scroll${firstCoordinate}${lastCoordinate}`;
    return elementName;
}

const { TextArea } = Input;

const reviewActionDropdown = [
    {
        "key": "Pending",
        "label": "Pending",
        "value": "Pending"
    },
    {
        "key": "In Progress",
        "label": "In Progress",
        "value": "In Progress",
    },
    {
        "key": "Complete",
        "label": "Complete",
        "value": "Complete"
    }
]

const flattenFeatures = (featureCollection) => {
    const featureType = featureCollection?.type;
    const flattenedFeatures = featureCollection?.features?.flat();
    return {
        type: featureType,
        features: flattenedFeatures
    };
}

export const getReviewStatusColor = (reviewStatus) => {
    switch (reviewStatus) {
        case 'Complete':
            return 'reviewed';
        case 'In Progress':
            return 'inProgress';
        default:
            return 'defaultProgress';
    }
}

const getUserName = (users, id) => {
    return users?.find((user) => user?.userID === id)?.username
}

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const Data = ({ className }) => {
    const intl = useIntl();
    const baseClassName = clsx("data", className);
    const [assetID, setAssetID] = useState('');
    const [selectedLocationCoordinates, setSelectedLocationCoordinates] = useState('');
    const [selectedLocationName, setSelectedLocationName] = useState('');
    const [submissions, setSubmissions] = useState('');
    const [selectedCard, setSelectedCard] = useState('');
    const [fitToCoordinates, setFitToCoordinates] = useState('');
    const [filters, setFilters] = useState({});
    const [selectedLocation, setSelectedLocation] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [selectedButton, setSelectedButton] = useState('feedback');
    const [feedbackTypeDropdownOptions, setFeedbackTypeDropdownOptions] = useState([]);
    const [reviewStatusDropdownOptions, setReviewStatusDropdownOptions] = useState([]);
    const dispatch = useAppDispatch();
    const token = useAppSelector(tokenSelector) || localStorage.getItem("id_Token");
    const assets = useAppSelector(assetsSelector);
    const submissionsByCategory = useAppSelector(submissionsByCategorySelector);
    const submissionsMapData = useAppSelector(submissionsMapDataSelector);
    const feedbackMapData = useAppSelector(feedbackMapDataSelector);
    const { reviewID } = useAppSelector(addCeSubmissionReviewSelector) || {};
    const submissionReview = useAppSelector(submissionReviewSelector);
    const users = useAppSelector(usersSelector);
    const timerRef = useRef(null);
    const timerRef2 = useRef(null);

    const [form] = Form.useForm();
    const { getFieldValue } = form;
    const values = getFieldValue();
    const { feedbackType, reviewStatus } = values || {};

    const { mapStyles } = assets?.[0] || {};

    const assetId = sessionStorage.getItem('assetID');
    const phaseId = sessionStorage.getItem('phaseId');

    useEffect(() => {
        setSelectedButton('feedback')
    }, [])

    useEffect(() => {
        if (token && (assetID || assetId)) {
            dispatch(getAllAssets());
            dispatch(getUser());
        }
    }, [token, assetId, assetID]);

    useEffect(() => {
        if (feedbackType || reviewStatus) {
            dispatch(getSubmissionsByCategory({
                assetID: assetId || assetID,
                ...(phaseId !== 'All' && phaseId && { phase: phaseId }),
                feedbackType: feedbackType,
                submissionType: "feedback",
            }));
        }
    }, [feedbackType, reviewStatus]);

    useEffect(() => {
        if (reviewID || selectedCard?.assetID) {
            dispatch(getCeSubmissionReview(`SubmissionID=${selectedCard?.ID}`));
            form.setFieldsValue({
                reviewStatusDetails: undefined,
                comment: undefined
            });
            if (reviewID) {
                dispatch(getSubmissions({ assetID: assetID, submissionType: "feedback", }));
                dispatch(getSubmissionsByCategory({
                    assetID: assetID,
                    ...(phaseId !== 'All' && phaseId && { phase: phaseId }),
                    submissionType: "feedback",
                }));
                setShowAlert(true);
                dispatch(setAddCeSubmissionReview(''));
                setSelectedCard('');
            }
        }
    }, [reviewID, selectedCard?.assetID])

    useEffect(() => {
        if (!submissions && submissionsByCategory) {
            setSubmissions(submissionsByCategory)
        }
        if (Array.isArray(submissionsByCategory) && submissionsByCategory?.length) {
            const feedbackTypeDropdownOptions = submissionsByCategory?.map((el) => ({
                key: Array.isArray(el?.feedbackType) ? el?.feedbackType?.[0] : el?.feedbackType,
                label: Array.isArray(el?.feedbackType) ? el?.feedbackType?.[0] : el?.feedbackType,
                value: Array.isArray(el?.feedbackType) ? el?.feedbackType?.[0] : el?.feedbackType,
            }));
            const reviewStatusDropdownOptions = submissionsByCategory?.map((el) => ({
                key: el?.reviewStatus,
                label: el?.reviewStatus,
                value: el?.reviewStatus,
            }));
            setFeedbackTypeDropdownOptions(feedbackTypeDropdownOptions);
            setReviewStatusDropdownOptions(reviewStatusDropdownOptions);
        }
    }, [submissionsByCategory, assetId, phaseId, submissions]);

    useEffect(() => {
        if (assetId) {
            setAssetID(assetId)
            dispatch(getAssets({ assetID: assetId }));
        }
        if (token) {
            dispatch(getSubmissionsByCategory({
                assetID: assetId || assetID,
                ...(phaseId !== 'All' && phaseId && { phase: phaseId }),
                submissionType: "feedback",
            }));

            timerRef.current = setTimeout(() => {
                dispatch(getSubmissionsMapData({
                    assetID: assetId || assetID,
                    submissionType: "feedback",
                    action: "submitterLocations",
                    ...(phaseId !== 'All' && phaseId && { phase: phaseId })
                }));
                clearTimeout(timerRef.current);
            }, 0)
            timerRef2.current = setTimeout(() => {
                dispatch(getFeedbackMapData({
                    assetID: assetId || assetID,
                    submissionType: "feedback",
                    action: "locations"
                }));
                clearTimeout(timerRef2.current);
            }, 300)
        }
    }, [token, assetId, phaseId]);

    const onGeometryClick = (data, name) => {
        let scrollElement = `scroll${data?.geometry?.coordinates?.[0]}${data?.geometry?.coordinates?.[1]}`
        if (data?.geometry?.type === 'Polygon') {
            scrollElement = `scroll-${data?.geometry?.coordinates?.[0]?.[0]?.[0]}${data?.geometry?.coordinates?.[0]?.[data?.geometry?.coordinates?.[0]?.length - 1][1]}`
        }
        if (name) {
            scrollElement = `scroll-${name.replaceAll(' ', '-')}`;
        }
        const container = document.getElementsByClassName(scrollElement)
        setSelectedLocationCoordinates(scrollElement)
        setSelectedLocationName(name)
        setFitToCoordinates(data?.geometry?.coordinates);
        if (container) {
            container?.[0]?.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    }

    const onFilterChange = (val, key) => {
        const newFilters = {
            [key]: [...(filters?.[key] || []), ...(val || [])],
            ...filters
        };
        setFilters(newFilters);
    };

    const onCardClick = (cardData) => {
        setShowAlert(false);
        setFitToCoordinates('');
        dispatch(getCeSubmissionReview(`SubmissionID=${cardData?.ID}`));
        setSelectedCard(cardData);
    }

    const handleSaveClick = () => {
        form.validateFields(['comment', 'reviewStatusDetails'])
            .then((values) => {
                dispatch(addCeSubmissionReview({
                    submissionID: selectedCard?.ID,
                    assetID: assetId || assetID,
                    comment: values.comment,
                    reviewStatus: values.reviewStatusDetails
                }));
            })
            .catch((error) => {
                console.log('Validation failed:', error);
            });
    };

    return (
        <div className={baseClassName}>
            <HelmetAsync>
                <title>Data Page</title>
            </HelmetAsync>
            <Form
                {...formItemLayout}
                labelAlign="left"
                form={form}
                className="formContainer"
                scrollToFirstError
            >
                <Row className="filters">
                    <StyledText as="p" variant="B3" className="status">
                        {intl.formatMessage({ id: "filters" })}
                    </StyledText>
                    <Col span={2.5}>
                        <Form.Item
                            name="feedbackType"
                        >
                            <CustomSelect
                                type="formSelect"
                                name="feedbackType"
                                onChange={(e) => onFilterChange(e, 'feedbackType')}
                                options={removeDuplicatesByKey(feedbackTypeDropdownOptions, 'key')}
                                mode="multiple"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2.5}>
                        <Form.Item
                            name="reviewStatus"
                        >
                            <CustomSelect
                                type="formSelect"
                                name="reviewStatus"
                                onChange={(e) => onFilterChange(e, 'reviewStatus')}
                                options={removeDuplicatesByKey(reviewStatusDropdownOptions, 'key')}
                                mode="multiple"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div className="detailsContainer">
                    <Row className="cardsContainer">
                        {submissionsByCategory?.map((card) => <Col span={24} key={card.ID}
                            className={getScrollCoordinates(card, selectedButton)}>
                            <DataCard
                                selectedCard={selectedLocationCoordinates === getScrollCoordinates(card, selectedButton) || card.ID === selectedCard?.ID}
                                onCardClick={() => onCardClick(card)}
                                comment={card?.comment}
                                feedbackType={card.feedbackType}
                                location={card.location}
                                selectedButton={selectedLocation}
                                reviewStatus={card.reviewStatus}
                                onButtonClick={(mapType) => {
                                    if (mapType === 'submission') {
                                        setFitToCoordinates(card?.submitterLocation?.[0]?.geometry?.coordinates);
                                    } else {
                                        setFitToCoordinates(card?.location?.[0]?.geometry?.coordinates);
                                    }
                                    setSelectedCard('');
                                    setSelectedButton(mapType);
                                    setSelectedLocation({ comment: card?.comment, mapType })
                                }
                                }
                            />
                        </Col>)}
                    </Row>
                    <div className="cardDetailsContainer">
                        {selectedCard ? <>
                            <button
                                className="backButton"
                                onClick={() => {
                                    setSelectedCard(null)
                                    setSelectedLocation('');
                                    setSelectedLocationCoordinates('');
                                    setFitToCoordinates('');
                                    setSelectedLocationName('')
                                }}
                            >
                                <Avatar src={backArrow} />
                                <StyledText as="p" variant="CTAa">
                                    {intl.formatMessage({ id: "back_to_map" })}
                                </StyledText>
                            </button>
                            <Card className="detailsCard">
                                {showAlert && <Alert message={intl.formatMessage({ id: "comments_added_successfully" })} visible={showAlert} />}
                                <div className="textContainer">
                                    <StyledText as="p" variant="B4">
                                        {intl.formatMessage({ id: "date_submitted" })}
                                    </StyledText>
                                    <StyledText as="p" variant="B4">
                                        {selectedCard?.createDate}
                                    </StyledText>
                                    <StyledText as="p" variant="H2" className={`status ${getReviewStatusColor(selectedCard.reviewStatus)}`}>
                                        {selectedCard.reviewStatus}
                                    </StyledText>
                                </div>
                                <StyledText as="p" variant="B2" className="subHeading">
                                    {intl.formatMessage({ id: "comment" })}
                                </StyledText>
                                <StyledText as="p" variant="B3" className="comment">
                                    {selectedCard?.comment}
                                </StyledText>
                                <div className="textContainer">
                                    <StyledText as="p" variant="B2" className="subHeading">
                                        {intl.formatMessage({ id: "attributes" })}
                                    </StyledText>
                                </div>
                                {selectedCard?.submitterType && <div className="textContainer">
                                    <StyledText as="p" variant="B3c">
                                        {`${intl.formatMessage({ id: "submitter_type" })}:`}
                                    </StyledText>
                                    <StyledText as="p" variant="B3" className="category">
                                        {selectedCard?.submitterType}
                                    </StyledText>
                                </div>}
                                {selectedCard?.location?.[0]?.properties?.name && <div className="textContainer">
                                    <StyledText as="p" variant="B3c">
                                        {`${intl.formatMessage({ id: "submission_location" })}:`}
                                    </StyledText>
                                    <StyledText as="p" variant="B3" className="category">
                                        {selectedCard?.location?.[0]?.properties?.name}
                                    </StyledText>
                                </div>}
                                <div className="textContainer">
                                    <StyledText as="p" variant="B3c">
                                        {`${intl.formatMessage({ id: "categories" })}:`}
                                    </StyledText>
                                    {selectedCard?.feedbackType?.map((el) => <StyledText key={el} as="p" variant="B3" className="category">
                                        {el}
                                    </StyledText>)}
                                </div>
                                <StyledText as="p" variant="B2" className="subHeading">
                                    {intl.formatMessage({ id: "happy_to_be_contacted" })}
                                </StyledText>
                                <div className="textContainer">
                                    <StyledText as="p" variant="B3c">
                                        {`${intl.formatMessage({ id: "requestedContact" })}:`}
                                    </StyledText>
                                    <StyledText as="p" variant="B3" className="category">
                                        {selectedCard?.requestedContact === 'Y' ? intl.formatMessage({ id: "yes" }) : intl.formatMessage({ id: "no" })}
                                    </StyledText>
                                </div>
                                {selectedCard?.name && <div className="textContainer">
                                    <StyledText as="p" variant="B3c">
                                        {`${intl.formatMessage({ id: "name" })}:`}
                                    </StyledText>
                                    <StyledText as="p" variant="B3" className="category">
                                        {selectedCard?.name}
                                    </StyledText>
                                </div>}
                                {selectedCard?.telephone && <div className="textContainer">
                                    <StyledText as="p" variant="B3c">
                                        {`${intl.formatMessage({ id: "telephone" })}:`}
                                    </StyledText>
                                    <StyledText as="p" variant="B3" className="category">
                                        {selectedCard?.telephone}
                                    </StyledText>
                                </div>}
                                {selectedCard?.email && <div className="textContainer">
                                    <StyledText as="p" variant="B3c">
                                        {`${intl.formatMessage({ id: "email" })}:`}
                                    </StyledText>
                                    <StyledText as="p" variant="B3" className="category">
                                        {selectedCard?.email}
                                    </StyledText>
                                </div>}
                                <Divider />
                                <StyledText as="p" variant="B2" className="subHeading actions">
                                    {intl.formatMessage({ id: "actions" })}
                                </StyledText>
                                <div className="formActionContainer">
                                    <Form.Item
                                        name="comment"
                                        className="commentField"
                                        rules={[
                                            {
                                                whitespace: true,
                                                required: true,
                                                message: intl.formatMessage({ id: "please_populate" }),
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            placeholder={intl.formatMessage({
                                                id: "add_comment",
                                            })}
                                            rows={4}
                                            className="commentFieldTextArea"
                                        />
                                    </Form.Item>
                                    <Col span={3}>
                                        <Form.Item
                                            name="reviewStatusDetails"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: intl.formatMessage({ id: "please_populate" }),
                                                },
                                            ]}
                                        >
                                            <CustomSelect
                                                type="formSelect"
                                                name="reviewStatus"
                                                onChange={(e) => onFilterChange(e, 'reviewStatus')}
                                                options={reviewActionDropdown}
                                            />
                                        </Form.Item>
                                    </Col>
                                </div>
                                <Button
                                    variant="primary"
                                    type="button"
                                    className="submitButton"
                                    onClick={() => handleSaveClick()}
                                >
                                    {intl.formatMessage({ id: "save" })}
                                </Button>
                                {submissionReview?.length > 0 && <><Divider />
                                    {submissionReview?.map((review) => <div key={review?.userID}>
                                        <StyledText as="p" variant="B2" className={`${getReviewStatusColor(selectedCard.reviewStatus)}`}>
                                            {review?.reviewStatus}
                                        </StyledText>
                                        <div className="textContainer">
                                            <StyledText as="p" variant="CTAa" className="question">
                                                {`${intl.formatMessage({ id: "date_actioned" })} : `}
                                            </StyledText>
                                            <StyledText as="p" variant="B3">
                                                {review?.createDate}
                                            </StyledText>
                                        </div>
                                        <div className="textContainer">
                                            <StyledText as="p" variant="CTAa" className="question">
                                                {`${intl.formatMessage({ id: "actioned_by" })} : `}
                                            </StyledText>
                                            <StyledText as="p" variant="B3">
                                                {getUserName(users, review?.userID)}
                                            </StyledText>
                                        </div>
                                        <div className="textContainer">
                                            <StyledText as="p" variant="CTAa" className="question">
                                                {`${intl.formatMessage({ id: "comments" })} : `}
                                            </StyledText>
                                            <StyledText as="p" variant="B3">
                                                {review?.comment}
                                            </StyledText>
                                        </div>
                                        <Divider />
                                    </div>)}
                                </>}
                            </Card></> : <div className="mapContainer">
                            <div className="mapButtons">
                                <button className={`mapButton ${selectedButton === 'submission' ? 'selected' : ''}`} onClick={() => { setSelectedButton('submission'); setSelectedLocation(''); setSelectedLocationCoordinates(''); setFitToCoordinates(''); setSelectedLocationName('') }}>
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "submitter_location" })}
                                    </StyledText>
                                </button>
                                <button className={`mapButton ${selectedButton === 'feedback' ? 'selected' : ''}`} onClick={() => { setSelectedButton('feedback'); setSelectedLocation(''); setSelectedLocationCoordinates(''); setFitToCoordinates(''); setSelectedLocationName('') }}>
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "feedback_location" })}
                                    </StyledText>
                                </button>
                            </div>
                            {(feedbackMapData || submissionsMapData) && <div className="mapBoxContainer">
                                {selectedButton === 'feedback' ? <MapBoxComponent
                                    zoom={10}
                                    styles={mapStyles?.Default}
                                    defaultStyle="MonoCrome"
                                    enableDraw
                                    enableClick
                                    geoJsonData={flattenFeatures(feedbackMapData)}
                                    id="feedback"
                                    fitToCoordinates={fitToCoordinates}
                                    onGeometryClick={onGeometryClick}
                                    selectedLocationName={selectedLocationName}
                                /> :
                                    <MapBoxComponent
                                        zoom={10}
                                        styles={mapStyles?.Default}
                                        defaultStyle="MonoCrome"
                                        enableDraw
                                        enableClick
                                        id="submission"
                                        geoJsonData={flattenFeatures(submissionsMapData)}
                                        fitToCoordinates={fitToCoordinates}
                                        onGeometryClick={onGeometryClick}
                                        selectedLocationName={selectedLocationName}
                                    />}
                            </div>}
                        </div>}
                    </div>
                </div>
            </Form>
        </div>
    );
};

const StyledData = styled(Data)`
  &.data {
    background: ${(props) => props.theme.backgroundCanvas};
    display: flex;
    flex-direction: column;
    gap: 20px;
    .textContainer{
        display: flex;
        gap: 10px;
        align-items: center;
        margin-block: 15px;
        flex-wrap: wrap;
        .category{
            padding: 5px;
            line-height: 14px;
            background: ${constants.PRIMARY_COLOR_2};  
            white-space: nowrap;
            height: max-content;
        }
        &.title {
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
        }
        .textBold {
            font-weight: 550;
            width: 200px;
        }
    }
    .formActionContainer{
        display: flex;
        align-items: flex-start;
        margin-bottom: 15px;
        width: 100%;

        .commentField{
            .ant-form-item-control{
                width: 30vw !important;
            }
        }
        .customSelect{
            width: 150px !important;
        }
    }
        .filters{
            display: flex;
            gap: 20px;
            align-items: center;
            .customSelect{
                max-width: 150px !important;
                min-width: 150px !important;
                p{
                    font-size: 12px;
                }
            }
        }
    .categoryContainer, .formContainer{
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }
    .formContainer{
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 10px;
        .ant-form-item {
            margin-bottom: 0px !important;
        }
    }

    .title {
      color: #6F6AF8;
    }
    .ant-card{
        position: relative;
        .status{
            position: absolute;
            top: 8px;
            right: 48px;
        }
    }
    .detailsContainer{
        display: flex;
        gap: 30px;
        justify-content: space-between;
        .cardsContainer{
            display: flex;
            gap: 20px;
            max-height: 62vh;
            overflow-y: scroll;
            width: 45vw;
        }
        .backButton{
            border: 0;
            display: flex;
            gap: 10px;
            margin: 0px 0 10px 0px;
            background: transparent;
            align-items: center;
            height: max-content;
        }
        .cardDetailsContainer{
            display: flex;
            height: 65vh;
            overflow-y: scroll;
            width: 45vw;
            flex-direction: column;
            width: max-content;
        }
        .detailsCard{
            width: 45vw;
            display: grid;
            gap: 5px;
        }
    }

    .mapContainer{
        width: 45vw;
        .mapButtons{
            display: flex;
            gap: 10px;
            width: 100%;
            justify-content: flex-end;
            margin-bottom: 10px;
            .mapButton {
                cursor: pointer;
                border-radius: 4px;
                background: white;
                border: 1px solid #F0F0F0;
                &.selected{
                    background: #FFF7F5;
                border: 1px solid #ED6C2D;
                }
            }
        }
        .mapBoxContainer{
            position: relative;
            height: 60vh;
        }
    }
    .reviewed{
        color: #6DBCB6!important;
    }
    .inProgress{
        color: #FDB228!important;
    }
    .defaultProgress {
        color: #FDB228!important;
    }
    .question{
        font-weight: bold;
    }
    .answer {
        color: ${constants.PRIMARY_DEFAULT_MENU_COLOR};
    }
    .submitButton{
        width: max-content;
    }
    .subHeading{
        font-weight: 550;
    }
    .actions{
        margin-block: 20px;
    }
  }
`;

export default StyledData;
