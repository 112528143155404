import { fetchData, onDownloadFile } from "../utils/utilities";

const createAction = (type, key) => (payload) => ({ type, payload, key });

export const setAllAssets = createAction("SET_ALL_ASSETS", "allAssets");
export const setAssets = createAction("SET_ASSETS");
export const setProgressAssetAnalytics = createAction("SET_PROGRESS_ASSETS_ANALYTICS", 'progressAnalytics');
export const setResponderTypeAnalytics = createAction("SET_RESPONDER_TYPES_ANALYTICS", 'responderTypeAnalytics');
export const setSubmissionsByPhase = createAction("SET_SUBMISSIONS_BY_PHASE", 'submissionsByPhase');
export const setThemeAnalytics = createAction("SET_THEME_ANALYTICS", 'themeAnalytics');
export const setPopularCategoryAnalytics = createAction("SET_POPULAR_CATEGORY_ANALYTICS", 'popularCategoryAnalytics');
export const setPopularSubCategoryAnalytics = createAction("SET_POPULAR_SUB_CATEGORY_ANALYTICS", 'popularSubCategoryAnalytics');
export const setLoginData = createAction("SET_LOGIN_DATA", "loginObj");
export const setErrorObj = createAction("SET_ERROR", "errorObj")
export const setProfileData = createAction("SET_PROFILE", "profileData")
export const setResetPassword = createAction("RESET_PASSWORD", "resetObj")
export const setPassword = createAction("SET_PASSWORD", "password")
export const resetState = createAction("RESET_REDUX_STATE");
export const setSubmissions = createAction("SET_SUBMISSIONS", 'submissions');
export const setSubmissionsByCategory = createAction("SET_SUBMISSIONS_BY_CATEGORY", 'submissionsByCategory');
export const setAddCeSubmissionReview = createAction("SET_ADD_SUBMISSION_REVIEW", 'addCeSubmissionReview');
export const setSubmissionsMapData = createAction("SET_SUBMISSIONS_MAP_DATA", 'submissionsMapData');
export const setFeedbackMapData = createAction("SET_FEEDBACK_MAP_DATA", 'feedbackMapData');
export const setSubmissionReview = createAction("SET_SUBMISSION_REVIEW", 'submissionReview');
export const setUsers = createAction("SET_USERS", 'users');
export const setSubmissionTotalAnalytics = createAction("SET_SUBMISSION_TOTAL_ANALYTICS", 'totalVisits');
export const setIdeaProgressAnalytics = createAction("SET_IDEA_PROGRESS_ANALYTICS", 'ideaProgressAnalytics');
export const setFeedbackProgressAnalytics = createAction("SET_FEEDBACK_PROGRESS_ANALYTICS", 'feedbackProgressAnalytics');
export const setCollaborateProgressAnalytics = createAction("SET_COLLABORATE_PROGRESS_ANALYTICS", 'collaborateProgressAnalytics');
export const setIdeasPendingActions = createAction("SET_IDEA_PENDING_ACTIONS", 'ideasPendingActions');
export const setFeedbackPendingActions = createAction("SET_FEEDBACK_PENDING_ACTIONS", 'feedbackPendingActions');
export const setCollaboratePendingActions = createAction("SET_COLLABORATE_PENDING_ACTIONS", 'collaboratePendingActions');
export const setHistoricalVisitors = createAction("SET_HISTORICAL_VISITORS", 'historicalVisitors');
export const setSignup = createAction("SET_SIGN_UP_DATA", 'signup');
export const setEditProfile = createAction("SET_EDIT_PROFILE", 'editProfile');
export const setCreateProfile = createAction("SET_CREATE_PROFILE", 'createProfile');
export const setEditUserProfile = createAction("SET_EDIT_USER_PROFILE", 'editUserProfile');
export const setProgrammeList = createAction("SET_PROGRAMME_LIST", 'programmeList');
export const setCreatePassword = createAction("SET_CREATE_PASSWORD", 'createPassword');
export const setAllInteraction = createAction("SET_ALL_INTERACTION", 'allInteractions');
export const setInteraction = createAction("SET_INTERACTION", 'interaction');
export const setActionInteraction = createAction("SET_ACTION_INTERACTION", 'actionInteraction');
export const setInteractionStatus = createAction("SET_INTERACTION_STATUS", 'interactionStatus');
export const setDownloadProgrammeData = createAction("SET_DOWNLOAD_PROGRAM", 'downloadProgrammeData');
export const setSearchInputFieldValue = createAction("SET_SEARCH_INPUT_VALUE", 'searchActionValue');
export const setSharePreApproved = createAction("SET_SHARE_PRE_APPROVED", 'sharePreApproved');
export const setApprovedProgramData = createAction("SET_APPROVED_PROGRAMME_DATA", 'approvedProgrammeData');
export const setDonationListData = createAction("SET_DONATION_DATA", 'donationListData');
export const setDonationTwo = createAction("SET_DONATION_TWO", 'donationTwo');
export const setOpportunitiesDropDownAction = createAction("SET_OPPORTUNITIES_DROPDOWN", 'opportunitiesOptions');
export const toggleSpinner = createAction("TOGGLE_SPINNER", 'toggleSpinner');
export const setProgramById = createAction("SET_PROGRAM_DATA", 'programData');
export const setUserImage = createAction("SET_USER_IMAGE");
export const setOpportunitiesData = createAction("SET_OPPORTUNITIES_DATA", 'opportunityData');
export const setEditOpportunitiesData = createAction("SET_EDIT_OPPORTUNITIES_DATA", 'editOpportunityData');
export const setCreateOpportunity = createAction("SET_CREATE_OPPORTUNITIES_DATA", 'createOpportunity');
export const setAddInteraction = createAction("SET_ADD_INTERACTION", 'addInteraction');
export const setIndicatorData = createAction("SET_INDICATOR_DATA", 'indicatorData');
export const setProgramList = createAction("SET_PROGRAM_LIST", 'programList');
export const setCreateProgram = createAction("SET_CREATE_PROGRAM", 'createProgram');
export const setUpdateProgram = createAction("SET_UPDATE_PROGRAM", 'updateProgram');
export const setImpactStrategyListProgram = createAction("SET_IMPACT_STRATEGY_LIST_PROGRAM", 'impactStrategyListProgram');
export const setNewToken = createAction("SET_NEW_TOKEN", 'guestToken');
export const setGuestProgramList = createAction("SET_GUEST_PROGRAM_DATA", 'guestProgramData');
export const setConversation = createAction("SET_CONVERSATION_DATA", 'conversation');
export const setDeleteProgram = createAction("SET_DELETE_PROGRAM_DATA", 'deleteProgram');
export const setSendConversation = createAction("SET_SEND_CONVERSATION_DATA", 'declineConversation');
export const setVoteAnalytics = createAction("SET_VOTE_ANALYTICS_DATA", 'voteAnalytics');
export const setAddVote = createAction("SET_ADD_VOTE_DATA", 'addVote');
export const setGetVote = createAction("SET_ADD_VOTE_DATA", 'getVote');
export const setSaveVote = createAction("SET_SAVE_VOTE_DATA", 'saveVote');
export const setDeleteOpportunity = createAction("SET_DELETE_OPPORTUNITY_DATA", 'deleteOpportunity');

export const getLoginData = (data) => async (dispatch) => {
  await fetchData("signIn", "POST", data, dispatch, setLoginData, setErrorObj, true);
};

export const getSignUpData = (data) => async (dispatch) => {
  await fetchData("signup", "POST", data, dispatch, setSignup, setErrorObj, true);
};

export const getProfileData = (data) => async (dispatch) => {
  await fetchData("getCharityProfile", "GET", data, dispatch, setProfileData, setErrorObj);
};

export const getProfileDataCompany = (data) => async (dispatch) => {
  await fetchData("getProfile", "GET", data, dispatch, setProfileData, setErrorObj);
};

export const getAllAssets = (data) => async (dispatch) => {
  await fetchData("getceAssets", "POST", data, dispatch, setAllAssets, setErrorObj);
};

export const getResetPassword = (data) => async (dispatch) => {
  await fetchData("forgetPasswordEmailSent", "POST", data, dispatch, setResetPassword, setErrorObj, true);
};

export const getPassword = (data) => async (dispatch) => {
  await fetchData("resetPassword", "POST", data, dispatch, setPassword, setErrorObj);
};

export const getCreatePassword = (data) => async (dispatch) => {
  await fetchData("createPassword", "POST", data, dispatch, setCreatePassword, setErrorObj, true);
};

export const getAssets = (data) => async (dispatch) => {
  await fetchData("getceAssets", "POST", data, dispatch, setAssets, setErrorObj);
};

export const getSubmissions = (data) => async (dispatch) => {
  await fetchData("getSubmissions", "POST", data, dispatch, setSubmissions, setErrorObj);
};

export const getSubmissionsMapData = (data) => async (dispatch) => {
  await fetchData("getSubmissions", "POST", data, dispatch, setSubmissionsMapData, setErrorObj);
};

export const getFeedbackMapData = (data) => async (dispatch) => {
  await fetchData("getSubmissions", "POST", data, dispatch, setFeedbackMapData, setErrorObj);
};

export const getSubmissionsByCategory = (data) => async (dispatch) => {
  await fetchData("getSubmissions", "POST", data, dispatch, setSubmissionsByCategory, setErrorObj);
};

export const getProgressAssetAnalytics = (data) => async (dispatch) => {
  await fetchData(
    "getAssetAnalytics",
    "POST",
    data,
    dispatch,
    setProgressAssetAnalytics,
    setErrorObj
  );
};

export const getResponderTypeAssetAnalytics = (data) => async (dispatch) => {
  await fetchData(
    "getAssetAnalytics",
    "POST",
    data,
    dispatch,
    setResponderTypeAnalytics,
    setErrorObj
  );
};

export const getSubmissionsByPhaseAnalytics = (data) => async (dispatch) => {
  await fetchData(
    "getAssetAnalytics",
    "POST",
    data,
    dispatch,
    setSubmissionsByPhase,
    setErrorObj
  );
};

export const getThemeAnalytics = (data) => async (dispatch) => {
  await fetchData(
    "getAssetAnalytics",
    "POST",
    data,
    dispatch,
    setThemeAnalytics,
    setErrorObj
  );
};

export const getPopularCategoryAnalytics = (data) => async (dispatch) => {
  await fetchData(
    "getAssetAnalytics",
    "POST",
    data,
    dispatch,
    setPopularCategoryAnalytics,
    setErrorObj
  );
};

export const getPopularSubCategoryAnalytics = (data) => async (dispatch) => {
  await fetchData(
    "getAssetAnalytics",
    "POST",
    data,
    dispatch,
    setPopularSubCategoryAnalytics,
    setErrorObj
  );
};

export const getSubmissionTotalAnalytics = (data) => async (dispatch) => {
  await fetchData(
    "getAssetAnalytics",
    "POST",
    data,
    dispatch,
    setSubmissionTotalAnalytics,
    setErrorObj
  );
};

export const getIdeaProgressAnalytics = (data) => async (dispatch) => {
  await fetchData(
    "getAssetAnalytics",
    "POST",
    data,
    dispatch,
    setIdeaProgressAnalytics,
    setErrorObj
  );
};

export const getFeedbackProgressAnalytics = (data) => async (dispatch) => {
  await fetchData(
    "getAssetAnalytics",
    "POST",
    data,
    dispatch,
    setFeedbackProgressAnalytics,
    setErrorObj
  );
};

export const getCollaborateProgressAnalytics = (data) => async (dispatch) => {
  await fetchData(
    "getAssetAnalytics",
    "POST",
    data,
    dispatch,
    setCollaborateProgressAnalytics,
    setErrorObj
  );
};

export const getIdeasPendingActions = (data) => async (dispatch) => {
  await fetchData(
    "getAssetAnalytics",
    "POST",
    data,
    dispatch,
    setIdeasPendingActions,
    setErrorObj
  );
};

export const getFeedbackPendingActions = (data) => async (dispatch) => {
  await fetchData(
    "getAssetAnalytics",
    "POST",
    data,
    dispatch,
    setFeedbackPendingActions,
    setErrorObj
  );
};

export const getCollaboratePendingActions = (data) => async (dispatch) => {
  await fetchData(
    "getAssetAnalytics",
    "POST",
    data,
    dispatch,
    setCollaboratePendingActions,
    setErrorObj
  );
};

export const getHistoricalVisitors = (data) => async (dispatch) => {
  await fetchData(
    "getAssetAnalytics",
    "POST",
    data,
    dispatch,
    setHistoricalVisitors,
    setErrorObj
  );
};

export const addCeSubmissionReview = (data) => async (dispatch) => {
  await fetchData(
    "addCeSubmissionReview",
    "POST",
    data,
    dispatch,
    setAddCeSubmissionReview,
    setErrorObj
  );
};

export const getCeSubmissionReview = (data) => async (dispatch) => {
  await fetchData(
    "getCeSubmissionReview",
    "GET",
    data,
    dispatch,
    setSubmissionReview,
    setErrorObj
  );
};

export const getUser = (data) => async (dispatch) => {
  await fetchData(
    "getUser",
    "GET",
    data,
    dispatch,
    setUsers,
    setErrorObj
  );
};

export const editProfile = (data) => async (dispatch) => {
  await fetchData(
    "editProfile",
    "POST",
    data,
    dispatch,
    setEditProfile,
    setErrorObj
  );
};

export const createProfile = (data) => async (dispatch) => {
  await fetchData(
    "createProfile",
    "POST",
    data,
    dispatch,
    setCreateProfile,
    setErrorObj
  );
};

export const editUserProfile = (data) => async (dispatch) => {
  await fetchData(
    "editUserProfile",
    "POST",
    data,
    dispatch,
    setEditUserProfile,
    setErrorObj
  );
};

export const getMyProgramList = (data) => async (dispatch) => {
  await fetchData(
    "getMyProgramList",
    "GET",
    data,
    dispatch,
    setProgrammeList,
    setErrorObj
  );
};

export const getAllInteraction = (data) => async (dispatch) => {
  await fetchData(
    "getInteraction",
    "POST",
    data,
    dispatch,
    setAllInteraction,
    setErrorObj
  );
};

export const getInteraction = (data) => async (dispatch) => {
  await fetchData(
    "getInteraction",
    "POST",
    data,
    dispatch,
    setInteraction,
    setErrorObj
  );
};

export const getActionInteraction = (data) => async (dispatch) => {
  await fetchData(
    "getInteraction",
    "POST",
    data,
    dispatch,
    setActionInteraction,
    setErrorObj
  );
}

export const getInteractionStatus = (data) => async (dispatch) => {
  await fetchData(
    "getInteraction",
    "POST",
    data,
    dispatch,
    setInteractionStatus,
    setErrorObj
  );
}

export const downloadProgrammeData = (data) => async (dispatch) => {
  const response = await fetchData(
    "downloadProgrammeData",
    "POST",
    data,
    dispatch,
    setDownloadProgrammeData,
    setErrorObj
  );
  onDownloadFile(response?.key, response?.key)
}

export const getSharePreApproved = (data) => async (dispatch) => {
  await fetchData(
    "sharePreApproved",
    "POST",
    data,
    dispatch,
    setSharePreApproved,
    setErrorObj
  );
}

export const getProgramById = (data) => async (dispatch) => {
  await fetchData(
    "getProgramById",
    "GET",
    data,
    dispatch,
    setProgramById,
    setErrorObj,
    false,
    true
  );
};

export const getUserImage = (data) => async (dispatch) => {
  await fetchData(
    "getUserImage",
    "GET",
    data,
    dispatch,
    setUserImage,
    setErrorObj,
    false,
    true
  );
};

export const editOpportunity = (data) => async (dispatch) => {
  await fetchData(
    "editOpportunity",
    "POST",
    data,
    dispatch,
    setEditOpportunitiesData,
    setErrorObj,
    false,
    true
  );
};

export const createOpportunity = (data) => async (dispatch) => {
  await fetchData(
    "createOpportunity",
    "POST",
    data,
    dispatch,
    setCreateOpportunity,
    setErrorObj,
    false,
    true
  );
};

export const addInteraction = (data) => async (dispatch) => {
  await fetchData(
    "addInteraction",
    "POST",
    data,
    dispatch,
    setAddInteraction,
    setErrorObj,
    false,
    true
  );
}

export const getOpportunity = (data) => async (dispatch) => {
  await fetchData(
    "getOpportunity",
    "GET",
    data,
    dispatch,
    setOpportunitiesData,
    setErrorObj,
    false,
    true
  );
}

export const getIndicatorAnswers = (data) => async (dispatch) => {
  await fetchData(
    "getIndicatorAnswers",
    "POST",
    data,
    dispatch,
    setIndicatorData,
    setErrorObj,
  )
}

export const getProgramList = (data) => async (dispatch) => {
  await fetchData(
    "getProgram",
    "GET",
    data,
    dispatch,
    setProgramList,
    setErrorObj,
  )
}

export const createProgram = (data) => async (dispatch) => {
  await fetchData(
    "programApi",
    "POST",
    data,
    dispatch,
    setCreateProgram,
    setErrorObj,
  )
}

export const updateProgram = (data) => async (dispatch) => {
  await fetchData(
    "updateProgram",
    "POST",
    data,
    dispatch,
    setUpdateProgram,
    setErrorObj,
  )
}

export const getImpactStrategyListProgram = (data) => async (dispatch) => {
  await fetchData(
    "getImpactStrategyListProgram",
    "POST",
    data,
    dispatch,
    setImpactStrategyListProgram,
    setErrorObj,
  )
}

export const getNewToken = (data) => async (dispatch) => { 
  await fetchData(
    "getNewTokenUrl",
    "GET",
    data,
    dispatch,
    setNewToken,
    setErrorObj,
    true,
    false
  )
}

export const getGuestProgramList = (data) => async (dispatch) => {
  await fetchData(
     "getProgramURL",
    "GET",
    data,
    dispatch,
    setGuestProgramList,
    setErrorObj,
    false,
    true
  )
}

export const getProfileDataGuestCompany = (data) => async (dispatch) => {
  await fetchData(
    "getProfile",
    "GET",
    data,
    dispatch,
    setProfileData,
    setErrorObj,
    false,
    true
  )
};

export const getConversation = (data) => async (dispatch) => {
  await fetchData(
    "getConversation",
    "POST",
    data,
    dispatch,
    setConversation,
    setErrorObj,
  )
}

export const deleteProgram = (data) => async (dispatch) => {
  await fetchData(
    "deleteProgram",
    "POST",
    data,
    dispatch,
    setDeleteProgram,
    setErrorObj,
  )
}

export const sendDeclineConversation = (data) => async (dispatch) => {
  await fetchData(
    "sendConversation",
    "POST",
    data,
    dispatch,
    setSendConversation,
    setErrorObj,
  )
}

export const voteAnalytics = (data) => async (dispatch) => {
  await fetchData(
    "voteAnalytics",
    "POST",
    data,
    dispatch,
    setVoteAnalytics,
    setErrorObj,
  )
}

export const addVote = (data) => async (dispatch) => {
  await fetchData(
    "addVote",
    "POST",
    data,
    dispatch,
    setAddVote,
    setErrorObj,
  )
}

export const getVote = (data) => async (dispatch) => {
  await fetchData(
    "addVote",
    "POST",
    data,
    dispatch,
    setGetVote,
    setErrorObj,
  )
}

export const saveVote = (data) => async (dispatch) => {
  await fetchData(
    "addVote",
    "POST",
    data,
    dispatch,
    setSaveVote,
    setErrorObj,
  )
}

export const deleteOpportunity = (data) => async (dispatch) => {
  await fetchData(
    "deleteOpportunity",
    "POST",
    data,
    dispatch,
    setDeleteOpportunity,
    setErrorObj,
  )
}
