import React from "react";
import styled from 'styled-components';
import { Avatar } from "antd";
import clsx from "clsx";
import openExternal from "../assets/openExternal.svg";
import StyledText from "../components/StyledText";

const LinkComponent = ({
    className,
    children,
    ...rest
}) => {
    const baseClassName = clsx("linkComponent", className);

    return (
        <StyledText as="a" variant="B3" target="_blank" className={baseClassName} {...rest}>{children}<Avatar src={openExternal} shape="square" /></StyledText>
    );
};

const StyledLinkComponent = styled(LinkComponent)`
  &.linkComponent {
    display: flex;
    align-items: center;
    gap: 5px;
    .ant-avatar img {
        transform: scale(0.6);
    }
        a{
            display: flex;
            align-items: center;
        }
    }
`;

export default StyledLinkComponent;

