// Font Weights
export const FONT_WEIGHT_REGULAR = 400;
export const FONT_WEIGHT_MEDIUM = 500;
export const FONT_WEIGHT_MEDIUM_BOLD = 600;
export const FONT_WEIGHT_SEMIBOLD = 700;

export const POPPINS_FONT_FAMILY = "Poppins, sans-serif";

export const COLOR_BIZ_GIVE_THEME = "#04ac9c";

export const B3B_COLOR = "#5550F7";

export const B3A_COLOR = "#a2a0a0";

// Border Sizes
export const BORDER_SIZE_DEFAULT = "1px";

// Border Radii
export const BORDER_RADIUS_DEFAULT = "6px";

// Text Alignments
export const TEXT_ALIGNMENT_CENTER = "center";

// Colors
export const COLOR_WHITE = "#FFFFFF";
export const COLOR_BLACK = "#000000";
export const COLOR_GREY_LIGHT = "#F0F0F0";
export const COLOR_GREY_DARK = "#242424";
export const COLOR_TRANSPARENT = "#00000000";
export const COLOR_CTA = "#ED6C2D";

// Background Colors
export const BACKGROUND_CANVAS_COLOR = "#F8F9FB";
export const BACKGROUND_CANVAS_COLOR1 = "#F8F9FB";


// Primary Colors
export const PRIMARY_DEFAULT_MENU_COLOR = "#6F6AF8";
export const PRIMARY_COLOR_1 = "#E8E7FA";
export const PRIMARY_COLOR_2 = "#F3F2FD";

// Secondary Colors
export const SECONDARY_COLOR_1 = "#ED6C2D";
export const SECONDARY_COLOR_2 = "#FFF7F5";

// Supporting Colors
export const SUPPORTING_COLOR_1 = "#6DBCB6";
export const SUPPORTING_COLOR_2 = "#FDB228";
export const SUPPORTING_COLOR_3 = "#4D88C9";
export const SUPPORTING_COLOR_4 = "#AB7D65";
export const SUPPORTING_COLOR_5 = "#EF5644";

// Base Colors
export const BASE_COLOR_1 = "#242424";
export const BASE_COLOR_2 = "#F0F0F0";
export const BASE_COLOR_3 = "#FFFCE8";
//hardcoded value
export const CHARITY = 'charity';
export const COMPANY = 'company';

// Currency Data
export const currencyData = [
    {
        Code: 'AED',
        CountryName: 'United Arab Emirates Dirham'
    },
    {
        Code: 'AFN',
        CountryName: 'Afghanistan Afghani'
    },
    {
        Code: 'ALL',
        CountryName: 'Albania Lek'
    },
    {
        Code: 'AMD',
        CountryName: 'Armenia Dram'
    },
    {
        Code: 'ANG',
        CountryName: 'Netherlands Antilles Guilder'
    },
    {
        Code: 'AOA',
        CountryName: 'Angola Kwanza'
    },
    {
        Code: 'ARS',
        CountryName: 'Argentina Peso'
    },
    {
        Code: 'AUD',
        CountryName: 'Australia Dollar'
    },
    {
        Code: 'AWG',
        CountryName: 'Aruba Guilder'
    },
    {
        Code: 'AZN',
        CountryName: 'Azerbaijan Manat'
    },
    {
        Code: 'BAM',
        CountryName: 'Bosnia and Herzegovina Convertible Mark'
    },
    {
        Code: 'BBD',
        CountryName: 'Barbados Dollar'
    },
    {
        Code: 'BDT',
        CountryName: 'Bangladesh Taka'
    },
    {
        Code: 'BGN',
        CountryName: 'Bulgaria Lev'
    },
    {
        Code: 'BHD',
        CountryName: 'Bahrain Dinar'
    },
    {
        Code: 'BIF',
        CountryName: 'Burundi Franc'
    },
    {
        Code: 'BMD',
        CountryName: 'Bermuda Dollar'
    },
    {
        Code: 'BND',
        CountryName: 'Brunei Darussalam Dollar'
    },
    {
        Code: 'BOB',
        CountryName: 'Bolivia Bolíviano'
    },
    {
        Code: 'BRL',
        CountryName: 'Brazil Real'
    },
    {
        Code: 'BSD',
        CountryName: 'Bahamas Dollar'
    },
    {
        Code: 'BTN',
        CountryName: 'Bhutan Ngultrum'
    },
    {
        Code: 'BWP',
        CountryName: 'Botswana Pula'
    },
    {
        Code: 'BYN',
        CountryName: 'Belarus Ruble'
    },
    {
        Code: 'BZD',
        CountryName: 'Belize Dollar'
    },
    {
        Code: 'CAD',
        CountryName: 'Canada Dollar'
    },
    {
        Code: 'CDF',
        CountryName: 'Congo/Kinshasa Franc'
    },
    {
        Code: 'CHF',
        CountryName: 'Switzerland Franc'
    },
    {
        Code: 'CLP',
        CountryName: 'Chile Peso'
    },
    {
        Code: 'CNY',
        CountryName: 'China Yuan Renminbi'
    },
    {
        Code: 'COP',
        CountryName: 'Colombia Peso'
    },
    {
        Code: 'CRC',
        CountryName: 'Costa Rica Colon'
    },
    {
        Code: 'CUC',
        CountryName: 'Cuba Convertible Peso'
    },
    {
        Code: 'CUP',
        CountryName: 'Cuba Peso'
    },
    {
        Code: 'CVE',
        CountryName: 'Cape Verde Escudo'
    },
    {
        Code: 'CZK',
        CountryName: 'Czech Republic Koruna'
    },
    {
        Code: 'DJF',
        CountryName: 'Djibouti Franc'
    },
    {
        Code: 'DKK',
        CountryName: 'Denmark Krone'
    },
    {
        Code: 'DOP',
        CountryName: 'Dominican Republic Peso'
    },
    {
        Code: 'DZD',
        CountryName: 'Algeria Dinar'
    },
    {
        Code: 'EGP',
        CountryName: 'Egypt Pound'
    },
    {
        Code: 'ERN',
        CountryName: 'Eritrea Nakfa'
    },
    {
        Code: 'ETB',
        CountryName: 'Ethiopia Birr'
    },
    {
        Code: 'EUR',
        CountryName: 'Euro Member Countries'
    },
    {
        Code: 'FJD',
        CountryName: 'Fiji Dollar'
    },
    {
        Code: 'FKP',
        CountryName: 'Falkland Islands (Malvinas) Pound'
    },
    {
        Code: 'GBP',
        CountryName: 'United Kingdom Pound'
    },
    {
        Code: 'GEL',
        CountryName: 'Georgia Lari'
    },
    {
        Code: 'GGP',
        CountryName: 'Guernsey Pound'
    },
    {
        Code: 'GHS',
        CountryName: 'Ghana Cedi'
    },
    {
        Code: 'GIP',
        CountryName: 'Gibraltar Pound'
    },
    {
        Code: 'GMD',
        CountryName: 'Gambia Dalasi'
    },
    {
        Code: 'GNF',
        CountryName: 'Guinea Franc'
    },
    {
        Code: 'GTQ',
        CountryName: 'Guatemala Quetzal'
    },
    {
        Code: 'GYD',
        CountryName: 'Guyana Dollar'
    },
    {
        Code: 'HKD',
        CountryName: 'Hong Kong Dollar'
    },
    {
        Code: 'HNL',
        CountryName: 'Honduras Lempira'
    },
    {
        Code: 'HRK',
        CountryName: 'Croatia Kuna'
    },
    {
        Code: 'HTG',
        CountryName: 'Haiti Gourde'
    },
    {
        Code: 'HUF',
        CountryName: 'Hungary Forint'
    },
    {
        Code: 'IDR',
        CountryName: 'Indonesia Rupiah'
    },
    {
        Code: 'ILS',
        CountryName: 'Israel Shekel'
    },
    {
        Code: 'IMP',
        CountryName: 'Isle of Man Pound'
    },
    {
        Code: 'INR',
        CountryName: 'India Rupee'
    },
    {
        Code: 'IQD',
        CountryName: 'Iraq Dinar'
    },
    {
        Code: 'IRR',
        CountryName: 'Iran Rial'
    },
    {
        Code: 'ISK',
        CountryName: 'Iceland Krona'
    },
    {
        Code: 'JEP',
        CountryName: 'Jersey Pound'
    },
    {
        Code: 'JMD',
        CountryName: 'Jamaica Dollar'
    },
    {
        Code: 'JOD',
        CountryName: 'Jordan Dinar'
    },
    {
        Code: 'JPY',
        CountryName: 'Japan Yen'
    },
    {
        Code: 'KES',
        CountryName: 'Kenya Shilling'
    },
    {
        Code: 'KGS',
        CountryName: 'Kyrgyzstan Som'
    },
    {
        Code: 'KHR',
        CountryName: 'Cambodia Riel'
    },
    {
        Code: 'KMF',
        CountryName: 'Comorian Franc'
    },
    {
        Code: 'KPW',
        CountryName: 'Korea (North) Won'
    },
    {
        Code: 'KRW',
        CountryName: 'Korea (South) Won'
    },
    {
        Code: 'KWD',
        CountryName: 'Kuwait Dinar'
    },
    {
        Code: 'KYD',
        CountryName: 'Cayman Islands Dollar'
    },
    {
        Code: 'KZT',
        CountryName: 'Kazakhstan Tenge'
    },
    {
        Code: 'LAK',
        CountryName: 'Laos Kip'
    },
    {
        Code: 'LBP',
        CountryName: 'Lebanon Pound'
    },
    {
        Code: 'LKR',
        CountryName: 'Sri Lanka Rupee'
    },
    {
        Code: 'LRD',
        CountryName: 'Liberia Dollar'
    },
    {
        Code: 'LSL',
        CountryName: 'Lesotho Loti'
    },
    {
        Code: 'LYD',
        CountryName: 'Libya Dinar'
    },
    {
        Code: 'MAD',
        CountryName: 'Morocco Dirham'
    },
    {
        Code: 'MDL',
        CountryName: 'Moldova Leu'
    },
    {
        Code: 'MGA',
        CountryName: 'Madagascar Ariary'
    },
    {
        Code: 'MKD',
        CountryName: 'Macedonia Denar'
    },
    {
        Code: 'MMK',
        CountryName: 'Myanmar (Burma) Kyat'
    },
    {
        Code: 'MNT',
        CountryName: 'Mongolia Tughrik'
    },
    {
        Code: 'MOP',
        CountryName: 'Macau Pataca'
    },
    {
        Code: 'MRU',
        CountryName: 'Mauritania Ouguiya'
    },
    {
        Code: 'MUR',
        CountryName: 'Mauritius Rupee'
    },
    {
        Code: 'MVR',
        CountryName: 'Maldives (Maldive Islands) Rufiyaa'
    },
    {
        Code: 'MWK',
        CountryName: 'Malawi Kwacha'
    },
    {
        Code: 'MXN',
        CountryName: 'Mexico Peso'
    },
    {
        Code: 'MYR',
        CountryName: 'Malaysia Ringgit'
    },
    {
        Code: 'MZN',
        CountryName: 'Mozambique Metical'
    },
    {
        Code: 'NAD',
        CountryName: 'Namibia Dollar'
    },
    {
        Code: 'NGN',
        CountryName: 'Nigeria Naira'
    },
    {
        Code: 'NIO',
        CountryName: 'Nicaragua Cordoba'
    },
    {
        Code: 'NOK',
        CountryName: 'Norway Krone'
    },
    {
        Code: 'NPR',
        CountryName: 'Nepal Rupee'
    },
    {
        Code: 'NZD',
        CountryName: 'New Zealand Dollar'
    },
    {
        Code: 'OMR',
        CountryName: 'Oman Rial'
    },
    {
        Code: 'PAB',
        CountryName: 'Panama Balboa'
    },
    {
        Code: 'PEN',
        CountryName: 'Peru Sol'
    },
    {
        Code: 'PGK',
        CountryName: 'Papua New Guinea Kina'
    },
    {
        Code: 'PHP',
        CountryName: 'Philippines Peso'
    },
    {
        Code: 'PKR',
        CountryName: 'Pakistan Rupee'
    },
    {
        Code: 'PLN',
        CountryName: 'Poland Zloty'
    },
    {
        Code: 'PYG',
        CountryName: 'Paraguay Guarani'
    },
    {
        Code: 'QAR',
        CountryName: 'Qatar Riyal'
    },
    {
        Code: 'RON',
        CountryName: 'Romania Leu'
    },
    {
        Code: 'RSD',
        CountryName: 'Serbia Dinar'
    },
    {
        Code: 'RUB',
        CountryName: 'Russia Ruble'
    },
    {
        Code: 'RWF',
        CountryName: 'Rwanda Franc'
    },
    {
        Code: 'SAR',
        CountryName: 'Saudi Arabia Riyal'
    },
    {
        Code: 'SBD',
        CountryName: 'Solomon Islands Dollar'
    },
    {
        Code: 'SCR',
        CountryName: 'Seychelles Rupee'
    },
    {
        Code: 'SDG',
        CountryName: 'Sudan Pound'
    },
    {
        Code: 'SEK',
        CountryName: 'Sweden Krona'
    },
    {
        Code: 'SGD',
        CountryName: 'Singapore Dollar'
    },
    {
        Code: 'SHP',
        CountryName: 'Saint Helena Pound'
    },
    {
        Code: 'SLL',
        CountryName: 'Sierra Leone Leone'
    },
    {
        Code: 'SOS',
        CountryName: 'Somalia Shilling'
    },
    {
        Code: 'SPL*',
        CountryName: 'Seborga Luigino'
    },
    {
        Code: 'SRD',
        CountryName: 'Suriname Dollar'
    },
    {
        Code: 'STN',
        CountryName: 'São Tomé and Príncipe Dobra'
    },
    {
        Code: 'SVC',
        CountryName: 'El Salvador Colon'
    },
    {
        Code: 'SYP',
        CountryName: 'Syria Pound'
    },
    {
        Code: 'SZL',
        CountryName: 'eSwatini Lilangeni'
    },
    {
        Code: 'THB',
        CountryName: 'Thailand Baht'
    },
    {
        Code: 'TJS',
        CountryName: 'Tajikistan Somoni'
    },
    {
        Code: 'TMT',
        CountryName: 'Turkmenistan Manat'
    },
    {
        Code: 'TND',
        CountryName: 'Tunisia Dinar'
    },
    {
        Code: 'TOP',
        CountryName: "Tonga Pa'anga"
    },
    {
        Code: 'TRY',
        CountryName: 'Turkey Lira'
    },
    {
        Code: 'TTD',
        CountryName: 'Trinidad and Tobago Dollar'
    },
    {
        Code: 'TVD',
        CountryName: 'Tuvalu Dollar'
    },
    {
        Code: 'TWD',
        CountryName: 'Taiwan New Dollar'
    },
    {
        Code: 'TZS',
        CountryName: 'Tanzania Shilling'
    },
    {
        Code: 'UAH',
        CountryName: 'Ukraine Hryvnia'
    },
    {
        Code: 'UGX',
        CountryName: 'Uganda Shilling'
    },
    {
        Code: 'USD',
        CountryName: 'United States Dollar'
    },
    {
        Code: 'UYU',
        CountryName: 'Uruguay Peso'
    },
    {
        Code: 'UZS',
        CountryName: 'Uzbekistan Som'
    },
    {
        Code: 'VEF',
        CountryName: 'Venezuela Bolívar'
    },
    {
        Code: 'VND',
        CountryName: 'Viet Nam Dong'
    },
    {
        Code: 'VUV',
        CountryName: 'Vanuatu Vatu'
    },
    {
        Code: 'WST',
        CountryName: 'Samoa Tala'
    },
    {
        Code: 'XAF',
        CountryName: 'Communauté Financière Africaine (BEAC) CFA Franc BEAC'
    },
    {
        Code: 'XCD',
        CountryName: 'East Caribbean Dollar'
    },
    {
        Code: 'XDR',
        CountryName: 'International Monetary Fund (IMF) Special Drawing Rights'
    },
    {
        Code: 'XOF',
        CountryName: 'Communauté Financière Africaine (BCEAO) Franc'
    },
    {
        Code: 'XPF',
        CountryName: 'Comptoirs Français du Pacifique (CFP) Franc'
    },
    {
        Code: 'YER',
        CountryName: 'Yemen Rial'
    },
    {
        Code: 'ZAR',
        CountryName: 'South Africa Rand'
    },
    {
        Code: 'ZMW',
        CountryName: 'Zambia Kwacha'
    },
    {
        Code: 'ZWD',
        CountryName: 'Zimbabwe Dollar'
    },
    {
        Code: 'AED',
        CountryName: 'United Arab Emirates Dirham'
    },
    {
        Code: 'AFN',
        CountryName: 'Afghanistan Afghani'
    },
    {
        Code: 'ALL',
        CountryName: 'Albania Lek'
    },
    {
        Code: 'AMD',
        CountryName: 'Armenia Dram'
    },
    {
        Code: 'ANG',
        CountryName: 'Netherlands Antilles Guilder'
    },
    {
        Code: 'AOA',
        CountryName: 'Angola Kwanza'
    },
    {
        Code: 'ARS',
        CountryName: 'Argentina Peso'
    },
    {
        Code: 'AUD',
        CountryName: 'Australia Dollar'
    },
    {
        Code: 'AWG',
        CountryName: 'Aruba Guilder'
    },
    {
        Code: 'AZN',
        CountryName: 'Azerbaijan Manat'
    },
    {
        Code: 'BAM',
        CountryName: 'Bosnia and Herzegovina Convertible Mark'
    },
    {
        Code: 'BBD',
        CountryName: 'Barbados Dollar'
    },
    {
        Code: 'BDT',
        CountryName: 'Bangladesh Taka'
    },
    {
        Code: 'BGN',
        CountryName: 'Bulgaria Lev'
    },
    {
        Code: 'BHD',
        CountryName: 'Bahrain Dinar'
    },
    {
        Code: 'BIF',
        CountryName: 'Burundi Franc'
    },
    {
        Code: 'BMD',
        CountryName: 'Bermuda Dollar'
    },
    {
        Code: 'BND',
        CountryName: 'Brunei Darussalam Dollar'
    },
    {
        Code: 'BOB',
        CountryName: 'Bolivia Bolíviano'
    },
    {
        Code: 'BRL',
        CountryName: 'Brazil Real'
    },
    {
        Code: 'BSD',
        CountryName: 'Bahamas Dollar'
    },
    {
        Code: 'BTN',
        CountryName: 'Bhutan Ngultrum'
    },
    {
        Code: 'BWP',
        CountryName: 'Botswana Pula'
    },
    {
        Code: 'BYN',
        CountryName: 'Belarus Ruble'
    },
    {
        Code: 'BZD',
        CountryName: 'Belize Dollar'
    },
    {
        Code: 'CAD',
        CountryName: 'Canada Dollar'
    },
    {
        Code: 'CDF',
        CountryName: 'Congo/Kinshasa Franc'
    },
    {
        Code: 'CHF',
        CountryName: 'Switzerland Franc'
    },
    {
        Code: 'CLP',
        CountryName: 'Chile Peso'
    },
    {
        Code: 'CNY',
        CountryName: 'China Yuan Renminbi'
    },
    {
        Code: 'COP',
        CountryName: 'Colombia Peso'
    },
    {
        Code: 'CRC',
        CountryName: 'Costa Rica Colon'
    },
    {
        Code: 'CUC',
        CountryName: 'Cuba Convertible Peso'
    },
    {
        Code: 'CUP',
        CountryName: 'Cuba Peso'
    },
    {
        Code: 'CVE',
        CountryName: 'Cape Verde Escudo'
    },
    {
        Code: 'CZK',
        CountryName: 'Czech Republic Koruna'
    },
    {
        Code: 'DJF',
        CountryName: 'Djibouti Franc'
    },
    {
        Code: 'DKK',
        CountryName: 'Denmark Krone'
    },
    {
        Code: 'DOP',
        CountryName: 'Dominican Republic Peso'
    },
    {
        Code: 'DZD',
        CountryName: 'Algeria Dinar'
    },
    {
        Code: 'EGP',
        CountryName: 'Egypt Pound'
    },
    {
        Code: 'ERN',
        CountryName: 'Eritrea Nakfa'
    },
    {
        Code: 'ETB',
        CountryName: 'Ethiopia Birr'
    },
    {
        Code: 'EUR',
        CountryName: 'Euro Member Countries'
    },
    {
        Code: 'FJD',
        CountryName: 'Fiji Dollar'
    },
    {
        Code: 'FKP',
        CountryName: 'Falkland Islands (Malvinas) Pound'
    },
    {
        Code: 'GBP',
        CountryName: 'United Kingdom Pound'
    },
    {
        Code: 'GEL',
        CountryName: 'Georgia Lari'
    },
    {
        Code: 'GGP',
        CountryName: 'Guernsey Pound'
    },
    {
        Code: 'GHS',
        CountryName: 'Ghana Cedi'
    },
    {
        Code: 'GIP',
        CountryName: 'Gibraltar Pound'
    },
    {
        Code: 'GMD',
        CountryName: 'Gambia Dalasi'
    },
    {
        Code: 'GNF',
        CountryName: 'Guinea Franc'
    },
    {
        Code: 'GTQ',
        CountryName: 'Guatemala Quetzal'
    },
    {
        Code: 'GYD',
        CountryName: 'Guyana Dollar'
    },
    {
        Code: 'HKD',
        CountryName: 'Hong Kong Dollar'
    },
    {
        Code: 'HNL',
        CountryName: 'Honduras Lempira'
    },
    {
        Code: 'HRK',
        CountryName: 'Croatia Kuna'
    },
    {
        Code: 'HTG',
        CountryName: 'Haiti Gourde'
    },
    {
        Code: 'HUF',
        CountryName: 'Hungary Forint'
    },
    {
        Code: 'IDR',
        CountryName: 'Indonesia Rupiah'
    },
    {
        Code: 'ILS',
        CountryName: 'Israel Shekel'
    },
    {
        Code: 'IMP',
        CountryName: 'Isle of Man Pound'
    },
    {
        Code: 'INR',
        CountryName: 'India Rupee'
    },
    {
        Code: 'IQD',
        CountryName: 'Iraq Dinar'
    },
    {
        Code: 'IRR',
        CountryName: 'Iran Rial'
    },
    {
        Code: 'ISK',
        CountryName: 'Iceland Krona'
    },
    {
        Code: 'JEP',
        CountryName: 'Jersey Pound'
    },
    {
        Code: 'JMD',
        CountryName: 'Jamaica Dollar'
    },
    {
        Code: 'JOD',
        CountryName: 'Jordan Dinar'
    },
    {
        Code: 'JPY',
        CountryName: 'Japan Yen'
    },
    {
        Code: 'KES',
        CountryName: 'Kenya Shilling'
    },
    {
        Code: 'KGS',
        CountryName: 'Kyrgyzstan Som'
    },
    {
        Code: 'KHR',
        CountryName: 'Cambodia Riel'
    },
    {
        Code: 'KMF',
        CountryName: 'Comorian Franc'
    },
    {
        Code: 'KPW',
        CountryName: 'Korea (North) Won'
    },
    {
        Code: 'KRW',
        CountryName: 'Korea (South) Won'
    },
    {
        Code: 'KWD',
        CountryName: 'Kuwait Dinar'
    },
    {
        Code: 'KYD',
        CountryName: 'Cayman Islands Dollar'
    },
    {
        Code: 'KZT',
        CountryName: 'Kazakhstan Tenge'
    },
    {
        Code: 'LAK',
        CountryName: 'Laos Kip'
    },
    {
        Code: 'LBP',
        CountryName: 'Lebanon Pound'
    },
    {
        Code: 'LKR',
        CountryName: 'Sri Lanka Rupee'
    },
    {
        Code: 'LRD',
        CountryName: 'Liberia Dollar'
    },
    {
        Code: 'LSL',
        CountryName: 'Lesotho Loti'
    },
    {
        Code: 'LYD',
        CountryName: 'Libya Dinar'
    },
    {
        Code: 'MAD',
        CountryName: 'Morocco Dirham'
    },
    {
        Code: 'MDL',
        CountryName: 'Moldova Leu'
    },
    {
        Code: 'MGA',
        CountryName: 'Madagascar Ariary'
    },
    {
        Code: 'MKD',
        CountryName: 'Macedonia Denar'
    },
    {
        Code: 'MMK',
        CountryName: 'Myanmar (Burma) Kyat'
    },
    {
        Code: 'MNT',
        CountryName: 'Mongolia Tughrik'
    },
    {
        Code: 'MOP',
        CountryName: 'Macau Pataca'
    },
    {
        Code: 'MRU',
        CountryName: 'Mauritania Ouguiya'
    },
    {
        Code: 'MUR',
        CountryName: 'Mauritius Rupee'
    },
    {
        Code: 'MVR',
        CountryName: 'Maldives (Maldive Islands) Rufiyaa'
    },
    {
        Code: 'MWK',
        CountryName: 'Malawi Kwacha'
    },
    {
        Code: 'MXN',
        CountryName: 'Mexico Peso'
    },
    {
        Code: 'MYR',
        CountryName: 'Malaysia Ringgit'
    },
    {
        Code: 'MZN',
        CountryName: 'Mozambique Metical'
    },
    {
        Code: 'NAD',
        CountryName: 'Namibia Dollar'
    },
    {
        Code: 'NGN',
        CountryName: 'Nigeria Naira'
    },
    {
        Code: 'NIO',
        CountryName: 'Nicaragua Cordoba'
    },
    {
        Code: 'NOK',
        CountryName: 'Norway Krone'
    },
    {
        Code: 'NPR',
        CountryName: 'Nepal Rupee'
    },
    {
        Code: 'NZD',
        CountryName: 'New Zealand Dollar'
    },
    {
        Code: 'OMR',
        CountryName: 'Oman Rial'
    },
    {
        Code: 'PAB',
        CountryName: 'Panama Balboa'
    },
    {
        Code: 'PEN',
        CountryName: 'Peru Sol'
    },
    {
        Code: 'PGK',
        CountryName: 'Papua New Guinea Kina'
    },
    {
        Code: 'PHP',
        CountryName: 'Philippines Peso'
    },
    {
        Code: 'PKR',
        CountryName: 'Pakistan Rupee'
    },
    {
        Code: 'PLN',
        CountryName: 'Poland Zloty'
    },
    {
        Code: 'PYG',
        CountryName: 'Paraguay Guarani'
    },
    {
        Code: 'QAR',
        CountryName: 'Qatar Riyal'
    },
    {
        Code: 'RON',
        CountryName: 'Romania Leu'
    },
    {
        Code: 'RSD',
        CountryName: 'Serbia Dinar'
    },
    {
        Code: 'RUB',
        CountryName: 'Russia Ruble'
    },
    {
        Code: 'RWF',
        CountryName: 'Rwanda Franc'
    },
    {
        Code: 'SAR',
        CountryName: 'Saudi Arabia Riyal'
    },
    {
        Code: 'SBD',
        CountryName: 'Solomon Islands Dollar'
    },
    {
        Code: 'SCR',
        CountryName: 'Seychelles Rupee'
    },
    {
        Code: 'SDG',
        CountryName: 'Sudan Pound'
    },
    {
        Code: 'SEK',
        CountryName: 'Sweden Krona'
    },
    {
        Code: 'SGD',
        CountryName: 'Singapore Dollar'
    },
    {
        Code: 'SHP',
        CountryName: 'Saint Helena Pound'
    },
    {
        Code: 'SLL',
        CountryName: 'Sierra Leone Leone'
    },
    {
        Code: 'SOS',
        CountryName: 'Somalia Shilling'
    },
    {
        Code: 'SPL*',
        CountryName: 'Seborga Luigino'
    },
    {
        Code: 'SRD',
        CountryName: 'Suriname Dollar'
    },
    {
        Code: 'STN',
        CountryName: 'São Tomé and Príncipe Dobra'
    },
    {
        Code: 'SVC',
        CountryName: 'El Salvador Colon'
    },
    {
        Code: 'SYP',
        CountryName: 'Syria Pound'
    },
    {
        Code: 'SZL',
        CountryName: 'eSwatini Lilangeni'
    },
    {
        Code: 'THB',
        CountryName: 'Thailand Baht'
    },
    {
        Code: 'TJS',
        CountryName: 'Tajikistan Somoni'
    },
    {
        Code: 'TMT',
        CountryName: 'Turkmenistan Manat'
    },
    {
        Code: 'TND',
        CountryName: 'Tunisia Dinar'
    },
    {
        Code: 'TOP',
        CountryName: "Tonga Pa'anga"
    },
    {
        Code: 'TRY',
        CountryName: 'Turkey Lira'
    },
    {
        Code: 'TTD',
        CountryName: 'Trinidad and Tobago Dollar'
    },
    {
        Code: 'TVD',
        CountryName: 'Tuvalu Dollar'
    },
    {
        Code: 'TWD',
        CountryName: 'Taiwan New Dollar'
    },
    {
        Code: 'TZS',
        CountryName: 'Tanzania Shilling'
    },
    {
        Code: 'UAH',
        CountryName: 'Ukraine Hryvnia'
    },
    {
        Code: 'UGX',
        CountryName: 'Uganda Shilling'
    },
    {
        Code: 'USD',
        CountryName: 'United States Dollar'
    },
    {
        Code: 'UYU',
        CountryName: 'Uruguay Peso'
    },
    {
        Code: 'UZS',
        CountryName: 'Uzbekistan Som'
    },
    {
        Code: 'VEF',
        CountryName: 'Venezuela Bolívar'
    },
    {
        Code: 'VND',
        CountryName: 'Viet Nam Dong'
    },
    {
        Code: 'VUV',
        CountryName: 'Vanuatu Vatu'
    },
    {
        Code: 'WST',
        CountryName: 'Samoa Tala'
    },
    {
        Code: 'XAF',
        CountryName: 'Communauté Financière Africaine (BEAC) CFA Franc BEAC'
    },
    {
        Code: 'XCD',
        CountryName: 'East Caribbean Dollar'
    },
    {
        Code: 'XDR',
        CountryName: 'International Monetary Fund (IMF) Special Drawing Rights'
    },
    {
        Code: 'XOF',
        CountryName: 'Communauté Financière Africaine (BCEAO) Franc'
    },
    {
        Code: 'XPF',
        CountryName: 'Comptoirs Français du Pacifique (CFP) Franc'
    },
    {
        Code: 'YER',
        CountryName: 'Yemen Rial'
    },
    {
        Code: 'ZAR',
        CountryName: 'South Africa Rand'
    },
    {
        Code: 'ZMW',
        CountryName: 'Zambia Kwacha'
    },
    {
        Code: 'ZWD',
        CountryName: 'Zimbabwe Dollar'
    },
    {
        Code: 'AED',
        CountryName: 'United Arab Emirates Dirham'
    },
    {
        Code: 'AFN',
        CountryName: 'Afghanistan Afghani'
    },
    {
        Code: 'ALL',
        CountryName: 'Albania Lek'
    },
    {
        Code: 'AMD',
        CountryName: 'Armenia Dram'
    },
    {
        Code: 'ANG',
        CountryName: 'Netherlands Antilles Guilder'
    },
    {
        Code: 'AOA',
        CountryName: 'Angola Kwanza'
    },
    {
        Code: 'ARS',
        CountryName: 'Argentina Peso'
    },
    {
        Code: 'AUD',
        CountryName: 'Australia Dollar'
    },
    {
        Code: 'AWG',
        CountryName: 'Aruba Guilder'
    },
    {
        Code: 'AZN',
        CountryName: 'Azerbaijan Manat'
    },
    {
        Code: 'BAM',
        CountryName: 'Bosnia and Herzegovina Convertible Mark'
    },
    {
        Code: 'BBD',
        CountryName: 'Barbados Dollar'
    },
    {
        Code: 'BDT',
        CountryName: 'Bangladesh Taka'
    },
    {
        Code: 'BGN',
        CountryName: 'Bulgaria Lev'
    },
    {
        Code: 'BHD',
        CountryName: 'Bahrain Dinar'
    },
    {
        Code: 'BIF',
        CountryName: 'Burundi Franc'
    },
    {
        Code: 'BMD',
        CountryName: 'Bermuda Dollar'
    },
    {
        Code: 'BND',
        CountryName: 'Brunei Darussalam Dollar'
    },
    {
        Code: 'BOB',
        CountryName: 'Bolivia Bolíviano'
    },
    {
        Code: 'BRL',
        CountryName: 'Brazil Real'
    },
    {
        Code: 'BSD',
        CountryName: 'Bahamas Dollar'
    },
    {
        Code: 'BTN',
        CountryName: 'Bhutan Ngultrum'
    },
    {
        Code: 'BWP',
        CountryName: 'Botswana Pula'
    },
    {
        Code: 'BYN',
        CountryName: 'Belarus Ruble'
    },
    {
        Code: 'BZD',
        CountryName: 'Belize Dollar'
    },
    {
        Code: 'CAD',
        CountryName: 'Canada Dollar'
    },
    {
        Code: 'CDF',
        CountryName: 'Congo/Kinshasa Franc'
    },
    {
        Code: 'CHF',
        CountryName: 'Switzerland Franc'
    },
    {
        Code: 'CLP',
        CountryName: 'Chile Peso'
    },
    {
        Code: 'CNY',
        CountryName: 'China Yuan Renminbi'
    },
    {
        Code: 'COP',
        CountryName: 'Colombia Peso'
    },
    {
        Code: 'CRC',
        CountryName: 'Costa Rica Colon'
    },
    {
        Code: 'CUC',
        CountryName: 'Cuba Convertible Peso'
    },
    {
        Code: 'CUP',
        CountryName: 'Cuba Peso'
    },
    {
        Code: 'CVE',
        CountryName: 'Cape Verde Escudo'
    },
    {
        Code: 'CZK',
        CountryName: 'Czech Republic Koruna'
    },
    {
        Code: 'DJF',
        CountryName: 'Djibouti Franc'
    },
    {
        Code: 'DKK',
        CountryName: 'Denmark Krone'
    },
    {
        Code: 'DOP',
        CountryName: 'Dominican Republic Peso'
    },
    {
        Code: 'DZD',
        CountryName: 'Algeria Dinar'
    },
    {
        Code: 'EGP',
        CountryName: 'Egypt Pound'
    },
    {
        Code: 'ERN',
        CountryName: 'Eritrea Nakfa'
    },
    {
        Code: 'ETB',
        CountryName: 'Ethiopia Birr'
    },
    {
        Code: 'EUR',
        CountryName: 'Euro Member Countries'
    },
    {
        Code: 'FJD',
        CountryName: 'Fiji Dollar'
    },
    {
        Code: 'FKP',
        CountryName: 'Falkland Islands (Malvinas) Pound'
    },
    {
        Code: 'GBP',
        CountryName: 'United Kingdom Pound'
    },
    {
        Code: 'GEL',
        CountryName: 'Georgia Lari'
    },
    {
        Code: 'GGP',
        CountryName: 'Guernsey Pound'
    },
    {
        Code: 'GHS',
        CountryName: 'Ghana Cedi'
    },
    {
        Code: 'GIP',
        CountryName: 'Gibraltar Pound'
    },
    {
        Code: 'GMD',
        CountryName: 'Gambia Dalasi'
    },
    {
        Code: 'GNF',
        CountryName: 'Guinea Franc'
    },
    {
        Code: 'GTQ',
        CountryName: 'Guatemala Quetzal'
    },
    {
        Code: 'GYD',
        CountryName: 'Guyana Dollar'
    },
    {
        Code: 'HKD',
        CountryName: 'Hong Kong Dollar'
    },
    {
        Code: 'HNL',
        CountryName: 'Honduras Lempira'
    },
    {
        Code: 'HRK',
        CountryName: 'Croatia Kuna'
    },
    {
        Code: 'HTG',
        CountryName: 'Haiti Gourde'
    },
    {
        Code: 'HUF',
        CountryName: 'Hungary Forint'
    },
    {
        Code: 'IDR',
        CountryName: 'Indonesia Rupiah'
    },
    {
        Code: 'ILS',
        CountryName: 'Israel Shekel'
    },
    {
        Code: 'IMP',
        CountryName: 'Isle of Man Pound'
    },
    {
        Code: 'INR',
        CountryName: 'India Rupee'
    },
    {
        Code: 'IQD',
        CountryName: 'Iraq Dinar'
    },
    {
        Code: 'IRR',
        CountryName: 'Iran Rial'
    },
    {
        Code: 'ISK',
        CountryName: 'Iceland Krona'
    },
    {
        Code: 'JEP',
        CountryName: 'Jersey Pound'
    },
    {
        Code: 'JMD',
        CountryName: 'Jamaica Dollar'
    },
    {
        Code: 'JOD',
        CountryName: 'Jordan Dinar'
    },
    {
        Code: 'JPY',
        CountryName: 'Japan Yen'
    },
    {
        Code: 'KES',
        CountryName: 'Kenya Shilling'
    },
    {
        Code: 'KGS',
        CountryName: 'Kyrgyzstan Som'
    },
    {
        Code: 'KHR',
        CountryName: 'Cambodia Riel'
    },
    {
        Code: 'KMF',
        CountryName: 'Comorian Franc'
    },
    {
        Code: 'KPW',
        CountryName: 'Korea (North) Won'
    },
    {
        Code: 'KRW',
        CountryName: 'Korea (South) Won'
    },
    {
        Code: 'KWD',
        CountryName: 'Kuwait Dinar'
    },
    {
        Code: 'KYD',
        CountryName: 'Cayman Islands Dollar'
    },
    {
        Code: 'KZT',
        CountryName: 'Kazakhstan Tenge'
    },
    {
        Code: 'LAK',
        CountryName: 'Laos Kip'
    },
    {
        Code: 'LBP',
        CountryName: 'Lebanon Pound'
    },
    {
        Code: 'LKR',
        CountryName: 'Sri Lanka Rupee'
    },
    {
        Code: 'LRD',
        CountryName: 'Liberia Dollar'
    },
    {
        Code: 'LSL',
        CountryName: 'Lesotho Loti'
    },
    {
        Code: 'LYD',
        CountryName: 'Libya Dinar'
    },
    {
        Code: 'MAD',
        CountryName: 'Morocco Dirham'
    },
    {
        Code: 'MDL',
        CountryName: 'Moldova Leu'
    },
    {
        Code: 'MGA',
        CountryName: 'Madagascar Ariary'
    },
    {
        Code: 'MKD',
        CountryName: 'Macedonia Denar'
    },
    {
        Code: 'MMK',
        CountryName: 'Myanmar (Burma) Kyat'
    },
    {
        Code: 'MNT',
        CountryName: 'Mongolia Tughrik'
    },
    {
        Code: 'MOP',
        CountryName: 'Macau Pataca'
    },
    {
        Code: 'MRU',
        CountryName: 'Mauritania Ouguiya'
    },
    {
        Code: 'MUR',
        CountryName: 'Mauritius Rupee'
    },
    {
        Code: 'MVR',
        CountryName: 'Maldives (Maldive Islands) Rufiyaa'
    },
    {
        Code: 'MWK',
        CountryName: 'Malawi Kwacha'
    },
    {
        Code: 'MXN',
        CountryName: 'Mexico Peso'
    },
    {
        Code: 'MYR',
        CountryName: 'Malaysia Ringgit'
    },
    {
        Code: 'MZN',
        CountryName: 'Mozambique Metical'
    },
    {
        Code: 'NAD',
        CountryName: 'Namibia Dollar'
    },
    {
        Code: 'NGN',
        CountryName: 'Nigeria Naira'
    },
    {
        Code: 'NIO',
        CountryName: 'Nicaragua Cordoba'
    },
    {
        Code: 'NOK',
        CountryName: 'Norway Krone'
    },
    {
        Code: 'NPR',
        CountryName: 'Nepal Rupee'
    },
    {
        Code: 'NZD',
        CountryName: 'New Zealand Dollar'
    },
    {
        Code: 'OMR',
        CountryName: 'Oman Rial'
    },
    {
        Code: 'PAB',
        CountryName: 'Panama Balboa'
    },
    {
        Code: 'PEN',
        CountryName: 'Peru Sol'
    },
    {
        Code: 'PGK',
        CountryName: 'Papua New Guinea Kina'
    },
    {
        Code: 'PHP',
        CountryName: 'Philippines Peso'
    },
    {
        Code: 'PKR',
        CountryName: 'Pakistan Rupee'
    },
    {
        Code: 'PLN',
        CountryName: 'Poland Zloty'
    },
    {
        Code: 'PYG',
        CountryName: 'Paraguay Guarani'
    },
    {
        Code: 'QAR',
        CountryName: 'Qatar Riyal'
    },
    {
        Code: 'RON',
        CountryName: 'Romania Leu'
    },
    {
        Code: 'RSD',
        CountryName: 'Serbia Dinar'
    },
    {
        Code: 'RUB',
        CountryName: 'Russia Ruble'
    },
    {
        Code: 'RWF',
        CountryName: 'Rwanda Franc'
    },
    {
        Code: 'SAR',
        CountryName: 'Saudi Arabia Riyal'
    },
    {
        Code: 'SBD',
        CountryName: 'Solomon Islands Dollar'
    },
    {
        Code: 'SCR',
        CountryName: 'Seychelles Rupee'
    },
    {
        Code: 'SDG',
        CountryName: 'Sudan Pound'
    },
    {
        Code: 'SEK',
        CountryName: 'Sweden Krona'
    },
    {
        Code: 'SGD',
        CountryName: 'Singapore Dollar'
    },
    {
        Code: 'SHP',
        CountryName: 'Saint Helena Pound'
    },
    {
        Code: 'SLL',
        CountryName: 'Sierra Leone Leone'
    },
    {
        Code: 'SOS',
        CountryName: 'Somalia Shilling'
    },
    {
        Code: 'SPL*',
        CountryName: 'Seborga Luigino'
    },
    {
        Code: 'SRD',
        CountryName: 'Suriname Dollar'
    },
    {
        Code: 'STN',
        CountryName: 'São Tomé and Príncipe Dobra'
    },
    {
        Code: 'SVC',
        CountryName: 'El Salvador Colon'
    },
    {
        Code: 'SYP',
        CountryName: 'Syria Pound'
    },
    {
        Code: 'SZL',
        CountryName: 'eSwatini Lilangeni'
    },
    {
        Code: 'THB',
        CountryName: 'Thailand Baht'
    },
    {
        Code: 'TJS',
        CountryName: 'Tajikistan Somoni'
    },
    {
        Code: 'TMT',
        CountryName: 'Turkmenistan Manat'
    },
    {
        Code: 'TND',
        CountryName: 'Tunisia Dinar'
    },
    {
        Code: 'TOP',
        CountryName: "Tonga Pa'anga"
    },
    {
        Code: 'TRY',
        CountryName: 'Turkey Lira'
    },
    {
        Code: 'TTD',
        CountryName: 'Trinidad and Tobago Dollar'
    },
    {
        Code: 'TVD',
        CountryName: 'Tuvalu Dollar'
    },
    {
        Code: 'TWD',
        CountryName: 'Taiwan New Dollar'
    },
    {
        Code: 'TZS',
        CountryName: 'Tanzania Shilling'
    },
    {
        Code: 'UAH',
        CountryName: 'Ukraine Hryvnia'
    },
    {
        Code: 'UGX',
        CountryName: 'Uganda Shilling'
    },
    {
        Code: 'USD',
        CountryName: 'United States Dollar'
    },
    {
        Code: 'UYU',
        CountryName: 'Uruguay Peso'
    },
    {
        Code: 'UZS',
        CountryName: 'Uzbekistan Som'
    },
    {
        Code: 'VEF',
        CountryName: 'Venezuela Bolívar'
    },
    {
        Code: 'VND',
        CountryName: 'Viet Nam Dong'
    },
    {
        Code: 'VUV',
        CountryName: 'Vanuatu Vatu'
    },
    {
        Code: 'WST',
        CountryName: 'Samoa Tala'
    },
    {
        Code: 'XAF',
        CountryName: 'Communauté Financière Africaine (BEAC) CFA Franc BEAC'
    },
    {
        Code: 'XCD',
        CountryName: 'East Caribbean Dollar'
    },
    {
        Code: 'XDR',
        CountryName: 'International Monetary Fund (IMF) Special Drawing Rights'
    },
    {
        Code: 'XOF',
        CountryName: 'Communauté Financière Africaine (BCEAO) Franc'
    },
    {
        Code: 'XPF',
        CountryName: 'Comptoirs Français du Pacifique (CFP) Franc'
    },
    {
        Code: 'YER',
        CountryName: 'Yemen Rial'
    },
    {
        Code: 'ZAR',
        CountryName: 'South Africa Rand'
    },
    {
        Code: 'ZMW',
        CountryName: 'Zambia Kwacha'
    },
    {
        Code: 'ZWD',
        CountryName: 'Zimbabwe Dollar'
    }
];