import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { Row, Input, Table } from "antd";
import { Helmet as HelmetAsync } from "react-helmet-async";
import styled from "styled-components";
import { useIntl } from "react-intl";
import {
    useAppDispatch,
    useAppSelector,
} from "../../redux/store";
import {
    programListSelector,
} from "../../redux/selectors";

import {
    getProgramList,
} from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";

const DashboardPage = ({ className }) => {
    const intl = useIntl();
    const baseClassName = clsx("dashboardActionPage", className);
    const [allInteractions, setAllInteractions] = useState([]);
    const [searchFieldValue, setSearchFieldValue] = useState('');
    const programList = useAppSelector(programListSelector);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (programList?.length) {
            setAllInteractions(programList)
        }
    }, [programList]);

    useEffect(() => {
        if (searchFieldValue) {
            const filterTable = allInteractions?.filter((o) =>
                Object.keys(o).some((k) =>
                    String(o[k]).toLowerCase().includes(searchFieldValue.toLowerCase())));
            const val = filterTable?.length ? filterTable : null;
            setAllInteractions(val);
        } else {
            setAllInteractions(programList)
        }
    }, [searchFieldValue]);

    useEffect(() => {
        dispatch(getProgramList());
    }, []);

    const onSearch = ({ target }) => {
        const { value } = target || {};
        setSearchFieldValue(value)
    }

    const getTableData = (list) => {
        if (list?.length > 0) {
            return list?.map((item, idx) => {
                const {
                    programName,
                    programmeLocation,
                    currentBudgetEndDate,
                    acceptStartDate,
                    acceptEndDate,
                    status,
                    coverImage,
                    ID
                } = item || {};

                return {
                    key: idx,
                    programName,
                    location: programmeLocation?.preferred_name,
                    currentBudgetEndDate: currentBudgetEndDate,
                    acceptStartDate: acceptStartDate?.trim() ? acceptStartDate : '',
                    acceptEndDate: acceptEndDate?.trim() ? acceptEndDate : '',
                    status,
                    coverImage,
                    ID
                };
            });
        }
        return null;
    }

    const columns = [
        {
            title: intl.formatMessage({ id: "programme" }),
            dataIndex: 'programName',
            key: 'programName',
            render: (_, { programName }) => (
                <div className="nameContainer">
                    <div className="blockWithText">
                        {programName}
                    </div>
                </div>
            ),
        },
        {
            title: intl.formatMessage({ id: "location" }),
            dataIndex: 'charityName',
            key: 'charityName',
        },
        {
            title: intl.formatMessage({ id: "budget_end_date" }),
            dataIndex: 'currentBudgetEndDate',
            key: 'currentBudgetEndDate',
        },
        {
            title: intl.formatMessage({ id: "application_window" }),
            dataIndex: 'acceptStartDate',
            key: 'acceptStartDate',
            render: (_, { acceptStartDate, acceptEndDate }) => {
                return ((acceptStartDate && acceptEndDate) && <div key={acceptStartDate} type='invited'>
                    <span>{`${acceptStartDate} - ${acceptEndDate}`}</span>
                </div>)
            }
        },
        {
            title: intl.formatMessage({ id: "status" }),
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: intl.formatMessage({ id: "action" }),
            dataIndex: 'updateDate',
            key: 'updateDate',
            render: (_, { ID }) => {
                return (<button className="reviewButton" onClick={() => navigate(`/company/programmes/review/${ID}`)}>{intl.formatMessage({ id: "review_title" })}</button>)
            }
        }
    ];

    const isValid = Array.isArray(allInteractions) && allInteractions?.length === 0

    return (
        <div className={baseClassName}>
            <HelmetAsync>
                <title>Programmes Page</title>
            </HelmetAsync>
            <StyledText as="p" variant="H1" className="title">
                {intl.formatMessage({ id: "programmes" })}
            </StyledText>
            <Row className="filters">
                <div className="sortOptions">
                    <Button
                        variant="primary"
                        className="reset"
                        onClick={() => navigate('/company/programme/create/start')}
                    >
                        {intl.formatMessage({ id: 'add_programme' })}
                    </Button>
                    <Input.Search
                        allowClear
                        placeholder={intl.formatMessage({ id: 'search_placeholder' })}
                        className="searchTableCom"
                        onChange={onSearch}
                    />
                </div>
            </Row>
            <div className='subContentContainer'>
                <Table columns={columns} loading={isValid} dataSource={getTableData(allInteractions)} />
            </div>
        </div>
    );
};

const StyledDashboardPage = styled(DashboardPage)`
  &.dashboardActionPage {
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    padding: 20px;
    flex-direction: column;
    .nameContainer{
      display: flex;
      gap: 10px;
      img {
        width: 45px;
        height: 45px;
        vertical-align: top;
        display: inline-block;
        object-fit: cover;
        }
    }
    .filters{
      margin: 20px 0px;
      display: flex;
      justify-content: space-between;
      .searchTableCom{
        width: 200px;
    }
    .sortOptions{
      display: flex;
      gap: 20px;
      justify-content: space-between;
      width: 100%;
    }
    }
    .reviewButton{
        background: transparent;
        color: blue;
        border: none;
        cursor: pointer;
    }
  }
`;

export default StyledDashboardPage;
