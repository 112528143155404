import React, { useEffect, useState } from 'react';
import { Helmet as HelmetAsync } from "react-helmet-async";
import clsx from "clsx";
import { Tabs, Layout, Divider, Timeline } from 'antd';
import { useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";
import { getProfileDataCompany, updateProgram } from '../../../redux/actions';
import styled from "styled-components";
import { getSecuredUrl, getLocalStorageItem } from "../../../utils/utilities";
import {
    useAppSelector,
    useAppDispatch
} from "../../../redux/store";
import {
    programDataSelector,
    profileSelector
} from "../../../redux/selectors";
import CustomModal from "../../../components/Modal";
import ProgramReview from './CompanyCreateReview';
import StyledText from "../../../components/StyledText";
import Button from '../../../components/Button';
import MapBoxAreaComponent from "../../../components/MapBoxAreaComponent";
import ProgStartData from '../../charityCreate//bizGiveThemeImageData';
import FullScreenWrapper from "../../../components/FullscreenWrapper";
import {
    EnvironmentFilled,
    CheckOutlined,
    StarFilled,
    CalendarFilled
} from "@ant-design/icons";
import DateFormatter from "../../../components/DateFormatter";

const getUpdateDueDateMessage = ({
    updateDueDateRelativeInterval,
}) => {
    if (updateDueDateRelativeInterval) {
        return `Every ${updateDueDateRelativeInterval} days during the project running dates`;
    } else {
        return 'Not Required';
    }
}

const ProgramReviewHomePage = ({ className }) => {
    const baseClassName = clsx("dashboardPage", className);
    const [logoS3URL, setLogoS3URL] = useState('');
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBizGiveImage, setSelectedBizGiveImage] = useState({});
    const [logoImageURL, setLogoImageURL] = useState('');
    const programData = useAppSelector(programDataSelector);
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const programReviewDetails = JSON.parse(getLocalStorageItem('createdProgramID') || '{}');
    const { programID } = programReviewDetails || {};
    const { company: { logoS3URL: logoImage, } = {} } = useAppSelector(profileSelector) || {};
    const navigate = useNavigate();

    const {
        programmeLocation,
        programRadius,
        acceptStartDate,
        acceptEndDate,
        donationSubmitPeriod,
        donationSubmitStartDate,
        donationSubmitEndDate,
        outcomeDueDateRelativeDays,
        entitiesSupported,
        requiredDocuments,
        companyName,
        programName,
        applicationAcceptPeriod,
        programmeLocationType,
        updateDueDateRelativeInterval,
        opportunityStartWithinMonths,
        opportunityLengthMonths
    } = programData || {};

    const setLogoImage = async fileKey => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogoS3URL(securedURL);
        return fileKey ? securedURL : '';
    };

    const setLogoBannerImage = async fileKey => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogoImageURL(securedURL);
        return fileKey ? securedURL : '';
    };

    const handleSaveAndExit = () => {
        navigate(`/company/programmes/review/${programID}`);
    }

    const handlePrevious = () => {
        navigate("/company/programme/create/admin")
    }

    useEffect(() => {
        if (programData?.programName) {
            dispatch(getProfileDataCompany())
            if (programData?.coverImage) {
                const selectedImage = ProgStartData.bizGiveThemeImageData.find(el => el.id === programData?.coverImage);
                if (programData?.coverImageType === '2') {
                    setLogoImage(programData?.coverImage).then(savedCover => setSelectedBizGiveImage(savedCover));
                } else {
                    setSelectedBizGiveImage(selectedImage);
                }
                ProgStartData.bizGiveThemeImageData.forEach(item => {
                    if (item.id === programData?.coverImage) item.checked = true;
                });
            }
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [programData]);


    useEffect(() => {
        if (logoImage) {
            setLogoBannerImage(logoImage)
        }
    }, [logoImage])

    const renderCoverImage = () => {
        if (selectedBizGiveImage?.path && typeof selectedBizGiveImage?.path === 'string') return <img alt="covercc" className="cameraIcon" src={selectedBizGiveImage.path} />;
        if (logoS3URL && typeof logoS3URL === 'string') return <img alt="coverdd" className="cameraIcon" src={logoS3URL} />;
        return '';
    };
    const updateDueDateMessage = getUpdateDueDateMessage({
        updateDueDateRelativeInterval,
    });

    const items = [
        {
            key: "programme",
            label: <StyledText as="p" variant="B3" className="title">
                {intl.formatMessage({ id: "programme" })}
            </StyledText>,
            children: <ProgramReview />,
        },
    ];

    const onConfirm = () => {
        dispatch(updateProgram({
            status: "OPEN",
            programID
        }));
        setIsModalOpen(false)
        navigate(`/company/programmes/review/${programID}`)
    }

    return (
        <div className={baseClassName}>
            <HelmetAsync>
                <title>Programme Review Page</title>
            </HelmetAsync>
            <div className='leftContainer'>
                <div >
                    <Layout className="bannerImageContainer">
                        {renderCoverImage()}
                    </Layout>
                    <div className='companyTitleContainer'>
                        <div className='imageContainer'>{logoImageURL && <img src={logoImageURL} alt="logoImage" />}</div>
                        <div>
                            <StyledText as="p" variant="H1c" className="title">
                                {programName}
                            </StyledText>
                            <StyledText as="p" variant="H3" className="title">
                                {companyName}
                            </StyledText>
                        </div>
                    </div>
                </div>
                <Tabs defaultActiveKey={items[0]} items={items} />
            </div>
            {<div className="rightSideContainer">
                {Array.isArray(programmeLocation?.features) && programmeLocation?.features?.length > 0 && programmeLocation?.features?.[0] &&
                    <FullScreenWrapper isFullscreen={isFullscreen}
                        setIsFullscreen={setIsFullscreen}>
                        <MapBoxAreaComponent
                            zoom={6}
                            defaultStyle="MonoCrome"
                            enableDraw
                            enableClick
                            id="submission"
                            programID={programID}
                            geometries={programmeLocation?.features}
                            savedGeometries={programmeLocation?.features}
                            isFullscreen={isFullscreen}
                            isReviewScreen
                        />
                    </FullScreenWrapper>
                }
                <div className="rightContainer">
                    {Array.isArray(programmeLocation?.features) && programmeLocation?.features?.length > 0 && <div className="locationContainer subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "location_supported" })}
                        </StyledText>
                        {['polygon', 'administrative', 'radius']?.includes(programmeLocationType) && programmeLocation?.features?.map((item) => <div key={item} className="locationIcon">
                            <EnvironmentFilled />
                            <StyledText as="p" variant="B3" key={item} className="city">
                                {item === 'anyLocation'
                                    ? intl.formatMessage({ id: 'any_location' })
                                    : item?.preferred_name}
                            </StyledText>
                        </div>)}
                        {programmeLocationType === 'radius' && <StyledText as="p" variant="B3" className="locationDistance">
                            {intl.formatMessage({ id: "project_must_be_within" })} {programRadius} KM {intl.formatMessage({ id: "of_this_location" })}
                        </StyledText>}
                    </div>}
                    <Divider />
                    <StyledText as="p" variant="H2" className="subContainer">
                        {intl.formatMessage({ id: "programme_dates" })}
                    </StyledText>
                    <Timeline
                        items={[
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "application_accepted_between" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /> <StyledText as="p" variant="B3c">
                                                {acceptStartDate?.trim() && acceptEndDate?.trim() ? (
                                                    <>
                                                        <DateFormatter dateValue={acceptStartDate?.trim()} /> - <DateFormatter dateValue={acceptEndDate?.trim()} />
                                                    </>
                                                ) : (
                                                    applicationAcceptPeriod
                                                )}
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "donation_to_be_made_between" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /><StyledText as="p" variant="B3c">
                                                {donationSubmitPeriod || <>
                                                    <DateFormatter dateValue={donationSubmitStartDate?.trim()} /> - <DateFormatter dateValue={donationSubmitEndDate?.trim()} />
                                                </>}
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "interim_update_requested" })}
                                    </StyledText>
                                        {<StyledText as="p" variant="B3c">
                                            {updateDueDateMessage}
                                        </StyledText>}
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "final_report_requested" })}
                                    </StyledText>
                                        {<StyledText as="p" variant="B3c">
                                            {outcomeDueDateRelativeDays?.trim() ? `${outcomeDueDateRelativeDays} days after the project end date` : 'Not Required'}
                                        </StyledText>}
                                    </>
                                ),
                            },
                        ]}
                    />
                    <Divider />
                    <div className="subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "project_timeline" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {`${intl.formatMessage({ id: "projects_must_start_within" })} `}<StyledText as="span" variant="B3d">{`${opportunityStartWithinMonths} `}</StyledText><StyledText as="span" variant="B3">{intl.formatMessage({ id: "months" })}</StyledText>
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {`${intl.formatMessage({ id: "projects_must_not_be_longer_than" })} `}<StyledText as="span" variant="B3d">{`${opportunityLengthMonths} `}</StyledText><StyledText as="span" variant="B3">{intl.formatMessage({ id: "months" })}</StyledText>
                        </StyledText>
                    </div>
                    <Divider />
                    <div className="subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "application_criteria" })}
                        </StyledText>
                        <StyledText as="p" variant="B3c">
                            {intl.formatMessage({ id: "entities_supported" })}
                        </StyledText>
                        {Array.isArray(entitiesSupported) && entitiesSupported?.length > 0 ? <ul>{entitiesSupported?.map((el) => <li key={el}> <StyledText as="span" variant="B3">
                            {el}
                        </StyledText></li>)}</ul> :
                            <div className="locationIcon">
                                <CheckOutlined />
                                <StyledText as="p" variant="B3">
                                    {intl.formatMessage({ id: "any" })}
                                </StyledText>
                            </div>}
                    </div>
                    <Divider />
                    {<div className="subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "documentation_required" })}
                        </StyledText>
                        <div className="locationIcon">
                            {Array.isArray(requiredDocuments) && requiredDocuments?.length > 0 ? <ul>{requiredDocuments?.map((el) => <li key={el}> <StyledText as="span" variant="B3">
                                {el}
                            </StyledText></li>)}</ul> : <><CheckOutlined /><StyledText as="p" variant="B3d">
                                {intl.formatMessage({ id: "none" })}
                            </StyledText></>}
                        </div>
                    </div>}
                </div>
            </div>}
            <div className='buttonContainer'>
                <Divider />
                <div className='buttonContentContainer'>
                    <div className='nextAndPreviousContainer'>
                        <Button
                            variant="secondary"
                            type="button"
                            onClick={() => handlePrevious()}>
                            {intl.formatMessage({ id: "previous" })}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => setIsModalOpen(true)}
                        >
                            {intl.formatMessage({ id: "publish" })}
                        </Button>
                    </div>
                    <div className="saveAndExitButton">
                        <Button
                            variant="secondary"
                            htmlType="button"
                            onClick={() => handleSaveAndExit()}>
                            {intl.formatMessage({ id: "save_n_exit" })}
                        </Button>
                    </div>
                </div>
            </div>
            <CustomModal
                title={intl.formatMessage({ id: 'publish_your_programme' })}
                open={isModalOpen}
                onOk={() => setIsModalOpen(false)}
                onCancel={() => setIsModalOpen(false)}
                closable={false}
                footer={null}>
                <>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: 'this_will_make_your_programme_visible_as_per_your_visibility_settings' })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: 'you_can_still_edit_all_details_of_the_programmes_after_publishing' })}
                    </StyledText>
                    <div>
                        <div className='modalFooterButton'>
                            <Button
                                variant="secondary"
                                htmlType="button"
                                onClick={() => setIsModalOpen(false)}>
                                {intl.formatMessage({ id: "cancel" })}
                            </Button>
                            <Button
                                variant="primary"
                                htmlType="button"
                                onClick={() => onConfirm()}
                            >
                                {intl.formatMessage({ id: "confirm" })}
                            </Button>
                        </div>
                    </div>
                </>
            </CustomModal>
        </div>
    )
}

const StyledDashboardPage = styled(ProgramReviewHomePage)`
  &.dashboardPage {
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    padding: 20px;
    gap: 20px;

    .ant-tabs-tab-active .title {
      color: ${(props) => props.theme.bizGiveThemeColor} !important;
    }

    .ant-tabs-ink-bar {
      background: ${(props) => props.theme.bizGiveThemeColor} !important;
    }

    .companyTitleContainer {
      display: flex;
      padding: 10px 0px 20px 200px;
      justify-content: space-between;
      position: relative;
      align-items: center;

      .imageContainer {
        height: 144px;
        width: 144px;
        border-radius: 50%;
        top: -80px;
        left: 25px;
        position: absolute;
        img{
            height: 144px;
    width: 144px;
    border-radius: 50%;
    border: 1px solid black;
        }
      }
    }

    .bannerImageContainer {
      width: 100%;
      height: 216px;
      overflow: hidden;
      position: relative;
      background: white;
      border-radius: 10px;
    }

    .leftContainer {
      display: flex;
      flex-direction: column;
      gap: 35px;
      overflow: scroll;
      height: 90vh;
      width: 95%;

      svg {
        fill: #04ac9c !important;
      }
    }
    .spinner {
      z-index: 2000;
      margin: auto;
      left: 50%;
      right: 50%;
      position: absolute;
      bottom: 50%;
    }

    ul {
      margin: 0;
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }

    .ant-collapse-expand-icon {
      color: #313131 !important;
    }

    .mapIFrameStyle {
      border: 0;
    }

    .locationIcon {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .locationContainer {
        margin-top: 50px;
      .locationDistance {
        font-style: italic;
      }

      svg {
        color: #f96675;
      }
    }

    .subContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .descriptionContainer {
        p, li {
          font-size: 14px;
          font-weight: 500;
          color: #242424;
          font-family: sans-serif;
        }
      }
    }

    .ant-timeline-item-head {
      background: transparent !important;
    }

    .anticon-check {
      color: #58b55e;
    }

    .rightSideContainer {
      overflow: scroll;
      height: 90vh;
      width: 25vw;
      .rightContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        background: white;
        padding: 12px 16px;
      }
    }

    .ant-collapse {
      border: 0;
      border-bottom: 1px solid #D7DDE1;
      border-radius: 0px;

      .ant-collapse-header {
        background: white !important;
      }
    }

    .ant-collapse-content-active {
      border-top: 0;
    }
  }
`;

export default StyledDashboardPage;
