import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    MinusCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";
import { Divider, Form, Input, Space, Spin, Button as AntdButton, Radio } from "antd";
import styled from "styled-components";
import { getLocalStorageItem, numericValidator } from "../../utils/utilities";
import { updateProgram, getOpportunity, getProgramById, toggleSpinner, setUpdateProgram } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import {
    programDataSelector,
    updateProgramSelector,
    toggleSpinnerSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 24 },
};

const CompanyEntities = ({ className }) => {
    const baseClassName = clsx("companyEntities", className);
    const intl = useIntl();
    const [formData, setFormData] = useState({});
    const [savedClicked, setSavedClicked] = useState(false);
    const programData = useAppSelector(programDataSelector) || {};
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const programReviewDetails = JSON.parse(getLocalStorageItem('createdProgramID') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const updateProgramData = useAppSelector(updateProgramSelector) || {};
    const { programID: updateProgramID } = updateProgramData || {};

    useEffect(() => {
        if (updateProgramID && !savedClicked) {
            dispatch(setUpdateProgram(''))
            dispatch(toggleSpinner(false));
            navigate("/company/programme/create/approval");
        }
    }, [updateProgramID])

    useEffect(() => {
        if (programData?.programName) {
            const data = {
                ...(programData?.updateDueDateRelativeInterval?.trim() && { updateDueDateRelativeInterval: programData?.updateDueDateRelativeInterval.trim() }),
                ...(programData?.outcomeDueDateRelativeDays?.trim() && { outcomeDueDateRelativeDays: programData?.outcomeDueDateRelativeDays.trim() }),
                ...((programData?.contentAgreement?.trim() || programData?.contentAgreement?.trim() === "") && { contentAgreementRadio: programData?.contentAgreement?.trim() === "" ? 'N' : 'Y' }),
                ...(programData?.contentAgreement?.trim() && { contentAgreement: programData?.contentAgreement }),
                ...(programData?.customContentQuestion && { customContentQuestion: programData?.customContentQuestion }),
                ...(((programData?.updateDueDateRelativeInterval)?.trim() === "" || (programData?.updateDueDateRelativeInterval)?.trim()) && { updateDueDateRelativeIntervalRadio: (programData?.updateDueDateRelativeInterval)?.trim() ? '' : 'not required' }),
                ...(((programData?.outcomeDueDateRelativeDays)?.trim() === "" || (programData?.outcomeDueDateRelativeDays)?.trim()) && { outcomeDueDateRelativeDaysRadio: (programData?.outcomeDueDateRelativeDays)?.trim() ? '' : 'not required' }),
            };
            form.setFieldsValue(data);
            setFormData(data);
        }
    }, [programData]);

    useEffect(() => {
        if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [opportunityId, programID]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
        }
    }, [programID]);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = (e) => {
        const formData = form.getFieldsValue();
        const data = {
            updateDueDateRelativeInterval: formData?.updateDueDateRelativeInterval?.trim() || '',
            outcomeDueDateRelativeDays: formData?.outcomeDueDateRelativeDays?.trim() || '',
            contentAgreement: formData?.contentAgreement || '',
            customContentQuestion: formData?.customContentQuestion || '',
            programID
        }
        dispatch(updateProgram(data));
        dispatch(toggleSpinner(true));
    }

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
            navigate(`/company/programmes/review/${programID}`);
        }, 300);
    }

    const handlePrevious = () => {
        navigate("/company/programme/create/impact")
    }

    const onFormChange = (_, allValues) => {
        setFormData(allValues);
    };

    const { outcomeDueDateRelativeDaysRadio, updateDueDateRelativeIntervalRadio, contentAgreementRadio } = formData

    return (
        <div className={baseClassName}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <div className='contentContainer'>
                <div>
                    <StyledText as="p" variant="H1" className='mainTitle'>
                        {intl.formatMessage({ id: "measuring_impact" })}
                    </StyledText>
                    <Form
                        {...formItemLayout}
                        labelAlign="left"
                        form={form}
                        className="entitiesFormContainer formContainer"
                        onFinish={handleProceed}
                        onFinishFailed={onFinishFailed}
                        scrollToFirstError
                        onValuesChange={onFormChange}
                    >
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "when_do_you_want_to_receive_interim_updates" })}
                        </StyledText>
                        <Form.Item
                            name="updateDueDateRelativeIntervalRadio"
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (value === undefined) {
                                            return Promise.reject(intl.formatMessage({ id: "enter" }));
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ]}
                        >
                            <Radio.Group className="radioGroup">
                                <Radio className="radio" value="">
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "recurring_to_project_dates" })}
                                    </StyledText>
                                </Radio>
                                <Radio className="radio" value="not required">
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "not_required" })}
                                    </StyledText>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        {[""].includes(updateDueDateRelativeIntervalRadio) && <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                            <StyledText as="span" variant="B3">
                                {intl.formatMessage({ id: "projects_must_start_within" })}
                            </StyledText>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                name="updateDueDateRelativeInterval"
                                className="formItem"
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: "enter" }) },
                                    { validator: numericValidator }
                                ]}
                            >
                                <Input
                                    placeholder={intl.formatMessage({ id: "number_of_days" })}
                                    type="text"
                                />
                            </Form.Item>
                            <StyledText as="span" variant="B3">
                                {intl.formatMessage({ id: "days_between_project_start_and_end_dates" })}
                            </StyledText>
                        </div>}
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "when_do_you_want_to_receive_a_final_report" })}
                        </StyledText>
                        <Form.Item
                            name="outcomeDueDateRelativeDaysRadio"
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (value === undefined) {
                                            return Promise.reject(intl.formatMessage({ id: "enter" }));
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ]}
                        >
                            <Radio.Group className="radioGroup">
                                <Radio className="radio" value="">
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "relative_to_project_dates" })}
                                    </StyledText>
                                </Radio>
                                <Radio className="radio" value="not required">
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "not_required" })}
                                    </StyledText>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        {[""].includes(outcomeDueDateRelativeDaysRadio) && <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                            <Form.Item
                                name="outcomeDueDateRelativeDays"
                                className="formItem"
                                style={{ marginBottom: 0 }}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: "enter" }) },
                                    { validator: numericValidator }
                                ]}
                            >
                                <Input
                                    placeholder={intl.formatMessage({ id: "number_of_days" })}
                                    type="text"
                                />
                            </Form.Item>
                            <StyledText as="span" variant="B3">
                                {intl.formatMessage({ id: "days_after_the_project_end_date" })}
                            </StyledText>
                        </div>}
                        <StyledText as="span" variant="B3d">
                            {intl.formatMessage({ id: "are_there_any_additional_questions_you_would_like_to_ask_in_the_final_report" })}
                        </StyledText>
                        <Form.List name="customContentQuestion">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex', marginBottom: 0 }} className='optionsContainer'>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'question']}
                                                className="formItemInput questionField"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: intl.formatMessage({ id: "enter" }),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={intl.formatMessage({ id: "question" })}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'questionCharLimit']}
                                                className="formItemInput wordLimitField"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: intl.formatMessage({ id: "enter" }),
                                                    },
                                                    { validator: numericValidator, message: intl.formatMessage({ id: 'please_enter_numeric_value' }) }
                                                ]}
                                            >
                                                <Input
                                                    placeholder={intl.formatMessage({ id: "word_limit" })}
                                                />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} style={{ color: '#04ac9c' }} className='for' />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <AntdButton htmlType='button' className='addButton' type="dashed" onClick={() => add()} block icon={<PlusOutlined fill="red" />}>
                                            <StyledText as="p" variant="B3">
                                                {intl.formatMessage({ id: "add" })}
                                            </StyledText>
                                        </AntdButton>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "do_you_want_additional_legal_terms_for_entities_to_accept_on_submission_of_reports_to_you" })}
                        </StyledText>

                        <Form.Item
                            name="contentAgreementRadio"
                            rules={[
                                { whitespace: true, required: true, message: intl.formatMessage({ id: "enter" }) }
                            ]}
                        >
                            <Radio.Group className="radioGroup">
                                <Radio className="radio" value="N">
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "no" })}
                                    </StyledText>
                                </Radio>
                                <Radio className="radio" value="Y">
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "yes" })}
                                    </StyledText>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        {["Y"].includes(contentAgreementRadio) && <Form.Item
                            name='contentAgreement'
                            className="formItemTextField"
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: "enter" }),
                                },
                            ]}
                        >
                            <ReactQuill theme="snow" placeholder={intl.formatMessage({ id: "enter" })} />
                        </Form.Item>}
                        <div className='buttonContainer'>
                            <Divider />
                            <div className='buttonContentContainer'>
                                <div className='nextAndPreviousContainer'>
                                    <Button
                                        variant="secondary"
                                        type="button"
                                        onClick={() => handlePrevious()}>
                                        {intl.formatMessage({ id: "previous" })}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        htmlType="submit"
                                    >
                                        {intl.formatMessage({ id: "next" })}
                                    </Button>
                                </div>
                                <div className="saveAndExitButton">
                                    <Button
                                        variant="secondary"
                                        htmlType="button"
                                        onClick={() => handleSaveAndExit()}>
                                        {intl.formatMessage({ id: "save_n_exit" })}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

const StyledCompanyEntities = styled(CompanyEntities)`
&.companyEntities{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    .flex{
        display: flex;
        gap: 40px;
    }
    .questionField.formItemInput{
        width: 50vw;
    }
    .wordLimitField.formItemInput{
        width: 100px;
    }
    .flexColumn{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .errorText{
        color: #ff4d4f;
    }
    .contentContainer{
        display: flex;
        padding: 20px;
        justify-content: space-between;
        height: 180vh;
    }
    .addButton{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .formContainer{
        display: flex;
        gap: 12px;
        flex-direction: column;
    }
    .checkboxGroup{
        display: flex;
        flex-direction: row;
        width: 20vw;
    }
    .optionsContainer{
        display: flex;
        align-items: center;
        gap: 30px;
        .for{
            margin-bottom: 10px;
        }
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }

    .radioGroup {
            display: flex;
            flex-direction: column;
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .formItemTextField{
        .ant-form-item-control-input{
            width: 34.5vw !important;
        }
    }
    .formItemTextFieldPart{
        .ant-form-item-control-input{
            width: 16vw !important;
        } 
    }
  .ant-form-item{
    margin-bottom: 10px;
  }
  .formItemInput{
    width: 300px;
  }
}`;

export default StyledCompanyEntities;