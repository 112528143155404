import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Helmet as HelmetAsync } from "react-helmet-async";
import {
    EnvironmentFilled,
    StarFilled,
    CalendarFilled
} from "@ant-design/icons";
import clsx from "clsx";
import { Tabs, Layout, Divider, Timeline, Form, message } from 'antd';
import { useIntl } from 'react-intl';
import Feedback from "./Feedback";
import styled from "styled-components";
import {
    useAppSelector,
    useAppDispatch
} from "../../../redux/store";
import {
    programDataSelector,
    opportunityDataSelector,
    profileSelector,
    interactionSelector,
    addInteractionSelector,
    getConversationSelector,
    deleteOpportunitySelector
} from "../../../redux/selectors";
import MapBoxComponent from "../../../components/MapBoxComponent";
import FullScreenWrapper from '../../../components/FullscreenWrapper';
import { getProfileData, addInteraction, deleteOpportunity, setAddInteraction, setDeleteOpportunity, getIndicatorAnswers } from '../../../redux/actions';
import ProgStartData from '../bizGiveThemeImageData';
import { getSecuredUrl, setLocalStorageItem } from "../../../utils/utilities";
import DateFormatter from "../../../components/DateFormatter";
import ProgramReview from './ProgramReview';
import StyledText from "../../../components/StyledText";
import Button from '../../../components/Button';
import CustomModal from "../../../components/Modal";
import LinkComponent from "../../../components/LinkComponent";

const getUpdateDueDateMessage = ({
    updateDueDateRelativeDays,
    updateDueDate,
    currentBudgetStartDate,
    currentBudgetEndDate
}) => {
    if (updateDueDateRelativeDays) {
        if (currentBudgetStartDate && currentBudgetEndDate) {
            return `Every ${updateDueDateRelativeDays} days during the project running dates`;
        } else {
            return `${updateDueDateRelativeDays} days after the project start date`;
        }
    } else if (updateDueDate) {
        return updateDueDate.join(', ');
    } else {
        return 'Not Required';
    }
}

const ProgramReviewHomePage = ({ className }) => {
    const baseClassName = clsx("dashboardPage", className);
    const [selectedBizGiveImage, setSelectedBizGiveImage] = useState({});
    const [logoS3URL, setLogoS3URL] = useState('');
    const [deleteApplicationModal, setDeleteApplicationModal] = useState(false);
    const [withdrawApplicationModal, setWithdrawApplicationModal] = useState(false);
    const addInteractionData = useAppSelector(addInteractionSelector);
    const conversations = useAppSelector(getConversationSelector);
    const [logoImageURL, setLogoImageURL] = useState('');
    const programData = useAppSelector(programDataSelector);
    const interactions = useAppSelector(interactionSelector);
    const deleteOpportunityData = useAppSelector(deleteOpportunitySelector);
    const intl = useIntl();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { charity: { logoS3URL: logoImage } = {} } = useAppSelector(profileSelector) || {};
    const opportunityData = useAppSelector(opportunityDataSelector) || {};
    const [isFullscreen, setIsFullscreen] = useState(false);
    const { status } = interactions?.[0] || {};
    const { programID, opportunityID: opportunityId } = useParams();

    const { data, users } = conversations || {}

    const {
        currentBudgetStartDate,
        currentBudgetEndDate,
        programmeLocation,
        updateDueDateRelativeDays,
        outcomeDueDateRelativeDays,
        programName,
    } = programData || {};
    const { opportunity:
        { startDate,
            endDate,
            coverImage,
            donationDeadLineDate,
            updateDueDate,
            outComeDate,
            opportunityName,
            coverImageType,
            opportunityLocation2: {
                features
            } = {},
        } = {} }
        = opportunityData || {};


    const setLogoImage = async fileKey => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogoS3URL(securedURL);
        return fileKey ? securedURL : '';
    };

    const setLogoBannerImage = async fileKey => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogoImageURL(securedURL);
        return fileKey ? securedURL : '';
    };

    useEffect(() => {
        if (addInteractionData) {
            message.success("Success - application withdrawn")
            dispatch(setAddInteraction(''));
            navigate("/impact-maker/applications/actions")
        }
    }, [addInteractionData])

    useEffect(() => {
        if (deleteOpportunityData) {
            message.success("Success - application deleted")
            dispatch(setDeleteOpportunity(''))
            navigate("/impact-maker/applications/actions")
        }
    }, [deleteOpportunityData])

    useEffect(() => {
        if (opportunityName) {
            dispatch(getProfileData());
            dispatch(getIndicatorAnswers({
                programID,
                opportunityID: opportunityId
            }))
            form.setFieldsValue({
                opportunityName: opportunityName,
                coverImageType: coverImageType,
                coverImage: coverImage,
            });

            if (coverImage) {
                const selectedImage = ProgStartData.bizGiveThemeImageData.find(el => el.id === coverImage);
                if (coverImageType === '2') {
                    setLogoImage(coverImage).then(savedCover => setSelectedBizGiveImage(savedCover));
                } else {
                    setSelectedBizGiveImage(selectedImage);
                }
                ProgStartData.bizGiveThemeImageData.forEach(item => {
                    if (item.id === coverImage) item.checked = true;
                });
            }
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [opportunityName]);

    useEffect(() => {
        if (logoImage) {
            setLogoBannerImage(logoImage)
        }
    }, [logoImage])

    const startApplication = (values) => {
        setLocalStorageItem('programReviewDetails', JSON.stringify({ programID, opportunityId }));
        navigate('/impact-maker/applications/create/start');
    };


    const renderCoverImage = () => {
        if (selectedBizGiveImage?.path && typeof selectedBizGiveImage?.path === 'string') return <img alt="covercc" className="cameraIcon" src={selectedBizGiveImage.path} />;
        if (logoS3URL && typeof logoS3URL === 'string') return <img alt="coverdd" className="cameraIcon" src={logoS3URL} />;
        return '';
    };

    const updateDueDateMessage = getUpdateDueDateMessage({
        updateDueDateRelativeDays,
        updateDueDate,
        currentBudgetStartDate,
        currentBudgetEndDate
    });

    const items = [
        {
            key: "application",
            label: <StyledText as="p" variant="B3" className="title">
                {intl.formatMessage({ id: "application" })}
            </StyledText>,
            children: <ProgramReview />,
        },
        ((["InEdit", "Approved", "Declined"].includes(status)) || data?.length > 0) && {
            key: "feedback",
            label: <StyledText as="p" variant="B3" className="title">
                {intl.formatMessage({ id: "feedback" })}
            </StyledText>,
            children: <Feedback data={data} users={users} status={status} />,
        },
    ];

    const handlePrevious = () => {
        if (["Pending", "InEdit"].includes(status)) {
            navigate(`/impact-maker/applications/actions`)
        } else if (["Applied", "PreApproved"].includes(status)) {
            navigate(`/impact-maker/applications/pending`)
        } else if (["Declined"].includes(status)) {
            navigate(`/impact-maker/applications/declined`)
        } else {
            navigate(`/impact-maker/applications/approved`)
        }
    };

    return (
        <div className={baseClassName}>
            <HelmetAsync>
                <title>Programme Review Page</title>
            </HelmetAsync>
            <div className='leftContainer'>
                <div >
                    <Layout className="bannerImageContainer">
                        {renderCoverImage()}
                    </Layout>
                    <div className='companyTitleContainer'>
                        <div className='imageContainer'>{logoImageURL && <img src={logoImageURL} alt="logoImage" />}</div>
                        <div>
                            <StyledText as="p" variant="H1c" className="title">
                                {opportunityName}
                            </StyledText>
                            <LinkComponent href={`${window.location.origin}/impact-maker/programme/${programID}`}><StyledText as="p" variant="H2" className="title">
                                {`${intl.formatMessage({ id: 'application_to' })}: `}
                                <StyledText as="span" variant="B3b">
                                    {programName}
                                </StyledText>
                            </StyledText>
                            </LinkComponent>
                        </div>
                        {status?.toLowerCase() === "pending" && <div className="flexColumn"><Button variant="primary" onClick={() => {
                            startApplication()
                        }}>{intl.formatMessage({ id: 'continue_application' })}</Button>
                            <Button variant="secondary" onClick={() => {
                                setDeleteApplicationModal(true)
                            }}>{intl.formatMessage({ id: 'delete_application' })}</Button>
                        </div>}
                        {status?.toLowerCase() === "inedit" && <div className="flexColumn"><Button variant="primary" onClick={() => {
                            startApplication()
                        }}>{intl.formatMessage({ id: 'edit_resubmit' })}</Button>
                            <Button variant="secondary" onClick={() => {
                                setDeleteApplicationModal(true)
                            }}>{intl.formatMessage({ id: 'delete_application' })}</Button>
                        </div>}
                        {status?.toLowerCase() === "applied" && <div className="flexColumn">
                            <Button variant="secondary" onClick={() => {
                                setWithdrawApplicationModal(true)
                            }}>{intl.formatMessage({ id: 'withdraw' })}</Button>
                        </div>}
                    </div>
                </div>
                <Button
                    className='goBackButton'
                    variant="secondary"
                    type="button"
                    onClick={() => handlePrevious()}>
                    <StyledText as="p" variant="B3c">
                        {`< ${intl.formatMessage({ id: "go_back" })}`}
                    </StyledText>
                </Button>
                <Tabs defaultActiveKey={items[0]} items={items} />
            </div>
            <div className="rightSideContainer">
                <FullScreenWrapper isFullscreen={isFullscreen}
                    setIsFullscreen={setIsFullscreen}>
                    <MapBoxComponent
                        zoom={4}
                        defaultStyle="MonoCrome"
                        id="submission"
                        markers={features}
                        geoJsonData={programmeLocation}
                        hideStyles
                        isFullscreen={isFullscreen}
                        hideZoomControls
                        styles="mapbox://styles/mapbox/outdoors-v12"
                    />
                </FullScreenWrapper>
                <div className="rightContainer">
                    <div className="locationContainer subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "project_location" })}
                        </StyledText>
                        {features?.map((item) => <div key={item} className="locationIcon">
                            <EnvironmentFilled />
                            <StyledText as="p" variant="B3" key={item} className="city">
                                {item?.place_name === 'anyLocation'
                                    ? intl.formatMessage({ id: 'any_location' })
                                    : (item.preferred_name || item?.place_name)}
                            </StyledText>
                        </div>)}
                    </div>
                    <Divider />
                    <StyledText as="p" variant="H2" className="subContainer">
                        {intl.formatMessage({ id: "project_timelines" })}
                    </StyledText>
                    <Timeline
                        items={[
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "project_running_dates" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /> <StyledText as="p" variant="B3c">
                                                <>
                                                    <DateFormatter dateValue={startDate?.trim()} /> - <DateFormatter dateValue={endDate?.trim()} />
                                                </>
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "donations_required_before" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /><StyledText as="p" variant="B3c">
                                                <DateFormatter dateValue={donationDeadLineDate?.trim()} />
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "interim_updates" })}
                                    </StyledText>
                                        {Array.isArray(updateDueDate) && updateDueDate?.map((el) => <div key={el} className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /><StyledText as="p" variant="B3c">
                                                {el ? <DateFormatter dateValue={el} /> : updateDueDateMessage}
                                            </StyledText></div>)}
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "final_report" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /><StyledText as="p" variant="B3c">
                                                {<DateFormatter dateValue={outComeDate?.trim()} /> || `${outcomeDueDateRelativeDays} after the project end date`}
                                            </StyledText></div>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
            <CustomModal
                title={intl.formatMessage({ id: 'delete_application' })}
                open={deleteApplicationModal}
                onOk={() => setDeleteApplicationModal(false)}
                onCancel={() => setDeleteApplicationModal(false)}
                closable
                footer={null}
            >
                <>
                    <StyledText as="p" variant="B3" className="title">
                        {intl.formatMessage({ id: 'this_will_delete_your_application_and_saved_data' })}
                    </StyledText>
                    <div className='dFlexModal'>
                        <Button
                            variant="secondary"
                            htmlType="button"
                            onClick={() => setDeleteApplicationModal(false)}>
                            {intl.formatMessage({ id: "cancel" })}
                        </Button>
                        <Button
                            variant="primary"
                            htmlType="button"
                            onClick={() => dispatch(deleteOpportunity({ opportunityID: opportunityId }))}>
                            {intl.formatMessage({ id: "delete" })}
                        </Button>
                    </div>
                </>
            </CustomModal>
            <CustomModal
                title={intl.formatMessage({ id: 'withdraw_application' })}
                open={withdrawApplicationModal}
                onOk={() => setWithdrawApplicationModal(false)}
                onCancel={() => setWithdrawApplicationModal(false)}
                closable
                footer={null}
            >
                <>
                    <StyledText as="p" variant="B3" className="title">
                        {intl.formatMessage({ id: 'this_will_withdraw_your_application_and_return_it_to_a_pending_state' })}
                    </StyledText>
                    <div className='dFlexModal'>
                        <Button
                            variant="secondary"
                            htmlType="button"
                            onClick={() => setDeleteApplicationModal(false)}>
                            {intl.formatMessage({ id: "cancel" })}
                        </Button>
                        <Button
                            variant="primary"
                            htmlType="button"
                            onClick={() => dispatch(addInteraction({
                                programID,
                                opportunityID: opportunityId,
                                action: "withdrawApplication"
                            }))}>
                            {intl.formatMessage({ id: "withdraw" })}
                        </Button>
                    </div>
                </>
            </CustomModal>
        </div>
    )
}

const StyledDashboardPage = styled(ProgramReviewHomePage)`
    &.dashboardPage {
        background: ${(props) => props.theme.backgroundCanvas};
        min-height: 100vh;
        display: flex;
        padding: 20px;
        gap: 20px;

        .flexColumn{
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .ant-tabs-tab-active .title {
            color: ${(props) => props.theme.bizGiveThemeColor} !important;
        }

        .ant-tabs-ink-bar {
            background: ${(props) => props.theme.bizGiveThemeColor} !important;
        }

        .goBackButton {
            width: max-content;
        }

        .companyTitleContainer {
            display: flex;
            padding: 10px 70px 10px 200px;
            justify-content: space-between;
            position: relative;
            align-items: center;

            .imageContainer {
                height: 144px;
                width: 144px;
                border-radius: 50%;
                top: -80px;
                left: 25px;
                position: absolute;

                img {
                    height: 144px;
                    width: 144px;
                    border-radius: 50%;
                    border: 1px solid black;
                }
            }
        }

        .bannerImageContainer {
            width: 100%;
            height: 216px;
            overflow: hidden;
            position: relative;
            background: white;
            border-radius: 10px;
        }

        .leftContainer {
            display: flex;
            flex-direction: column;
            gap: 10px;
            overflow: scroll;
            height: 90vh;
            width: 95%;

            svg {
                fill: #04ac9c !important;
            }

            .customAlert {
                svg {
                    fill: red !important;
                }
            }
        }

        .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
        }

        ul {
            margin: 0;
        }

        .ant-collapse-expand-icon {
            color: #313131 !important;
        }

        .mapIFrameStyle {
            border: 0;
        }

        .locationIcon {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .locationContainer {
            margin-top: 50px;

            .locationDistance {
                font-style: italic;
            }

            svg {
                color: #f96675;
            }
        }

        .subContainer {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .descriptionContainer {
                p, li {
                    font-size: 14px;
                    font-weight: 500;
                    color: #242424;
                    font-family: sans-serif;
                }
            }
        }

        .ant-timeline-item-head {
            background: transparent !important;
        }

        .anticon-check {
            color: #58b55e;
        }

        .rightSideContainer {
            overflow: scroll;
            height: 90vh;
            width: 25vw;

            .rightContainer {
                display: flex;
                flex-direction: column;
                gap: 20px;
                background: white;
                padding: 12px 16px;
            }
        }

        .ant-collapse {
            border: 0;
            border-bottom: 1px solid #D7DDE1;
            border-radius: 0px;

            .ant-collapse-header {
                background: white !important;
            }
        }

        .ant-collapse-content-active {
            border-top: 0;
        }
    }
`;


export default StyledDashboardPage;
