import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { jwtDecode } from 'jwt-decode'
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Radio, Spin } from "antd";
import styled from "styled-components";
import {
    getProgramById,
    setActionInteraction,
    getAllInteraction,
    createProgram,
    setCreateProgram,
    toggleSpinner
} from '../../redux/actions';
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import Select from "../../components/CustomSelect"
import { getLocalStorageItem, removeDuplicatesByKey, setLocalStorageItem } from "../../utils/utilities";
import {
    programDataSelector,
    allInteractionsSelector,
    createProgramSelector,
    toggleSpinnerSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 20 },
};

const CompanyCreateStart = ({ className }) => {
    const baseClassName = clsx("createProfile", className);
    const intl = useIntl();
    const [form] = Form.useForm();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const programData = useAppSelector(programDataSelector);
    const allInteractions = useAppSelector(allInteractionsSelector);
    const { programID } = useAppSelector(createProgramSelector) || {};
    const { programID: programId, radioBox } = formData || {};
    const IDToken = getLocalStorageItem('id_Token');
    const decodedToken = jwtDecode(IDToken);
    const entityID = decodedToken['custom:entityID']

    const {
        programName: programeName,
    } = programData || {};

    const programNames = removeDuplicatesByKey(allInteractions?.map(({ programID, programName }) => ({
        key: programID,
        label: programName,
        value: programID
    })), 'label');

    useEffect(() => {
        if (programID) {
            dispatch(toggleSpinner(false));
            setLocalStorageItem('createdProgramID', JSON.stringify({ programID }));
            dispatch(setCreateProgram(''))
            navigate("/company/programme/create/name")
        }
    }, [programID])

    useEffect(() => {
        if (programeName) {
            const {
                ID,
                isDraft,
                ...rest
            } = programData || {};
            const data = { ...rest, createdBy: entityID, updatedBy: entityID, status: 'PENDING', programName: `${programeName} Copy` }
            dispatch(createProgram(data))
        }
    }, [programeName])

    useEffect(() => {
        dispatch(setActionInteraction(null));
        if (!allInteractions?.length) {
            dispatch(getAllInteraction({
                sortBy: "programName",
                asc: "true",
                filterBy: {
                    status: [
                        "Applied",
                        "Approved",
                        "InEdit",
                        "PreApproved",
                        "Completed",
                        "Declined"
                    ]
                }
            }));
        }
    }, []);

    const handleProceed = () => {
        dispatch(toggleSpinner(true));
        if (radioBox === '1') {
            navigate("/company/programme/create/name");
            dispatch(toggleSpinner(false));
        } else if (radioBox === '2') {
            dispatch(getProgramById(programId));
        }
    }

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const onFormChange = (_, allValues) => {
        setFormData(allValues);
    };

    return (
        <div className={baseClassName}>
            <StyledText as="p" variant="H1" className='mainTitle'>
                {intl.formatMessage({ id: "start" })}
            </StyledText>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <Form
                {...formItemLayout}
                labelAlign="left"
                form={form}
                onFinish={handleProceed}
                onFinishFailed={onFinishFailed}
                onValuesChange={onFormChange}
                className="formContainer"
                scrollToFirstError
            >
                <StyledText as="p" variant="B3d">
                    {intl.formatMessage({ id: "choose_either_option" })}
                </StyledText>
                <Form.Item
                    name="radioBox"
                    rules={[
                        { required: true, message: intl.formatMessage({ id: "please_select" }) }
                    ]}
                >
                    <Radio.Group className="radioGroup">
                        <Radio className="radio" value="1">
                            <StyledText as="p" variant="B3">
                                {intl.formatMessage({ id: "create_a_new_programme" })}
                            </StyledText>
                        </Radio>
                        <Radio className="radio" value="2">
                            <StyledText as="p" variant="B3">
                                {intl.formatMessage({ id: "copy_an_existing_programme" })}
                            </StyledText>
                        </Radio>
                    </Radio.Group>
                </Form.Item>
                {radioBox === '2' && <Form.Item label="" name="programID" rules={[
                    {
                        required: true,
                        message: intl.formatMessage({ id: "please_select" }),
                    },
                ]}>
                    <Select
                        allowClear
                        placeholder={intl.formatMessage({ id: "select_programme" })}
                        type="formSelect"
                        options={[
                            ...(programNames ?? []),
                        ]}
                    />
                </Form.Item>}
                <div className='buttonContainer'>
                    <Divider />
                    <Button
                        className='proceedButton'
                        variant="primary"
                        htmlType="submit"
                    >
                        {intl.formatMessage({ id: "start" })}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

const StyledCompanyCreateStart = styled(CompanyCreateStart)`
&.createProfile{
    background: white;
    display: flex;
    padding: 20px;
    flex-direction: column;
    height: 100vh;
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .radioGroup{
        display: flex;
        flex-direction: column;
    }
    .mainContainer{
        display: flex;
        gap: 20px;
        flex-direction: column;
        .contentContainer{
        display: flex;
        gap: 4px;
        }
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -30px;
        .proceedButton{
            margin-bottom: 15px;
        }
    }
}`;

export default StyledCompanyCreateStart;