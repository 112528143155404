import React from "react";
import { useParams } from "react-router-dom";
import CommentComponent from "../../../components/CustomComment";
import {
    useAppDispatch,
    useAppSelector
} from "../../../redux/store";
import {
    getConversationSelector
} from "../../../redux/selectors";

import { getConversation } from '../../../redux/actions';

const Feedback = ({ data, users, status }) => {
    const conversations = useAppSelector(getConversationSelector);
    const { data: conversationsData, users: conversationsUser } = conversations || {}
    const dispatch = useAppDispatch();
    const { programID, opportunityID } = useParams();

    const onSuccess = () => {
        dispatch(getConversation({
            programID,
            opportunityID
        }))
    }
    
    return (<CommentComponent data={conversationsData || data} users={conversationsUser || users} status={status} onSuccess={onSuccess} showSwitch />)
}

export default Feedback;