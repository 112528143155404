import React, { useState, Fragment } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Helmet as HelmetAsync } from "react-helmet-async";
import clsx from "clsx";
import { Tabs, Layout, Divider, Timeline, Form, Checkbox } from 'antd';
import { useIntl } from 'react-intl';
import styled from "styled-components";
import {
    useAppSelector
} from "../../redux/store";
import {
    programDataSelector
} from "../../redux/selectors";
import { setLocalStorageItem } from "../../utils/utilities";
import ProgramReview from './ProgramReview';
import StyledText from "../../components/StyledText";
import Button from '../../components/Button';
import ConfigDev from "../../Config.dev";
import {
    EnvironmentFilled,
    CheckOutlined,
    StarFilled,
    CalendarFilled
} from "@ant-design/icons";
import CustomModal from "../../components/Modal";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const getUpdateDueDateMessage = ({
    updateDueDateRelativeDays,
    updateDueDate,
    currentBudgetStartDate,
    currentBudgetEndDate
}) => {
    if (updateDueDateRelativeDays) {
        if (currentBudgetStartDate && currentBudgetEndDate) {
            return `Every ${updateDueDateRelativeDays} days during the project running dates`;
        } else {
            return `${updateDueDateRelativeDays} days after the project start date`;
        }
    } else if (updateDueDate) {
        return updateDueDate.join(', ');
    } else {
        return 'Not Required';
    }
}

const ProgramReviewHomePage = ({ className }) => {
    const baseClassName = clsx("dashboardPage", className);
    const [openModal, setOpenModal] = useState(false);
    const programData = useAppSelector(programDataSelector);
    const intl = useIntl();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { programID, opportunityId } = useParams();

    const {
        currentBudgetStartDate,
        currentBudgetEndDate,
        opportunityLocation,
        programRadius,
        acceptStartDate,
        acceptEndDate,
        donationSubmitPeriod,
        donationSubmitStartDate,
        donationSubmitEndDate,
        updateDueDate,
        updateDueDateRelativeDays,
        outComeDate,
        outcomeDueDateRelativeDays,
        entitiesSupported,
        opportunityCriteriaChecklist,
        requiredDocuments,
        companyName,
        programName
    } = programData || {};

    const updateDueDateMessage = getUpdateDueDateMessage({
        updateDueDateRelativeDays,
        updateDueDate,
        currentBudgetStartDate,
        currentBudgetEndDate
    });

    const onFinish = (values) => {
        setLocalStorageItem('programReviewDetails', JSON.stringify({ programID, opportunityId }));
        navigate('/impact-maker/applications/create/start');
        setOpenModal(false)
    };

    const items = [
        {
            key: "programme",
            label: <StyledText as="p" variant="B3" className="title">
                {intl.formatMessage({ id: "programme" })}
            </StyledText>,
            children: <ProgramReview />,
        },
    ];


    const validateCheckboxGroup = (_, value) => {
        if (!value || value.length < opportunityCriteriaChecklist.length) {
            return Promise.reject(new Error(intl.formatMessage({ id: 'please_select' })));
        }
        return Promise.resolve();
    };

    return (
        <div className={baseClassName}>
            <HelmetAsync>
                <title>Programme Review Page</title>
            </HelmetAsync>
            <div className='leftContainer'>
                <div >
                    <Layout className="bannerImageContainer">
                        <img src="check"></img>
                    </Layout>
                    <div className='companyTitleContainer'>
                        <div className='imageContainer'></div>
                        <div>
                            <StyledText as="p" variant="H1c" className="title">
                                {programName}
                            </StyledText>
                            <StyledText as="p" variant="H3" className="title">
                                {companyName}
                            </StyledText>
                        </div>
                        <div><Button variant="primary" onClick={() => setOpenModal(true)}>{intl.formatMessage({ id: 'apply' })}</Button></div>
                    </div>
                </div>
                <Tabs defaultActiveKey={items[0]} items={items} />
            </div>
            <CustomModal
                title={intl.formatMessage({ id: 'eligibility' })}
                open={openModal}
                onCancel={() => setOpenModal(false)}
                closable={false}>
                <Form
                    {...formItemLayout}
                    form={form}
                    className="eligibilityFormContainer"
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                >
                    {opportunityCriteriaChecklist?.length > 0 && <><StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "before_applying_please_confirm_your_eligibility" })}
                    </StyledText>
                        <Form.Item
                            name="checkboxGroup"
                            rules={[{ validator: validateCheckboxGroup }]}
                        >
                            <Checkbox.Group>
                                {opportunityCriteriaChecklist?.map((el) => typeof el === 'string' && (
                                    <div className="checkboxWrapper" key={el}>
                                        <Checkbox key={el} value={el} />
                                        <span className="checkboxText">{el}</span>
                                    </div>
                                ))}
                            </Checkbox.Group>
                        </Form.Item>
                    </>
                    }
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "please_confirm_that_your_project_is_located_within_or_that_it_benefits_those_within_the_supported_locations" })}
                    </StyledText>
                    <Form.Item
                        name="yesCheck"
                        valuePropName="checked"
                        rules={[{ required: true, message: intl.formatMessage({ id: 'please_select' }) }]}
                    >
                        <Checkbox value='yes'>
                            {intl.formatMessage({ id: "yes" })}
                        </Checkbox>
                    </Form.Item>
                    <div className='modalFooterButtonCenter'>
                        <Button
                            variant="secondary"
                            htmlType="button"
                            onClick={() => setOpenModal(false)}>
                            {intl.formatMessage({ id: "cancel" })}
                        </Button>
                        <Button
                            variant="primary"
                            htmlType="submit"
                            className='deleteButton'>
                            {intl.formatMessage({ id: "apply" })}
                        </Button>
                    </div>
                </Form>
            </CustomModal>
            <div className="rightSideContainer">
                {opportunityLocation?.[0] && <iframe
                    height="300"
                    className="mapIFrameStyle"
                    width="500"
                    title="map"
                    src={`${ConfigDev.embedMapUrl + ConfigDev.googleMapApiKey}&q=${opportunityLocation?.[0]}`}
                />}
                <div className="rightContainer">
                    <div className="locationContainer subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "location_supported" })}
                        </StyledText>
                        {opportunityLocation?.map((item) => <div key={item} className="locationIcon">
                            <EnvironmentFilled />
                            <StyledText as="p" variant="B3" key={item} className="city">
                                {item === 'anyLocation'
                                    ? intl.formatMessage({ id: 'any_location' })
                                    : item}
                            </StyledText>
                        </div>)}
                        <StyledText as="p" variant="B3" className="locationDistance">
                            {intl.formatMessage({ id: "project_must_be_within" })} {programRadius} KM {intl.formatMessage({ id: "of_this_location" })}
                        </StyledText>
                    </div>
                    <Divider />
                    <StyledText as="p" variant="H2" className="subContainer">
                        {intl.formatMessage({ id: "programme_dates" })}
                    </StyledText>
                    <Timeline
                        items={[
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "application_accepted_between" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /> <StyledText as="p" variant="B3c">
                                                {acceptStartDate} - {acceptEndDate}
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "donation_to_be_made_between" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /><StyledText as="p" variant="B3c">
                                                {donationSubmitPeriod || `${donationSubmitStartDate} - ${donationSubmitEndDate}`}
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "interim_update_requested" })}
                                    </StyledText>
                                        {<StyledText as="p" variant="B3c">
                                            {updateDueDateMessage}
                                        </StyledText>}
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "final_report_requested" })}
                                    </StyledText>
                                        {<StyledText as="p" variant="B3c">
                                            {outComeDate?.trim() || `${outcomeDueDateRelativeDays} after the project end date`}
                                        </StyledText>}
                                    </>
                                ),
                            },
                        ]}
                    />
                    <Divider />
                    <div className="subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "application_criteria" })}
                        </StyledText>
                        <StyledText as="p" variant="B3c">
                            {intl.formatMessage({ id: "entities_supported" })}
                        </StyledText>
                        {entitiesSupported?.length > 0 ? <ul>{entitiesSupported.map((el) => <li key={el}>{el}</li>)}</ul> :
                            <div className="locationIcon">
                                <CheckOutlined />
                                <StyledText as="p" variant="B3">
                                    {intl.formatMessage({ id: "any" })}
                                </StyledText>
                            </div>}
                    </div>
                    <Divider />
                    <div className="subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "eligibility_checklist" })}
                        </StyledText>
                        {opportunityCriteriaChecklist?.length > 0 ? <ul>{opportunityCriteriaChecklist?.map((el) => typeof el === 'string' && <li key={el}>{el}</li>)}</ul> : <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "none" })}
                        </StyledText>}
                    </div>
                    <Divider />
                    <div className="subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "documentation_required" })}
                        </StyledText>
                        <div className="locationIcon">
                            {requiredDocuments?.length > 0 ? <ul>{requiredDocuments?.map((el) => <li key={el}>{el}</li>)}</ul> : <><CheckOutlined /><StyledText as="p" variant="B3d">
                                {intl.formatMessage({ id: "none" })}
                            </StyledText></>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const StyledDashboardPage = styled(ProgramReviewHomePage)`
  &.dashboardPage {
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    padding: 20px;
    gap: 20px;

    .ant-tabs-tab-active .title {
      color: ${(props) => props.theme.bizGiveThemeColor} !important;
    }

    .ant-tabs-ink-bar {
      background: ${(props) => props.theme.bizGiveThemeColor} !important;
    }

    .bannerImageContainer {
      width: 100%;
      height: 216px;
      overflow: hidden;
      position: relative;
      background: white;
      border-radius: 10px;
    }

    .leftContainer {
      display: flex;
      flex-direction: column;
      gap: 35px;
      overflow: scroll;
      height: 90vh;
      width: 95%;

      svg {
        fill: #04ac9c !important;
      }
    }

    .companyTitleContainer {
      display: flex;
      padding: 10px 0px 20px 200px;
      justify-content: space-between;
      position: relative;
      align-items: center;

      .imageContainer {
        height: 144px;
        width: 144px;
        border-radius: 50%;
        border: 1px solid black;
        background-color: grey;
        top: -80px;
        left: 25px;
        position: absolute;
      }
    }

    .spinner {
      z-index: 2000;
      margin: auto;
      left: 50%;
      right: 50%;
      position: absolute;
      bottom: 50%;
    }

    ul {
      margin: 0;
    }

    .ant-collapse-expand-icon {
      color: #313131 !important;
    }

    .mapIFrameStyle {
      border: 0;
    }

    .locationIcon {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .locationContainer {
      .locationDistance {
        font-style: italic;
      }

      svg {
        color: #f96675;
      }
    }

    .subContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .ant-timeline-item-head {
      background: transparent !important;
    }

    .anticon-check {
      color: #58b55e;
    }

    .rightSideContainer {
      overflow: scroll;
      height: 90vh;

      .rightContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        background: white;
        padding: 12px 16px;
      }
    }

    .ant-collapse {
      border: 0;
      border-bottom: 1px solid #D7DDE1;
      border-radius: 0px;

      .ant-collapse-header {
        background: white !important;
      }
    }

    .ant-collapse-content-active {
      border-top: 0;
    }
  }
`;

export default StyledDashboardPage;
