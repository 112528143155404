import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
    MinusCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";
import { Divider, Form, Input, Checkbox, Space, Spin, Button as AntdButton } from "antd";
import styled from "styled-components";
import { getLocalStorageItem } from "../../utils/utilities";
import { updateProgram, getOpportunity, getProgramById, toggleSpinner, setUpdateProgram } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import Select from "../../components/CustomSelect"
import {
    programDataSelector,
    updateProgramSelector,
    toggleSpinnerSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";

const countryList = [
    "afghanistan",
    "albania",
    "algeria",
    "andorra",
    "angola",
    "antigua_and_barbuda",
    "argentina",
    "armenia",
    "australia",
    "austria",
    "azerbaijan",
    "bahrain",
    "bangladesh",
    "barbados",
    "belarus",
    "belgium",
    "belize",
    "benin",
    "bhutan",
    "bolivia",
    "bosnia_and_herzegovina",
    "botswana",
    "brazil",
    "brunei",
    "bulgaria",
    "burkina_faso",
    "burundi",
    "cambodia",
    "cameroon",
    "canada",
    "cape_verde",
    "central_african_republic",
    "chad",
    "chile",
    "china",
    "colombia",
    "comoros",
    "congo",
    "congo_democratic_republic",
    "costa_rica",
    "croatia",
    "cuba",
    "cyprus",
    "czechia",
    "denmark",
    "djibouti",
    "dominica",
    "dominican_republic",
    "east_timor",
    "ecuador",
    "egypt",
    "el_salvador",
    "equatorial_guinea",
    "eritrea",
    "estonia",
    "eswatini",
    "ethiopia",
    "fiji",
    "finland",
    "france",
    "gabon",
    "georgia",
    "germany",
    "ghana",
    "greece",
    "grenada",
    "guatemala",
    "guinea",
    "guinea_bissau",
    "guyana",
    "haiti",
    "honduras",
    "hungary",
    "iceland",
    "india",
    "indonesia",
    "iran",
    "iraq",
    "ireland",
    "israel",
    "italy",
    "ivory_coast",
    "jamaica",
    "japan",
    "jordan",
    "kazakhstan",
    "kenya",
    "kiribati",
    "kosovo",
    "kuwait",
    "kyrgyzstan",
    "laos",
    "latvia",
    "lebanon",
    "lesotho",
    "liberia",
    "libya",
    "liechtenstein",
    "lithuania",
    "luxembourg",
    "madagascar",
    "malawi",
    "malaysia",
    "maldives",
    "mali",
    "malta",
    "marshall_islands",
    "mauritania",
    "mauritius",
    "mexico",
    "federated_states_of_micronesia",
    "moldova",
    "monaco",
    "mongolia",
    "montenegro",
    "morocco",
    "mozambique",
    "myanmar_burma",
    "namibia",
    "nauru",
    "nepal",
    "netherlands",
    "new_zealand",
    "nicaragua",
    "niger",
    "nigeria",
    "north_korea",
    "north_macedonia",
    "norway",
    "oman",
    "pakistan",
    "palau",
    "panama",
    "papua_new_guinea",
    "paraguay",
    "peru",
    "philippines",
    "poland",
    "portugal",
    "qatar",
    "romania",
    "russia",
    "rwanda",
    "st_kitts_and_nevis",
    "st_lucia",
    "st_vincent",
    "samoa",
    "san_marino",
    "sao_tome_and_principe",
    "saudi_arabia",
    "senegal",
    "serbia",
    "seychelles",
    "sierra_leone",
    "singapore",
    "slovakia",
    "slovenia",
    "solomon_islands",
    "somalia",
    "south_africa",
    "south_korea",
    "south_sudan",
    "spain",
    "sri_lanka",
    "sudan",
    "suriname",
    "sweden",
    "switzerland",
    "syria",
    "tajikistan",
    "tanzania",
    "thailand",
    "the_bahamas",
    "the_gambia",
    "togo",
    "tonga",
    "trinidad_and_tobago",
    "tunisia",
    "turkey",
    "turkmenistan",
    "tuvalu",
    "uganda",
    "ukraine",
    "united_arab_emirates",
    "united_kingdom",
    "united_states",
    "uruguay",
    "uzbekistan",
    "vanuatu",
    "vatican_city",
    "venezuela",
    "vietnam",
    "yemen",
    "zambia",
    "zimbabwe"
];

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 24 },
};

const entitiesSupportedOptions = [
    "Registered Charity",
    "Community interest company",
    "Charitable trust",
    "Academic Institutions",
    "Unincorporated organisation",
    "Charitable company",
    "Local Authority",
    "For Profit Company",
    "Parish or Community Council"
];

const internationalOptions = [
    "Registered NonProfit",
    "Academic Institution",
    "Government Linked Entity",
    "Unregistered NonProfit",
    "Charitable Company",
    "For Profit Company"
]

const CompanyEntities = ({ className }) => {
    const baseClassName = clsx("companyEntities", className);
    const intl = useIntl();
    const [formData, setFormData] = useState({});
    const [savedClicked, setSavedClicked] = useState(false);
    const programData = useAppSelector(programDataSelector) || {};
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const programReviewDetails = JSON.parse(getLocalStorageItem('createdProgramID') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const updateProgramData = useAppSelector(updateProgramSelector) || {};
    const { programID: updateProgramID } = updateProgramData || {};

    useEffect(() => {
        if (updateProgramID && !savedClicked) {
            dispatch(setUpdateProgram(''))
            dispatch(toggleSpinner(false));
            navigate("/company/programme/create/questions");
        }
    }, [updateProgramID])

    useEffect(() => {
        if (programData?.programName) {
            const entitiesSupported = Array.isArray(programData?.entitiesSupported) && programData?.entitiesSupported?.filter(element => entitiesSupportedOptions.includes(element));
            const entitiesSupportedTwo = Array.isArray(programData?.entitiesSupported) && programData?.entitiesSupported?.filter(element => internationalOptions.includes(element));
            const data = {
                ...(entitiesSupported && { entitiesSupported: entitiesSupported }),
                ...(entitiesSupportedTwo && { entitiesSupportedTwo: entitiesSupportedTwo }),
                ...(programData?.opportunityCriteriaChecklist && { opportunityCriteriaChecklist: programData?.opportunityCriteriaChecklist }),
                ...(programData?.programmeCountry && { programmeCountry: programData?.programmeCountry }),
            };
            form.setFieldsValue(data);
            setFormData(data);
        }
    }, [programData]);

    useEffect(() => {
        if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [opportunityId, programID]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
        }
    }, [programID]);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = (e) => {
        const formData = form.getFieldsValue();
        const data = {
            entitiesSupported: [...(formData?.entitiesSupported || []), ...(formData?.entitiesSupportedTwo || [])],
            opportunityCriteriaChecklist: formData?.opportunityCriteriaChecklist,
            programmeCountry: formData?.programmeCountry || [],
            programID
        }
        dispatch(updateProgram(data));
        dispatch(toggleSpinner(true));
    }

    const filterOption = (input, option) => {
        return option?.value?.toLowerCase().includes(input.toLowerCase());
    };

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
            navigate(`/company/programmes/review/${programID}`);
        }, 300);
    }

    const handlePrevious = () => {
        navigate("/company/programme/create/timelines")
    }

    const onFormChange = (_, allValues) => {
        setFormData(allValues);
    };

    return (
        <div className={baseClassName}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <div className='contentContainer'>
                <div>
                    <StyledText as="p" variant="H1" className='mainTitle'>
                        {intl.formatMessage({ id: "eligible_entities" })}
                    </StyledText>
                    <StyledText as="p" variant="B3d" className='mainTitle'>
                        {intl.formatMessage({ id: "what_type_of_entity_is_able_to_apply_to_your_programme" })}
                    </StyledText>
                    <StyledText as="p" variant="B3" className='mainTitle'>
                        {intl.formatMessage({ id: "please_select_all_that_apply_below" })}
                    </StyledText>
                    <Form
                        {...formItemLayout}
                        labelAlign="left"
                        form={form}
                        className="entitiesFormContainer formContainer"
                        onFinish={handleProceed}
                        onFinishFailed={onFinishFailed}
                        scrollToFirstError
                        onValuesChange={onFormChange}
                    >
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "uk" })}
                        </StyledText>
                        <Form.Item
                            name="entitiesSupported"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select',
                                },
                            ]}
                        >
                            <Checkbox.Group className='checkboxGroup'>
                                {entitiesSupportedOptions?.map(el => (
                                    <div className="checkboxWrapper" key={el} style={{ marginBottom: '10px', gap: '5px' }}
                                    >
                                        <Checkbox key={el} value={el} />
                                        <span className="checkboxText">{el}</span>
                                    </div>
                                ))}
                            </Checkbox.Group>
                        </Form.Item>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "international" })}
                        </StyledText>
                        <Form.Item
                            name="entitiesSupportedTwo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select',
                                },
                            ]}
                        >
                            <Checkbox.Group className='checkboxGroup'>
                                {internationalOptions?.map(el => (
                                    <div className="checkboxWrapper" key={el} style={{ marginBottom: '10px', gap: '5px' }}
                                    >
                                        <Checkbox key={el} value={el} />
                                        <span className="checkboxText">{el}</span>
                                    </div>
                                ))}
                            </Checkbox.Group>
                        </Form.Item>
                        <StyledText as="span" variant="B3d">
                            {intl.formatMessage({ id: "eligibility_checklist" })}
                            <StyledText as="span" variant="B3a">
                                {` (${intl.formatMessage({ id: "optional" })})`}
                            </StyledText>
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "applicants_must_agree_to_these_before_applying" })}
                        </StyledText>
                        <Form.List name="opportunityCriteriaChecklist">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex', marginBottom: 0 }} className='optionsContainer'>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'monetaryUnit']}
                                                className="formItemInput"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: intl.formatMessage({ id: "enter" }),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={intl.formatMessage({ id: "entity_project_must_be" })}
                                                />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} style={{ color: '#04ac9c' }} className='for' />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <AntdButton htmlType='button' className='addButton' type="dashed" onClick={() => add()} block icon={<PlusOutlined fill="red" />}>
                                            <StyledText as="p" variant="B3">
                                                {intl.formatMessage({ id: "add" })}
                                            </StyledText>
                                        </AntdButton>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <StyledText as="span" variant="B3d">
                            {intl.formatMessage({ id: "entity_location" })}
                            <StyledText as="span" variant="B3a">
                                {` (${intl.formatMessage({ id: "optional" })})`}
                            </StyledText>
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "which_country_must_entities_be_located_in_leave_blank_if_any_are_accepted" })}
                        </StyledText>
                        <Form.Item label="" name="programmeCountry">
                            <Select
                                allowClear
                                placeholder={intl.formatMessage({ id: "search_country" })}
                                type="formSelect"
                                mode="multiple"
                                showSearch
                                filterOption={filterOption}
                                options={countryList.map((value) => ({
                                    key: value,
                                    label: intl.formatMessage({ id: value }),
                                    value: intl.formatMessage({ id: value })
                                }))
                                }
                            />
                        </Form.Item>
                        <div className='buttonContainer'>
                            <Divider />
                            <div className='buttonContentContainer'>
                                <div className='nextAndPreviousContainer'>
                                    <Button
                                        variant="secondary"
                                        type="button"
                                        onClick={() => handlePrevious()}>
                                        {intl.formatMessage({ id: "previous" })}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        htmlType="submit"
                                    >
                                        {intl.formatMessage({ id: "next" })}
                                    </Button>
                                </div>
                                <div className="saveAndExitButton">
                                    <Button
                                        variant="secondary"
                                        htmlType="button"
                                        onClick={() => handleSaveAndExit()}>
                                        {intl.formatMessage({ id: "save_n_exit" })}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

const StyledCompanyEntities = styled(CompanyEntities)`
&.companyEntities{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    .flex{
        display: flex;
        gap: 40px;
    }
    .flexColumn{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .errorText{
        color: #ff4d4f;
    }
    .contentContainer{
        display: flex;
        padding: 20px;
        justify-content: space-between;
        height: 180vh;
    }
    .addButton{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .formContainer{
        display: flex;
        gap: 12px;
        flex-direction: column;
    }
    .checkboxGroup{
        display: flex;
        flex-direction: row;
        width: 20vw;
    }
    .optionsContainer{
        display: flex;
        align-items: center;
        gap: 30px;
        .for{
            margin-bottom: 10px;
        }
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }

    .radioGroup {
            display: flex;
            flex-direction: column;
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .formItemTextField{
        .ant-form-item-control-input{
            width: 34.5vw !important;
        }
    }
    .formItemTextFieldPart{
        .ant-form-item-control-input{
            width: 16vw !important;
        } 
    }
  .ant-form-item{
    margin-bottom: 10px;
  }
  .formItemInput{
    width: 300px;
  }
}`;

export default StyledCompanyEntities;