import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import { Divider, Form, Input, Checkbox, Radio, Spin, DatePicker } from "antd";
import styled from "styled-components";
import { getLocalStorageItem, numericValidator } from "../../utils/utilities";
import { updateProgram, getOpportunity, getProgramById, toggleSpinner, setUpdateProgram } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import Select from "../../components/CustomSelect"
import {
    programDataSelector,
    updateProgramSelector,
    toggleSpinnerSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 24 },
};

const periodDropdownOptions = [
    "Monthly",
    "Quarterly",
    "Biannually",
    "Annually",
    "Ongoing",
]

const CompanyTimeline = ({ className }) => {
    const baseClassName = clsx("companyTimeline", className);
    const intl = useIntl();
    const [formData, setFormData] = useState({});
    const [savedClicked, setSavedClicked] = useState(false);
    const programData = useAppSelector(programDataSelector) || {};
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const programReviewDetails = JSON.parse(getLocalStorageItem('createdProgramID') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const updateProgramData = useAppSelector(updateProgramSelector) || {};
    const { programID: updateProgramID } = updateProgramData || {};

    useEffect(() => {
        if (updateProgramID && !savedClicked) {
            dispatch(setUpdateProgram(''))
            dispatch(toggleSpinner(false));
            navigate("/company/programme/create/entities");
        }
    }, [updateProgramID])


    useEffect(() => {
        if (programData?.programName) {
            const data = {
                ...(programData?.acceptStartDate && { acceptStartDate: dayjs(programData?.acceptStartDate?.trim()) }),
                ...(programData?.acceptEndDate && { acceptEndDate: dayjs(programData?.acceptEndDate?.trim()) }),
                ...(((programData?.applicationAcceptPeriod)?.trim() === "" || (programData?.applicationAcceptPeriod)?.trim()) && { applicationAcceptPeriod: (programData?.applicationAcceptPeriod)?.trim() }),
                ...(programData?.decisionStartDate && { decisionStartDate: dayjs(programData?.decisionStartDate?.trim()) }),
                ...(programData?.decisionEndDate && { decisionEndDate: dayjs(programData?.decisionEndDate?.trim()) }),
                ...(programData?.donationSubmitStartDate && { donationSubmitStartDate: dayjs(programData?.donationSubmitStartDate?.trim()) }),
                ...(programData?.donationSubmitEndDate && { donationSubmitEndDate: dayjs(programData?.donationSubmitEndDate?.trim()) }),
                ...(((programData?.decisionPeriod)?.trim() === "" || (programData?.decisionPeriod)?.trim()) && { decisionPeriod: (programData?.decisionPeriod)?.trim() }),
                ...(programData?.donationSubmitPeriod && { donationSubmitPeriod: programData?.donationSubmitPeriod }),
                ...(programData?.opportunityStartWithinMonths && { opportunityStartWithinMonths: programData?.opportunityStartWithinMonths }),
                ...(programData?.opportunityLengthMonths && { opportunityLengthMonths: programData?.opportunityLengthMonths }),
                ...(programData?.opportunityLengthMonths && { opportunityLengthMonthsCheckbox: true }),
                ...(programData?.opportunityStartWithinMonths && { opportunityStartWithinMonthsCheckbox: true }),
                ...(((programData?.donationSubmitPeriod)?.trim() === "" || (programData?.donationSubmitPeriod)?.trim()) && { expectApplicationDecisionPeriod: (programData?.donationSubmitPeriod)?.trim() ? 'periodically' : '' }),
                ...(((programData?.decisionPeriod)?.trim() === "" || (programData?.decisionPeriod)?.trim()) && { applicationDecisionPeriod: (programData?.decisionPeriod)?.trim() === "" ? "" : "periodically" }),
            };
            form.setFieldsValue(data);
            setFormData(data);
        }
    }, [programData]);


    useEffect(() => {
        if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [opportunityId, programID]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
        }
    }, [programID]);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = (e) => {
        const formData = form.getFieldsValue();
        const data = {
            acceptStartDate: formData?.acceptStartDate ? dayjs(formData?.acceptStartDate).format('YYYYMMDD') : undefined,
            acceptEndDate: formData?.acceptEndDate ? dayjs(formData?.acceptEndDate).format('YYYYMMDD') : undefined,
            applicationAcceptPeriod: formData?.applicationAcceptPeriod || "",
            decisionStartDate: formData?.decisionStartDate ? dayjs(formData?.decisionStartDate).format('YYYYMMDD') : undefined,
            decisionEndDate: formData?.decisionEndDate ? dayjs(formData?.decisionEndDate).format('YYYYMMDD') : undefined,
            donationSubmitStartDate: formData?.donationSubmitStartDate ? dayjs(formData?.donationSubmitStartDate).format('YYYYMMDD') : undefined,
            donationSubmitEndDate: formData?.donationSubmitEndDate ? dayjs(formData?.donationSubmitEndDate).format('YYYYMMDD') : undefined,
            decisionPeriod: formData?.decisionPeriod || "",
            donationSubmitPeriod: formData?.donationSubmitPeriod || "",
            opportunityStartWithinMonths: formData?.opportunityStartWithinMonths || "",
            opportunityLengthMonths: formData?.opportunityLengthMonths || "",
            programID,

        }
        dispatch(updateProgram(data));
        dispatch(toggleSpinner(true));
    }

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
            navigate(`/company/programmes/review/${programID}`);
        }, 300);
    }

    const handlePrevious = () => {
        navigate("/company/programme/create/support")
    }

    const onFormChange = (_, allValues) => {
        setFormData(allValues);
    };

    const { opportunityLengthMonthsCheckbox, opportunityStartWithinMonthsCheckbox, expectApplicationDecisionPeriod, applicationAcceptPeriod, applicationDecisionPeriod } = formData || {};

    return (
        <div className={baseClassName}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <div className='contentContainer'>
                <div>
                    <StyledText as="p" variant="H1" className='mainTitle'>
                        {intl.formatMessage({ id: "timelines" })}
                    </StyledText>
                    <StyledText as="p" variant="B3d" className='mainTitle'>
                        {intl.formatMessage({ id: "when_are_you_accepting_applications" })}
                    </StyledText>
                    <Form
                        {...formItemLayout}
                        labelAlign="left"
                        form={form}
                        onFinish={handleProceed}
                        onFinishFailed={onFinishFailed}
                        className="formContainer"
                        scrollToFirstError
                        onValuesChange={onFormChange}
                    >
                        <Form.Item
                            name="applicationAcceptPeriod"
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (value === undefined) {
                                            return Promise.reject(intl.formatMessage({ id: "enter" }));
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ]}
                        >
                            <Radio.Group className="radioGroup">
                                <Radio className="radio" value="">
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "a_date_range" })}
                                    </StyledText>
                                </Radio>
                                <Radio className="radio" value="Ongoing">
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "ongoing" })}
                                    </StyledText>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <div className="flexColumn">
                            {[""].includes(applicationAcceptPeriod) && <div className='flex'>
                                <div className='flexColumn'>
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "start_date" })}
                                    </StyledText>
                                    <Form.Item
                                        name="acceptStartDate"
                                        className="formItem"
                                        rules={[
                                            { required: true, message: intl.formatMessage({ id: "enter" }) },
                                        ]}
                                    >
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            placeholder={intl.formatMessage({ id: "enter_date" })}
                                            disabledDate={(current) => current && current < dayjs().startOf('day')}
                                        />
                                    </Form.Item>
                                </div>

                                <div className='flexColumn'><StyledText as="p" variant="B3">
                                    {intl.formatMessage({ id: "end_date" })}
                                </StyledText>
                                    <Form.Item
                                        name="acceptEndDate"
                                        className="formItem"
                                        rules={[
                                            { required: true, message: intl.formatMessage({ id: "enter" }) },
                                        ]}
                                    >
                                        <DatePicker format="YYYY-MM-DD" placeholder={intl.formatMessage({ id: "enter_date" })} />
                                    </Form.Item>
                                </div>
                            </div>}
                            <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "when_are_application_decisions_made" })}</StyledText>
                            <Form.Item
                                name="applicationDecisionPeriod"
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (value === undefined) {
                                                return Promise.reject(intl.formatMessage({ id: "enter" }));
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                ]}
                            >
                                <Radio.Group className="radioGroup">
                                    <Radio className="radio" value="">
                                        <StyledText as="p" variant="B3">
                                            {intl.formatMessage({ id: "a_date_range" })}
                                        </StyledText>
                                    </Radio>
                                    <Radio className="radio" value="periodically">
                                        <StyledText as="p" variant="B3">
                                            {intl.formatMessage({ id: "periodically" })}
                                        </StyledText>
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                            {[""].includes(applicationDecisionPeriod) && <div className='flex'>
                                <div className='flexColumn'>
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "start_date" })}
                                    </StyledText>
                                    <Form.Item
                                        name="decisionStartDate"
                                        className="formItem"
                                        rules={[
                                            { required: true, message: intl.formatMessage({ id: "enter" }) },
                                        ]}
                                    >
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            placeholder={intl.formatMessage({ id: "enter_date" })}
                                            disabledDate={(current) => current && current < dayjs().startOf('day')}
                                        />
                                    </Form.Item>
                                </div>

                                <div className='flexColumn'><StyledText as="p" variant="B3">
                                    {intl.formatMessage({ id: "end_date" })}
                                </StyledText>
                                    <Form.Item
                                        name="decisionEndDate"
                                        className="formItem"
                                        rules={[
                                            { required: true, message: intl.formatMessage({ id: "enter" }) },
                                        ]}
                                    >
                                        <DatePicker format="YYYY-MM-DD" placeholder={intl.formatMessage({ id: "enter_date" })} />
                                    </Form.Item>
                                </div>
                            </div>}
                            {["periodically"].includes(applicationDecisionPeriod) && <div className='flex'>
                                <div className='flexColumn'>
                                    <Form.Item
                                        name="decisionPeriod"
                                        className="formItem"
                                        rules={[
                                            { required: true, message: intl.formatMessage({ id: "enter" }) },
                                        ]}
                                    >
                                        <Select
                                            allowClear
                                            placeholder={intl.formatMessage({ id: "period_title" })}
                                            type="formSelect"
                                            options={periodDropdownOptions.map((value, index) => ({
                                                key: value,
                                                label: value,
                                                value: value
                                            }))
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            </div>}
                            <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "when_do_you_expect_to_make_donations" })}</StyledText>
                            <Form.Item
                                name="expectApplicationDecisionPeriod"
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (value === undefined) {
                                                return Promise.reject(intl.formatMessage({ id: "enter" }));
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                ]}
                            >
                                <Radio.Group className="radioGroup">
                                    <Radio className="radio" value="">
                                        <StyledText as="p" variant="B3">
                                            {intl.formatMessage({ id: "a_date_range" })}
                                        </StyledText>
                                    </Radio>
                                    <Radio className="radio" value="periodically">
                                        <StyledText as="p" variant="B3">
                                            {intl.formatMessage({ id: "periodically" })}
                                        </StyledText>
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                            {[""].includes(expectApplicationDecisionPeriod) && <div className='flex'>
                                <div className='flexColumn'>
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "start_date" })}
                                    </StyledText>
                                    <Form.Item
                                        name="donationSubmitStartDate"
                                        className="formItem"
                                        rules={[
                                            { required: true, message: intl.formatMessage({ id: "enter" }) },
                                        ]}
                                    >
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            placeholder={intl.formatMessage({ id: "enter_date" })}
                                            disabledDate={(current) => current && current < dayjs().startOf('day')}
                                        />
                                    </Form.Item>
                                </div>

                                <div className='flexColumn'><StyledText as="p" variant="B3">
                                    {intl.formatMessage({ id: "end_date" })}
                                </StyledText>
                                    <Form.Item
                                        name="donationSubmitEndDate"
                                        className="formItem"
                                        rules={[
                                            { required: true, message: intl.formatMessage({ id: "enter" }) },
                                        ]}
                                    >
                                        <DatePicker format="YYYY-MM-DD" placeholder={intl.formatMessage({ id: "enter_date" })} />
                                    </Form.Item>
                                </div>
                            </div>}
                            {["periodically"].includes(expectApplicationDecisionPeriod) && <div className='flex'>
                                <div className='flexColumn'>
                                    <Form.Item
                                        name="donationSubmitPeriod"
                                        className="formItem"
                                        rules={[
                                            { required: true, message: intl.formatMessage({ id: "enter" }) },
                                        ]}
                                    >
                                        <Select
                                            allowClear
                                            placeholder={intl.formatMessage({ id: "period_title" })}
                                            type="formSelect"
                                            options={periodDropdownOptions.map((value, index) => ({
                                                key: value,
                                                label: value,
                                                value: value
                                            }))
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            </div>}
                            <StyledText as="span" variant="B3d">
                                {intl.formatMessage({ id: "do_you_want_to_restrict_project_timelines" })}
                                <StyledText as="span" variant="B3a">
                                    {` (${intl.formatMessage({ id: "optional" })})`}
                                </StyledText>
                            </StyledText>
                            <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                <Form.Item
                                    name="opportunityStartWithinMonthsCheckbox"
                                    valuePropName="checked"
                                >
                                    <Checkbox
                                        key="internal"
                                    >
                                        {intl.formatMessage({ id: "projects_must_start_within" })}
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item
                                    name="opportunityStartWithinMonths"
                                    className="formItem"
                                    rules={[
                                        { required: (opportunityStartWithinMonthsCheckbox), message: intl.formatMessage({ id: "enter" }) },
                                        { validator: numericValidator }
                                    ]}
                                >
                                    <Input
                                        placeholder={intl.formatMessage({ id: "months" })}
                                        type="text"
                                        disabled={!opportunityStartWithinMonthsCheckbox}
                                    />
                                </Form.Item>
                                <StyledText as="span" variant="B3">
                                    {intl.formatMessage({ id: "months" })}
                                </StyledText>
                            </div>
                            <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                <Form.Item
                                    name="opportunityLengthMonthsCheckbox"
                                    valuePropName="checked"
                                >
                                    <Checkbox
                                        key="internal"
                                    >
                                        {intl.formatMessage({ id: "projects_must_not_be_longer_than" })}
                                    </Checkbox>
                                </Form.Item>

                                <Form.Item
                                    name="opportunityLengthMonths"
                                    className="formItem"
                                    rules={[
                                        { required: (opportunityLengthMonthsCheckbox), message: intl.formatMessage({ id: "enter" }) },
                                        { validator: numericValidator }
                                    ]}
                                >
                                    <Input
                                        placeholder={intl.formatMessage({ id: "months" })}
                                        type="text"
                                        disabled={!opportunityLengthMonthsCheckbox}
                                    />
                                </Form.Item>
                                <StyledText as="span" variant="B3">
                                    {intl.formatMessage({ id: "months" })}
                                </StyledText>
                            </div>
                        </div>
                        <div className='buttonContainer'>
                            <Divider />
                            <div className='buttonContentContainer'>
                                <div className='nextAndPreviousContainer'>
                                    <Button
                                        variant="secondary"
                                        type="button"
                                        onClick={() => handlePrevious()}>
                                        {intl.formatMessage({ id: "previous" })}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        htmlType="submit"
                                    >
                                        {intl.formatMessage({ id: "next" })}
                                    </Button>
                                </div>
                                <div className="saveAndExitButton">
                                    <Button
                                        variant="secondary"
                                        htmlType="button"
                                        onClick={() => handleSaveAndExit()}>
                                        {intl.formatMessage({ id: "save_n_exit" })}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

const StyledCompanyTimeline = styled(CompanyTimeline)`
&.companyTimeline{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    .flex{
        display: flex;
        gap: 40px;
    }
    .flexColumn{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .errorText{
        color: #ff4d4f;
    }
    .contentContainer{
        display: flex;
        padding: 20px;
        justify-content: space-between;
        height: 180vh;
    }
    .addButton{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .formContainer{
        display: flex;
        gap: 12px;
        flex-direction: column;
    }
    .checkboxGroup{
        display: flex;
        flex-direction: column;
    }
    .optionsContainer{
        display: flex;
        align-items: center;
        gap: 30px;
        .for{
            margin-bottom: 10px;
        }
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }

    .radioGroup {
            display: flex;
            flex-direction: column;
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .formItemTextField{
        .ant-form-item-control-input{
            width: 34.5vw !important;
        }
    }
    .formItemTextFieldPart{
        .ant-form-item-control-input{
            width: 16vw !important;
        } 
    }
  .ant-form-item{
    margin-bottom: 10px;
  }
  .formItemInput{
    width: 300px;
  }
}`;

export default StyledCompanyTimeline;