import React from 'react';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import clsx from 'clsx';

const FullscreenWrapper = ({ children, className, isFullscreen, setIsFullscreen }) => {
    const toggleFullscreen = () => {
        setIsFullscreen((prev) => !prev);
    };

    return (
        <div className={clsx(className, { 'fullscreen': isFullscreen })}>
            <button className="fullscreenToggle" onClick={toggleFullscreen}>
                {isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
            </button>
            <div className={clsx('content', { 'overlay': false })}>
                {children}
            </div>
        </div>
    );
};

export default styled(FullscreenWrapper)`
    position: relative;
    .fullscreenToggle {
        cursor: pointer;
        position: absolute;
        top: ${props => props.isFullscreen ? '90px' : '10px'};
        right: ${props => props.isFullscreen ? '25px' : '10px'};
        background: white;
        border: none;
        font-size: 24px;
        z-index: 999;
    }

    .content {
        transition: height 0.3s ease;
        height: ${props => props.isFullscreen ? '100vh' : '300px'};
        width: ${props => props.isFullscreen ? '100vw' : '100%'};
        #map{
        scale: ${props => props.isFullscreen ? '0.9' : '1.0'};
        left: ${props => props.isFullscreen ? '10%!important' : '0'};
        height: ${props => props.isFullscreen ? '80vh!important' : '400px'};
        width: ${props => props.isFullscreen ? '80vw!important' : '100%'};
    }
        ${props => props.isFullscreen && `
         position: fixed;
        top:50px;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(8px);
        z-index: 900;
        `}
    }

    &.fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        width: 80%;
        height: 80%;
        z-index: 1000;
        background-color: white;
    }


`;
