import React, { useState, useEffect } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Input, Checkbox, Avatar, Spin, Steps, Button as AntButton } from "antd";
import styled from "styled-components";
import CustomModal from "../../components/Modal";
import { editOpportunity, getOpportunity, getProgramById, setEditOpportunitiesData, toggleSpinner } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import { getLocalStorageItem } from "../../utils/utilities";
import goal1 from "../../assets/goal1.png";
import goal2 from "../../assets/goal2.png";
import goal3 from "../../assets/goal3.png";
import goal4 from "../../assets/goal4.png";
import goal5 from "../../assets/goal5.png";
import goal6 from "../../assets/goal6.png";
import goal7 from "../../assets/goal7.png";
import goal8 from "../../assets/goal8.png";
import goal9 from "../../assets/goal9.png";
import goal10 from "../../assets/goal10.png";
import goal11 from "../../assets/goal11.png";
import goal12 from "../../assets/goal12.png";
import goal13 from "../../assets/goal13.png";
import goal14 from "../../assets/goal14.png";
import goal15 from "../../assets/goal15.png";
import goal16 from "../../assets/goal16.png";
import goal17 from "../../assets/goal17.png";
import { opportunityDataSelector, programDataSelector, editOpportunityData, toggleSpinnerSelector } from "../../redux/selectors";
import { useAppDispatch, useAppSelector } from "../../redux/store";

const outcomeUNSDGImages = [
    { key: 1, image: goal1 },
    { key: 2, image: goal2 },
    { key: 3, image: goal3 },
    { key: 4, image: goal4 },
    { key: 5, image: goal5 },
    { key: 6, image: goal6 },
    { key: 7, image: goal7 },
    { key: 8, image: goal8 },
    { key: 9, image: goal9 },
    { key: 10, image: goal10 },
    { key: 11, image: goal11 },
    { key: 12, image: goal12 },
    { key: 13, image: goal13 },
    { key: 14, image: goal14 },
    { key: 15, image: goal15 },
    { key: 16, image: goal16 },
    { key: 17, image: goal17 },
];

const { TextArea } = Input;

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const CharityImpactProgram = ({ className }) => {
    const baseClassName = clsx("charityImpactProgram", className);
    const intl = useIntl();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [savedClicked, setSavedClicked] = useState(false);
    const [selectedItems, setSelectedItems] = useState({});
    const opportunityData = useAppSelector(opportunityDataSelector) || {};
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const programData = useAppSelector(programDataSelector) || {};
    const { impactStrategy } = programData || {};
    const programReviewDetails = JSON.parse(getLocalStorageItem('programReviewDetails') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const { opportunity } = opportunityData || {};
    const editOpp = useAppSelector(editOpportunityData) || {};
    const maxWordsOutcomeApproach = 80;

    const { opportunityID } = editOpp || {};

    useEffect(() => {
        if (opportunityID && !savedClicked) {
            dispatch(setEditOpportunitiesData(''))
            dispatch(toggleSpinner(false));
            navigate("/impact-maker/applications/create/beneficiaries");
        }
    }, [opportunityID]);

    useEffect(() => {
        if (opportunity?.impactApproach) {
            const { objectives } = opportunity.impactApproach;
            const checkboxGroup = objectives?.map(el => el?.name);

            const outcomeApproachValues = {};
            if (Array.isArray(checkboxGroup)) {
                objectives?.forEach(el => {
                    el.objectivesOutcome?.forEach(out => {
                        if (out.outcomeApproach) {
                            outcomeApproachValues[`outcomeApproach-${el.name}-${out.name}`] = out.outcomeApproach;
                        }
                    });
                });
            }

            form.setFieldsValue({
                checkboxGroup,
                ...outcomeApproachValues,
            });

            setFormData({
                checkboxGroup,
                ...outcomeApproachValues,
            });

            const initialSelectedItems = {};
            if (Array.isArray(checkboxGroup)) {
                checkboxGroup.forEach(name => {
                    objectives
                        .find(obj => obj.name === name)
                        ?.objectivesOutcome.forEach(out => {
                            if (out.outcomeApproach) {
                                initialSelectedItems[`${name}-${out.name}`] = true;
                            }
                        });
                });
            }
            setSelectedItems(initialSelectedItems);
        }
    }, [opportunity, form]);

    useEffect(() => {
        if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [opportunityId, programID]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
        }
    }, [programID]);

    const countWords = (text) => {
        return text?.trim()?.split(/\s+/)?.filter(word => word.length > 0).length;
    };

    const handleTextChange = (name, setter, maxWords) => (e) => {
        const text = e.target.value;
        if (countWords(text) <= maxWords) {
            setter(text);
            form.setFieldsValue({ [name]: text });
        }
    };

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const onFormChange = (_, allValues) => {
        setFormData(allValues);
    };

    const validateForm = (values, selectedItems) => {
        const errors = {};
        let isValid = true;

        if (!values.checkboxGroup || values.checkboxGroup.length === 0) {
            isValid = false;
            errors.checkboxGroup = intl.formatMessage({ id: "please_select_at_least_one_objective" });
        }
        if (selectedItems) {
            Object.keys(selectedItems).forEach(key => {
                if (selectedItems[key]) {
                    const outcomeApproachKey = `outcomeApproach-${key}`;
                    if (!values[outcomeApproachKey] || values[outcomeApproachKey].trim() === "") {
                        isValid = false;
                        errors[outcomeApproachKey] = `${key.split('-')[1]} must be filled`;
                    }
                }
            });
        }

        return { isValid, errors };
    };

    const handleProceed = () => {
        form.validateFields().then(values => {
            const { isValid, errors } = validateForm(values, selectedItems);
            if (!isValid) {
                form.setFields(Object.keys(errors)?.map(key => ({
                    name: key,
                    errors: [errors[key]],
                })));
                return;
            }

            const data = {
                programID,
                opportunityID: opportunityId,
                application: "Y",
                impactApproach: {
                    objectives: impactStrategy?.objectives?.filter((el) =>
                        values.checkboxGroup.includes(el.name)
                    )?.map((el) => {
                        const outcomes = el.objectivesOutcome
                            ? el.objectivesOutcome?.filter((out) => selectedItems[`${el.name}-${out.name}`])
                            : [];
                        return {
                            name: el.name,
                            objectiveID: el.objectiveID,
                            themeName: el.themeName,
                            themeID: el.themeID,
                            objectiveReportType: "application",
                            objectiveUNSDG: el.objectiveUNSDG || [],
                            indicatorDetails: el.indicatorDetails || [],
                            objectivesOutcome: outcomes?.map((out) => ({
                                name: out.name,
                                outcomeID: out.outcomeID,
                                objectiveId: el.objectiveID,
                                outcomeReportType: "application",
                                outcomeDescription: out.outcomeDescription,
                                outcomeUNSDG: out.outcomeUNSDG || [],
                                outcomeApproach: values[`outcomeApproach-${el.name}-${out.name}`] || '',
                            })),
                        };
                    }),
                },
            };
            dispatch(toggleSpinner(true));
            dispatch(editOpportunity(data));
        }).catch(errorInfo => {
            console.log('Validate Failed:', errorInfo);
        });
    };

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
            navigate(`/impact-maker/programme/${programID}/${opportunityId}`);
        }, 300);
    };

    const handlePrevious = () => {
        navigate("/impact-maker/applications/create/request");
    };

    const handleCheckboxChange = (elName, outName) => {
        if (Object.keys(selectedItems)?.includes(`${elName}-${outName}`)) {
            setIsModalOpen(`${elName}-${outName}`)
        } else {
            setSelectedItems((prev) => ({
                ...prev,
                [`${elName}-${outName}`]: !prev[`${elName}-${outName}`],
            }));
        }
    };

    const onDelete = () => {
        setSelectedItems((prev) => {
            const updatedItems = { ...prev };
            delete updatedItems[isModalOpen];
            return updatedItems;
        });
        setIsModalOpen(false);
    };
    return (
        <div className={baseClassName}>
            <Steps
                current={2}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "about_your_project" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "your_request" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "planned_impact_title" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_4" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "award_criteria" })}
                        </StyledText>,
                    },
                ]}
            />
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <StyledText as="p" variant="H1" className='mainTitle'>
                {intl.formatMessage({ id: "planned_impact_title" })}
            </StyledText>
            <div>
                <Form
                    {...formItemLayout}
                    labelAlign="left"
                    form={form}
                    onFinish={handleProceed}
                    onFinishFailed={onFinishFailed}
                    className="formContainer"
                    onValuesChange={onFormChange}
                    scrollToFirstError
                >
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "which_of_the_company_impact_objectives_will_your_project_support" })}
                    </StyledText>
                    <Form.Item name="checkboxGroup">
                        <Checkbox.Group
                            className='checkboxGroup'
                            defaultValue={formData.checkboxGroup}
                        >
                            {impactStrategy?.objectives?.map((el) => (
                                <Checkbox key={el.name} value={el.name}>
                                    <StyledText as="p" variant="B3e">
                                        {el.name}
                                    </StyledText>
                                </Checkbox>
                            ))}
                        </Checkbox.Group>
                    </Form.Item>

                    {impactStrategy?.objectives?.filter((el) => formData.checkboxGroup?.includes(el.name))?.map((el, index) => (
                        <React.Fragment key={el.name}>
                            {index === 0 && <StyledText as="p" variant="B3d">
                                {intl.formatMessage({ id: "what_outcomes_do_you_hope_your_project_will_achieve" })}
                            </StyledText>}
                            <div className='outcomeParentContainer'>
                                <StyledText as="p" variant="B3e" className='outcomeParentName'>
                                    {el.name}
                                </StyledText>
                                {el?.objectivesOutcome?.map((out, idx) => (
                                    <div key={idx} className='outcomeContainer'>
                                        <Form.Item name={`${el.name}-${out.name}`} className="formItem" rules={[{
                                            required: !el?.objectivesOutcome?.some((outcome) => selectedItems[`${el.name}-${outcome.name}`]),
                                            message: intl.formatMessage({ id: "at_least_one_outcome_must_be_selected" }),
                                        }]}>
                                            <Checkbox
                                                checked={selectedItems[`${el.name}-${out.name}`]}
                                                onChange={() => handleCheckboxChange(el.name, out.name)}
                                            >
                                                <StyledText as="p" variant="B3d">
                                                    {out.name}
                                                </StyledText>
                                            </Checkbox>
                                        </Form.Item>
                                        <div className='outcomeUNSDGImagesContainer'>
                                            {outcomeUNSDGImages
                                                ?.filter((im) => out?.outcomeUNSDG?.includes(im.key))
                                                ?.map((im) => (
                                                    <Avatar shape="square" key={im?.key} src={im?.image} />
                                                ))}
                                        </div>
                                        {out?.outcomeDescription && <StyledText as="p" variant="B3" className='mainSubTitle'>
                                            {out?.outcomeDescription}
                                        </StyledText>}
                                        {selectedItems[`${el.name}-${out.name}`] && (
                                            <>
                                                <StyledText as="p" variant="B3d">
                                                    {intl.formatMessage({ id: "how_will_you_achieve_this_outcome" })}
                                                </StyledText>
                                                <>
                                                    <Form.Item
                                                        name={`outcomeApproach-${el.name}-${out.name}`}
                                                        className="formItem textArea"
                                                    >
                                                        <TextArea
                                                            rows={2}
                                                            placeholder={intl.formatMessage({ id: "enter_description" })}
                                                            value={formData?.[`outcomeApproach-${el.name}-${out.name}`]}
                                                            onChange={handleTextChange(`outcomeApproach-${el.name}-${out.name}`, (text) => {
                                                                const updatedFormData = { ...formData, [`outcomeApproach-${el.name}-${out.name}`]: text };
                                                                setFormData(updatedFormData);
                                                            }, maxWordsOutcomeApproach)}
                                                        />
                                                        <div className={`wordCount ${countWords(formData[`outcomeApproach-${el.name}-${out.name}`] || '') >= maxWordsOutcomeApproach ? 'error' : ''}`}>
                                                            {countWords(formData[`outcomeApproach-${el.name}-${out.name}`] || '')}/{maxWordsOutcomeApproach} words
                                                        </div>
                                                    </Form.Item>

                                                </>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    ))}
                    <div className='buttonContainer'>
                        <Divider />
                        <div className='buttonContentContainer'>
                            <div className='nextAndPreviousContainer'>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    onClick={handlePrevious}>
                                    {intl.formatMessage({ id: "previous" })}
                                </Button>
                                <Button
                                    variant="primary"
                                    htmlType="submit"
                                >
                                    {intl.formatMessage({ id: "next" })}
                                </Button>
                            </div>
                            <div className="saveAndExitButton">
                                <Button
                                    variant="secondary"
                                    htmlType="button"
                                    onClick={handleSaveAndExit}>
                                    {intl.formatMessage({ id: "save_n_exit" })}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
                <CustomModal
                    title={intl.formatMessage({ id: 'delete_confirmation' })}
                    open={isModalOpen}
                    onOk={() => setIsModalOpen(false)}
                    onCancel={() => setIsModalOpen(false)}
                    closable={false}
                    footer={null}>
                    <>
                        <StyledText as="p" variant="B3" className="title">
                            {intl.formatMessage({ id: 'unchecking_this_box_will_delete_any_underlying_data_you_have_entered_for_this_objective_or_outcome' })}
                        </StyledText>
                        <div>
                            <div className='modalFooterButton'>
                                <Button
                                    variant="secondary"
                                    htmlType="button"
                                    onClick={() => setIsModalOpen(false)}>
                                    {intl.formatMessage({ id: "cancel" })}
                                </Button>
                                <AntButton
                                    variant="primary"
                                    htmlType="button"
                                    className='deleteButton'
                                    onClick={() => onDelete()}>
                                    {intl.formatMessage({ id: "delete" })}
                                </AntButton>
                            </div>
                        </div>
                    </>
                </CustomModal>
            </div>
        </div>
    );
};

export default styled(CharityImpactProgram)`
&.charityImpactProgram{
    background: white;
    display: flex;
    padding: 20px;
    flex-direction: column;
    height: 150vh;
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .formItem.textArea .ant-form-item-explain-error{
        margin-top: -20px !important;
    }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .mainSubTitle{
        margin-bottom: 5px;
    }
    .formContainer{
        display: flex;
        gap: 20px;
        flex-direction: column;
    }
    .outcomeParentContainer{
        display: flex;
        flex-direction: column;
        .outcomeParentName{
            margin-bottom: 10px;
        }
        .outcomeContainer{
        display: flex;
        flex-direction: column;
        gap: 10px;

        .ant-form-item{
            margin-bottom: 0px !important;
        }
    }
    }

    .outcomeUNSDGImagesContainer{
        display: flex;
        gap: 20px;
        .ant-avatar{
            border-radius: 0 !important;
            height: 50px !important;
            width: 50px !important;
        }
    }

    .wordCount{
        text-align: right;
        &.error{
            color: red;
        }
    }

    .checkboxGroup{
        display: flex;
        flex-direction: column;
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
}
`;

