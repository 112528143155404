import React from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import StyledText from "./StyledText";
import styled from "styled-components";
import { Layout, Menu, Avatar } from "antd";
import dashBoard from "../assets/dashboard.svg";
import engage from "../assets/engage.svg";
import impact from "../assets/impact.svg";
import organisation from "../assets/organisation.svg";
import help from "../assets/help.svg";
import profile from "../assets/profile.svg";
import arrowLeft from "../assets/arrow-left.svg";
import application from "../assets/applications.svg";
import { CHARITY } from "../constants";

const { Sider } = Layout;

const SideNav = ({ className, setMenuCollapsed, menuCollapsed, userType }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const baseClassName = clsx("customSideNav", className);

  const location = window?.location?.pathname?.includes("impact-maker");

  const charityTypeUser = userType === CHARITY;

  const route = charityTypeUser || location ? `impact-maker` : `company`

  const CharityMenuItem = [
    {
      key: `${route}/home`,
      icon: <Avatar src={dashBoard} shape="square" />,
      label: (
        <StyledText as="p" variant="B3" className="status">
          {intl.formatMessage({ id: "dashboard" })}
        </StyledText>
      ),
      to: `${route}/home`,
      title: null
    },
    {
      key: "discover",
      icon: <Avatar src={engage} shape="square" />,
      label: (
        <StyledText as="p" variant="B3" className="status">
          {intl.formatMessage({ id: "Discover" })}
        </StyledText>
      ),
      to: `${route}/discover`
    },
    {
      key: "applications",
      icon: <Avatar src={application} shape="square" />,
      label: (
        <StyledText as="p" variant="B3" className="status">
          {intl.formatMessage({ id: "applications" })}
        </StyledText>
      ),
      title: null,
      children: [
        {
          key: `${route}/applications/actions`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "action_required" })}
            </StyledText>
          ),
          to: `${route}/applications/actions`,
        },
        {
          key: `${route}/applications/pending`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "pending_response" })}
            </StyledText>
          ),
          to: `${route}/applications/pending`,
        },
        {
          key: "approved",
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "approved" })}
            </StyledText>
          ),
          to: `${route}/applications/approved`,
        },
        {
          key: `${route}/applications/declined`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "declined" })}
            </StyledText>
          ),
          to: `${route}/applications/declined`,
        }
      ],
    },
    {
      key: "impact",
      icon: <Avatar src={impact} shape="square" />,
      label: (
        <StyledText as="p" variant="B3" className="status">
          {intl.formatMessage({ id: "impact" })}
        </StyledText>
      ),
      title: null,
      children: [
        {
          key: `${route}/content`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "content" })}
            </StyledText>
          ),
          to: `${route}/content`,
        },
        {
          key: `${route}/donations`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "donations" })}
            </StyledText>
          ),
          to: `${route}/donations`,
        }
      ],
    },
    {
      key: `${route}/my-profile`,
      icon: <Avatar src={profile} shape="square" />,
      label: (
        <StyledText as="p" variant="B3" className="status">
          {intl.formatMessage({ id: "my_profile" })}
        </StyledText>
      ),
      to: `${route}/my-profile`,
      title: null,
    },
    {
      key: "organization",
      icon: <Avatar src={organisation} shape="square" />,
      label: (
        <StyledText as="p" variant="B3" className="status">
          {intl.formatMessage({ id: "organization" })}
        </StyledText>
      ),
      title: null,
      children: [
        {
          key: `${route}/users`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "users" })}
            </StyledText>
          ),
          to: `${route}/users`,
        },
        {
          key: `${route}/profile`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "profile" })}
            </StyledText>
          ),
          to: `${route}/profile`,
        }
      ],
    },
    {
      key: "/support",
      icon: <Avatar src={help} shape="square" />,
      label: (
        <StyledText as="p" variant="B3" className="status">
          {intl.formatMessage({ id: "help" })}
        </StyledText>
      ),
      to: `/support`,
      title: null,
    },
  ];

  const CompanyMenuItems = [
    {
      key: `${route}/home`,
      icon: <Avatar src={dashBoard} shape="square" />,
      label: (
        <StyledText as="p" variant="B3" className="status">
          {intl.formatMessage({ id: "dashboard" })}
        </StyledText>
      ),
      to: `${route}/home`,
      title: null
    },
    {
      key: "engage",
      icon: <Avatar src={engage} shape="square" />,
      label: (
        <StyledText as="p" variant="B3" className="status">
          {intl.formatMessage({ id: "engage" })}
        </StyledText>
      ),
      title: null,
      children: [
        {
          key: `${route}/assets`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "assets" })}
            </StyledText>
          ),
          to: `${route}/assets`,
        },
        {
          key: `${route}/submissions`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "submissions" })}
            </StyledText>
          ),
          to: `${route}/submissions`,
        },
      ],
    },
    {
      key: "applications",
      icon: <Avatar src={application} shape="square" />,
      label: (
        <StyledText as="p" variant="B3" className="status">
          {intl.formatMessage({ id: "applications" })}
        </StyledText>
      ),
      title: null,
      children: [
        {
          key: `${route}/applications/actions`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "action_required" })}
            </StyledText>
          ),
          to: `${route}/applications/actions`,
        },
        {
          key: `${route}/applications/pending`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "pending_response" })}
            </StyledText>
          ),
          to: `${route}/applications/pending`,
        },
        {
          key: `${route}/applications/pre-approved`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "pre_approved" })}
            </StyledText>
          ),
          to: `${route}/applications/pre-approved`,
        },
        {
          key: "approved",
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "approved" })}
            </StyledText>
          ),
          to: `${route}/applications/approved`,
        },
        {
          key: `${route}/applications/declined`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "declined" })}
            </StyledText>
          ),
          to: `${route}/applications/declined`,
        }
      ],
    },
    {
      key: "impact",
      icon: <Avatar src={impact} shape="square" />,
      label: (
        <StyledText as="p" variant="B3" className="status">
          {intl.formatMessage({ id: "impact" })}
        </StyledText>
      ),
      title: null,
      children: [
        {
          key: `${route}/dashboard`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "dashboard" })}
            </StyledText>
          ),
          to: `${route}/dashboard`,
        },
        {
          key: `${route}/content`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "content" })}
            </StyledText>
          ),
          to: `${route}/content`,
        },
        {
          key: `${route}/donations`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "donations" })}
            </StyledText>
          ),
          to: `${route}/donations`,
        }
      ],
    },
    {
      key: `${route}/my-profile`,
      icon: <Avatar src={profile} shape="square" />,
      label: (
        <StyledText as="p" variant="B3" className="status">
          {intl.formatMessage({ id: "my_profile" })}
        </StyledText>
      ),
      to: `${route}/my-profile`,
      title: null,
    },
    {
      key: "organization",
      icon: <Avatar src={organisation} shape="square" />,
      label: (
        <StyledText as="p" variant="B3" className="status">
          {intl.formatMessage({ id: "organization" })}
        </StyledText>
      ),
      title: null,
      children: [
        {
          key: `${route}/users`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "users" })}
            </StyledText>
          ),
          to: `${route}/users`,
        },
        {
          key: `${route}/profile`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "profile" })}
            </StyledText>
          ),
          to: `${route}/profile`,
        },
        {
          key: `${route}/programmes`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "programmes" })}
            </StyledText>
          ),
          to: `${route}/programmes`,
        },
        {
          key: `${route}/impact-strategy`,
          label: (
            <StyledText as="p" variant="B3" className="status">
              {intl.formatMessage({ id: "impact_strategy" })}
            </StyledText>
          ),
          to: `${route}/impact-strategy`,
        },
      ],
    },
    {
      key: "/support",
      icon: <Avatar src={help} shape="square" />,
      label: (
        <StyledText as="p" variant="B3" className="status">
          {intl.formatMessage({ id: "help" })}
        </StyledText>
      ),
      to: `/support`,
      title: null,
    },
  ];

  const handleNavigation = (e) => {
    sessionStorage.removeItem('savedProgramId');
    sessionStorage.removeItem('savedRoundId');
    navigate(`/${e.key}`)
  }

  return (
    <div className={baseClassName} menuCollapsed={menuCollapsed}>
      <Sider trigger={null} collapsible collapsed={menuCollapsed}>
        <Menu mode="inline" defaultSelectedKeys={["1"]} items={charityTypeUser ? CharityMenuItem : CompanyMenuItems} onClick={handleNavigation} />
      </Sider>
      <button
        onClick={() => setMenuCollapsed(!menuCollapsed)}
        className={`toggleButton ${menuCollapsed ? "collapsed" : ""}`}
      >
        <Avatar src={arrowLeft} shape="square" />
      </button>
    </div>
  );
};

const StyledSideNav = styled(SideNav)`
  &.customSideNav {
    position: fixed;
    margin-top: 50px;
    z-index: 999;
    background-color: #fff;
    height: 100vh;

    .ant-menu-item.ant-menu-item-selected {
      background-color: #e8e7fa;
      p {
        color: #6f6af8;
      }
    }
    .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open {
      background-color: #f3f2fd;
      .ant-menu-submenu-title {
        p {
          color: #6f6af8;
        }
      }
      .ant-menu-item.ant-menu-item-selected {
        background-color: transparent;
        p {
          font-weight: 800;
        }
      }
    }
    .toggleButton {
      background-color: transparent;
      border: none;
      width: 100%;
      text-align: end;
      padding: 0 25px;
      &.collapsed {
        padding-left: 0;
        padding-right: 15px;
        img {
          transform: rotate(180deg);
        }
      }
    }
  }
`;

export default StyledSideNav;
